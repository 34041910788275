import React from 'react';

interface Props {
  /** The wanted asset */
  asset: string;
}

const SidePic = ({ asset }: Props) => (
  <div className="image-container">
    <img src={asset} alt="" className="image-fully-height" />
  </div>
);

export default SidePic;
