import React from 'react';
import {
  Badge, Row, Col, Card,
} from 'react-bootstrap';

import { Block as IBlock } from '@customer/interfaces/block.interface';
import EditIcon from '@root/assets/icon_modif.svg';
import DeleteIcon from '@root/assets/trash.svg';

interface Props {
  block: IBlock;
  onUpdate: (id: string) => void;
  onDelete: (id: string) => void;
}

const BlockInline = ({
  block, onUpdate, onDelete,
}: Props) => (
  <Card className="mb-3" border="0">
    <Card.Body>
      <Row className="align-items-center justify-content-between p-3">
        <Col xs="12" md="8">
          <Card.Title>{block.description}</Card.Title>
          <Card.Text>{new Date(block.created_at!).toLocaleDateString('fr-FR')}</Card.Text>
          <Badge bg="secondary" className="mb-2">
            {block.type}
          </Badge>
        </Col>
        <Col xs="12" md="4" className="text-md-end">
          <img
            alt="card-icon"
            src={EditIcon}
            height="20"
            width="20"
            onClick={() => onUpdate(block.id)}
            className="pointer me-2"
          />
          <img
            alt="card-icon"
            src={DeleteIcon}
            height="20"
            width="20"
            onClick={() => onDelete(block.id)}
            className="pointer"
          />
        </Col>
      </Row>
    </Card.Body>
  </Card>
);

export default BlockInline;
