import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { UserContext } from '@root/contexts/user.context';
import { Field } from '@root/interfaces/field.interface';
import {
  EmployeeNumber,
  BusinessTurnover,
  Department,
  Activity,
  Certification,
  Enterprise as IEnterprise,
} from '@root/interfaces/enterprise.interface';

import EnterpriseGeneral from '@root/components/Enterprise/EnterpriseGeneral';
import EnterpriseActivity from '@root/components/Enterprise/EnterpriseActivity';

import { getHigherObjectBy } from '@root/helpers/utils';
import useSafeFetch, { useSafeFetchCallback } from '@root/hooks/useSafeFetch';
import {
  getBusinessTurnoversConfig,
  getEmployeeNumbersConfig,
  getEnterpriseActivitiesConfig,
  getEnterpriseDepartmentsConfig,
  getProviderFieldsConfig,
  getProviderCertificationsConfig,
} from '@root/api-configs/enterprise.api.config';
import useSafeState from '@root/hooks/useSafeState';
import { CUSTOMER_BASE_PATH } from '@root/helpers/constants.helper';
import AWContainer from '@root/components/AWContainer/AWContainer';
import { enterpriseTabsView, fetchEnterprise } from '@root/helpers/enterprise.helper';
import ReturnButton from '@root/components/ReturnButton/ReturnButton';
import Tabs from '@customer/components/Tabs/Tabs';
import EnterpriseContact from '@root/components/Enterprise/EnterpriseContact';
import BurgerMenu from '@root/components/BurgerMenu/BurgerMenu';
import { BurgerMenu as IBurgerMenu } from '@root/interfaces/menu.interface';
import { getConnectionsByEnterpriseIdConfig } from '@root/api-configs/connection.api.config';

const Enterprise = () => {
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  const { enterpriseId } = useParams<{ enterpriseId: string }>();
  const { replace } = useHistory();
  const { pathname } = useLocation();

  const [enterprise, setEnterprise] = useSafeState<IEnterprise>();

  const [activeTab, setActiveTab] = useState(enterpriseTabsView[0]);

  // API STATES
  const [
    initConnections,
    connections,
  ] = useSafeFetch(getConnectionsByEnterpriseIdConfig, []);

  const {
    init: initFields,
  } = useSafeFetch<Field[]>(getProviderFieldsConfig, []).toObject;

  const [
    initBusinessTurnover,
    businessTurnover,
  ] = useSafeFetch<BusinessTurnover>(
    getBusinessTurnoversConfig,
    undefined,
    (turnovers: BusinessTurnover[]) => getHigherObjectBy('year', turnovers),
  );
  const [
    initCertifications,
    certifications,
  ] = useSafeFetch<Certification[]>(getProviderCertificationsConfig, []);
  const [
    initEmployeeNumber,
    employeeNumber,
  ] = useSafeFetch<EmployeeNumber>(
    getEmployeeNumbersConfig,
    undefined,
    (_employees: EmployeeNumber[]) => getHigherObjectBy('year', _employees),
  );

  const [initActivities, activities] = useSafeFetch<Activity[]>(getEnterpriseActivitiesConfig, []);
  const [
    initDepartments,
    departments,
  ] = useSafeFetch<Department[]>(getEnterpriseDepartmentsConfig, []);

  const burgerMenu: IBurgerMenu = useMemo(() => {
    let options = [
      {
        label: t('EnterpriseBurgerMenu.myContracts', 'Mes contrats'),
        linkTo: `${CUSTOMER_BASE_PATH}/v1/contract`,
      },
    ];

    if (connections?.length) {
      options = [
        ...options,
        {
          label: t('EnterpriseBurgerMenu.myConnections', 'Mes mises en relation'),
          linkTo: `${CUSTOMER_BASE_PATH}/connections`,
        }];
    }
    return {
      sections: [{ options }],
    };
  }, [connections]);

  const getEnterpriseService = useSafeFetchCallback(fetchEnterprise);

  const fetchData = async () => {
    if (user.currentEnterprise?.id && user.currentEnterprise.id === enterpriseId) {
      const _enterprise = await getEnterpriseService(enterpriseId);
      setEnterprise(_enterprise);
      if (_enterprise?.id) {
        initConnections({ enterpriseId: _enterprise.id });
        initActivities({ id: _enterprise.id }, { fields: 'activity' });
        initFields({ providerId: _enterprise.id });
        initDepartments(
          { identificationNumber: _enterprise.identification_number },
          { fields: 'insee_code,display_name', page_size: 150 },
        );
        initCertifications({ providerId: _enterprise.id }, { fields: 'label' });
        initBusinessTurnover(
          { enterpriseId: _enterprise.id },
          { fields: 'year,declarative_amount,official_amount' },
        );
        initEmployeeNumber(
          { enterpriseId: _enterprise.id },
          {
            fields: [
              'year',
              'declarative_range_id',
              'official_range_id',
              'declarative_min',
              'official_min',
              'declarative_max',
              'official_max',
            ],
          },
        );
      }
    } else {
      replace(CUSTOMER_BASE_PATH);
    }
  };

  useEffect(() => {
    const newPath = `${CUSTOMER_BASE_PATH}/enterprises/${user.currentEnterprise?.id}`;
    if (newPath !== pathname) replace(newPath);
  }, [user.currentEnterprise?.id]);

  useEffect(() => {
    if (enterpriseId) {
      fetchData();
    }
  }, [enterpriseId]);

  const mainContent = () => {
    switch (activeTab.key) {
      case 'info':
        return (
          <>
            <EnterpriseGeneral
              enterprise={enterprise}
              businessTurnover={businessTurnover}
              employeeNumber={employeeNumber}
            />
            <h2 className="text-dark mt-5 mb-3">
              {t('Enterprise.activities', 'Activités')}
            </h2>
            <EnterpriseActivity
              departments={departments}
              activities={activities}
              certifications={certifications}
            />
          </>
        );

      default:
        return (
          <EnterpriseContact
            representatives={enterprise.representatives || []}
            phoneNumbers={enterprise.phone_numbers || []}
            limitedContacts
            noConnectionContact
          />
        );
    }
  };

  return (
    <AWContainer isLoading={!enterprise}>
      <AWContainer.Main
        title={(
          <div className="d-flex">
            <ReturnButton />
            <span>{`${enterprise?.legal_form} - ${enterprise?.name}`}</span>
          </div>
        )}
        titleSide={<BurgerMenu sections={burgerMenu.sections} />}
      >
        <Tabs
          tabs={enterpriseTabsView}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {mainContent()}
      </AWContainer.Main>
      <AWContainer.Side />
    </AWContainer>
  );
};

export default Enterprise;
