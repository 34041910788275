import React, {
  useEffect,
  useState,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal, ModalBody, ModalHeader, Col, Row, Spinner,
} from 'react-bootstrap';

import {
  Connection,
  ConnectionStatus,
  ConnectionModalLoadingOption,
} from '@root/interfaces/connection.interface';
import AWButton from '@root/components/AWButtons/AWButton';
import { AWColors } from '@root/interfaces/utils.interface';
import { getLogoFromEnterpriseId } from '@root/helpers/enterprise.helper';
import { UserContext } from '@root/contexts/user.context';

import { InvitationStatus, UserInvitation } from '@root/interfaces/invitation.interface';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { AlertContext } from '@root/contexts/alert.context';
import useSafeFetch, { useSafeFetchCallback } from '@root/hooks/useSafeFetch';
import {
  InvitationAndConnection,
  getInvitationAndConnection,
  MODAL_SEEN,
} from '@root/components/AWInvitationModals/invitationModals.builder';
import { updateInvitationConfig } from '@root/api-configs/invitation.api.config';
import { joinUserEnterpriseConfig } from '@root/api-configs/user.api.config';
import { updateConnectionConfig } from '@root/api-configs/connection.api.config';
import { PROVIDER_BASE_PATH } from '@root/helpers/constants.helper';
import { useHistory } from 'react-router-dom';

const ACCEPT = 'accept';
const DECLINE = 'decline';

const AWInvitationModals = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { setNotif } = useContext(AlertContext);
  const { user, refreshEnterprises } = useContext(UserContext);
  const { waitWithLoad } = useLoadingPromise();

  const [loadingOption, setLoadingOption] = useState<ConnectionModalLoadingOption>('');
  const [invitations, setInvitations] = useState<UserInvitation[]>();
  const [connections, setConnections] = useState<Connection[]>();
  const [invitation, setInvitation] = useState<UserInvitation>();
  const [connection, setConnection] = useState<Connection>();
  const [logo, setLogo] = useState<string>();

  const getLogoService = useSafeFetchCallback<string>(getLogoFromEnterpriseId);
  const getConnectionAndInvitationService = useSafeFetchCallback<InvitationAndConnection>(
    getInvitationAndConnection,
  );
  const updateConnection = useSafeFetch<Connection>(updateConnectionConfig).callApi;

  const updateInvitation = useSafeFetch(updateInvitationConfig).callApi;
  const joinUserEnterprise = useSafeFetch(joinUserEnterpriseConfig).callApi;

  const getLogo = async () => {
    const customerLogo = await getLogoService(connection?.customer_id);
    setLogo(customerLogo);
  };

  const getSeenCount = () => {
    const modalSeen = sessionStorage.getItem(MODAL_SEEN);
    return modalSeen ? JSON.parse(modalSeen) : undefined;
  };

  const addToSeenCount = (id: string) => {
    const seenCount = getSeenCount();
    const newSeenCount = seenCount ? [...seenCount, id] : [id];
    sessionStorage.setItem(MODAL_SEEN, JSON.stringify(newSeenCount));
  };

  const nextModal = () => {
    if (connections?.length) {
      const seenCount = getSeenCount();
      const _connection = connections.find((con) => (
        !seenCount || (con?.provider_id && !seenCount.includes(con.provider_id))
      ));
      if (_connection) {
        if (invitations?.length) {
          setInvitation(
            invitations.find((inv) => (
              inv.enterprise_guest_id === _connection.provider_id
            )),
          );
        }
        setConnection(_connection);
      }
    }
  };

  const closeModal = () => {
    if (connection?.provider_id) addToSeenCount(connection.provider_id);
    setConnection(undefined);
    setInvitation(undefined);
    setLoadingOption('');
    nextModal();
  };

  const handleInvitedUser = async () => {
    const {
      connections: _connections,
      invitations: _invitations,
    } = await getConnectionAndInvitationService(user);
    if (_connections) setConnections(_connections);
    if (_invitations) setInvitations(_invitations);
  };

  const onAccept = async () => {
    if (connection?.id) {
      setLoadingOption('accept');
      if (invitation?.id && connection?.provider_identification_number) {
        await Promise.all([
          updateInvitation(
            {
              id: invitation.id,
              body: { status: InvitationStatus.ACCEPTED_IF_IS_MEMBER },
            },
          ),
          joinUserEnterprise({
            userId: user.id,
            identificationNumber: connection.provider_identification_number,
            body: {},
            query: { is_new: 'true' },
          }),
        ]);
      }
      const inProgressRes = await updateConnection({
        connectionId: connection.id,
        body: {
          status: ConnectionStatus.IN_PROGRESS,
        },
      });
      if (inProgressRes?.success) {
        if (connection.config_id) {
          await refreshEnterprises(connection.provider_id);
          closeModal();
          push(`/provider/connections/${connection.id}`);
        } else {
          const validateRes = await updateConnection({
            connectionId: connection.id,
            body: {
              status: ConnectionStatus.VALIDATED,
            },
          });
          if (validateRes?.success) {
            setNotif({
              message: t(
                'Connections.youAreConnected',
                'Vous êtes en relation avec {{customer}}',
                { customer: connection?.customer_name },
              ),
              variant: 'success',
            });
            if (!user.currentAppEnterprises?.length) {
              await refreshEnterprises(connection.provider_id);
              push(PROVIDER_BASE_PATH);
            } else {
              await refreshEnterprises(connection.provider_id);
            }
            closeModal();
          }
        }
      }
    }
  };

  const onDecline = async () => {
    if (connection) {
      setLoadingOption('decline');
      const res = await waitWithLoad(
        updateConnection(
          {
            connectionId: connection.id,
            body: {
              status: ConnectionStatus.DECLINED,
            },
          },
        ),
      );
      if (invitation?.id) {
        await updateInvitation({ id: invitation.id, body: { status: InvitationStatus.REJECTED } });
      }
      if (res?.success) {
        closeModal();
      }
    }
  };

  useEffect(() => {
    if (connection?.customer_id) {
      getLogo();
    }
  }, [connection?.customer_id]);

  useEffect(() => {
    if (user.id && user.email) {
      handleInvitedUser();
    }
  }, [user.id]);

  useEffect(() => {
    nextModal();
  }, [invitations, connections]);

  return (
    (user && user.id) ? (
      <Modal
        show={!!connection}
        animation={false}
        backdrop="static"
        centered
        className="modal-md"
        onHide={closeModal}
        close
      >
        <ModalHeader closeButton />
        <ModalBody
          className="d-flex flex-column justify-content-center align-items-center"
        >
          {
            logo
              ? <img src={logo} alt="companyLogo" className="collab-modal__avatar my-3" />
              : <div className="collab-modal__avatar my-3" />
          }
          <p className="font-size-18 font-style-semibold">
            {connection?.customer_name}
          </p>
          <p className="text-grey">
            {t(
              'Invitation.Collaboration.invitesYouToCollaborate',
            )}
          </p>
          <br />
          <p className="font-size-18 font-style-semibold">
            {connection?.provider_name}
          </p>
        </ModalBody>
        <Modal.Footer className="d-block">
          <Row className="my-2">
            <Col md="6" className="mb-3 mb-md-0">
              <AWButton
                backgroundColor="transparent"
                className="border"
                color={AWColors.Grey}
                type="button"
                disabled={!!loadingOption}
                onClick={onDecline}
              >
                {
                  loadingOption === DECLINE ? (
                    <Spinner animation="border" variant="dark" size="sm" />
                  ) : (
                    t('Action.decline', 'Décliner')
                  )
                }
              </AWButton>
            </Col>
            <Col md="6">
              <AWButton
                type="button"
                disabled={!!loadingOption}
                onClick={onAccept}
              >
                {
                  (loadingOption === ACCEPT) ? (
                    <Spinner animation="border" variant="white" size="sm" />
                  ) : t('Action.accept', 'Accepter')
                }

              </AWButton>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    ) : <span />
  );
};

export default AWInvitationModals;
