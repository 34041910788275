import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  ReactNode,
  useContext,
} from 'react';
import { Connection, ConnectionStatus } from '@root/interfaces/connection.interface';
import { Enterprise } from '@root/interfaces/enterprise.interface';
import { Notification } from '@root/interfaces/menu.interface';
import { getConnectionsByEnterpriseId } from '@provider/services/connection.service';
import { UserContext } from '@root/contexts/user.context';
import useCurrentApp, { Apps } from '@root/hooks/useCurrentApp';

interface INotificationContext {
  notification: Notification,
  setUnseenConnections: (connections: Connection[]) => void,
}

export const NotificationContext = createContext<INotificationContext>({
  notification: {},
  setUnseenConnections: () => { },
});

const NotificationContextProvider = ({ children }: { children: ReactNode }) => {
  const [notification, setNotification] = useState<Notification>({});
  const { user } = useContext(UserContext);
  const { isApp } = useCurrentApp();

  const setUnseenConnections = (connections: Connection[]) => {
    setNotification({
      ...notification,
      connections: connections.filter((connection) => (
        connection.status === ConnectionStatus.UNSEEN
      )).length,
    });
  };

  useEffect(() => {
    (async () => {
      if (user.currentEnterprise?.id && isApp(Apps.Provider)) {
        const connectionResponse = await getConnectionsByEnterpriseId(user.currentEnterprise?.id);
        if (connectionResponse.success) {
          setUnseenConnections(
            connectionResponse.data.filter((c: Connection) => (
              c.provider_id === (user.currentEnterprise as Enterprise).id
            )),
          );
        }
      }
    })();
  }, [user.currentEnterprise?.id]);

  const value = useMemo(() => ({
    notification,
    setUnseenConnections,
  }), [notification]);

  return (
    <NotificationContext.Provider
      value={value}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
