import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { flatten } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';

import StatusTag, { Status } from '@customer/components/StatusTag/StatusTag';
import {
  Survey as ISurvey,
  SurveyAnswer,
  SurveyQuestion,
  Types,
} from '@root/interfaces/survey.interface';
import List from '@customer/components/List/List';

import { getAnswers, getSurvey } from '@customer/services/survey.service';
import { getProviders } from '@customer/services/provider.service';
import { Provider, ProviderSourcing } from '@customer/interfaces/provider.interface';
import { Column } from '@customer/interfaces/list.interface';
import { getLegalForm } from '@root/helpers/utils';

import { ApiResponse } from '@root/helpers/response-handler';

interface Rows {
  columns: Column[];
  data: {
    name: string;
    identificationNumber: string;
    [key: string]: any;
  }[]
}

const Survey = () => {
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const [survey, setSurvey] = useState<ISurvey>({});
  const [answers, setAnswers] = useState<SurveyAnswer[]>([]);
  const [rows, setRows] = useState<Rows>({ columns: [], data: [] });
  const history = useHistory();

  const fetchSurvey = async (): Promise<void> => {
    const response = await getSurvey(id);
    if (response.success) {
      setSurvey(response.data as ISurvey);
    }
  };

  const fetchAnswers = async (): Promise<void> => {
    const response = await getAnswers(survey.id as string);
    if (response.success) {
      setAnswers(response.data as SurveyAnswer[]);
    }
  };

  const buildRow = (
    questions: SurveyQuestion[],
    providers: ProviderSourcing[],
    answer: SurveyAnswer,
  ) => {
    const provider = providers.find((el: ProviderSourcing) => (
      el.id === answer.enterpriseId
    ));
    const visibleValues = questions
      .map((question: SurveyQuestion) => (
        answer.values?.find((v) => question.key === v.question)?.value
      ));
    const row = {
      name: provider?.name || '',
      identificationNumber: provider?.identificationNumber || '',
      legalForm: provider?.legalForm ? getLegalForm(provider.legalForm) : '',
    };
    for (let i = 0; i < questions.length; i += 1) {
      row[questions[i].key as string] = visibleValues[i];
    }
    return row;
  };

  const fetchProviders = async (): Promise<void> => {
    const ids = answers.reduce((acc: string[], answer: SurveyAnswer): string[] => (
      answer.enterpriseId && !acc.includes(answer.enterpriseId)
        ? [...acc, answer.enterpriseId] : acc
    ), []);
    const response = await getProviders({
      fields: 'name,identificationNumber,legalForm',
      search: { _id: ids },
    });
    if (response.success) {
      const visibleQuestions: SurveyQuestion[] = (flatten(survey.content) as SurveyQuestion[])
        .filter((question) => question.visible);
      setRows({
        columns: [
          {
            label: t('Provider.name', 'Raison social'),
            width: 10,
            key: 'name',
          },
          {
            label: t('Provider.siret', 'Siret'),
            width: 10,
            key: 'identificationNumber',
          },
          {
            label: t('Answers.legalForm', 'Forme légale'),
            width: 10,
            key: 'legalForm',
          },
          ...visibleQuestions.map((question: SurveyQuestion) => ({
            label: question.title || 'N/A',
            key: question.key!,
            isArray: question.type === Types.Array,
            isBoolean: question.type === Types.Boolean,
            units: t('List.responses', 'réponses'),
          })),
        ],
        data: answers.map((answer: SurveyAnswer) => (
          buildRow(visibleQuestions, response.data as ProviderSourcing[], answer)
        )),
      });
    }
  };

  const goToVendor = async ({ identificationNumber }: { identificationNumber }): Promise<void> => {
    const response: ApiResponse = await getProviders({
      fields: '_id',
      search: { identificationNumber },
    });

    if (response?.success && (response?.data as Provider[]).length) {
      history.push(`/customer/surveys/${id}/provider/${(response?.data as Provider[])[0].id}`);
    }
  };

  useEffect(() => {
    if (answers.length) {
      fetchProviders();
    }
  }, [answers]);

  useEffect(() => {
    if (survey.id) {
      fetchAnswers();
    }
  }, [survey]);

  useEffect(() => {
    fetchSurvey();
  }, [id]);

  return (
    <div className="h-100">
      <div className="d-flex align-items-center">
        <h1 className="text-dark">{survey.label}</h1>
        <div className="mb-2 mx-3">
          <StatusTag status={survey.status || Status.Sketch} />
        </div>
      </div>
      <div>
        <List
          columns={rows.columns}
          data={rows.data}
          onClick={goToVendor}
        />
      </div>
    </div>
  );
};

export default Survey;
