import i18n from '@root/locales/i18n';
import { ApprovalStep } from '@customer/interfaces/approval-step.interface';

export enum ApprovalStepStatus {
  COMPLETED = 'completed',
  ACTIVE = 'active',
  WAITING = 'waiting',
}

export enum StepPathKeyword {
  MISSION = 'mission',
  MODEL = 'model',
  INFOS = 'infos',
  CHECK = 'check',
  PREVIEW = 'preview',
  VALIDATION = 'validation',
}

export const NewApprovalStepLabelList: ApprovalStep[] = [
  {
    id: 1,
    label: i18n.t('ApprovalStepper.selectMission', 'Choix de la mission'),
    status: ApprovalStepStatus.WAITING,
    path: StepPathKeyword.MISSION,
  },
  {
    id: 2,
    label: i18n.t('ApprovalStepper.selectTemplate', 'Choix du modèle de document'),
    status: ApprovalStepStatus.WAITING,
    path: StepPathKeyword.MODEL,
  },
  {
    id: 3,
    label: i18n.t('ApprovalStepper.additionalInfo', 'Informations complémentaires'),
    status: ApprovalStepStatus.WAITING,
    path: StepPathKeyword.INFOS,
  },
  {
    id: 4,
    label: i18n.t('ApprovalStepper.checkVariables', 'Vérification des variables'),
    status: ApprovalStepStatus.WAITING,
    path: StepPathKeyword.CHECK,
  },
  {
    id: 5,
    label: i18n.t('ApprovalStepper.preview', 'Prévisualisation'),
    status: ApprovalStepStatus.WAITING,
    path: StepPathKeyword.PREVIEW,
  },
  {
    id: 6,
    label: i18n.t('ApprovalStepper.validation', 'Validation'),
    status: ApprovalStepStatus.WAITING,
    path: StepPathKeyword.VALIDATION,
  },
];
