import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

const RedirectWithParams = () => {
  const { offerId } = useParams<{ offerId: string }>();

  return (
    <Redirect to={`/offer/${offerId}`} />
  );
};

export default RedirectWithParams;
