import { Auth } from 'aws-amplify';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { UserContext } from '@root/contexts/user.context';
import { createUserV1 } from '@root/hooks/cognito.builder';
import {
  CognitoErrors,
  CognitoUserAttributes,
  SessionUser,
} from '@root/interfaces/cognito.interface';
import { AWColors as Color } from '@root/interfaces/utils.interface';
// DO NOT GLOBALIZE IT !
import {
  createACL,
  getUsers,
  updateUser as updateUserService,
} from '@user/services/user.service';
import { LoginRouterContext, Views } from '@user/contexts/loginRouter.context';
import {
  CUSTOMER_BASE_PATH,
  PROVIDER_BASE_PATH,
  PUNCHOUT,
} from '@root/helpers/constants.helper';
import { getUserEnterprises } from '@root/helpers/user.helper';
import { goTo, formatEmail } from '@root/helpers/utils';
import useSafeFetch, { useSafeFetchCallback } from '@root/hooks/useSafeFetch';
import { getMyAclConfig } from '@root/api-configs/user.api.config';
import { NotFound } from '@root/helpers/catalog.error';

const success = 'SUCCESS';

/**
 * Embed Cognito API functions with loading and message responses.
 * @message The message to display depending on the Cognito API response received.
 */

const useCognito = () => {
  const [message, setMessage] = useState<{
    text?: string,
    color?: Color,
    toSupport?: boolean,
    opt?: unknown,
  } | null>(null);
  const { t } = useTranslation();
  const { push, location } = useHistory();
  const { user, redirect } = useContext(UserContext);
  const fetchCurrentEnterprises = useSafeFetchCallback(getUserEnterprises);
  const getMyAcl = useSafeFetch<Record<string, any>>(getMyAclConfig).callApi;

  const {
    setUser,
  } = useContext(UserContext);
  const { setCurrentView } = useContext(LoginRouterContext);

  const handleError = async (e: any, _email?: string) => {
    switch (e.name) {
      case 'ExpiredCodeException':
        setMessage({
          text: t('Errors.expiredCodeException', 'Le code est expiré'),
          color: Color.Red,
        });
        break;

      case 'NotAuthorizedException':
        setMessage({
          text: t('Login.wrongCredentials', 'Les identifiants renseignés sont incorrects'),
          color: Color.Red,
        });
        break;

      case 'UserNotConfirmedException':
        try {
          await Auth.resendSignUp((_email) ? formatEmail(_email) : '');
          push('/signup/email-confirmation');
        } catch {
          setMessage({
            text: t('Login.unknownError', 'Une erreur est survenue, veuillez réessayer plus tard.'),
            color: Color.Red,
          });
        }
        break;

      case 'CodeMismatchException':
        setMessage({
          text: t('PasswordReset.wrongCode', 'Le code est expiré ou non valide.'),
          color: Color.Red,
        });
        break;

      case 'InvalidPasswordException':
        setMessage({
          text: t(
            'PasswordReset.passwordFormat',
            `
              Le mot de passe doit contenir au moins 8 caractères dont
              une minuscule, une majuscule, un caractère spécial et un chiffre.
            `,
          ),
          color: Color.Red,
        });
        break;

      case 'UsernameExistsException':
        setMessage({
          text: t('Errors.userAlreadyExists', "L'utilisateur existe déjà"),
          color: Color.Red,
        });
        break;

      case 'LimitExceededException':
        setMessage({
          text: t(
            'Errors.limitExceeded',
            "Vous avez atteint la limite du nombre d'essais, veuillez réessayer plus tard.",
          ),
          color: Color.Red,
        });
        break;

      case 'InvalidParameterException':
        if (e.message === 'Invalid phone number format.') {
          setMessage({
            text: t(
              'Errors.invalidPhoneNumber',
              "Le format du numéro de téléphone renseigné n'est pas valide.",
            ),
            color: Color.Red,
            opt: CognitoErrors.PhoneNumberFormat,
          });
        }
        break;

      default:
        setMessage({ toSupport: true });
        break;
    }
  };

  const checkAndReloadAcl = async (_email: string) => {
    const aclRes = await getMyAcl({});
    if (aclRes?.hasError(NotFound)) {
      await createACL(_email);
    }
  };

  const redirectUser = async (_email: string) => {
    const email = formatEmail(_email);
    setMessage(null);
    try {
      const res = await getUsers({
        fields: 'personal_token',
        search: { email },
      });
      if (
        res.data
        && Array.isArray(res.data)
        && res.data[0].personal_token
      ) {
        const v1token = res.data[0].personal_token;
        localStorage.setItem('v1token', v1token);
      } else {
        throw new Error();
      }
      if (location.search) {
        const query = new URLSearchParams(location.search);
        if (query.has('t')) {
          sessionStorage.setItem(PUNCHOUT, query.get('t') as string);
        }
      }
      await checkAndReloadAcl(_email);

      // usual behavior
      const enterprises = await fetchCurrentEnterprises();
      if (redirect) {
        goTo(redirect);
      } else if (
        !enterprises.length
        || enterprises.every((e) => e.is_vendor && !e.is_customer)
      ) {
        goTo(PROVIDER_BASE_PATH);
      } else if (enterprises.every((e) => e.is_customer && !e.is_vendor)) {
        goTo(CUSTOMER_BASE_PATH);
      } else {
        if (location.pathname !== '/') push('/');
        setCurrentView(Views.Profile);
      }
    } catch (e) {
      await handleError(e, email);
    }
  };

  /**
   * Sign user in using Cognito API.
   * @param _email User email
   * @param password User password
   */

  const signIn = async (_email, password) => {
    const email = formatEmail(_email);
    try {
      await Auth.signIn(email, password);
      await redirectUser(email);
    } catch (e: any) {
      await handleError(e, email);
    }
  };

  /** Creates a user in Cognito. */
  const signUp = async (_user: SessionUser, captchaToken: string): Promise<void> => {
    const email = _user.email?.toLowerCase().trim();

    const formatPhone = (phoneNumber?: string) => {
      if (!phoneNumber) return '';
      const p = phoneNumber.trim().replace(/\s/g, '');
      return p.includes('+') ? p : `+${p}`;
    };
    try {
      if (email && _user.password) {
        await Auth.signUp({
          username: email,
          password: _user.password,
          attributes: {
            email,
            family_name: _user.lastname,
            given_name: _user.firstname,
            name: `${_user.firstname} ${_user.lastname}`,
            gender: _user.gender,
            phone_number: formatPhone(_user.phoneNumber),
          },
          validationData: {
            recaptchaToken: captchaToken,
          },
        });
      }
      setUser({
        gender: _user.gender,
        phoneNumber: `+${_user.phoneNumber}`,
        firstname: _user.firstname,
        lastname: _user.lastname,
        email,
        password: _user.password,
      });
      push('/signup/email-confirmation');
    } catch (error: any) {
      await handleError(error);
    }
  };

  /**
   * Send a verification code to the user to recover his password.
   * @param _email User recovery email
   */

  const sendPwdCodeConfirmation = async (_email) => {
    const email = formatEmail(_email);
    setMessage(null);
    try {
      await Auth.forgotPassword(email);
      setCurrentView(Views.Update);
    } catch (error: any) {
      await handleError(error, email);
    }
  };

  /**
   * Reset the user password.
   * @param _email The user email
   * @param code The validation code received by email
   * @param newPassword The new password, must match cognito password allowed format.
   */

  const resetPassword = async (_email: string, code: string, newPassword: string) => {
    const email = formatEmail(_email);
    setMessage(null);
    try {
      const userIdRes = await getUsers({ fields: 'id', search: { email } });
      if (userIdRes.success && userIdRes.data?.length && userIdRes.data[0].id) {
        const [v2Update, v1Update] = await Promise.all([
          Auth.forgotPasswordSubmit(email, code, newPassword),
          updateUserService(userIdRes.data[0].id, { password: newPassword, email }),
        ]);
        if (v2Update && !v1Update.hasError()) {
          setMessage({
            text: t('PasswordReset.success', 'Votre mot de passe a été mis à jour.'),
            color: Color.Green,
          });
        } else {
          setMessage({
            text: t(
              'PasswordReset.error',
              'Une erreur est survenue lors la mise à jour de votre mot de passe.',
            ),
            color: Color.Red,
          });
        }
      }
    } catch (error: any) {
      await handleError(error, email);
      throw new Error();
    }
  };

  /**
   * Confirm the user email.
   * @param _email The user email
   * @param code The verification code received by email
   */

  const confirmUserEmail = async (_user: SessionUser, code) => {
    const email = _user?.email?.toLowerCase().trim();
    setMessage(null);
    try {
      if (email && _user.password) {
        await Auth.confirmSignUp(email, code);
        await Auth.signIn(email, _user.password);
        const { attributes }: { attributes: CognitoUserAttributes } = await Auth.currentUserInfo();
        const v1user: SessionUser = {
          ..._user,
          firstname: attributes.given_name,
          lastname: attributes.family_name,
          gender: attributes.gender,
          phoneNumber: attributes.phone_number,
        };
        const v1UserRes = await createUserV1(v1user);

        if (v1UserRes.success) {
          redirectUser(email);
        } else {
          setMessage({
            text: t('Login.unknownError', 'Une erreur est survenue, veuillez réessayer plus tard.'),
            color: Color.Red,
          });
        }
      }
    } catch (error: any) {
      await handleError(error, email);
    }
  };

  const updatePassword = async (data) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const cognitoResponse = await Auth.changePassword(
        cognitoUser,
        data.oldpassword,
        data.password,
      );
      if (cognitoResponse === success && user.id) {
        const body = {
          email: cognitoUser.attributes.email,
          password: data.password,
        };
        const apiResponse = await updateUserService(user.id, body);
        if (apiResponse.hasError()) {
          setMessage({
            text: t('Error.unknownError', 'Une erreur est survenue, veuillez réessayer plus tard.'),
            color: Color.Red,
          });
          await Auth.changePassword(user, data.password, data.oldpassword);
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        await handleError(e);
      }
    }
  };

  const updateUser = async (data) => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const cognitoResponse = await Auth.updateUserAttributes(cognitoUser, {
      family_name: data.lastname,
      given_name: data.firstname,
      name: `${data.firstname} ${data.lastname}`,
    });
    if (cognitoResponse === success && user.id) {
      const apiResponse = await updateUserService(
        user.id,
        {
          email: cognitoUser.attributes.email,
          lastname: data.lastname,
          firstname: data.firstname,
          gender: data.gender,
        },
      );
      if (apiResponse.hasError()) {
        setMessage({
          text: t('Error.unknownError', 'Une erreur est survenue, veuillez réessayer plus tard.'),
          color: Color.Red,
        });
        await Auth.updateUserAttributes(user, {
          family_name: cognitoUser.attributes.family_name,
          given_name: cognitoUser.attributes.given_name,
          name: `${cognitoUser.attributes.given_name} ${cognitoUser.attributes.family_name}`,
        });
      }
    }
  };

  return {
    message,
    updatePassword,
    updateUser,
    setMessage,
    signIn,
    signUp,
    sendPwdCodeConfirmation,
    resetPassword,
    confirmUserEmail,
  };
};

export default useCognito;
