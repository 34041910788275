import React from 'react';

interface Props {
  title: string,
  number: number,
  color: string,
  className?: string,
  onClick?: () => void,
}

const AWMicroCard = ({
  title,
  number,
  color,
  className,
  onClick,
}: Props) => (
  <div
    className={`aw-micro-card ${className}`}
    onClick={onClick}
    aria-hidden="true"
  >
    <p>{title}</p>
    <p
      className="font-size-32 font-style-semibold"
      style={{ color }}
    >
      {number}
    </p>
  </div>
);

AWMicroCard.defaultProps = {
  className: '',
  onClick: () => { },
};

export default AWMicroCard;
