/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { cloneDeep } from 'lodash';
import { Form } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useForm,
} from 'react-hook-form';

import InfoMessage from '@root/components/InfoMessage/InfoMessage';
import { AlertContext } from '@root/contexts/alert.context';
import { AWColors } from '@root/interfaces/utils.interface';
import trash from '@root/assets/trash.svg';

import {
  createEnterprise as createEnterpriseService,
} from '@customer/services/enterprise.service';

import {
  MissionProvider, OfferInvite, ProviderIdAndMail,
} from '@customer/interfaces/mission.interface';
import { Mission as IMission } from '@root/interfaces/mission.interface';
import {
  getMissionProviders,
  getMissionOffer,
  addToCart as addToCartService,
  deleteFromCart as deleteFromCartService,
  sendInvitation,
} from '@customer/services/mission.service';
import AWContainer from '@root/components/AWContainer/AWContainer';
import Separator from '@customer/components/ProviderInfo/Separator';
import { fetchUsersWithRights } from '@customer/helpers/mission.helper';
import AWButton from '@root/components/AWButtons/AWButton';
import { AlreadyExist } from '@root/helpers/catalog.error';
import { EnterpriseCreationContext } from '@root/contexts/enterpriseCreation.context';
import NewEnterpriseModal from '@customer/components/NewEnterpriseModal/NewEnterpriseModal';
import EmailsModal from '@customer/views/Providers/Partners/EmailsModal';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { CUSTOMER_BASE_PATH } from '@root/helpers/constants.helper';
import useSafeFetch from '@root/hooks/useSafeFetch';
import { getEnterpriseConfig, getLegalFormsConfig } from '@root/api-configs/enterprise.api.config';
import { Enterprise } from '@root/interfaces/enterprise.interface';
import { LegalForm } from '@provider/interfaces/provider.interface';

const SelectedPartners = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const { offerId } = useParams<{ offerId: string }>();

  const { waitWithLoad, isLoading } = useLoadingPromise();

  const [providersInBasket, setProvidersInBasket] = useState<MissionProvider[]>();
  const [providersWithStatus, setProvidersWithStatus] = useState<MissionProvider[]>();
  const [mission, setMission] = useState<IMission>();
  const [missionName, setMissionName] = useState<string>();
  const [existingProvidersEmails, setExistingProvidersEmails] = useState<ProviderIdAndMail[]>([]);
  const [newProvidersEmails, setNewProvidersEmails] = useState<ProviderIdAndMail[]>([]);
  const [newEnterpriseModalOpen, setNewEnterpriseModalOpen] = useState(false);
  const [emailsModalOpen, setEmailsModalOpen] = useState(false);
  const [activeProvider, setActiveProvider] = useState<MissionProvider>();

  const { setNotif } = useContext(AlertContext);
  const { enterprise, resetEnterprise } = useContext(EnterpriseCreationContext);

  const getEnterprise = useSafeFetch<Enterprise>(getEnterpriseConfig).callApi;
  const getLegalForms = useSafeFetch<LegalForm>(getLegalFormsConfig).callApi;

  useEffect(() => {
    const providers = cloneDeep(existingProvidersEmails)
      .map((epe) => {
        const existing = newProvidersEmails.find((npe) => npe.id === epe.id);
        return existing || { id: epe.id, emails: [] };
      });
    setNewProvidersEmails(providers);
  }, [existingProvidersEmails]);

  const providers: ProviderIdAndMail[] = useMemo(() => (
    cloneDeep([...existingProvidersEmails, ...newProvidersEmails])
      .reduce((acc: ProviderIdAndMail[], current: ProviderIdAndMail) => {
        const provider = acc.find((p) => p.id === current.id);
        if (provider) {
          provider.emails = [...provider.emails, ...current.emails];
          return acc;
        }
        return [...acc, current];
      }, [])
  ), [existingProvidersEmails, newProvidersEmails]);

  const getMission = async (id) => {
    const missionRes = await getMissionOffer(id, {
      fields: 'id,starts_at_desired,ends_at,status,label,description',
    });
    if (missionRes.success) {
      if (missionRes?.data) {
        setMission(missionRes.data);
        setMissionName(missionRes.data.label);
      }
    }
  };

  const getProviderInBasket = async (id) => {
    const proposalsInBasketRes = await getMissionProviders(
      id,
      {
        fields: 'mission_offer_id,status,vendor_enterprise_id',
      },
    );
    if (proposalsInBasketRes.success && proposalsInBasketRes.data) {
      const _providers = await Promise.all((proposalsInBasketRes.data?.map(async (
        proposal,
      ) => {
        const provider = await getEnterprise(
          { identifier: proposal.vendor_enterprise_id },
          { fields: 'identification_number,name,legal_form_id' },
        );
        const legalRes = await getLegalForms({}, {
          fields: 'display_name',
          search: { id: provider?.data?.legal_form_id },
        });
        return {
          ...proposal,
          enterprise_name: provider?.data?.name,
          legal_form_name: legalRes?.data[0]?.display_name,
          users: await fetchUsersWithRights(provider?.data?.identification_number),
        };
      })));
      const providersInBasketList = _providers
        .filter((missionOffer) => missionOffer.status === 'inBasket');
      setProvidersInBasket(providersInBasketList);
      setProvidersWithStatus(_providers);
      const emailsFromDatabase: ProviderIdAndMail[] = [...existingProvidersEmails];
      providersInBasketList?.map((provider) => {
        if (!emailsFromDatabase.find((el) => el.id === provider.vendor_enterprise_id)) {
          const emails: string[] = [];
          provider?.users?.map((user) => {
            if (user?.data?.email) {
              emails.push(user?.data?.email);
              return emails;
            }
            return emails;
          });
          emailsFromDatabase.push({ id: provider.vendor_enterprise_id, emails });
        }
        return provider;
      });
      setExistingProvidersEmails(emailsFromDatabase);
    }
  };

  const addProviderToCart = async (providerId) => {
    const addToCartRes = await addToCartService(offerId, {
      vendor_enterprise_id: providerId,
      status: 'inBasket',
      label: mission?.label || '',
      details: mission?.description,
      valid_from: mission?.starts_at_desired,
      valid_until: mission?.ends_at,
      mission_offer_id: mission?.id,
    });
    if (addToCartRes.hasError()) {
      setNotif({
        message: t(
          'SelectedPartner.errorAddingToCart',
          "Ce prestataire n'a pas pu être ajouté à votre sélection",
        ),
        variant: 'danger',
      });
    } else {
      getProviderInBasket(offerId);
    }
  };

  // API pour envoyer une invitations aux prestataires sélectionnés
  const diffuseInvite = async (id) => {
    const invitationRes = await sendInvitation(id, providers);
    if (invitationRes.success) {
      history.push(`/customer/v1/sector/offer/${offerId}#nav-proposals-tab`);
    } else {
      setNotif({
        message: t(
          'SelectedPartner.errorSendingInvite',
          "Une erreur est survenue, l'invitation n'a pas pu être envoyée",
        ),
        variant: 'danger',
      });
    }
  };

  useEffect(() => {
    getProviderInBasket(offerId);
    getMission(offerId);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
  } = useForm<OfferInvite>({ mode: 'onChange' });

  const findErrors = (value) => {
    let error = null;
    if (errors[value]?.type === 'required') {
      error = t('Errors.missingField', 'Merci de remplir ce champ');
    } else if (errors[value]?.type === 'manual') {
      error = t(errors[value]?.message);
    }
    return error ? <InfoMessage message={error} color={AWColors.Red} /> : null;
  };

  const submit = (data) => {
    if (data.offerBeginDate > data.offerEndDate) {
      setError(
        'offerEndDate',
        {
          type: 'manual',
          message: t(
            'Errors.dateNotPossible',
            "L'expiration de la proposition doit intervenir après la date de début de la proposition.",
          ),
        },
      );
    } else {
      waitWithLoad(diffuseInvite(offerId));
    }
  };

  const handleDelete = async (offerMissionId, providerId) => {
    const deleteRes = await deleteFromCartService(offerMissionId, providerId);
    if (deleteRes.success) {
      setNotif({
        message: t(
          'SelectedPartner.removedFromCart',
          'Ce prestataire a bien été retiré de votre sélection',
        ),
        variant: 'success',
      });
      getProviderInBasket(offerId);
    } else {
      setNotif(t('SelectedPartner.removeError', "Ce prestataire n'a pas pu être retiré de votre sélection"));
    }
  };

  const closeModal = () => {
    resetEnterprise();
    setNewEnterpriseModalOpen(false);
  };

  const createEnterprise = async () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { legal_form, ...rest } = enterprise;
    const enterpriseResponse = await createEnterpriseService(rest);
    if (
      (
        enterpriseResponse.success
        && enterpriseResponse.data.id
      ) || enterpriseResponse.hasError(AlreadyExist)
    ) {
      let { id } = enterpriseResponse.data;
      if (enterpriseResponse.hasError(AlreadyExist)) {
        const res = await getEnterprise(
          { identifier: enterprise.identification_number },
          { fields: 'id' },
        );
        if (res?.success && res.data?.id) {
          id = res.data.id;
        }
      }
      const selectedProvider = providersWithStatus?.find((p) => p.vendor_enterprise_id === id);
      if (selectedProvider?.status === 'inBasket') {
        setNotif({
          message: t('SelectedPartner.alreadyInBasket', 'Ce prestataire a déjà été ajouté à votre sélection'),
          variant: 'info',
        });
        closeModal();
      } else if (selectedProvider) {
        setNotif({
          message: t('SelectedPartner.alreadyInvited', 'Ce prestataire a déjà reçu cette offre de mission'),
          variant: 'info',
        });
        closeModal();
      } else {
        addProviderToCart(id);
        closeModal();
      }
    }
    return enterpriseResponse;
  };

  const insertEmail = (email: string) => {
    const _providers = newProvidersEmails.map((npe) => (
      npe.id === activeProvider?.vendor_enterprise_id
        ? { id: npe.id, emails: [...npe.emails, email] } : npe
    ));
    setNewProvidersEmails(_providers);
  };

  const deleteEmail = (provider: MissionProvider, email: string) => () => {
    const _providers = newProvidersEmails.map((npe) => {
      if (npe.id === provider.vendor_enterprise_id) {
        return { id: npe.id, emails: npe.emails.filter((_email) => _email !== email) };
      }
      return npe;
    });
    setNewProvidersEmails(_providers);
  };

  return (
    <>
      <NewEnterpriseModal
        isOpen={newEnterpriseModalOpen}
        onClose={closeModal}
        onSubmit={createEnterprise}
      />
      <EmailsModal
        isOpen={emailsModalOpen}
        setIsOpen={setEmailsModalOpen}
        activeProvider={activeProvider}
        newProvidersEmails={newProvidersEmails}
        existingEmails={providers
          .find((p) => p.id === activeProvider?.vendor_enterprise_id)?.emails || []}
        onSubmit={insertEmail}
      />
      <AWContainer isLoading={isLoading}>
        <AWContainer.Main
          title={t(
            'SelectedPartners.title',
            "Les prestataires sélectionnés pour l'offre de mission {{mission}}",
            { mission: missionName },
          )}
        >
          {
            providersInBasket && providersInBasket.map((provider) => (
              <div key={provider.id}>
                <div className="d-flex align-items-center justify-content-between p-4">
                  <div className="d-flex align-items-center">
                    <img
                      src={trash}
                      alt="delete"
                      height={24}
                      className="pointer me-4"
                      onClick={() => handleDelete(offerId, provider?.vendor_enterprise_id)}
                    />
                    <Link
                      to={`${CUSTOMER_BASE_PATH}/providers/${provider.vendor_enterprise_id}/${offerId}`}
                    >
                      <p>{`${provider.legal_form_name} - ${provider.enterprise_name}`}</p>
                      <p>{provider.identification_number}</p>
                    </Link>
                  </div>
                  <div>
                    {[...existingProvidersEmails, ...newProvidersEmails].length ? (
                      <div className="mb-2">
                        {existingProvidersEmails
                          .find((pe) => pe.id === provider.vendor_enterprise_id)?.emails
                          .map((email) => <li key={email}>{email}</li>)}
                        {newProvidersEmails
                          .find((npe) => npe.id === provider.vendor_enterprise_id)?.emails
                          .map((email) => (
                            <div key={email} className="d-flex align-items-center mt-1">
                              <li>{email}</li>
                              <img
                                src={trash}
                                alt="delete"
                                className="ms-auto pointer"
                                height={16}
                                onClick={deleteEmail(provider, email)}
                              />
                            </div>
                          ))}
                      </div>
                    ) : null}
                    {providers
                      .some((p) => (p.id === provider.vendor_enterprise_id && !p.emails.length))
                      ? (
                        <small className="text-danger">
                          {t('SelectedPartners.missingEmails', 'Au moins un destinataire doit être renseigné')}
                        </small>
                      ) : null}
                    <p className="font-style-semibold text-end">
                      <u
                        className="pointer"
                        onClick={() => {
                          setActiveProvider(provider);
                          setEmailsModalOpen(true);
                        }}
                      >
                        {t('SelectedPartners.addEmails', 'Ajouter un ou plusieurs destinataires')}
                      </u>
                    </p>
                  </div>
                </div>
                <Separator noPadding />
              </div>
            ))
          }
          <div className="justify-content-end">
            <AWButton
              type="button"
              className="mt-4 add-company w-25"
              label="open-modal"
              onClick={() => setNewEnterpriseModalOpen(true)}
              backgroundColor="transparent"
              color="#272829"
            >
              {t('SelectedPartners.addProvider', 'Inviter une entreprise')}
            </AWButton>
          </div>
        </AWContainer.Main>
        <AWContainer.Side>
          <Form
            onSubmit={handleSubmit(submit)}
            autoComplete="off"
            className="offer-form"
          >
            <Form.Group className="mt-3">
              <Form.Label htmlFor="offerBeginDate">
                {t('SelectedPartners.offerBeginDate', 'Date de début de la proposition')}
              </Form.Label>
              <Form.Control
                id="offerBeginDate"
                {...register('offerBeginDate', { required: true })}
                type="date"
              />
              {findErrors('offerBeginDate')}
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label htmlFor="offerEndDate">
                {t('SelectedPartners.offerEndDate', 'Date de fin de la proposition')}
              </Form.Label>
              <Form.Control
                id="offerEndDate"
                {...register('offerEndDate', { required: true })}
                type="date"
              />
              {findErrors('offerEndDate')}
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label htmlFor="comments">
                {t('SelectedPartners.comments', 'Informations complémentaires')}
              </Form.Label>
              <Form.Control
                id="comments"
                as="textarea"
                rows={3}
                {...register('comments')}
              />
              {findErrors('comments')}
            </Form.Group>
            <AWButton
              type="submit"
              className="w-50 mt-4"
              label="submit-form"
              disabled={!isValid || isLoading || providers.some((p) => !p.emails.length)}
            >
              {t('SelectedPartners.sendOffer', "Diffuser l'offre de mission")}
            </AWButton>
          </Form>
        </AWContainer.Side>
      </AWContainer>
    </>
  );
};

export default SelectedPartners;
