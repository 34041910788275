import React, {
  createContext,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Lang } from '@root/interfaces/utils.interface';

interface ILanguageContext {
  changeLanguage: (lang: Lang) => void,
}

export const LanguageContext = createContext<ILanguageContext>({
  changeLanguage: () => { },
});

const LanguageContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang: Lang) => {
    i18n.changeLanguage(
      lang,
      () => window.location.reload(),
    );
  };

  const value = useMemo(() => ({
    changeLanguage,
  }), []);

  return (
    <LanguageContext.Provider
      value={value}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
