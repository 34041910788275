import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ILabelValueList } from '@root/interfaces/labelValueList.interface';

interface Props {
  labelValues: ILabelValueList[];
}

const LabelValueList = ({ labelValues }: Props) => (
  <div>
    {labelValues.map((labelValue: ILabelValueList) => (
      <Col key={labelValue.title} className="label-value-list">
        <Row>
          <div className="label-value-list__title">
            <div>{labelValue.title}</div>
            {labelValue.subTitle ? <div>{labelValue.subTitle}</div> : null}
          </div>
        </Row>
        <hr />
        <Row>
          <div className="label-value-list__content">
            <div className="label-value-list__content__list">
              {labelValue.content.length > 1 ? labelValue.content.map((content: string) => (
                <div key={content}>
                  -
                  {' '}
                  {
                  content
                  }
                </div>
              ))
                : <div>{labelValue.content[0]}</div>}
            </div>
            {labelValue.subContent && (
            <div className="label-value-list__content__list">
              {labelValue.subContent.length > 1 ? labelValue.subContent.map((content: string) => (
                <div key={content}>
                  -
                  {' '}
                  {content}
                </div>
              ))
                : <div>{labelValue.subContent[0]}</div>}
            </div>
            )}
          </div>
        </Row>
      </Col>
    ))}
  </div>
);

export default LabelValueList;
