import i18n from '@root/locales/i18n';
import { ConnectionStep } from '@root/interfaces/connection.interface';
import { AWColors } from '@root/interfaces/utils.interface';
import { capitalize } from '@root/helpers/utils';
import { Apps } from '@root/hooks/useCurrentApp';

export const getStepStatus = (step: ConnectionStep) => {
  i18n.setDefaultNamespace(capitalize(Apps.Provider));

  if (step.validated_at) {
    return {
      label: i18n.t('Connection.validate', 'Validée'),
      color: AWColors.Green,
    };
  }
  if (step.tasks.every((task) => !!task.validated_at) && !step.validated_at) {
    return {
      label: i18n.t('Connection.pendingValidation', 'En attente de validation client'),
      color: AWColors.Orange,
    };
  }
  return {
    label: i18n.t('Status.inProgress'),
    color: AWColors.Grey,
  };
};
