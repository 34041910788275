/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Select, { components, GroupHeadingProps } from 'react-select';
import { AWColors, Option } from '@root/interfaces/utils.interface';

interface Props {
  options?: Option[],
  noNullOption?: boolean,
  placeholder?: string,
  isMulti?: boolean,
  isSearchable?: boolean,
  onChange: any,
  isDisabled?: boolean,
  group?: { label: string; options: Option[] }[],
  value?: Option | Option[],
  stayOpen?: boolean,
  menuHeight?: string,
  optionHeight?: string,
  valueMaxHeight?: string,
  height?: string,
}

const GroupHeading = (props: GroupHeadingProps) => (
  <div>
    <components.GroupHeading {...props} />
  </div>
);

const AWSelect = ({
  options,
  noNullOption,
  placeholder,
  isMulti,
  isSearchable,
  onChange,
  isDisabled,
  group,
  value,
  stayOpen,
  menuHeight,
  valueMaxHeight,
  height,
  optionHeight,
}: Props) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? `1px solid ${AWColors.Green}` : provided.border,
      '&:hover': {
        border: state.isFocused ? `1px solid ${AWColors.Green}` : provided.border,
      },
      height,
      minHeight: height,
      borderRadius: '10px',
      boxShadow: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      height: optionHeight,
      color: 'black',
      '&:hover': {
        backgroundColor: state.isSelected ? AWColors.Green : AWColors.LightGreen,
      },
      '&:active': {
        backgroundColor: AWColors.Green,
      },
      backgroundColor: state.isSelected ? AWColors.Green : 'white',
    }),
    menuList: (provided) => ({
      ...provided,
      height: menuHeight,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      width: 0,
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      border: `solid 1px ${AWColors.MediumGrey}`,
      borderRadius: '6px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: AWColors.Grey,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: AWColors.Grey,
      ':hover': {
        color: 'black',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: valueMaxHeight,
      overflow: 'auto',
    }),
  };

  const selectProps: any = {
    styles: customStyles,
    placeholder,
    isMulti,
    isSearchable,
    onChange,
    menuPlacement: 'auto',
    isDisabled,
  };
  if (group?.length) {
    selectProps.components = { GroupHeading };
    selectProps.options = noNullOption ? group : [{ label: '-', value: '' }, ...(group || [])];
  } else {
    selectProps.options = noNullOption ? options : [{ label: '-', value: '' }, ...(options || [])];
    if (value) selectProps.value = value;
    if (stayOpen || isMulti) selectProps.closeMenuOnSelect = false;
  }
  return <Select {...selectProps} />;
};

AWSelect.defaultProps = {
  placeholder: '',
  noNullOption: false,
  isMulti: false,
  isSearchable: false,
  isDisabled: false,
  group: [],
  options: [],
  value: undefined,
  stayOpen: false,
  menuHeight: '100%',
  optionHeight: '100%',
  valueMaxHeight: '100%',
  height: '100%',
};

export default AWSelect;
