import { ErrorDetail } from '@root/interfaces/error.interface';

export const SUPPORT = 'SUPPORT';
export const ENTITY_ALREADY_EXIST = 'ENTITY_ALREADY_EXIST';

/* 400 */
export const BadRequest: ErrorDetail = {
  httpStatus: 400,
  message: 'Bad request',
  code: 400.1,
};

export const UnreadableApiKey: ErrorDetail = {
  httpStatus: 400,
  message: 'Unreadable Api Key.',
  code: 400.1001,
};

export const UnreadableToken: ErrorDetail = {
  httpStatus: 400,
  message: 'Unreadable Token.',
  code: 400.1002,
};

export const UnknownVerb: ErrorDetail = {
  httpStatus: 400,
  message: 'Unknown verb.',
  code: 400.1003,
};

export const NotPermitedSort: ErrorDetail = {
  httpStatus: 400,
  message: 'Sort not permited on unselected field.',
  code: 400.1004,
};

export const AlreadyExist: ErrorDetail = {
  httpStatus: 400,
  message: ENTITY_ALREADY_EXIST,
  code: 400.2001,
};

export const ForbiddenAction: ErrorDetail = {
  httpStatus: 403,
  message: 'Forbidden action',
  code: 403.1006,
};

/* 401 */
export const Unauthorized: ErrorDetail = {
  httpStatus: 401,
  message: 'Unauthorized access',
  code: 401.1,
};

/* 403 */
export const ForbiddenApp: ErrorDetail = {
  httpStatus: 403,
  message: 'Forbidden app for current user.',
  code: 403.1001,
};

export const ForbiddenVerb: ErrorDetail = {
  httpStatus: 403,
  message: 'Forbidden verb.',
  code: 403.1002,
};

export const NoResource: ErrorDetail = {
  httpStatus: 403,
  message: 'Forbidden resource.',
  code: 403.1003,
};

export const NoScope: ErrorDetail = {
  httpStatus: 403,
  message: 'No scope found.',
  code: 403.1004,
};

export const UnauthorizedAuthenticationMode: ErrorDetail = {
  httpStatus: 403,
  message: 'Unautorized authentication mode',
  code: 403.1005,
};

/* 404 */
export const NotFound: ErrorDetail = {
  httpStatus: 404,
  message: 'Resource not found',
  code: 404.1,
};

/* 440 */
export const SessionExpired: ErrorDetail = {
  httpStatus: 440,
  message: 'Session expired',
  code: 440.1,
};

/* 500 */
export const Server: ErrorDetail = {
  httpStatus: 500,
  message: 'Server Error',
  code: 500.1,
};

export const NoDatabaseConfiguration: ErrorDetail = {
  httpStatus: 500,
  message: 'No database configured.',
  code: 500.2,
};

export const NoCatalog: ErrorDetail = {
  httpStatus: 500,
  message: 'No catalog configured.',
  code: 500.3,
};

/* 520 */
export const Unknown: ErrorDetail = {
  httpStatus: 520,
  message: 'Unknown error',
  code: 520.1,
};

export const MailProcess: ErrorDetail = {
  httpStatus: 500,
  message: 'Mail processing error',
  code: 500.4,
};

export const NeedSupport: ErrorDetail = {
  httpStatus: 500,
  message: SUPPORT,
  code: 500.1001,
};
