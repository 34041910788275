import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col } from 'react-bootstrap';
import { File as IFile } from '@root/interfaces/file.interface';
import { formatDate } from '@root/helpers/utils';
import { UserContext } from '@root/contexts/user.context';

import PunchoutQuoteForm from '@customer/components/PunchoutQuoteForm/PunchoutQuoteForm';
import { Item } from '@customer/interfaces/punchout.interface';
import { ProposalResponse } from '@customer/interfaces/proposal.interface';
import { Provider } from '@customer/interfaces/provider.interface';
import LabelValue from '@customer/components/LabelValue/LabelValue';
import PunchoutComplianceError from '@customer/components/PunchoutComplianceError/PunchoutComplianceError';
import useSafeFetch from '@root/hooks/useSafeFetch';
import { getComplianceCountConfig } from '@root/api-configs/compliance.api.config';
import { ComplianceCount } from '@root/interfaces/enterprise.interface';

interface Props {
  show: boolean;
  onCancel: () => void;
  provider: Provider;
  proposalResponse?: ProposalResponse;
  onSubmit?: (items: Item[]) => Promise<Item[]>;
  file?: IFile;
}

const ModalPunchOut = ({
  show,
  onCancel,
  onSubmit,
  provider,
  proposalResponse,
  file,
}: Props) => {
  const { t } = useTranslation();
  const [
    initComplianceCount,
    complianceCount,
  ] = useSafeFetch<ComplianceCount>(getComplianceCountConfig);
  const { user } = useContext(UserContext);

  const isProviderIsCompliant = useMemo(() => (
    complianceCount?.legal_total === complianceCount?.legal_validated
  ), [complianceCount]);

  const handleSubmit = async (items: Item[]) => { // Need to be async even if no await
    if (proposalResponse?.ends_at) {
      const newItems = items.map((item) => ({
        ...item,
        deliveryDate: formatDate(proposalResponse.ends_at, 'YYYY-MM-DD'),
      }));
      if (onSubmit) return onSubmit(newItems);
      return newItems;
    }
    return items;
  };

  const Form = useCallback(() => {
    if (provider.id) {
      return isProviderIsCompliant ? (
        <PunchoutQuoteForm
          onSubmit={handleSubmit}
          onCancel={onCancel}
          provider={provider}
        />
      ) : (
        <PunchoutComplianceError
          onCancel={onCancel}
          provider={provider}
        />
      );
    }
    return <span />;
  }, [onCancel, onSubmit, provider, isProviderIsCompliant]);

  useEffect(() => {
    if (show && user.currentEnterprise?.id && provider.id) {
      initComplianceCount({
        providerId: provider.id,
        query: { customer_id: user.currentEnterprise.id },
      });
    }
  }, [show, user.currentEnterprise?.id, provider.id]);

  return (
    <Modal
      show={show}
      animation={false}
      backdrop="static"
      centered
      size="xl"
      dialogClassName={file ? 'modal-quote__size' : ''}
    >
      <Modal.Body className={file ? 'modal-quote__body' : 'punchout-modal__body'}>
        {
          proposalResponse ? (
            <Row className="h-100">
              <Col xs={file ? 6 : 12}>
                <h2 className="text-dark mb-3">
                  {provider?.legalForm
                    ? `${provider.legalForm} - ${provider?.name}`
                    : provider?.name}
                </h2>
                <div className="modal-quote__info-container">
                  <LabelValue keyName={t('ModalWithQuote.startsat', 'Date de début possible')}>
                    <span className="provider-name">
                      {proposalResponse?.starts_at ? formatDate(proposalResponse.starts_at) : ''}
                    </span>
                  </LabelValue>
                  <LabelValue keyName={t('ModalWithQuote.endsat', 'Date de fin possible')}>
                    <span className="provider-name">
                      {proposalResponse?.ends_at ? formatDate(proposalResponse.ends_at) : ''}
                    </span>
                  </LabelValue>
                  <LabelValue keyName={t('ModalWithQuote.price', 'Prix')}>
                    <span className="provider-name">
                      {proposalResponse?.unit_price
                        ? `${proposalResponse?.unit_price}/${proposalResponse?.unit}`
                        : `${proposalResponse?.amount_before_taxes || ''}€`}
                    </span>
                  </LabelValue>
                  <LabelValue keyName={t('ModalWithQuote.description', 'Description')}>
                    <span className="provider-name">
                      {proposalResponse?.argument || '-'}
                    </span>
                  </LabelValue>
                </div>
                <Form />
              </Col>
              {
                file?.signedUrl ? (
                  <Col xs={6}>
                    <object
                      data={file.signedUrl}
                      type="application/pdf"
                      className="modal-quote__document"
                      aria-label="document pdf"
                    />
                  </Col>
                ) : ''
              }
            </Row>
          ) : (
            <Form />
          )
        }
      </Modal.Body>
    </Modal>
  );
};

ModalPunchOut.defaultProps = {
  proposalResponse: undefined,
  file: undefined,
  onSubmit: undefined,
};

export default ModalPunchOut;
