import React, { useContext, useEffect } from 'react';
import { convertModelDocumentFromStringToImg } from '@customer/views/Approvals/NewApproval/NewApprovalSteps/utils/convertHtmlToImg.service';
import { ApprovalContext } from '@customer/contexts/approval.context';
import { mimeType } from '@root/helpers/utils';

const ApprovalPreview = () => {
  const { approvalToDisplay, modelData, pdfFilePath } = useContext(ApprovalContext);
  let document = '';

  if (approvalToDisplay.mime_type === mimeType.html) {
    document = modelData;
  }
  if (approvalToDisplay.mime_type === mimeType.pdf) {
    document = pdfFilePath;
  }

  useEffect(() => {
    if (approvalToDisplay.mime_type === mimeType.html) {
      convertModelDocumentFromStringToImg(document, 'id-preview-temp-customer', 'id-preview-customer');
    }
  }, []);

  return (
    <div>
      {approvalToDisplay.mime_type === mimeType.html
        ? (
          <>
            <div
              id="id-preview-customer"
              className="approvalCustomerPreview"
            />
            <div id="id-preview-temp-customer" />
          </>
        )
        : (
          <div className="preview__pdf">
            <iframe title="pdf preview" src={pdfFilePath} />
          </div>
        )}
    </div>
  );
};

export default ApprovalPreview;
