import React, { useContext } from 'react';
import Select from 'react-select';
import { AWColors, Lang } from '@root/interfaces/utils.interface';
import { LanguageContext } from '@root/contexts/language.context';
import i18n from '@root/locales/i18n';

interface Props {
  menuPlacement: 'top' | 'bottom';
  noMargin?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
}

const languages = [
  { value: Lang.FR, label: 'Francais' },
  { value: Lang.EN, label: 'English' },
  { value: Lang.DE, label: 'Deutsch' },
];

const ChooseLanguage = ({
  menuPlacement,
  noMargin,
  onMenuOpen,
  onMenuClose,
}: Props) => {
  const { changeLanguage } = useContext(LanguageContext);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      fontSize: '12px',
      lineHeight: '17px',
      letterSpacing: '0.62px',
      textTransform: 'uppercase',
      minHeight: '100%',
      cursor: 'pointer',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      opacity: 1,
      '&:hover': {
        backgroundColor: state.isSelected ? AWColors.Green : AWColors.LightGreen,
      },
      '&:active': {
        backgroundColor: AWColors.Green,
      },
      backgroundColor: state.isSelected ? AWColors.Green : 'white',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: noMargin ? 0 : '10px',
      color: 'black',
    }),
    indicatorSeparator: () => ({
      width: 0,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: 0,
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen
        ? `${menuPlacement === 'top' ? 'rotate(-180deg)' : null}` : 'rotate(-90deg)',
    }),
  };

  return (
    <Select
      styles={customStyles}
      options={languages}
      value={languages.find(
        (l) => l.value === i18n.language || l.value.split('-')[0] === i18n.language,
      )}
      onChange={(e) => changeLanguage(e!.value as Lang)}
      isSearchable={false}
      menuPlacement={menuPlacement}
      menuPosition="fixed"
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
    />
  );
};

ChooseLanguage.defaultProps = {
  noMargin: false,
  onMenuOpen: () => { },
  onMenuClose: () => { },
};

export default ChooseLanguage;
