import React, {
  Children, useContext, useEffect, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ApprovalContext } from '@customer/contexts/approval.context';
import AWButton from '@root/components/AWButtons/AWButton';

const NavSignataires = () => {
  const { totalPages, currentPage, setCurrentPage } = useContext(ApprovalContext);
  const [pagesNumberArray, setPagesNumberArray] = useState<number[]>([]);

  function changePage(newCurrentPage: number) {
    setCurrentPage(newCurrentPage);
  }

  function previousPage() {
    changePage(currentPage - 1);
  }

  function nextPage() {
    if (totalPages && currentPage <= totalPages) {
      changePage(currentPage + 1);
    }
  }

  function optionsValuesPageNumber() {
    const numberArray: number[] = [];
    if (totalPages) {
      for (let i = 1; i <= totalPages; i += 1) {
        numberArray.push(i);
      }
      setPagesNumberArray(numberArray);
    } else {
      setPagesNumberArray([1]);
    }
  }

  function handleChange(e) {
    changePage(Number(e.target.value));
  }

  useEffect(() => {
    optionsValuesPageNumber();
  }, [totalPages]);

  return (
    <nav className="pagination pagination-container justify-content-center align-items-baseline">
      <AWButton
        className="pagination-item mr-5"
        onClick={() => previousPage()}
        aria-hidden="true"
      >
        <FontAwesomeIcon icon={faChevronLeft} className="arrow" />
      </AWButton>
      <div className="form-group c-selecet bold no-margin">
        <select name="selectPagination" id="" className="form-control" value={currentPage} onChange={handleChange}>
          {Children.toArray(
            pagesNumberArray.map((page) => (
              <option value={page}>{page}</option>
            )),
          )}
        </select>
      </div>
      <AWButton
        className="pagination-item ml-5"
        onClick={() => nextPage()}
        aria-hidden="true"
      >
        <FontAwesomeIcon icon={faChevronRight} className="arrow pagination-item" />
      </AWButton>
    </nav>
  );
};

export default NavSignataires;
