import React, { SetStateAction } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import EmailForm from '@customer/views/Providers/Partners/EmailForm';
import { MissionProvider, ProviderIdAndMail } from '@customer/interfaces/mission.interface';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  activeProvider?: MissionProvider;
  newProvidersEmails: ProviderIdAndMail[];
  existingEmails: string[];
  onSubmit: (email: string) => void;
}

const EmailsModal = ({
  isOpen,
  setIsOpen,
  activeProvider,
  newProvidersEmails,
  existingEmails,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={isOpen}
      onHide={() => setIsOpen(false)}
      centered
    >
      <Modal.Header closeButton>
        {t(
          'SelectedPartners.recipients',
          'Sélection des destinataires pour {{name}}',
          { name: activeProvider?.enterprise_name },
        )}
      </Modal.Header>
      <Modal.Body>
        {newProvidersEmails
          .find((npe) => npe.id === activeProvider?.vendor_enterprise_id)?.emails
          .map((email) => (
            <EmailForm
              key={email}
              defaultValue={email}
            />
          ))}
        <EmailForm
          onSubmit={onSubmit}
          existingEmails={existingEmails}
        />
      </Modal.Body>
    </Modal>
  );
};

EmailsModal.defaultProps = {
  activeProvider: {},
};

export default EmailsModal;
