import { handleResponse } from '@root/helpers/response-handler';
import { queryToString } from '@root/helpers/utils';

// eslint-disable-next-line import/prefer-default-export
export const acceptProviderResponse = (
  offerId: string,
  responseId: string,
  personal_token: string,
) => (
  handleResponse(
    fetch(
      `${process.env.REACT_APP_LARAVEL_URL}/support/offer/${offerId}/response/${responseId}/accept${queryToString({ personal_token })}`,
      {
        method: 'POST',
      },
    ),
  )
);
