import React, {
  ReactNode, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import AWContainer from '@root/components/AWContainer/AWContainer';
import { AlertContext } from '@root/contexts/alert.context';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { initApprovalById as initApprovalsBuilder } from '@customer/views/Approvals/approvals.builder';
import { ApprovalContext } from '@customer/contexts/approval.context';
import Tabs from '@customer/components/Tabs/Tabs';
import { TabMenu } from '@root/interfaces/menu.interface';
import { TabApprovalProviderKeyword } from '@customer/views/Approvals/ApprovalContainer/tab-approval.list';
import { getFile } from '@root/services/file.service';
import { mimeType } from '@root/helpers/utils';
import { getApprovalDocumentModelByS3Id } from '@root/services/approvalDocument.service';
import ApprovalSideContainer from '@customer/views/Approvals/ApprovalSideContainer/ApprovalSideContainer';

const ApprovalContainer = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const { setNotif } = useContext(AlertContext);
  const { waitWithLoad } = useLoadingPromise();
  const { approvalId } = useParams<{ approvalId: string }>();
  const { setApprovalToDisplay, setPdfFilePath, setModelData } = useContext(ApprovalContext);

  const tabs: TabMenu[] = [
    { key: 'infos', label: t('ApprovalsContainer.infos', 'INFOS') },
    {
      key: 'preview',
      label: t('ApprovalsContainer.previsualisation', 'PRÉVISUALISATION'),
    },
    {
      key: 'signataires',
      label: t('ApprovalsContainer.tabSignataires', 'SIGNATAIRES'),
    },
    {
      key: 'signature',
      label: t('ApprovalsContainer.tabSignatures', 'SIGNATURE'),
    },
  ];
  const [activeTab, setActiveTab] = useState<TabMenu>({ key: '', label: '' });
  let isLoadingTab = false;
  const initApprovals = async () => {
    if (approvalId && !isLoadingTab) {
      const { approval, isSessionExpired } = await waitWithLoad(
        initApprovalsBuilder(approvalId),
      );
      setApprovalToDisplay(approval);
      if (isSessionExpired) setNotif(t('Punchout.expired', 'Votre session est expirée'));
      isLoadingTab = true;
      if (approval.document_id) {
        if (approval.mime_type === mimeType.html) {
          const fileRes = await getApprovalDocumentModelByS3Id(approval.document_id);
          if (fileRes.success) {
            setModelData(fileRes.data);
          }
        }
        if (approval.mime_type === mimeType.pdf) {
          const fileRes = await getFile(approval.document_id);
          if (fileRes.success) {
            setPdfFilePath(fileRes.data.signedUrl);
          }
        }
      }
    }
  };

  const switchTab = () => {
    switch (activeTab.label) {
      case TabApprovalProviderKeyword.INFOS:
        history.push(`/customer/approvals/${approvalId}/infos`);
        break;
      case TabApprovalProviderKeyword.PREVIEW:
        history.push(`/customer/approvals/${approvalId}/previsualisation`);
        break;
      case TabApprovalProviderKeyword.SIGNATAIRES:
        history.push(`/customer/approvals/${approvalId}/signataires`);
        break;
      case TabApprovalProviderKeyword.SIGNATURE:
        history.push(`/customer/approvals/${approvalId}/signature`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    initApprovals();
  }, []);

  useEffect(
    () => {
      switchTab();
    },
    [activeTab],
  );

  return (
    <AWContainer className="approvals">
      <AWContainer.Main
        title={t('ApprovalsContainer.title', "Demande d'agrément")}
      >
        <Breadcrumb>
          <BreadcrumbItem>{t('Approvals.myActivity', 'Mon activité')}</BreadcrumbItem>
          <BreadcrumbItem href="/customer/approvals">{t('Approvals.approvals', "Demandes d'agrément")}</BreadcrumbItem>
          <BreadcrumbItem active>{t('Approvals.details', 'Détails')}</BreadcrumbItem>
        </Breadcrumb>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        <div>
          {children}
        </div>
      </AWContainer.Main>
      <AWContainer.Side>
        {
          pathname.match('/signataires')
            ? <ApprovalSideContainer />
            : ''
        }
      </AWContainer.Side>
    </AWContainer>
  );
};

export default ApprovalContainer;
