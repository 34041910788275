import {
  ApiResponse,
  safeFetch,
} from '@root/helpers/response-handler';
import { queryBoySearch } from '@root/helpers/utils';
import { UserInvitation } from '@root/interfaces/invitation.interface';
import { QueryboyOptions } from '@root/interfaces/queryboy.interface';
import { User } from '@root/interfaces/user.interface';

const { REACT_APP_USER_API } = process.env;

const baseUrl = `${REACT_APP_USER_API}/v1`;

export const getUsers = (options = {}): Promise<ApiResponse<User[]>> => safeFetch(
  `${baseUrl}/user/provider/users${queryBoySearch(options)}`,
);

export const getUser = async (id: string): Promise<ApiResponse<User>> => (
  safeFetch(`${baseUrl}/user/provider/users/${id}?fields=firstname,lastname,email`)
);

export const getEnterpriseUsers = (userId: string): Promise<ApiResponse> => safeFetch(
  `${baseUrl}/user/provider/users/${userId}/enterprises?fields=enterprise_id&page_size=1000`,
);

export const getUserEmail = (
  userId: string,
): Promise<ApiResponse<{ email: string }>> => safeFetch(
  `${baseUrl}/user/provider/users/${userId}?fields=email`,
);

export const updateUser = (
  userId: string,
  body: User,
): Promise<ApiResponse> => safeFetch(`${REACT_APP_USER_API}/v1/user/provider/users/${userId}`, {
  method: 'PUT',
  body: JSON.stringify(body),
});

export const getEnterpriseInvitations = (
  enterpriseId: string,
  options: QueryboyOptions = {
    fields: 'id,created_at,expired_at,email,status',
    search: {
      enterprise_host_id: enterpriseId,
      type: 1, // external
    },
    page_size: 100,
  },
): Promise<ApiResponse<UserInvitation[]>> => (
  safeFetch(`${baseUrl}/invitations${queryBoySearch(options)}`)
);
