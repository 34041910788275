import {
  createACL,
  createUser,
} from '@user/services/user.service';
import { BadRequest } from '@root/helpers/catalog.error';
import { SessionUser } from '@root/interfaces/cognito.interface';
import { newDate } from '@root/helpers/utils';

export const createUserV1 = async (user: SessionUser) => {
  const aclResponse = await createACL(user.email || '');
  if (aclResponse.success || aclResponse.hasError(BadRequest)) {
    const userResponse = await createUser({
      firstname: user.firstname,
      lastname: user.lastname,
      gender: user.gender,
      phoneNumber: user.phoneNumber,
      password: user.password,
      email: user.email,
      is_confirmed: true,
      last_login: newDate(),
    });
    return userResponse;
  }
  return aclResponse;
};
