import i18next, { ResourceLanguage, ResourceKey } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { mergeWith, isObject } from 'lodash';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationDE from '@root/locales/de';
import translationEN from '@root/locales/en';
import translationFR from '@root/locales/fr';
import globalTranslationDE from '@root/locales/global/de.json';
import globalTranslationEN from '@root/locales/global/en.json';
import globalTranslationFR from '@root/locales/global/fr.json';
import { Apps } from '@root/hooks/useCurrentApp';

const mergeTranslations = (translation1: ResourceKey, translation2: ResourceKey) => (
  // eslint-disable-next-line consistent-return
  mergeWith({}, translation1, translation2, (obj1, obj2) => {
    if (isObject(obj1)) {
      return { ...obj1, ...obj2 };
    }
  })
);

const getTranslation = (translation: ResourceLanguage, globalTranslation: ResourceLanguage) => (
  {
    Provider: mergeTranslations(translation.Provider, globalTranslation),
    Customer: mergeTranslations(translation.Customer, globalTranslation),
    User: mergeTranslations(translation.User, globalTranslation),
  }
);

const i18n = i18next.createInstance();

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'lang',
    },
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      de: getTranslation(translationDE, globalTranslationDE),
      fr: getTranslation(translationFR, globalTranslationFR),
      en: getTranslation(translationEN, globalTranslationEN),
    },
    ns: Object.keys(Apps),
  });

export default i18n;
