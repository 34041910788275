/* eslint-disable react/no-unknown-property */
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UserContext } from '@root/contexts/user.context';
import AWButton from '@root/components/AWButtons/AWButton';
import {
  getProposalResponse as getProposalResponseService,
  getEnterprises,
} from '@customer/services/enterprise.service';
import { getLegalForms } from '@root/services/enterprise.service';
import { ProposalResponse } from '@customer/interfaces/proposal.interface';
import { Mission as Offer } from '@root/interfaces/mission.interface';
import { Provider } from '@customer/interfaces/provider.interface';
import { File } from '@root/interfaces/file.interface';
import { getFile } from '@root/services/file.service';
import ModalPunchOut from '@customer/components/ModalPunchOut/ModalPunchOut';
import { acceptProviderResponse } from '@customer/services/v1Api.service';
import { getMissionOffer } from '@customer/services/mission.service';
import Frame from '@root/components/Frame/Frame';
import { Item } from '@customer/interfaces/punchout.interface';

const OfferStatus = {
  Closed: 'closed',
};

const Proposal = () => {
  const { t } = useTranslation();
  const { tokenPO, user } = useContext(UserContext);
  const [providerId, setProviderId] = useState<string>();
  const [provider, setProvider] = useState<Provider>();
  const [proposalResponse, setProposalResponse] = useState<ProposalResponse>();
  const [offer, setOffer] = useState<Offer>();
  const [file, setFile] = useState<File>();
  const { responseId, offerId } = useParams<{ responseId: string, offerId: string }>();

  const [showPunchOutModal, setShowPunchOutModal] = useState(false);

  const getProposalResponse = async () => {
    const [proposalResponseApi, offerApi] = await Promise.all([
      getProposalResponseService(
        responseId,
        {
          fields: [
            'id',
            'starts_at',
            'ends_at',
            'unit_price',
            'quantity',
            'unit',
            'argument',
            'file_id',
            'enterprise_id',
            'amount_before_taxes',
            'offer_id',
          ],
        },
      ),
      getMissionOffer(
        offerId,
        {
          fields: 'id,status',
        },
      ),
    ]);
    if (proposalResponseApi.success && proposalResponseApi.data) {
      setProposalResponse(proposalResponseApi.data);
      setProviderId(proposalResponseApi.data.enterprise_id);
      if (proposalResponseApi.data.file_id) {
        const fileRes = await getFile(proposalResponseApi.data.file_id);
        if (fileRes.success && fileRes.data) {
          setFile(fileRes.data);
        }
      }
    }
    if (offerApi.success) {
      setOffer(offerApi.data);
    }
  };

  useEffect(() => {
    getProposalResponse();
  }, [responseId, offerId]);

  const getProviderInfo = async (id) => {
    const providerRes = await getEnterprises({
      fields: 'id,name,legal_form_id',
      search: {
        id,
      },
    });
    if (providerRes.success && providerRes.data?.length) {
      setProvider(providerRes.data[0]);
      const legalFormRes = await getLegalForms({
        fields: 'display_name',
        search: {
          id: providerRes.data[0].legal_form_id,
        },
      });
      if (legalFormRes.success && legalFormRes.data?.length) {
        const [_provider] = providerRes.data;
        setProvider({
          id: _provider.id,
          name: _provider.name,
          legalForm: legalFormRes.data[0].display_name,
        });
      }
    }
  };

  useEffect(() => {
    if (providerId) {
      getProviderInfo(providerId);
    }
  }, [providerId]);

  const handleModal = (isOpen: boolean) => () => {
    setShowPunchOutModal(isOpen);
  };

  const acceptProvider = async (items: Item[]) => {
    if (proposalResponse?.id) {
      const res = await acceptProviderResponse(
        proposalResponse.offer_id,
        proposalResponse.id,
        user.v1token!,
      );
      return res.success ? items : [];
    }
    return [];
  };

  return (
    <div className="proposal-response">
      {provider ? (
        <ModalPunchOut
          show={showPunchOutModal}
          onCancel={handleModal(false)}
          proposalResponse={proposalResponse}
          onSubmit={acceptProvider}
          file={file}
          provider={provider}
        />
      ) : ''}
      <Frame title="v1" className="h-100 w-100" />
      {
        offer?.status !== OfferStatus.Closed ? (
          <div className="proposal-response__btn-choice">
            <AWButton
              onClick={handleModal(true)}
              className={!tokenPO ? 'invisible' : ''}
            >
              {t('Provider.chooseProvider', 'Choisir ce sous-traitant')}
            </AWButton>
          </div>
        ) : ''
      }
    </div>
  );
};

export default Proposal;
