import React, {
  ReactNode, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { initApprovalById as initApprovalsBuilder } from '@provider/views/Approvals/approvals.provider.builder';
import Tabs from '@provider/components/Tabs/Tabs';
import AWContainer from '@root/components/AWContainer/AWContainer';
import { AlertContext } from '@root/contexts/alert.context';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { useHistory, useParams } from 'react-router-dom';

import { ApprovalProviderContext } from '@provider/contexts/approvalProvider.context';
import { getFile } from '@root/services/file.service';
import { mimeType } from '@root/helpers/utils';
import { getApprovalDocumentModelByS3Id } from '@root/services/approvalDocument.service';
import { TabApprovalProviderKeyword } from '../tab-approval.list';

const ApprovalContainer = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { setNotif } = useContext(AlertContext);
  const { waitWithLoad } = useLoadingPromise();
  const { approvalId } = useParams<{ approvalId: string }>();
  const {
    setPdfFilePath,
    setApprovalToDisplay,
    setModelSelected,
  } = useContext(ApprovalProviderContext);

  const tabs: string[] = [
    t('ApprovalsContainer.infos', 'INFOS'),
    t('ApprovalsContainer.previsualisation', 'PRÉVISUALISATION'),
    t('ApprovalsContainer.variables', 'VARIABLES'),
    t('ApprovalsContainer.waitingMySignature', 'EN ATTENTE DE MA SIGNATURE'),
  ];
  const [activeTab, setActiveTab] = useState<string>('');
  let isLoadingTab = false;
  const initApprovals = async () => {
    if (approvalId && !isLoadingTab) {
      const { approval, isSessionExpired } = await waitWithLoad(
        initApprovalsBuilder(approvalId),
      );
      setApprovalToDisplay(approval);
      if (isSessionExpired) setNotif(t('Punchout.expired', 'Votre session est expirée'));
      isLoadingTab = true;
      if (approval.document_id) {
        if (approval.mime_type === mimeType.html) {
          const fileRes = await getApprovalDocumentModelByS3Id(approval.document_id);
          if (fileRes.success) {
            setModelSelected(fileRes.data);
          }
        }
        if (approval.mime_type === mimeType.pdf) {
          const fileRes = await getFile(approval.document_id);
          if (fileRes.success) {
            setPdfFilePath(fileRes.data.signedUrl);
          }
        }
      }
    }
  };

  const switchTab = () => {
    switch (activeTab) {
      case TabApprovalProviderKeyword.INFOS:
        history.push(`/provider/approvals/${approvalId}/infos`);
        break;
      case TabApprovalProviderKeyword.PREVIEW:
        history.push(`/provider/approvals/${approvalId}/previsualisation`);
        break;
      case TabApprovalProviderKeyword.VARIABLES:
        history.push(`/provider/approvals/${approvalId}/variables`);
        break;
      case TabApprovalProviderKeyword.SIGNATURE:
        history.push(`/provider/approvals/${approvalId}/signature`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    initApprovals();
  }, []);

  useEffect(
    () => {
      switchTab();
    },
    [activeTab],
  );

  return (
    <AWContainer className="approvals">
      <AWContainer.Main
        title={t('ApprovalsContainer.title', "Demande d'agrément")}
      >
        <Breadcrumb>
          <BreadcrumbItem>{t('Approvals.myActivity', 'Mon activité')}</BreadcrumbItem>
          <BreadcrumbItem href="/provider/approvals">{t('Approvals.approvals', "Demandes d'agrément")}</BreadcrumbItem>
          <BreadcrumbItem active>{t('Approvals.details', 'Détails')}</BreadcrumbItem>
        </Breadcrumb>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        <div>
          {children}
        </div>
      </AWContainer.Main>
      <AWContainer.Side />
    </AWContainer>
  );
};

export default ApprovalContainer;
