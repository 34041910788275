import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@root/helpers/utils';
import { ConnectionTask } from '@root/interfaces/connection.interface';
import AWLongCard from '@root/components/Cards/AWLongCard/AWLongCard';
import { Col, Row } from 'react-bootstrap';

interface Props {
  task: ConnectionTask,
  className?: string,
  actionText?: string,
  onClick?: () => void,
}

const CardConnectionTask = ({
  task,
  className,
  actionText,
  onClick,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div data-for={task.id} data-tip>
      <AWLongCard
        onClick={onClick}
        borderColor={task.color}
        icon={task.icon || ''}
        className={className}
        disabled={task.disabled}
      >
        <Row>
          <Col xs="9" className="d-flex flex-column justify-content-between">
            <p className="font-style-semibold font-size-15">
              {task.title || '-'}
            </p>
            <p className="text-grey my-1">
              {`${t('ConnectionTask.updatedAt', 'Mis à jour le')} `}
              <span className="text-dark">
                {task.updatedAt ? formatDate(task?.updatedAt) : '-'}
              </span>
              {task.updatedAt && task.updatedBy ? (
                <>
                  {` ${t('Common.by', 'par')} `}
                  <span className="text-dark">
                    {task.updatedBy}
                  </span>
                </>
              ) : <> </>}
            </p>
            <p className="text-grey">
              {`${t('ConnectionTask.status', 'Statut')}: `}
              <span>{task.status}</span>
            </p>
          </Col>
          {
            !task.disabled ? (
              <Col xs="3" className="d-flex align-items-center justify-content-end">
                <p className="font-style-semibold font-size-15">
                  {actionText}
                </p>
              </Col>
            ) : <> </>
          }
        </Row>
      </AWLongCard>
    </div>
  );
};

CardConnectionTask.defaultProps = {
  className: '',
  onClick: () => { },
  actionText: '',
};

export default CardConnectionTask;
