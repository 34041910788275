import React from 'react';
import ErrorInput from '@provider/components/ErrorInput/ErrorInput';
import { AWColors } from '@root/interfaces/utils.interface';
import {
  Col,
  Form,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AWButton from '@root/components/AWButtons/AWButton';

interface Props {
  onSubmit: () => void;
  onChange: (job: string) => void;
  enterpriseName: string;
  onReturn?: () => void;
  loading?: boolean;
  disabled?: boolean;
  error?: boolean
}

const JobForm = ({
  onSubmit,
  onChange,
  onReturn,
  error,
  enterpriseName,
  disabled,
  loading,
}: Props) => {
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  const handleChange = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <Form
      className="h-100 w-50 mx-auto d-flex flex-column justify-content-center"
      onSubmit={handleSubmit}
    >
      <div className="highlight-text--uppercased text-center font-size-40 mb-4">
        {`${t('Role.almostDone', 'Vous y êtes presque')} !`}
      </div>
      <Form.Group>
        <p className="mb-4 text-center">
          {`${t(
            'Role.provideRole',
            `Afin de terminer l'enregistrement de votre entreprise,
               veuillez renseigner votre rôle au sein de `,
          )}`}
          <span className="font-style-bold text-dark text-uppercase">
            {enterpriseName}
          </span>
        </p>
        <Form.Control
          onChange={handleChange}
          placeholder={t('Role.examples', 'PDG, DG, Responsable achats, Directeur commercial ...')}
        />
        <ErrorInput
          error={!!error}
          message={t(
            'Role.commonError',
            'Une erreur est survenue, veuillez réessayer plus tard',
          )}
          type="form"
        />
      </Form.Group>
      <Row className="mt-4 justify-content-center">
        {onReturn ? (
          <Col md="4">
            <AWButton
              backgroundColor="transparent"
              className="border"
              color={AWColors.Grey}
              type="button"
              onClick={onReturn}
            >
              {t('Action.return')}
            </AWButton>
          </Col>
        ) : ''}
        <Col md={onReturn ? 4 : 8}>
          <AWButton
            disabled={disabled}
          >
            {
              loading ? (
                <Spinner animation="border" variant="white" size="sm" />
              ) : (
                t('Action.validate')
              )
            }
          </AWButton>
        </Col>
      </Row>
    </Form>
  );
};

JobForm.defaultProps = {
  error: false,
  disabled: false,
  loading: false,
  onReturn: undefined,
};

export default JobForm;
