import React from 'react';
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import Header from '@provider/components/Header/Header';
import SidePic from '@provider/components/SidePic/SidePic';

interface Props {
  children: React.ReactNode,
  image: string,
  headerMenu?: boolean,
  title?: string,
  description?: string,
}

const ProcessContainer = ({
  children,
  image,
  headerMenu,
  title,
  description,
}: Props) => (
  <Container fluid className="bg-gradient">
    <Row>
      <Col lg={8} xs={12}>
        <Header headerMenu={headerMenu} />
        <Container>
          {!!title && (
            <Row className="justify-content-center mt-5">
              <Col lg="8">
                <h1>
                  {title}
                </h1>
              </Col>
            </Row>
          )}
          <Row className="content-box">
            <Col lg="8">
              <p>
                {description}
              </p>
              {children}
            </Col>
          </Row>
        </Container>
      </Col>
      <Col lg={4} className=" p-0 d-none d-lg-block ">
        <SidePic asset={image} />
      </Col>
    </Row>
  </Container>
);

ProcessContainer.defaultProps = {
  headerMenu: true,
  title: '',
  description: '',
};

export default ProcessContainer;
