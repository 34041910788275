import React, { useContext } from 'react';
import { ADDWORKING_SUPPORT } from '@root/helpers/constants.helper';
import { useTranslation } from 'react-i18next';
import EnterpriseList from '@root/components/EnterpriseCreation/Pappers/EnterpriseList';
import { EnterpriseCreationContext } from '@root/contexts/enterpriseCreation.context';
import { Spinner } from 'react-bootstrap';

interface Props {
  notFoundText: string;
}

const PappersSearchResult = ({
  notFoundText,
}: Props) => {
  const { t } = useTranslation();
  const {
    isManual,
    setIsManual,
    searchEstablishments,
    enterprisePappers,
    isPappersError,
    papperLoading,
  } = useContext(EnterpriseCreationContext);

  return (
    <div>
      {
        papperLoading ? (
          <div className="vh-90 d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        ) : ''
      }
      {!papperLoading && !isPappersError ? (
        <>
          {enterprisePappers?.length && !isManual ? (
            <EnterpriseList
              enterprises={enterprisePappers}
              onClick={searchEstablishments}
            />
          ) : ''}
          {
            enterprisePappers && !enterprisePappers?.length && !isManual ? (
              <p className="mt-auto mb-0">
                {notFoundText}
                <span
                  onClick={() => setIsManual(true)}
                  aria-hidden="true"
                  className="text-dark pointer"
                >
                  <u>{t('CreateEnterprise.addManually', 'ajoutez-la manuellement')}</u>
                </span>
              </p>
            ) : ''
          }
        </>
      ) : ''}
      {
        !papperLoading && isPappersError ? (
          <p className="mt-auto mb-0">
            {`${t(
              'Error.UnknownError',
              'Une erreur est survenue',
            )} ${t(
              'Error.TryLater',
              'Veuillez réessayer plus tard',
            )} ${t(
              'Common.or',
              'ou',
            )} `}
            <a
              href={ADDWORKING_SUPPORT}
              target="_blank"
              rel="noreferrer"
              className="text-dark"
            >
              <u>{`${t('Error.ContactSupport', 'contacter notre support')}.`}</u>
            </a>
          </p>
        ) : ''
      }
    </div>
  );
};

export default PappersSearchResult;
