import i18n from '@root/locales/i18n';
import { cloneDeep } from 'lodash';
import { Enterprise } from '@root/interfaces/enterprise.interface';
import { NotFound } from '@root/helpers/catalog.error';
import { AWColors } from '@root/interfaces/utils.interface';
import { ApiResponse } from '@root/helpers/response-handler';

import {
  CreateConnectionParams,
  ConnectionStep,
  ConnectionStepStatus,
  Connection,
  InvitationRes,
} from '@root/interfaces/connection.interface';
import { UserInvitation } from '@root/interfaces/invitation.interface';
import { SessionUser } from '@root/interfaces/cognito.interface';
import { CallbackSafeFetch } from '@root/hooks/useSafeFetch';
import { createConnectionConfig } from '@root/api-configs/connection.api.config';
import { createEnterpriseConfig, getEnterpriseConfig } from '@root/api-configs/enterprise.api.config';
import { createInvitationConfig } from '@root/api-configs/invitation.api.config';
import { EMAIL_REGEX } from '@root/helpers/patterns';

const getEnterpriseAndCreateConnection = async (
  callbackApi: CallbackSafeFetch,
  connection: CreateConnectionParams,
  enterprise: Enterprise,
): Promise<ApiResponse<Connection | Enterprise>> => {
  const newConnection: CreateConnectionParams = cloneDeep(connection);
  const providerIdRes = await callbackApi(
    getEnterpriseConfig,
    { identifier: enterprise.identification_number },
    { fields: 'id' },
  );
  if (providerIdRes.success) {
    newConnection.provider_id = providerIdRes.data.id as string;
    const newConnectionRes = await callbackApi(createConnectionConfig, { body: newConnection });
    return newConnectionRes;
  }
  return providerIdRes;
};

export const createConnection = async (
  callbackApi: CallbackSafeFetch,
  connection: CreateConnectionParams,
  enterprise: Enterprise,
): Promise<ApiResponse<Connection | Enterprise>> => {
  const res = await getEnterpriseAndCreateConnection(callbackApi, connection, enterprise);
  if (res.hasError(NotFound)) {
    const newEnterpriseRes = await callbackApi(createEnterpriseConfig, { body: enterprise });
    if (newEnterpriseRes.success) {
      const withNewEnterpriseRes = await getEnterpriseAndCreateConnection(
        callbackApi,
        connection,
        enterprise,
      );
      return withNewEnterpriseRes;
    }
  }
  return res;
};

export const getStepStatus = (step: ConnectionStep) => {
  if (step.status === ConnectionStepStatus.Validated && step.validated_at) {
    return {
      label: i18n.t('Connection.fvalidated', 'Validée'),
      color: AWColors.Green,
    };
  }
  if (step.status === ConnectionStepStatus.Rejected && step.refused_at) {
    return {
      label: i18n.t('Status.refused'),
      color: AWColors.Red,
    };
  }
  if (step.tasks.every((task) => !!task.validated_at)
    && step.status === ConnectionStepStatus.Pending) {
    return {
      label: i18n.t('Connection.pendingValidation', 'En attente de validation'),
      color: AWColors.Orange,
    };
  }
  return {
    label: i18n.t('Status.inProgress'),
    color: AWColors.Grey,
  };
};

export const sendConnectionInvitations = async (
  callbackApi: CallbackSafeFetch,
  {
    emails, user, enterpriseId,
  }: {
    emails: string[],
    user: SessionUser,
    enterpriseId: string,
  },
) => {
  const invitationPromises = emails.reduce(
    (acc: Promise<ApiResponse<InvitationRes>>[], email: string) => {
      if (email.match(EMAIL_REGEX)) {
        const invitation: UserInvitation = {
          email,
          type: 'external',
          status: 'pending',
          enterprise_host_id: user.currentEnterprise?.id,
          user_host_id: user.id,
          enterprise_guest_id: enterpriseId,
        };
        acc.push(callbackApi(createInvitationConfig, { body: invitation }));
      }
      return acc;
    },
    [],
  );
  const invitationsRes = await Promise.all(invitationPromises);
  return invitationsRes;
};
