import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import SignupForm from '@user/components/SignupForm/SignupForm';
import logo from '@root/assets/addworking-logo.svg';
import welcome_fr from '@root/assets/welcome-fr.svg';
import welcome_en from '@root/assets/welcome-en.svg';
import welcome_de from '@root/assets/welcome-de.svg';
import ChooseLanguage from '@root/components/ChooseLanguage/ChooseLanguage';
import { Lang } from '@root/interfaces/utils.interface';

const Signup = () => {
  const { i18n, t } = useTranslation();

  const getWelcomeImage = () => {
    switch (i18n.language) {
      case Lang.DE || 'de':
        return welcome_de;
      case Lang.FR || 'fr':
        return welcome_fr;
      default:
        return welcome_en;
    }
  };

  return (
    <Container fluid>
      <Row className="min-vh-100">
        <Col xl="6" className="signup__register p-5">
          <div className="signup__language">
            <ChooseLanguage menuPlacement="bottom" />
          </div>
          <img
            alt="AddWorking"
            src={logo}
            width="40%"
          />
          <h1 className="mt-5">
            {t(
              'UserInfo.title',
              'Rejoindre le réseau AddWorking',
            )}
          </h1>
          <p className="mb-4">
            {t(
              'UserInfo.helper1',
              `Vous êtez sous-traitant ? Invité par un de vos clients
              ou simplement envie d'étendre votre réseau et de
              vous ouvrir à de nouvelles missions ?`,
            )}
            <br />
            {t('UserInfo.helper2', "Vous êtes au bon endroit. L'inscription est ")}
            <span className="font-style-bold">
              {t('UserInfo.helper3', ' simple, rapide et gratuite.')}
            </span>
          </p>
          <SignupForm />
        </Col>
        <Col xl="6" className="signup__info p-5">
          <h1 className="text-center">
            {t('Signup.title-explanation', 'Vos projets de sous traitance commencent ici')}
          </h1>
          <img
            src={getWelcomeImage()}
            alt="diagram-addworking"
            width="90%"
          />
          <Row className="mt-3">
            <Col md="6" className="text-end border-end">
              <div className="me-3">
                <h1>
                  {t('Signup.customer', "Donneurs d'ordre")}
                </h1>
                <div className="color-grey mt-3">
                  <p className="mb-3">
                    {t('Signup.customer-item1', 'Enrichissez votre réseau de sous-traitants')}
                  </p>
                  <p className="mb-3">
                    {t('Signup.customer-item2', 'Maîtrisez les coûts cachés')}
                  </p>
                  <p className="mb-3">
                    {t('Signup.customer-item3', 'limitez les risques juridiques et financiers')}
                  </p>
                  <p className="mb-3">
                    {t('Signup.customer-item4', "Pilotez une politique d'achats responsables")}
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="ms-3">
                <h1>
                  {t('Signup.provider', 'Sous-traitants')}
                </h1>
                <div className="color-grey">
                  <p className="mb-3">
                    {t('Signup.provider-item1', 'Réduisez votre travail administratif')}
                  </p>
                  <p className="mb-3">
                    {t('Signup.provider-item2', 'Trouvez de nouvelles missions, des clients')}
                  </p>
                  <p className="mb-3">
                    {t('Signup.provider-item3', 'Gagnez en visibilité chez vos clients')}
                  </p>
                  <p className="mb-3">
                    {t('Signup.provider-item4', 'Fiabilisez les détails de paiement')}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Signup;
