import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';

import { UserContext } from '@root/contexts/user.context';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { AWColors } from '@root/interfaces/utils.interface';

import { createPunchoutLog } from '@customer/services/punchout.service';
import { PunchoutLogLabel } from '@customer/interfaces/punchout.interface';
import { Provider } from '@customer/interfaces/provider.interface';
import AWButton from '@root/components/AWButtons/AWButton';

interface Props {
  onCancel: () => void;
  provider: Provider;
}

const PunchoutComplianceError = ({ onCancel, provider }: Props) => {
  const { t } = useTranslation();
  const { tokenPO, user } = useContext(UserContext);
  const { isLoading, waitWithLoad } = useLoadingPromise();

  const writePunchoutLog = async (label: `${PunchoutLogLabel}`) => {
    if (user?.id && user?.email) {
      await createPunchoutLog({
        userId: user.id,
        userEmail: user.email,
        customerId: user.currentEnterprise?.id || '',
        customerName: user.currentEnterprise?.name || '',
        providerId: provider.id || '',
        providerName: provider.name || '',
        sessionToken: tokenPO,
        label,
      });
    }
  };

  const handleSupportRequest = async (label: `${PunchoutLogLabel}`) => {
    await waitWithLoad(writePunchoutLog(label));
    onCancel();
  };

  useEffect(() => {
    (async () => {
      await waitWithLoad(writePunchoutLog(PunchoutLogLabel.NotConform));
    })();
  }, []);

  return (
    <div>
      <span className="space-translation">
        {t(
          'Punchout.complianceNok',
          "Ce sous-traitant n'est pas conforme au regard des documents légaux et métiers exigés.\nSi vous souhaitez lui affecter une mission, ce sous-traitant doit se mettre à jour, ce qui débloquera la possibilité de lui affecter une mission.",
        )}
      </span>
      <p className="my-4 font-size-16">
        {t(
          'Provider.isSupportConformityCheck',
          "Souhaitez-vous qu'Addworking valide la conformité de cette entreprise ?",
        )}
      </p>
      <Row className="justify-content-around">
        <Col xs="3">
          <AWButton
            type="button"
            onClick={() => handleSupportRequest(PunchoutLogLabel.RefuseConfo)}
            disabled={isLoading}
            backgroundColor={AWColors.LightGrey}
            color="black"
          >
            {t('Common.no', 'Non')}
          </AWButton>
        </Col>
        <Col xs="3">
          <AWButton
            type="button"
            disabled={isLoading}
            onClick={() => handleSupportRequest(PunchoutLogLabel.RequireConfo)}
          >
            {t('Common.yes', 'Oui')}
          </AWButton>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 4, offset: 4 }} className="justify-content-center mt-3">
          <AWButton
            type="button"
            className="border"
            backgroundColor="#ACACAC"
            color="white"
            onClick={onCancel}
            noFlex
          >
            {t('Action.cancel')}
          </AWButton>
        </Col>
      </Row>
    </div>
  );
};

export default PunchoutComplianceError;
