import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Col, Row, Spinner } from 'react-bootstrap';

import EnterpriseCreation from '@root/components/EnterpriseCreation/EnterpriseCreation';
import AWLinkButton from '@root/components/AWButtons/AWLinkButton';
import AddContact, { getBlanckContact } from '@customer/views/Connections/Contacts/AddContact';
import {
  Connection,
  ConnectionConfig,
  ConnectionContact,
  CreateConnectionParams,
  InvitationResError,
} from '@root/interfaces/connection.interface';
import { UserContext } from '@root/contexts/user.context';
import {
  defaultCountries,
  EnterpriseCreationContext,
} from '@root/contexts/enterpriseCreation.context';
import useSafeFetch, { useSafeFetchCallback } from '@root/hooks/useSafeFetch';
import { getConnectionConfigsConfig } from '@root/api-configs/connection.api.config';
import { createConnection, sendConnectionInvitations } from '@customer/helpers/connection.helper';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { Enterprise } from '@root/interfaces/enterprise.interface';
import {
  buildNotifFromInvitations,
  buildConnectionFailedMessage,
} from '@customer/views/Connections/connections.builder';
import { AlertContext } from '@root/contexts/alert.context';
import { AlreadyExist } from '@root/helpers/catalog.error';
import AWSelect from '@root/components/AWSelect/AWSelect';
import { blankOption, CountryCode, Option } from '@root/interfaces/utils.interface';

enum Steps {
  CreateEnterprise = 'create-enterprise',
  Contacts = 'contacts',
}

const NewConnection = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState<Steps>(Steps.CreateEnterprise);
  const [contacts, setContacts] = useState<ConnectionContact[]>([getBlanckContact()]);
  const [configId, setConfigId] = useState<string>();
  const { user } = useContext(UserContext);
  const {
    enterprise,
    resetEnterprise,
    setIsManual,
    setCountries,
  } = useContext(EnterpriseCreationContext);
  const { waitWithLoad } = useLoadingPromise();
  const { setNotif } = useContext(AlertContext);

  const [
    initConfigs,
    _configs,
    configsLoading,
  ] = useSafeFetch<ConnectionConfig[]>(getConnectionConfigsConfig, []);
  const createConnectionService = useSafeFetchCallback(createConnection);
  const sendConnectionInvitationsService = useSafeFetchCallback(sendConnectionInvitations);

  const configs = useMemo(() => (
    _configs?.filter((c) => c.customer_id === user.currentEnterprise?.id) || []
  ), [_configs, user.currentEnterprise?.id]);

  const configsOptions = useMemo(() => (
    configs
      ?.map((c) => ({ label: c.title, value: c.id })) || []
  ), [configs]);

  const resetView = () => {
    resetEnterprise();
    setStep(Steps.CreateEnterprise);
    setContacts([getBlanckContact()]);
    setConfigId(undefined);
    setIsManual(false);
    setCountries(defaultCountries);
  };

  useEffect(() => {
    initConfigs({}, {
      fields: 'id,title,customer_id',
      search: {
        status: 2, // Validated
      },
      page_size: 50,
    });
    return () => {
      resetView();
    };
  }, []);

  useEffect(() => {
    if (user.currentEnterprise?.country === CountryCode.Fr) {
      setCountries((c) => ({ ...c, [CountryCode.Be]: false, [CountryCode.De]: false }));
    } else {
      setCountries((c) => ({ ...c, [CountryCode.Be]: true, [CountryCode.De]: true }));
    }
  }, [user.currentEnterprise?.country]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.currentEnterprise?.id) {
      const [emails, phone_numbers] = contacts.reduce((
        acc: [string[], string[]],
        { email, phone_number }: ConnectionContact,
      ) => {
        acc[0].push(email);
        acc[1].push(phone_number || '');
        return acc;
      }, [[], []]);
      const newConnection: CreateConnectionParams = {
        provider_id: '',
        config_id: configId,
        customer_id: user.currentEnterprise.id,
        phone_numbers,
        emails,
      };
      const connectionRes = await waitWithLoad(
        createConnectionService(newConnection, enterprise),
      );
      if (connectionRes.success && connectionRes.data && emails) {
        const guest_id = (connectionRes.data as Connection).provider_id
          || (connectionRes.data as Enterprise).id;
        if (guest_id) {
          const invitationsRes = await sendConnectionInvitationsService({
            emails: map(contacts, 'email'),
            user,
            enterpriseId: guest_id,
          });
          if (invitationsRes.every(
            (i) => i.success || i.details.code === InvitationResError.UserAlreadyMember,
          )) {
            setNotif(buildNotifFromInvitations(invitationsRes));
          }
        }
      } else if (connectionRes.hasError(AlreadyExist) && enterprise.name) {
        const connectionFailedMessage = buildConnectionFailedMessage(
          connectionRes.details.code,
          enterprise.name,
        );
        if (connectionFailedMessage) {
          setNotif(connectionFailedMessage);
        }
      }
      resetView();
    }
  };

  const handleConfigId = (opt: Option) => {
    setConfigId(opt.value);
  };

  const currentView = () => {
    if (step === Steps.CreateEnterprise) {
      return (
        <EnterpriseCreation
          title={t('NewConnection.title', 'Inviter un sous-traitant')}
          titleSide={(
            <div onClick={() => { resetView(); }}>
              <AWLinkButton
                href="/customer/connections"
                className="bg-white text-dark"
              >
                {t('NewConnection.backToConnections', 'Retour aux mises en relation')}
              </AWLinkButton>
            </div>
          )}
          onSubmit={() => setStep(Steps.Contacts)}
        >
          {
            configsLoading ? (
              <div className="mt-3 mb-3 ml-3">
                <Spinner />
              </div>
            ) : ''
          }
          {
            !configsLoading && configs?.length ? (
              <Row className="mb-3">
                <Col md={6} xs={12}>
                  <h2 className="text-dark font-style-regular font-size-20 mt-2 mb-3">
                    {t('InviteEnterpriseForm.config', 'Processus de mise en relation')}
                  </h2>
                  <AWSelect
                    options={configsOptions}
                    value={configsOptions.find((c) => c.value === configId) || blankOption}
                    onChange={handleConfigId}
                  />
                </Col>
              </Row>
            ) : ''
          }
        </EnterpriseCreation>
      );
    }
    if (step === Steps.Contacts) {
      return (
        <AddContact
          contacts={contacts}
          setContacts={setContacts}
          onSubmit={handleSubmit}
        />
      );
    }
    return <span />;
  };

  return currentView();
};

export default NewConnection;
