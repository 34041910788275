/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AWButton from '@root/components/AWButtons/AWButton';
import InfoMessage from '@root/components/InfoMessage/InfoMessage';
import useCognito from '@root/hooks/useCognito';
import { AWColors } from '@root/interfaces/utils.interface';

interface Props {
  onCancel: () => void
}

const PasswordForm = ({ onCancel }: Props) => {
  const { t } = useTranslation();
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const { updatePassword, message, setMessage } = useCognito();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const findErrors = (value: string) => {
    let error = null;
    if ((errors as any)[value]?.type === 'required') {
      error = t('Errors.missingField', 'Merci de remplir ce champ');
    }
    return error ? <InfoMessage message={error} color={AWColors.Red} /> : null;
  };

  const onSubmit = async (data) => {
    if (data.password !== data.passwordConfirmation) {
      setIsPasswordMatch(false);
    } else {
      setIsPasswordMatch(true);
      updatePassword(data);
      setMessage({ text: t('Success.saveChanges'), color: AWColors.Green });
    }
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <Row className="mb-4">
        <Col lg={{ span: 6, offset: 3 }} md={{ span: 10, offset: 1 }} className="mb-2">
          <Form.Group>
            <Form.Label htmlFor="oldpassword">
              {t('ModifyProfile.oldpassword', 'Ancien mot de passe')}
            </Form.Label>
            <Form.Control
              id="oldpassword"
              type="password"
              placeholder="••••••••"
              autoComplete="new-password"
              {...register('oldpassword', { required: true })}
            />
            {findErrors('oldpassword')}
          </Form.Group>
        </Col>
        <Col lg={{ span: 6, offset: 3 }} md={{ span: 10, offset: 1 }} className="mb-2">
          <Form.Group>
            <Form.Label htmlFor="password">
              {t(
                'Signup.password',
                'Mot de passe',
              )}
            </Form.Label>
            <Form.Control
              id="password"
              type="password"
              placeholder="••••••••"
              autoComplete="new-password"
              {...register('password', {
                required: true,
                minLength: 8,
              })}
            />
            {findErrors('password')}
          </Form.Group>
        </Col>
        <Col lg={{ span: 6, offset: 3 }} md={{ span: 10, offset: 1 }} className="mb-2">
          <Form.Group>
            <Form.Label htmlFor="passwordConfirmation">
              {t(
                'Signup.confirmPassword',
                'Confirmation mot de passe',
              )}
            </Form.Label>
            <Form.Control
              id="passwordConfirmation"
              type="password"
              autoComplete="new-password"
              placeholder="••••••••"
              {...register('passwordConfirmation', {
                required: true,
                minLength: 8,
              })}
            />
            {findErrors('passwordConfirmation')}
          </Form.Group>
          {
            !isPasswordMatch && (
              <InfoMessage
                message={t(
                  'PasswordReset.passwordMatch',
                  'Le mot de passe et sa confirmation ne sont pas identiques',
                )}
              />
            )
          }
        </Col>
        <Col lg={{ span: 6, offset: 3 }} md={{ span: 10, offset: 1 }} className="mb-2">
          <div className="mt-4 d-flex flex-column align-items-center buttons-container">
            <AWButton
              type="submit"
              label="submit-form"
              disabled={!isValid}
              className="mb-4"
              containerClassname="w-100"
            >
              {t('Action.modify')}
            </AWButton>
            <AWButton
              label="submit-form"
              backgroundColor={AWColors.Grey}
              containerClassname="w-100"
              onClick={onCancel}
            >
              {t('Action.cancel')}
            </AWButton>
          </div>
          {message && <InfoMessage message={message.text} color={message.color} />}
        </Col>
      </Row>
    </Form>
  );
};

export default PasswordForm;
