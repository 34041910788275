import React from 'react';
import {
  useRouteMatch,
  Switch,
} from 'react-router-dom';
import ProtectedRoute from '@root/routes/ProtectedRoute';
import Enterprise from '@customer/views/Enterprise/Enterprise';

const EnterpriseRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={`${path}/:enterpriseId`} exact>
        <Enterprise />
      </ProtectedRoute>
    </Switch>
  );
};

export default EnterpriseRoutes;
