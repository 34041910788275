import {
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { map } from 'lodash';
import { useIntercom } from 'react-use-intercom';
import { getCustomersConfig } from '@root/api-configs/enterprise.api.config';
import { UserContext } from '@root/contexts/user.context';
import { getFullName } from '@root/helpers/user.helper';
import useSafeFetch from '@root/hooks/useSafeFetch';

const useRunIntercom = () => {
  const { user } = useContext(UserContext);
  const [
    initCustomers,
    customers,
  ] = useSafeFetch<{ customer_id: string }[]>(getCustomersConfig, []);
  const { boot } = useIntercom();
  const [isRunning, setIsRunning] = useState<boolean>(false);

  useEffect(() => {
    if (user?.currentEnterprise && isRunning) {
      boot({
        email: user.email,
        name: getFullName(user),
        customAttributes: {
          user_id: user.id,
          last_name: user.lastname,
          first_name: user.firstname,
          job_title: user.currentEnterprise?.job_title || '',
        },
        company: {
          companyId: user.currentEnterprise?.id || '',
          name: user.currentEnterprise?.name,
          customAttributes: {
            country: user.currentEnterprise?.country || 'fr',
            is_vendor: user.currentEnterprise?.is_vendor,
            is_customer: user.currentEnterprise?.is_customer,
            customers: customers?.length ? map(customers, 'customer_id').join(',') : '',
          },
        },
        alignment: 'right',
      });
    }
  }, [user.currentEnterprise, customers, isRunning]);

  useEffect(() => {
    if (user.currentEnterprise?.identification_number) {
      initCustomers(
        { identificationNumber: user.currentEnterprise?.identification_number },
        { fields: 'customer_id' },
      );
    }
  }, [user.currentEnterprise?.identification_number]);

  return useCallback(() => {
    if (!isRunning) {
      setIsRunning(true);
    }
  }, []);
};

export default useRunIntercom;
