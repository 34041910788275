import { map } from 'lodash';
import { getConnectionsSearchConfig } from '@root/api-configs/connection.api.config';
import { getInvitationsConfig } from '@root/api-configs/invitation.api.config';
import { ApiResponse } from '@root/helpers/response-handler';
import { CallbackSafeFetch } from '@root/hooks/useSafeFetch';
import { SessionUser } from '@root/interfaces/cognito.interface';
import { Connection, ConnectionStatus } from '@root/interfaces/connection.interface';
import { InvitationStatusToInt, UserInvitation } from '@root/interfaces/invitation.interface';

export interface InvitationAndConnection {
  connections?: Connection[];
  invitations?: UserInvitation[];
}

export const MODAL_SEEN = 'connection_modal_seen';

export const getInvitationAndConnection = async (
  callbackApi: CallbackSafeFetch,
  user: SessionUser,
): Promise<InvitationAndConnection> => {
  if (user.email) {
    const connectionsRes: ApiResponse<Connection[]> = await callbackApi(
      getConnectionsSearchConfig,
      {
        query: {
          status: [ConnectionStatus.UNSEEN, ConnectionStatus.PENDING],
          emails: [user.email],
        },
      },
    );
    if (connectionsRes.success && connectionsRes.data?.length) {
      const userInvitationsRes = await callbackApi(
        getInvitationsConfig,
        {},
        {
          fields: [
            'id',
            'enterprise_guest_id',
            'enterprise_host_id',
            'user_host_id',
            'status',
            'type',
            'email',
          ],
          search: {
            email: user.email,
            status: InvitationStatusToInt.PENDING,
          },
        },
      );
      const modalSeen = sessionStorage.getItem(MODAL_SEEN);
      const seens: string[] = modalSeen ? JSON.parse(modalSeen) : null;

      if (connectionsRes.data?.length) {
        const connections = connectionsRes.data.filter((con) => (
          !seens || !seens.includes(con.provider_id!)
        ));
        let invitations = [];
        if (userInvitationsRes.success && userInvitationsRes.data?.length) {
          invitations = userInvitationsRes.data.filter((inv) => (
            map(connections, 'provider_id').includes(inv.enterprise_guest_id)
          ));
        }
        return { invitations, connections };
      }
    }
  }
  return {};
};
