import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import { queryBoySearch } from '@root/helpers/utils';
import { DocumentType } from '@root/interfaces/document.interface';

const { REACT_APP_COMPLIANCE_API } = process.env;

export const getDocument = (id: string, options): Promise<ApiResponse<DocumentType>> => (
  safeFetch(
    `${REACT_APP_COMPLIANCE_API}/v1/compliance/provider/document-types/${id}${queryBoySearch(options)}`,
  )
);
