import { HookApiConfig } from '@root/interfaces/api-response.interface';

const { REACT_APP_ENTERPRISE_API } = process.env;

const baseUrl = `${REACT_APP_ENTERPRISE_API}/v1/enterprise/#userType`;

export const createEnterpriseConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises`,
  method: 'POST',
};

export const getEnterprisesConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises`,
};

export const getEnterpriseConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#identifier`,
};

export const getCustomersConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#identificationNumber/customers`,
};

export const getCustomersCountConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#enterpriseId/customers/count`,
};

export const getEnterprisePhoneNumbersConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#identificationNumber/phone_numbers`,
};

export const getEnterpriseAddressesConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#identificationNumber/addresses`,
};

export const getEnterpriseMembersConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#identificationNumber/members`,
};

export const getEnterpriseDepartmentsConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#identificationNumber/departments`,
};

export const getEnterpriseActivitiesConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#id/activities`,
};

export const getPartnershipConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/customers/#customerId/providers/#providerId`,
};

export const getBusinessAmountConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/customers/#customerId/providers/#providerId/business`,
};

export const getContractsBetweenEnterprisesConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/customers/#customerId/providers/#providerId/contracts`,
};

export const getEmployeeNumbersConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#enterpriseId/employees`,
};

export const getBusinessTurnoversConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#enterpriseId/turnovers`,
};

export const getCompletionConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#enterpriseId/completion`,
};

export const getProviderCertificationsConfig: HookApiConfig = {
  url: `${baseUrl}/certifications/providers/#providerId`,
};

export const getProviderFieldsConfig: HookApiConfig = {
  url: `${baseUrl}/fields/providers/#providerId`,
};

export const getCustomerFieldsConfig: HookApiConfig = {
  url: `${baseUrl}/fields/customers/#customerId`,
};

export const getFieldConfig: HookApiConfig = {
  url: `${baseUrl}/fields/#fieldId`,
};

export const getEnterpriseLegalFormConfig: HookApiConfig = {
  url: `${baseUrl}/legal-forms/#legalFormId`,
};

export const getLegalFormsConfig: HookApiConfig = {
  url: `${baseUrl}/legal-forms`,
};

export const getEnterpriseActivePartnersConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#enterpriseId/partners?#search`,
};

export const getEnterpriseContactsConfig: HookApiConfig = {
  url: `${baseUrl}/contacts`,
};

export const getProviderCustomersConfig: HookApiConfig = {
  url: `${baseUrl}/providers/#providerId/partners`,
};

export const updateHasUserConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#enterpriseId/users/#userId`,
  method: 'PATCH',
};

export const getEnterpriseRepresentativesConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#enterpriseId/representatives`,
};

export const updatePartnershipConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#customerId/provider/#providerId`,
  method: 'PATCH',
};
