import { HookApiConfig } from '@root/interfaces/api-response.interface';

const baseUrl = `${process.env.REACT_APP_USER_API}/v1/user/#userType`;

export const joinUserEnterpriseConfig: HookApiConfig = {
  url: `${baseUrl}/users/#userId/enterprise/#identificationNumber`,
  method: 'POST',
};

export const getUserConfig: HookApiConfig = {
  url: `${baseUrl}/users/#id`,
};

export const getUsersConfig: HookApiConfig = {
  url: `${baseUrl}/users`,
};

export const getMyEnterprisesConfig: HookApiConfig = {
  url: `${baseUrl}/me/enterprises`,
};

export const sendMailConfig: HookApiConfig = {
  url: `${baseUrl}/mails/#idTemplate`,
  method: 'POST',
};

export const swapCurrentEnterpriseV1Config: HookApiConfig = {
  url: `${baseUrl}/platform/swap/enterprises/#enterpriseId`,
  method: 'POST',
};

export const getUserPhoneNumbersConfig: HookApiConfig = {
  url: `${baseUrl}/users/#userId/phone_numbers`,
};

export const getMyAclConfig: HookApiConfig = {
  url: `${baseUrl}/me/acl`,
};
