export enum AWColors {
  Green = '#12957D',
  LightGreen = '#f0fef6',
  Red = '#FF0000',
  Orange = '#E1C22A',
  Blue = '#00C2FF',
  Grey = '#3A3A3A',
  MediumGrey = '#ced4da',
  LightGrey = '#EEEEEE',
  Yellow = '#F9BA4F',
  Tomato = '#F9604F',
  Black = '#272928',
}

export interface Option {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  label: string;
  disabled?: boolean;
}

export const blankOption: Option = {
  label: '-',
  value: '',
};

export interface MultiStringInput {
  id: string;
  value: string;
  current: boolean;
}

export enum KeyboardValues {
  SpaceKey = 32,
  EnterKey = 13,
}

export type ValueOf<T> = T[keyof T];

export type MergeOf<T, U> = T & U;

// Needed to use the Google Analytics gtag() function on the window property.
declare global {
  interface Window {
    gtag: (command: string, target: string, options?: Record<string, string>) => void;
  }
}

export enum CountryCode {
  Fr = 'fr',
  De = 'de',
  Be = 'be',
}

export const AvailableCountries = [
  CountryCode.Fr,
  CountryCode.De,
  CountryCode.Be,
];

export type AddWorkingCountry = `${CountryCode}`;

export enum Lang {
  FR = 'fr-FR',
  EN = 'en-EN',
  DE = 'de-DE',
  FR_BE = 'fr-BE',
  NL_BE = 'nl-BE',
  EN_BE = 'en-BE',
  DE_BE = 'de-BE',
}
