import React, { useContext, useEffect, useState } from 'react';
import { ApprovalContext } from '@customer/contexts/approval.context';
import { ApprovalStatusEnum } from '@root/interfaces/approval.interface';
import LabelValueCard from '@root/components/LabelValueCard/LabelValueCard';
import { ILabelValueCard } from '@root/interfaces/labelValueCard.interface';
import { useTranslation } from 'react-i18next';

const ApprovalInformations = () => {
  const { approvalToDisplay, setApprovalToDisplay } = useContext(ApprovalContext);
  const { t } = useTranslation();
  const [labelValue, setLabelValue] = useState<ILabelValueCard[]>([]);

  const signatureStatus = {
    allSignaturesMissing: t('CardApproval.allSignaturesMissing', 'aucune signature'),
    providerValidationMissing: t(
      'CardApproval.providerValidationMissing',
      'en attente de validation ST',
    ),
    customerValidationMissing: t(
      'CardApproval.customerValidationMissing',
      'en attente de validation client',
    ),
    providerSignatureMissing: t(
      'CardApproval.providerSignatureMissing',
      'en attente de signature ST',
    ),
    customerSignatureMissing: t(
      'CardApproval.customerSignatureMissing',
      'en attente de signature client',
    ),
    sponsorSignatureMissing: t(
      'CardApproval.sponsorSignatureMissing',
      'en attente de signature MOA',
    ),
    signed: t('CardApproval.signed', 'signée'),
  };

  const setApprovalToDisplayStatus = (str: string) => setApprovalToDisplay(
    { ...approvalToDisplay, status: str.toUpperCase() },
  );

  const setSignatureProperties = () => {
    if (
      approvalToDisplay.status === ApprovalStatusEnum.DRAFT
      || approvalToDisplay.status === ApprovalStatusEnum.READY
    ) {
      setApprovalToDisplayStatus(signatureStatus.allSignaturesMissing);
    }
    if (approvalToDisplay.status === ApprovalStatusEnum.WAITING_PROVIDER_VALIDATION) {
      setApprovalToDisplayStatus(signatureStatus.providerValidationMissing);
    }
    if (approvalToDisplay.status === ApprovalStatusEnum.WAITING_CUSTOMER_VALIDATION) {
      setApprovalToDisplayStatus(signatureStatus.customerValidationMissing);
    }
    if (approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_PROVIDER) {
      setApprovalToDisplayStatus(signatureStatus.providerSignatureMissing);
    }
    if (approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_CUSTOMER) {
      setApprovalToDisplayStatus(signatureStatus.customerSignatureMissing);
    }
    if (approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_SPONSOR) {
      setApprovalToDisplayStatus(signatureStatus.sponsorSignatureMissing);
    }
    if (approvalToDisplay.status === ApprovalStatusEnum.ACCEPTED) {
      setApprovalToDisplayStatus(signatureStatus.signed);
    }
  };

  const setLabelValueDatas = async () => {
    setSignatureProperties();
    const labelValueDatas: ILabelValueCard[] = [
      {
        title: t('Approvals.sponsor', 'MOA'),
        content: `${approvalToDisplay?.sponsor_email ? approvalToDisplay?.sponsor_email : '-'}` || `${approvalToDisplay?.sponsor_legal_form ? approvalToDisplay?.sponsor_legal_form : ''} ${approvalToDisplay?.sponsor_name ? approvalToDisplay?.sponsor_name : '-'}`,
      },
      {
        title: t('Approvals.provider', 'Sous-traitant'),
        content: `${approvalToDisplay?.provider_legal_form ? approvalToDisplay?.provider_legal_form : ''} ${approvalToDisplay?.provider_name ? approvalToDisplay?.provider_name : '-'}`,
      },
      {
        title: t('Approvals.workfield', 'Chantier'),
        content: approvalToDisplay?.workfield_label ? approvalToDisplay?.workfield_label : '-',
      },
      {
        title: t('Approvals.description', 'Description'),
        content: approvalToDisplay?.workfield_description ? approvalToDisplay?.workfield_description : '-',
      },
      {
        title: t('Approvals.status', 'Statut'),
        content: approvalToDisplay?.status ? approvalToDisplay?.status : '-',
      },
    ];
    setLabelValue(labelValueDatas);
  };

  useEffect(() => {
    setLabelValueDatas();
  }, [approvalToDisplay]);

  return (
    <div>
      <LabelValueCard labelValues={labelValue} />
    </div>
  );
};

export default ApprovalInformations;
