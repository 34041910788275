import React, {
  ReactNode, useContext, useEffect, useState,
} from 'react';
import {
  Col, Modal, Row, Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import AWButton from '@root/components/AWButtons/AWButton';
import AWContainer from '@root/components/AWContainer/AWContainer';
import Stepper from '@customer/components/Stepper/Stepper';
import { ApprovalStep } from '@customer/interfaces/approval-step.interface';
import {
  NewApprovalStepLabelList,
  StepPathKeyword,
  ApprovalStepStatus,
} from '@customer/views/Approvals/NewApproval/steps.list';
import arrowLeft from '@root/assets/arrow-left-white.svg';
import { ApprovalContext } from '@customer/contexts/approval.context';
import {
  createApprovalVariables, createNewApproval, updateApproval, updateApprovalVariables,
} from '@root/services/approval.service';
import {
  ApprovalStatusEnum, ApprovalVariable, ApprovalVariableUserTypeEnum, NewApproval as INewApproval,
} from '@root/interfaces/approval.interface';
import { UserContext } from '@root/contexts/user.context';
import i18n from '@root/locales/i18n';
import BurgerMenu from '@root/components/BurgerMenu/BurgerMenu';
import { BurgerMenu as IBurgerMenu } from '@root/interfaces/menu.interface';
import { getUploadSignedURL, uploadToS3 } from '@root/services/file.service';
import { ApprovalDocument } from '@root/interfaces/approvalDocument.interface';
import { createApprovalDocument } from '@root/services/approvalDocument.service';
import { goTo } from '@root/helpers/utils';
import { CUSTOMER_BASE_PATH } from '@root/helpers/constants.helper';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { ApprovalNotificationEventEnum } from '@root/interfaces/approvalNotification.interface';
import { sendApprovalEmail } from '@root/services/approvalNotification.service';
import { getEnterpriseMembers } from '@provider/services/enterprise.service';

interface Props {
  children: ReactNode;
}

const burgerMenu: IBurgerMenu = {
  sections: [
    {
      options: [
        { label: i18n.t('ApprovalBurgerMenu.models', 'Modèles') },
        { label: i18n.t('ApprovalBurgerMenu.users', 'Utilisateurs') },
        { label: i18n.t('ApprovalBurgerMenu.configuration', 'Configuration') },
      ],
    },
  ],
};

const NewApproval = ({ children }: Props) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const {
    selectedMission,
    modelSelected,
    modelVariableDictionary,
    pdfFile,
    currentNewApprovalId,
    setCurrentNewApprovalId,
    variablesHaveBeenCreatedInDb,
    setVariablesHaveBeenCreatedInDb,
    documentCompletedWithCustomerVariables,
    sponsorEmail,
  } = useContext(ApprovalContext);

  const { waitWithLoad, isLoading } = useLoadingPromise();

  const { pathname } = useLocation();
  const lastSegment = pathname.split('/').pop();
  const [stepList, setStepList] = useState<ApprovalStep[]>(NewApprovalStepLabelList);
  const [currentActiveStep, setCurrentActiveStep] = useState<ApprovalStep>({
    id: 0,
    label: '',
    status: '',
    path: '',
  });
  const [modal, setModal] = useState<{
    isOpen: boolean;
    title?: string;
  }>({ isOpen: false });

  const attributeStepStatus = () => {
    let isAnyStepActive = false;

    const updatedList = stepList.map((step) => {
      if (step.path === lastSegment) {
        isAnyStepActive = true;
        const updatedActiveStep = { ...step, status: ApprovalStepStatus.ACTIVE };
        setCurrentActiveStep(updatedActiveStep);
        return updatedActiveStep;
      }
      if (isAnyStepActive) {
        return { ...step, status: ApprovalStepStatus.WAITING };
      }
      return { ...step, status: ApprovalStepStatus.COMPLETED };
    });

    setStepList(updatedList);
  };

  useEffect(() => {
    attributeStepStatus();
  }, [pathname]);

  const goToStep = (direction: 'next' | 'previous', fileUploaded?: File) => {
    const operator = direction === 'next' ? 1 : -1;
    let stepIdToNavigateTo = currentActiveStep!.id + operator;
    const stepIds = stepList.map((step) => step.id);

    const minStepId = Math.min(...stepIds);
    const maxStepId = Math.max(...stepIds);

    // if a PDF file is uploaded...
    if (fileUploaded && fileUploaded.size > 0) {
      // clicking Next will directly go to validation step if current step is Model Step
      if (direction === 'next' && currentActiveStep.path === StepPathKeyword.MODEL) {
        stepIdToNavigateTo = stepList.find((step) => step.path === StepPathKeyword.VALIDATION)!.id;
      }
      // clicking Previous will directly go to model step if current step is Validation Step
      if (direction === 'previous' && currentActiveStep.path === StepPathKeyword.VALIDATION) {
        stepIdToNavigateTo = stepList.find((step) => step.path === StepPathKeyword.MODEL)!.id;
      }
    }

    if (stepIdToNavigateTo >= minStepId && stepIdToNavigateTo <= maxStepId) {
      return stepList.find((step) => step.id === stepIdToNavigateTo)?.path;
    }
    return currentActiveStep?.path;
  };

  const handleDisablingNextButton = (): boolean => {
    switch (currentActiveStep.path) {
      case StepPathKeyword.MISSION:
        return !selectedMission.id;
      case StepPathKeyword.MODEL:
        return !(modelSelected.id || pdfFile.size);
      case StepPathKeyword.VALIDATION:
        return !currentNewApprovalId;
      default:
        return false;
    }
  };

  const handleCreateEditApprovalAtMissionStep = async () => {
    if (user.currentEnterprise?.id) {
      const newApproval: INewApproval = {
        mission_id: selectedMission.id,
        provider_id: selectedMission.provider_id,
        customer_id: user.currentEnterprise.id,
        created_by: user.id,
      };
      // check if an approval has been initiated during the current process
      if (currentNewApprovalId) {
        // if so, edit the current approval
        return updateApproval({ ...newApproval, id: currentNewApprovalId }, currentNewApprovalId);
        // else, we create a new approval
      }
      const approvalCreated = await createNewApproval(newApproval);
      setCurrentNewApprovalId(approvalCreated.data.id!);
    }
    return '';
  };

  const handleCreateEditVariablesAtCheckStep = async () => {
    const variableArray: ApprovalVariable[] = [];
    Object.keys(modelVariableDictionary).forEach((key) => {
      variableArray.push({
        variable: key,
        value: modelVariableDictionary[key].value,
        user_type: modelVariableDictionary[key].disabled
          ? ApprovalVariableUserTypeEnum.PROVIDER
          : ApprovalVariableUserTypeEnum.CUSTOMER,
      });
    });
    if (variablesHaveBeenCreatedInDb) {
      return updateApprovalVariables(variableArray, currentNewApprovalId);
    }
    await createApprovalVariables(variableArray, currentNewApprovalId);
    return setVariablesHaveBeenCreatedInDb(true);
  };

  const saveDocument = async () => {
    let fileMimeType = 'application/pdf';
    let fileBody: File | string = pdfFile;

    if (documentCompletedWithCustomerVariables) {
      fileMimeType = 'text/html';
      fileBody = documentCompletedWithCustomerVariables;
    }

    if (user.currentEnterprise?.id) {
      const uploadURLRes = await getUploadSignedURL(fileMimeType);
      if (uploadURLRes.success) {
        const uploadRes = await uploadToS3(
          uploadURLRes.data.signedUrl,
          fileBody,
        );
        // if file is successfully uploaded in S3, save its info in database
        if (uploadRes.success) {
          const document: ApprovalDocument = {
            id: uploadURLRes.data.key,
            owner_id: user.currentEnterprise?.id,
            // TO DO: get S3.headObject() to get real size (Content-Length)
            size: 1,
            mime_type: fileMimeType,
            approval_id: currentNewApprovalId,
          };
          await createApprovalDocument(document, currentNewApprovalId);
        }
      }
      return '';
    }
    return '';
  };

  const sendNewApprovalCreatedNotificationToProvider = async () => {
    const userRes = await getEnterpriseMembers(selectedMission.provider_id, {
      fields: 'user_id,enterprise_id,firstname,lastname,name,email',
    });
    const emails = userRes.data?.filter((u) => !!u.email)?.map((u) => u.email) || [];

    await sendApprovalEmail({
      event: ApprovalNotificationEventEnum.NEW_APPROVAL_CREATED,
      emails,
      customer_enterprise: user.currentEnterprise!.name,
      customer_name: `${user.firstname} ${user.lastname}`,
      provider_enterprise: userRes.data[0].name,
    });
  };

  const handleFinalizeApprovalCreationAtValidationStep = async () => {
    if (user.currentEnterprise?.id && currentNewApprovalId) {
      const approval: INewApproval = {
        id: currentNewApprovalId,
        mission_id: selectedMission.id,
        provider_id: selectedMission.provider_id,
        customer_id: user.currentEnterprise.id,
        status: ApprovalStatusEnum.WAITING_PROVIDER_VALIDATION,
        valid_from: new Date(),
      };
      await updateApproval(approval, currentNewApprovalId);
      await saveDocument();
      await sendNewApprovalCreatedNotificationToProvider();
      // redirect user to approvals main page
      setModal({
        isOpen: true,
        title: "Demande d'agrément envoyée",
      });
      setTimeout(
        () => goTo(`${CUSTOMER_BASE_PATH}/approvals`),
        5000,
      );
    }
    return '';
  };

  const handleSaveSponsorEmailAtInfosStep = async () => {
    if (user.currentEnterprise?.id && currentNewApprovalId) {
      const approval: INewApproval = {
        id: currentNewApprovalId,
        mission_id: selectedMission.id,
        provider_id: selectedMission.provider_id,
        customer_id: user.currentEnterprise.id,
        sponsor_email: sponsorEmail,
      };
      await updateApproval(approval, currentNewApprovalId);
    }
    return '';
  };

  const handleNextStepClickAction = async () => {
    switch (currentActiveStep.path) {
      case StepPathKeyword.MISSION:
        return handleCreateEditApprovalAtMissionStep();
      case StepPathKeyword.INFOS:
        return handleSaveSponsorEmailAtInfosStep();
      case StepPathKeyword.CHECK:
        return handleCreateEditVariablesAtCheckStep();
      case StepPathKeyword.VALIDATION:
        return waitWithLoad(handleFinalizeApprovalCreationAtValidationStep());
      default:
        return '';
    }
  };

  return (
    <AWContainer className="new-approval">
      <AWContainer.Main title={t('NewApproval.title', "Nouvelle demande d'agrément")}>
        <Row className="d-flex justify-content-end mx-0">
          <Col sm="4">
            <Row>
              <Col sm="4" xxl="3">
                {lastSegment !== StepPathKeyword.MISSION ? (
                  <Link to={`${goToStep('previous', pdfFile)}`}>
                    <AWButton>
                      <img
                        src={arrowLeft}
                        alt="left arrow to previous page"
                        style={{ minHeight: '75%' }}
                      />
                    </AWButton>
                  </Link>
                ) : null}
              </Col>
              <Col sm="8" xxl="9">
                {lastSegment !== StepPathKeyword.VALIDATION ? (
                  <Link to={`${goToStep('next', pdfFile)}`}>
                    <AWButton
                      disabled={handleDisablingNextButton()}
                      onClick={() => handleNextStepClickAction()}
                    >
                      {t('Common.next', 'Suivant')}
                    </AWButton>
                  </Link>
                ) : (
                  <AWButton
                    disabled={handleDisablingNextButton() || isLoading}
                    onClick={() => handleNextStepClickAction()}
                  >
                    {isLoading ? <Spinner size="sm" animation="border" /> : t('ApprovalStepper.send', 'Envoyer au ST')}
                  </AWButton>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {children}
      </AWContainer.Main>
      <AWContainer.Side titleSide={<BurgerMenu sections={burgerMenu.sections} />}>
        <Stepper stepList={stepList} title={t('ApprovalStepper.title', 'Étapes de création')} />
      </AWContainer.Side>
      <Modal
        show={modal.isOpen}
        dialogClassName="new-approval__modal"
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <h2>
              {t('Approvals.messageRequest', "Votre demande d'agrément a bien été enregistrée et transmise au sous-traitant.")}
            </h2>
            <p>{`${t('Approvals.redirect', 'Redirection')}...`}</p>
          </>
        </Modal.Body>
      </Modal>
    </AWContainer>
  );
};

export default NewApproval;
