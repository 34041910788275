import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import { queryBoySearch } from '@root/helpers/utils';

import { Document } from '@root/interfaces/document.interface';
import {
  Enterprise,
  EnterpriseHasMember,
  Customer,
  Department,
  Activity,
  BusinessTurnover,
  EmployeeNumber,
  EmployeeNumberRange,
} from '@root/interfaces/enterprise.interface';
import { QueryboyOptions } from '@root/interfaces/queryboy.interface';

const { REACT_APP_ENTERPRISE_API, REACT_APP_COMPLIANCE_API } = process.env;

export const getEnterprise = async (
  options: QueryboyOptions,
  identification_number,
): Promise<ApiResponse<Enterprise>> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/enterprises/${identification_number}${queryBoySearch(options)}`)
);

export const getEnterprises = async (
  options: QueryboyOptions,
): Promise<ApiResponse<Enterprise[]>> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/enterprises${queryBoySearch(options)}`)
);

export const createEnterprise = async (
  enterprise: Enterprise,
): Promise<ApiResponse<Enterprise>> => safeFetch(
  `${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/enterprises`,
  {
    method: 'POST',
    body: JSON.stringify(enterprise),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
);

export const getEnterpriseMembers = async (
  identifier: string,
  options: QueryboyOptions,
): Promise<ApiResponse<EnterpriseHasMember[]>> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/enterprises/${identifier}/members${queryBoySearch(options)}`)
);

export const updateEnterprise = async (
  identificationNumber: string,
  enterprise: Enterprise,
): Promise<ApiResponse> => (
  safeFetch(
    `${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/enterprises/${identificationNumber}`,
    {
      method: 'PATCH',
      body: JSON.stringify(enterprise),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )
);

export const getCustomers = async (
  identification_number: string,
  options: QueryboyOptions,
): Promise<ApiResponse<Customer[]>> => (
  safeFetch(
    `${REACT_APP_ENTERPRISE_API
    }/v1/enterprise/provider/enterprises/${
      identification_number
    }/customers${
      queryBoySearch(options)
    }`,
  )
);

export const getDocumentsFromEnterprise = async (
  enterpriseId: string,
): Promise<ApiResponse<Document[]>> => (
  safeFetch(
    `${REACT_APP_COMPLIANCE_API}/v1/compliance/provider/enterprises/${enterpriseId}/documents`,
  )
);

export const getAllDepartments = async (
  options: QueryboyOptions,
): Promise<ApiResponse<Department[]>> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/enterprises/departments${queryBoySearch(options)}`)
);

export const getDepartments = async (
  identificationNumber: string,
  options: QueryboyOptions,
): Promise<ApiResponse<unknown[]>> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/enterprises/${identificationNumber}/departments${queryBoySearch(options)}`)
);

export const getActivities = async (
  id: string,
  options: QueryboyOptions,
): Promise<ApiResponse<Activity[]>> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/enterprises/${id}/activities${queryBoySearch(options)}`)
);

export const createActivity = async (
  id: string,
  body: Activity,
): Promise<ApiResponse> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/enterprises/${id}/activities`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
);

export const deleteActivity = async (activityId: string): Promise<ApiResponse> => (
  safeFetch(
    `${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/enterprises/activities/${activityId}`,
    { method: 'DELETE' },
  )
);

export const createEnterpriseTurnover = async (
  enterpriseId: string,
  enterpriseTurnover: BusinessTurnover,
): Promise<ApiResponse> => (
  safeFetch(
    `${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/enterprises/${enterpriseId}/turnover`,
    {
      method: 'POST',
      body: JSON.stringify(enterpriseTurnover),
    },
  )
);

export const updateEnterpriseTurnover = async (
  enterpriseId: string,
  enterpriseTurnoverId: string,
  enterpriseTurnover: BusinessTurnover,
): Promise<ApiResponse> => (
  safeFetch(
    `${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/enterprises/${enterpriseId}/turnover/${enterpriseTurnoverId}`,
    {
      method: 'PATCH',
      body: JSON.stringify(enterpriseTurnover),
    },
  )
);

export const getEnterpriseEmployeeNumbers = async (
  enterpriseId: string,
  options: QueryboyOptions,
): Promise<ApiResponse<EmployeeNumber[]>> => (
  safeFetch(
    `${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/enterprises/${enterpriseId}/employees${queryBoySearch(options)}`,
  )
);

export const createEmployeeNumber = async (
  enterpriseId: string,
  employeeNumber: EmployeeNumber,
): Promise<ApiResponse> => (
  safeFetch(
    `${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/enterprises/${enterpriseId}/employees`,
    {
      method: 'POST',
      body: JSON.stringify(employeeNumber),
    },
  )
);

export const updateEmployeeNumber = async (
  enterpriseId: string,
  employeeNumberId: string,
  employeeNumber: EmployeeNumber,
): Promise<ApiResponse> => (
  safeFetch(
    `${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/enterprises/${enterpriseId}/employees/${employeeNumberId}`,
    {
      method: 'PATCH',
      body: JSON.stringify(employeeNumber),
    },
  )
);

export const getEnterpriseBusinessTurnovers = async (
  enterpriseId: string,
  options: QueryboyOptions,
): Promise<ApiResponse<BusinessTurnover[]>> => (
  safeFetch(
    `${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/enterprises/${enterpriseId}/turnovers${queryBoySearch(options)}`,
  )
);

export const getEmployeeNumberRanges = async (
  options: QueryboyOptions,
): Promise<ApiResponse<EmployeeNumberRange[]>> => (
  safeFetch(
    `${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/employees/ranges${queryBoySearch(options)}`,
  )
);
