import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from '@root/helpers/patterns';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import InfoMessage from '@root/components/InfoMessage/InfoMessage';
import useCognito from '@root/hooks/useCognito';
import AWButton from '@root/components/AWButtons/AWButton';
import { UserContext } from '@root/contexts/user.context';
import { CognitoErrors, SessionUser } from '@root/interfaces/cognito.interface';
import i18n from '@root/locales/i18n';

interface Error {
  message: string,
}

type Errors = {
  [key in keyof SessionUser]?: Error;
};

const SignupForm = () => {
  const { t } = useTranslation();
  const { user: contextUser } = useContext(UserContext);

  const { isLoading, waitWithLoad } = useLoadingPromise();
  const { signUp, message } = useCognito();

  const [captchaToken, setCaptchaToken] = useState('');
  const [acceptedConditions, setAcceptedConditions] = useState(false);
  const [errors, setErrors] = useState<Errors>();

  const [user, setUser] = useState<SessionUser>({
    email: contextUser.email || '',
    gender: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    password: '',
    passwordConfirmation: '',
  });

  const checkErrors = () => {
    const _errors: Errors = {};

    const missings = Object.keys(user).filter((k) => !user[k]) as Array<keyof SessionUser>;
    if (missings.length) {
      missings.forEach((m) => {
        _errors[m] = { message: t('Error.missingField') };
      });
    }

    if (user.password
      && user.passwordConfirmation
      && user.password !== user.passwordConfirmation
    ) {
      _errors.passwordConfirmation = { message: t('PasswordReset.passwordMatch') };
    }

    if (user.email && !user.email?.match(EMAIL_REGEX)) {
      _errors.email = { message: t('Error.emailFormat') };
    }

    if (user.phoneNumber && !user.phoneNumber?.match(PHONE_NUMBER_REGEX)) {
      _errors.phoneNumber = { message: t('Errors.formatNotValid') };
    }

    return _errors;
  };

  useEffect(() => {
    if (errors) {
      setErrors(checkErrors());
    }
  }, [user]);

  const createUser = (e) => {
    e.preventDefault();
    const _errors = checkErrors();
    return Object.keys(_errors).length
      ? setErrors(_errors) : waitWithLoad(signUp(user, captchaToken));
  };

  const handleUserChange = (key: keyof SessionUser) => (event) => {
    const value = event?.target?.value === undefined ? event : event.target.value;
    setUser({ ...user, [key]: value });
  };

  const phoneNumberErrors = () => {
    if (errors?.phoneNumber) {
      return <InfoMessage message={errors.phoneNumber.message} />;
    }
    if (message?.opt === CognitoErrors.PhoneNumberFormat) {
      return <InfoMessage message={message.text} color={message.color} />;
    }
    return null;
  };

  const isDisabled = isLoading
    || !acceptedConditions
    || !captchaToken
    || !!(errors && Object.keys(errors).length);

  return (
    <Form
      onSubmit={createUser}
      autoComplete="off"
    >
      <Row className="mb-4">
        <Col md="2">
          <Form.Group>
            <Form.Label htmlFor="gender">
              {t('Signup.gender', 'Civilité')}
            </Form.Label>
            <Form.Select
              id="gender"
              onChange={handleUserChange('gender')}
            >
              <option value="">-</option>
              <option value="male">{t('Signup.man', 'Mr')}</option>
              <option value="female">{t('Signup.woman', 'Mme')}</option>
            </Form.Select>
            {errors?.gender ? <InfoMessage message={errors.gender.message} /> : null}
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <Form.Label htmlFor="firstname">
              {t('Signup.firstName', 'Prénom')}
            </Form.Label>
            <Form.Control
              id="firstname"
              onChange={handleUserChange('firstname')}
            />
            {errors?.firstname ? <InfoMessage message={errors.firstname.message} /> : null}
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group>
            <Form.Label htmlFor="lastname">
              {t('Signup.lastName', 'Nom')}
            </Form.Label>
            <Form.Control
              id="lastname"
              onChange={handleUserChange('lastname')}
            />
            {errors?.lastname ? <InfoMessage message={errors.lastname.message} /> : null}
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md="6">
          <Form.Group>
            <Form.Label htmlFor="email">
              {t('Signup.email', 'Email')}
            </Form.Label>
            <Form.Control
              id="email"
              placeholder="john.doe@domain.com"
              autoComplete="new-password"
              onChange={handleUserChange('email')}
            />
            {errors?.email ? <InfoMessage message={errors.email.message} /> : null}
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group>
            <Form.Label htmlFor="phoneNumber">
              {t('Signup.phoneNumber', 'Numéro de téléphone')}
            </Form.Label>
            <PhoneInput
              country="fr"
              placeholder="+33 1 02 03 04 05"
              inputProps={{
                name: 'phone',
                required: true,
              }}
              inputClass="phone-number-input font-style-regular"
              buttonClass="phone-number-button"
              dropdownClass="font-style-light"
              onChange={handleUserChange('phoneNumber')}
            />
            {phoneNumberErrors()}
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md="6">
          <Form.Group>
            <Form.Label htmlFor="password">
              {t(
                'Signup.password',
                'Mot de passe',
              )}
            </Form.Label>
            <Form.Control
              id="password"
              type="password"
              placeholder="••••••••"
              autoComplete="new-password"
              onChange={handleUserChange('password')}
            />
            {errors?.password ? <InfoMessage message={errors.password.message} /> : null}
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group>
            <Form.Label htmlFor="passwordConfirmation">
              {t(
                'Signup.confirmPassword',
                'Confirmation mot de passe',
              )}
            </Form.Label>
            <Form.Control
              id="passwordConfirmation"
              type="password"
              autoComplete="new-password"
              placeholder="••••••••"
              onChange={handleUserChange('passwordConfirmation')}
            />
            {errors?.passwordConfirmation ? (
              <InfoMessage message={errors.passwordConfirmation.message} />
            ) : null}
          </Form.Group>
        </Col>
      </Row>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        onChange={setCaptchaToken}
        hl={i18n.language.split('-')[0]}
      />
      <Form.Group className="mt-3 d-flex">
        <Form.Check
          id="terms"
          type="checkbox"
          onChange={(e) => setAcceptedConditions(e.target.checked)}
        />
        <Form.Label htmlFor="terms" className="mx-2">
          {t('Signup.acceptTerms1')}
          &nbsp;
          <a href="https://www.addworking.com/legal/cgu" target="_blank" rel="noreferrer">
            <u>
              {t(
                'Signup.acceptTerms2',
                "conditions générales d'utilisation",
              )}
            </u>
          </a>
        </Form.Label>
      </Form.Group>
      {message && message.opt !== CognitoErrors.PhoneNumberFormat ? (
        <InfoMessage message={message.text} color={message.color} />
      ) : null}
      <div className="mt-4 d-flex flex-column align-items-center">
        <AWButton
          type="submit"
          label="submit-form"
          disabled={isDisabled}
          isLoading={isLoading}
          className="w-50"
          noFlex
        >
          {t('Signup.createAccount', 'Créer mon compte')}
        </AWButton>
        <Link
          className="text-center font-size-14 mt-2"
          to="/"
        >
          {t('Signup.haveAccount', "J'ai déjà un compte")}
        </Link>
      </div>
    </Form>
  );
};

export default SignupForm;
