import React, {
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { Activity } from '@root/interfaces/enterprise.interface';
import { getEnterpriseActivitiesConfig } from '@root/api-configs/enterprise.api.config';
import useSafeFetch from '@root/hooks/useSafeFetch';

interface Props {
  providerId?: string,
}

const ModalActivity = ({
  providerId,
}: Props) => {
  const { t } = useTranslation();
  const [initActivities, activities] = useSafeFetch<Activity[]>(getEnterpriseActivitiesConfig);

  useEffect(() => {
    initActivities({ id: providerId }, { fields: 'activity,id', page_size: 100 });
  }, []);

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label className="text-dark">
          {t('ModalActivity.providedActivities', 'Activités renseignées')}
        </Form.Label>
        {
          activities?.length ? (
            <Form.Control
              as="textarea"
              disabled
              value={activities && activities.map((activity) => activity.activity).join(', ')}
            />
          ) : (
            <p className="text-dark">
              {t('ModalActivity.noActivityYet', "L'entreprise n'a pas encore renseigné d'activité")}
            </p>
          )
        }
      </Form.Group>
    </Form>
  );
};

ModalActivity.defaultProps = {
  providerId: '',
};

export default ModalActivity;
