import { getAllDepartments } from '@root/helpers/enterprise.helper';
import { ApiResponse } from '@root/helpers/response-handler';
import { getHigherObjectBy } from '@root/helpers/utils';
import {
  ConnectionTaskField,
  Department,
  IConnectionTaskField,
} from '@root/interfaces/connection.interface';
import { EmployeeNumber, BusinessTurnover, EmployeeNumberRange } from '@root/interfaces/enterprise.interface';
import {
  getDepartments,
  getEnterprise,
  getEnterpriseEmployeeNumbers,
  getEnterpriseBusinessTurnovers,
  getEmployeeNumberRanges,
} from '@provider/services/enterprise.service';
import { getFile } from '@root/services/file.service';

const QUERYBOY_FIELDS = 'id,commercial_brochure,has_commercial_brochure,website,has_website,departments';

export const initFields = async (identificationNumber: string, fields: string[]) => {
  const data: {
    allDepartments?: Department[],
    employeeNumberRanges?: EmployeeNumberRange[],
    enterpriseFields?: IConnectionTaskField,
    latestTurnover?: BusinessTurnover,
    latestEmployeeNumber?: EmployeeNumber,
  } = {};
  const enterpriseFieldRes = await getEnterprise(
    { fields: QUERYBOY_FIELDS },
    identificationNumber,
  );
  if (enterpriseFieldRes.success) {
    const tmpEnterpriseFields: IConnectionTaskField = {};
    if (fields.includes(ConnectionTaskField.COMMERCIAL_BROCHURE)) {
      if (enterpriseFieldRes.data.commercial_brochure) {
        const fileRes = await getFile(enterpriseFieldRes.data.commercial_brochure);
        if (fileRes.success) {
          tmpEnterpriseFields.commercialBrochure = { url: fileRes.data.signedUrl };
        }
      } else if (enterpriseFieldRes.data.has_commercial_brochure === false) {
        tmpEnterpriseFields.commercialBrochure = { none: true };
      }
    }
    if (fields.includes(ConnectionTaskField.WEBSITE)) {
      if (enterpriseFieldRes.data.website) {
        tmpEnterpriseFields.website = { url: enterpriseFieldRes.data.website };
      } else if (enterpriseFieldRes.data.has_website === false) {
        tmpEnterpriseFields.website = { none: true };
      }
    }
    if (fields.includes(ConnectionTaskField.DEPARTMENTS)) {
      const allDepartmentsRes = await getAllDepartments();
      const departmentsRes = await getDepartments(
        identificationNumber,
        { fields: 'id' },
      ) as ApiResponse<{ id: string }[]>;
      const departmentsAsOptions: Department[] = allDepartmentsRes.map((department) => ({
        label: `${department.insee_code} - ${department.display_name} (${department.prefecture})`,
        value: department.insee_code,
        id: department.id as string,
        code: department.insee_code,
      }));
      data.allDepartments = departmentsAsOptions;
      if (departmentsRes.data.length) {
        tmpEnterpriseFields.departments = departmentsAsOptions.filter((dpt) => (
          departmentsRes.data.map((d) => d.id).includes(dpt.id)
        ));
      }
    }
    if (fields.includes(ConnectionTaskField.TURNOVER)) {
      if (enterpriseFieldRes.data.id) {
        const turnoversRes = await getEnterpriseBusinessTurnovers(
          enterpriseFieldRes.data.id,
          { fields: 'id,year,declarative_amount,currency' },
        );
        if (turnoversRes.success && turnoversRes.data.length) {
          tmpEnterpriseFields.turnovers = turnoversRes.data;
          data.latestTurnover = getHigherObjectBy('year', turnoversRes.data);
        }
      }
    }
    if (fields.includes(ConnectionTaskField.EMPLOYEES_NUMBER)) {
      if (enterpriseFieldRes.data.id) {
        const [employeeRangesRes, employeesRes] = await Promise.all([
          getEmployeeNumberRanges({ fields: 'id,min,max' }),
          getEnterpriseEmployeeNumbers(
            enterpriseFieldRes.data.id,
            { fields: 'id,year,declarative_range_id' },
          ),
        ]);
        if (employeeRangesRes.success && employeeRangesRes.data.length) {
          data.employeeNumberRanges = employeeRangesRes.data;
        }
        if (employeesRes.success && employeesRes.data.length) {
          tmpEnterpriseFields.employeeNumbers = employeesRes.data;
          data.latestEmployeeNumber = getHigherObjectBy('year', employeesRes.data);
        }
      }
    }
    data.enterpriseFields = tmpEnterpriseFields;
  }
  return data;
};
