import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import { cloneDeep } from 'lodash';

import { ApiResponse, handleResponse, safeFetch } from '@root/helpers/response-handler';
import { HookApiConfig } from '@root/interfaces/api-response.interface';
import { QueryboyOptions } from '@root/interfaces/queryboy.interface';
import useCurrentApp from '@root/hooks/useCurrentApp';
import { queryBoySearch, queryToString } from '@root/helpers/utils';
import useSafeState from './useSafeState';

type DynamicParams = Record<string, any>;

type ApiSafeFetchCallback<T> = (
  params: DynamicParams,
  opts?: QueryboyOptions,
) => Promise<T>;

type SafeFetchHookArr<T> = [
  init: ApiSafeFetchCallback<void>,
  data: T,
  loading: boolean,
  setData: Dispatch<SetStateAction<T>>,
  callApi: ApiSafeFetchCallback<ApiResponse<T> | null>,
];

interface SafeFetchHookObj<T> {
  init: ApiSafeFetchCallback<void>,
  data: T,
  loading: boolean,
  setData: Dispatch<SetStateAction<T>>,
  callApi: ApiSafeFetchCallback<ApiResponse<T> | null>,
}

type SafeFetchHook<T> = SafeFetchHookArr<T> & {
  toObject: SafeFetchHookObj<T>,
  callApi: ApiSafeFetchCallback<ApiResponse<T> | null>,
};

type CallbackTransform<T> = (x: any) => (T | undefined);

export type CallbackSafeFetch = (
  config: HookApiConfig,
  params: DynamicParams,
  opts?: QueryboyOptions,
) => Promise<ApiResponse<any>>;

const doCallApi = <T>(
  app: string,
  {
    config,
    params,
    opts,
    isPublic,
  }: { config: HookApiConfig, params: DynamicParams, opts?: QueryboyOptions, isPublic: boolean },
) => {
  let url = Object.keys(params).reduce(
    (acc: string, key: string) => acc.replace(`#${key}`, params[key]),
    config.url!.replace('#userType', app),
  );
  url += (opts ? queryBoySearch(opts) : '');
  if (url.includes('#')) throw new Error(`Dynamic data in url missing : ${url}`);
  if (params.query) {
    url += queryToString(params.query);
  }
  const _config = cloneDeep(config);
  if (params.body) {
    _config.body = JSON.stringify(params.body);
  }
  delete _config.url;
  if (isPublic) {
    const apiKey = { 'X-API-KEY': process.env.REACT_APP_API_KEY };
    _config.headers = _config.headers
      ? { ..._config.headers, ...apiKey }
      : apiKey;
    return handleResponse<T>(
      fetch(url, _config),
    );
  }
  return safeFetch<T>(url, _config);
};

const useSafeFetch = <T = unknown>(
  config: HookApiConfig,
  defaultValue?: T,
  transform?: CallbackTransform<T>
  | string
  | { isPublic?: boolean, callback?: CallbackTransform<T> },
): SafeFetchHook<T> => {
  const [data, setData] = useSafeState<T | undefined>(defaultValue);
  const [loading, setLoading] = useSafeState<boolean>(true);
  const { getApp } = useCurrentApp();

  const callApi = useCallback((
    params: DynamicParams = {},
    opts?: QueryboyOptions,
  ): Promise<ApiResponse<T> | null> => {
    setLoading(true);
    const app = getApp();
    const isPublic = typeof transform === 'object' ? !!transform.isPublic : false;
    if (app && config.url) {
      return doCallApi<T>(
        app,
        {
          config,
          params,
          opts,
          isPublic,
        },
      ).finally(() => setLoading(false));
    }
    return Promise.resolve(null);
  }, []);

  const init = async (params: DynamicParams = {}, opts?: QueryboyOptions) => {
    const res = await callApi(params, opts);
    setLoading(false);
    if (res?.success) {
      if (typeof transform === 'string') {
        setData(res.data[transform]);
      } else if (typeof transform === 'function') {
        setData(transform(res.data));
      } else if (typeof transform === 'object') {
        if (transform.callback) setData(transform.callback(res.data));
      } else {
        setData(res.data);
      }
    }
  };

  const obj = useMemo(() => ({
    init,
    data,
    loading,
    setData,
    callApi,
  }), [data, loading]);

  return useMemo(() => {
    const a = [init, data, loading, setData, callApi];
    (a as any).toObject = obj;
    (a as any).callApi = callApi;
    return a as SafeFetchHook<T>;
  }, [data, obj]);
};

export const useSafeFetchCallback = <T = unknown>(
  callback: (callbackSafeFetch: CallbackSafeFetch, ...args) => Promise<T>,
): (...args) => Promise<T> => {
  const { getApp } = useCurrentApp();

  const callApi = (
    config: HookApiConfig,
    params: DynamicParams = {},
    opts?: QueryboyOptions,
  ) => doCallApi(getApp(), {
    config,
    params,
    opts,
    isPublic: false,
  });

  return (...args) => callback(callApi, ...args);
};

export const usePublicFetch = <T = unknown>(
  config: HookApiConfig,
  defaultValue?: T,
  callbackOrKey?: ((x: any) => (T | undefined)) | string,
) => useSafeFetch(
    config,
    defaultValue,
    {
      callback: callbackOrKey as CallbackTransform<T>,
      isPublic: true,
    },
  );

export default useSafeFetch;
