import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import AWContainer from '@root/components/AWContainer/AWContainer';
import BlockFilter from '@customer/components/BlockFilter/BlockFilter';
import { Block as IBlock, NewBlock as INewBlock } from '@customer/interfaces/block.interface';
import Block from '@customer/components/Block/Block';
import BlockInline from '@customer/components/Block/BlockInline';
import BlockEditorForm from '@customer/components/BlockEditorForm/BlockEditorForm';
import BlockList from '@customer/components/BlockList/BlockList';
import useSafeFetch from '@root/hooks/useSafeFetch';
import {
  getBlocksConfig, createBlockConfig, updateBlockConfig, deleteBlockConfig, searchBlocksConfig,
} from '@root/api-configs/blocks.api.config';
import { UserContext } from '@root/contexts/user.context';

const Library = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [blockToUpdate, setBlockToUpdate] = useState<IBlock | null>(null);
  const [isViewList, setViewList] = useState<boolean>(true);
  const [isOnEdit, setIsOnEdit] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<IBlock[]>([]);

  // CALL API
  const [initBlocks, blocks, loading] = useSafeFetch<IBlock[]>(getBlocksConfig, []);
  const createBlockService = useSafeFetch<IBlock>(createBlockConfig).callApi;
  const updateBlockService = useSafeFetch<IBlock>(updateBlockConfig).callApi;
  const deleteBlockService = useSafeFetch<IBlock>(deleteBlockConfig).callApi;
  const searchBlockService = useSafeFetch<IBlock[]>(searchBlocksConfig).callApi;

  useEffect(() => {
    initBlocks({}, { fields: 'id,description,type,content,enterprise_id,created_at' });
  }, []);

  useEffect(() => {
    setSearchResults(blocks);
  }, [blocks]);

  const getUserEnterpriseId = () => {
    const enterprise = user?.enterprises?.find((e) => e.is_customer);
    return enterprise?.id ?? null;
  };

  const handleBlockUpdate = (id: string) => {
    const selectedBlock = blocks.find((b) => b.id === id);
    if (selectedBlock) {
      setBlockToUpdate(selectedBlock);
      setIsOnEdit(true);
    }
  };

  const handleBlockCreate = () => {
    setBlockToUpdate(null);
    setIsOnEdit(true);
  };

  const handleFiltersSearch = async (term: string) => {
    if (term) {
      const response = await searchBlockService({ body: { searchTerm: term } });
      setSearchResults(response?.data || []);
    } else {
      initBlocks({}, { fields: 'id,description,type,content,enterprise_id,created_at' });
    }
  };

  const createNewBlock = async (content: string, description: string, type: string) => {
    const newBlock: INewBlock = {
      type,
      description,
      content,
      enterprise_id: getUserEnterpriseId(),
    };
    await createBlockService({ body: newBlock });
  };

  const updateBlock = async (content: string, description: string, type: string) => {
    const updatedBlock: IBlock = {
      ...blockToUpdate!,
      type,
      description,
      content,
    };
    await updateBlockService({
      id: blockToUpdate?.id,
      body: updatedBlock,
    });
  };

  const deleteBlock = async (blockId: string) => {
    await deleteBlockService({
      id: blockId,
    });
    handleFiltersSearch(searchTerm);
    initBlocks({}, { fields: 'id,description,type,content,enterprise_id,created_at' });
  };

  const handleBlockSave = async (content: string, description: string, type: string) => {
    if (!blockToUpdate) {
      await createNewBlock(content, description, type);
    } else {
      await updateBlock(content, description, type);
    }
    handleFiltersSearch(searchTerm);
    setIsOnEdit(false);
  };

  return (
    <AWContainer
      isLoading={loading}
    >
      <AWContainer.Main
        title={t('Library.library', 'Bibliothèque de blocs')}
      >
        {isOnEdit ? (
          <BlockEditorForm
            block={blockToUpdate || null}
            saveChanges={handleBlockSave}
            cancelChanges={() => setIsOnEdit(false)}
          />
        ) : (
          <BlockList
            handleBlockCreate={handleBlockCreate}
            isViewList={isViewList}
            setViewList={setViewList}
            blocks={searchTerm.length > 0 ? searchResults : blocks}
            handleBlockUpdate={handleBlockUpdate}
            deleteBlock={deleteBlock}
            BlockComponent={isViewList ? BlockInline : Block}
          />
        )}
      </AWContainer.Main>

      <AWContainer.Side>
        <h3>{t('Library.filter', 'Filtrer')}</h3>
        <BlockFilter
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleFiltersSearch={handleFiltersSearch}
        />
      </AWContainer.Side>
    </AWContainer>
  );
};

export default Library;
