import React, {
  createContext, ReactNode, useState, useMemo,
} from 'react';
import { LegendreProvider } from '@customer/interfaces/legendre.interface';

interface ILegendreProviderContext {
  setProvider: (provider?: LegendreProvider) => void;
  provider?: LegendreProvider;
}

export const LegendreProviderContext = createContext<ILegendreProviderContext>({
  setProvider: () => { },
});

const LegendreProviderContextProvider = ({ children }: { children: ReactNode }) => {
  const [provider, setProvider] = useState<LegendreProvider>();
  const value = useMemo(() => ({ provider, setProvider }), [provider]);
  return (
    <LegendreProviderContext.Provider
      value={value}
    >
      {children}
    </LegendreProviderContext.Provider>
  );
};

export default LegendreProviderContextProvider;
