import React from 'react';
import { Card } from 'react-bootstrap';

interface Props {
  image: string,
  title: string,
  text: string,
  isInactive?: boolean,
}

const AWCard = ({
  image,
  title,
  text,
  isInactive,
}: Props) => (
  <Card className={isInactive ? 'aw-card aw-card--inactive' : 'aw-card'}>
    <Card.Img variant="top" src={image} className="aw-card__img-top" />
    <Card.Body>
      <Card.Title className="aw-card__title">
        {title}
      </Card.Title>
      <Card.Text className="aw-card__text">
        {text}
      </Card.Text>
    </Card.Body>
  </Card>
);

AWCard.defaultProps = {
  isInactive: false,
};

export default AWCard;
