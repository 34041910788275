import React, {
  useContext, useState, useRef, useEffect,
} from 'react';
import { UserContext } from '@root/contexts/user.context';
import logo from '@root/assets/addworking_logo.svg';
import arrowDropDown from '@root/assets/arrow-dropdown.svg';
import addworking from '@root/assets/addworking.svg';
import { MenuTabsContext } from '@root/contexts/menuTabs.context';
import UserPopup from './UserPopup';
import SideBarMenu from './SideBarMenu';

const SideBar = () => {
  const { user } = useContext(UserContext);
  const { menuTabs: menus } = useContext(MenuTabsContext);
  const [isUserOpen, setIsUserOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsUserOpen(false);
    }
  };
  const handleFrameClicked = () => {
    setIsUserOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('frameClicked', handleFrameClicked);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('frameClicked', handleFrameClicked);
    };
  }, []);

  return (
    <div className="sidebar">
      <div className="section">
        <div className="section-logo">
          <img src={logo} alt="Addworking" />
          <img src={addworking} alt="Addworking" className="section-logo-addworking" />
        </div>
      </div>
      <ul className="scrollable">
        {menus.map((menu) => (
          <SideBarMenu
            key={menu.title}
            title={menu.title}
            subMenus={menu.subMenus}
          />
        ))}
      </ul>
      <div
        ref={wrapperRef}
        className="section section-user"
      >
        <div
          className="user-tab"
          onClick={() => setIsUserOpen(!isUserOpen)}
          aria-hidden="true"
        >
          <img
            src={user.picture}
            alt={`${user.firstname} ${user.lastname}`}
            className="user-tab-avatar"
          />
          <div className="user-tab-details">
            <p className="title">{user.currentEnterprise?.name || ''}</p>
            <p className="subtitle opacity-50">
              {`${user.firstname} ${user.lastname}`}
            </p>
          </div>
          <img
            src={arrowDropDown}
            alt="Account"
            className={`arrow${isUserOpen ? '--rotate' : ''}`}
          />
        </div>
        <UserPopup active={isUserOpen} closePopup={() => setIsUserOpen(false)} />
      </div>
    </div>
  );
};

export default SideBar;
