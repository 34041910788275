import React, {
  useEffect,
  useState,
  useContext,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Alert,
  Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import useLoadingPromise from '@root/hooks/useLoadingPromise';

import {
  ConnectionTask,
  Connection as IConnection,
  ConnectionStepStatus,
  ConnectionStep,
  ConnectionTaskTypes,
  ConnectionStatus,
  ConnectionUserType,
} from '@root/interfaces/connection.interface';
import { ValueOf } from '@root/interfaces/utils.interface';
import { UserContext } from '@root/contexts/user.context';
import {
  updateConnectionStep as updateConnectionStepService,
} from '@customer/services/connection.service';
import AWContainer from '@root/components/AWContainer/AWContainer';
import { getField } from '@customer/services/enterprise.service';
import { getStepStatus } from '@customer/helpers/connection.helper';
import ValidateChoice from '@customer/components/ModalConnection/ValidateChoice';
import ModalProvider from '@customer/components/ModalProvider/ModalProvider';
import ModalCustomField from '@customer/components/ModalCustomField/ModalCustomField';
import ModalContact from '@customer/components/TaskModals/ModalContact/ModalContact';
import FieldsModal from '@customer/components/TaskModals/FieldsModal/FieldsModal';
import ModalActivity from '@customer/components/TaskModals/ModalActivity/ModalActivity';
import FrameModal from '@customer/components/TaskModals/FrameModal/FrameModal';
import SurveyModal from '@customer/components/TaskModals/SurveyModal/SurveyModal';
import AWButton from '@root/components/AWButtons/AWButton';
import { getSurvey } from '@customer/services/survey.service';
import Tooltip from '@root/components/Tooltip/Tooltip';
import ConnectionTaskTooltip from '@customer/components/ConnectionTaskTooltip/ConnectionTaskTooltip';
import CardConnectionTask from '@root/components/Cards/CardConnectionTask/CardConnectionTask';
import useSafeFetch, { useSafeFetchCallback } from '@root/hooks/useSafeFetch';
import {
  getConnectionCheckersConfig,
  getConnectionConfig,
  getConnectionTasksOwnersConfig,
  getConnectionValidatorsConfig,
  updateConnectionConfig,
} from '@root/api-configs/connection.api.config';
import { Enterprise } from '@root/interfaces/enterprise.interface';
import { getEnterpriseConfig } from '@root/api-configs/enterprise.api.config';
import {
  buildTaskStatus,
  fillConnectionWithUsers,
  getUserWithRights,
  mapConnectionTasks,
  buildTaskTitle,
  asyncMapConnectionTasks,
  finalizeConnectionBehaviour,
} from '@customer/views/Connections/connections.builder';
import ConnectionSkeleton from '@root/components/ConnectionSkeleton/ConnectionSkeleton';

enum ConnectionLoadingSteps {
  Init = 'init',
  Reload = 'reload',
  Visual = 'visual',
  Titles = 'titles',
  Data = 'data',
  End = 'end',
}

const Connection = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { connectionId }: { connectionId: string } = useParams();
  const [providerId, setProviderId] = useState<string>();
  const { push } = useHistory();
  const { isLoading, waitWithLoad } = useLoadingPromise();
  const [isProviderOpen, setIsProviderOpen] = useState<boolean>(false);
  const [fullyLoaded, setFullyLoaded] = useState<boolean>(false);
  const [taskReloading, setTaskReloading] = useState<string>('');
  const [
    loadingSteps,
    setLoadingSteps,
  ] = useState<ConnectionLoadingSteps>(ConnectionLoadingSteps.Init);
  const [confirmButtonActive, setConfirmButtonActive] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<{
    message?: string,
    variant?: 'success' | 'danger',
    show: boolean,
  }>({ show: false });
  const [modal, setModal] = useState<{
    isOpen: boolean,
    isCloseButton?: boolean,
    title?: string,
    body?: JSX.Element,
  }>({ isOpen: false });
  const {
    init: initConnection,
    data: connection,
    setData: setConnection,
    callApi: getConnection,
  } = useSafeFetch<IConnection>(getConnectionConfig).toObject;
  const [initProvider, provider, loadingProvider] = useSafeFetch<Enterprise>(getEnterpriseConfig);
  const updateConnection = useSafeFetch<IConnection>(updateConnectionConfig).callApi;

  const getUsersRights = useSafeFetchCallback(getUserWithRights);
  const getTaskTitle = useSafeFetchCallback(buildTaskTitle);

  const getUsersWithRights = async () => {
    if (!connection.validators) {
      const [checkers, owners, validators] = await Promise.all([
        getUsersRights(
          ConnectionUserType.Checker,
          {
            params: { connectionId },
            config: getConnectionCheckersConfig,
          },
        ),
        getUsersRights(
          ConnectionUserType.Owner,
          {
            params: { connectionId },
            config: getConnectionTasksOwnersConfig,
          },
        ),
        getUsersRights(
          ConnectionUserType.Validator,
          {
            params: {
              configId: connection.config_id,
            },
            config: getConnectionValidatorsConfig,
          },
        ),
      ]);
      setConnection(fillConnectionWithUsers(connection, [...checkers, ...owners, ...validators]));
      setLoadingSteps(ConnectionLoadingSteps.Titles);
    }
  };

  const fillWithTitles = async () => {
    const _connection = await asyncMapConnectionTasks(
      connection,
      async (task) => {
        const res = await getTaskTitle(task);
        return { ...task, title: res.title };
      },
    );
    setConnection(_connection);
    setLoadingSteps(ConnectionLoadingSteps.Visual);
  };

  const reloadConnection = async () => {
    const res = await getConnection({ connectionId });
    if (res?.success) {
      // Merge old and new connection
      const steps = connection.steps?.map((step) => {
        const tasks = step.tasks.map((task) => {
          const _task = res.data!.steps
            ?.flatMap((s) => (s.tasks)).find((_t) => _t.id === task.id) || {};
          return { ...task, ..._task };
        });
        const _step = res.data!.steps?.find((s) => s.id === step.id) || {};
        return { ...step, ..._step, tasks };
      }) || [];
      const _connection = { ...connection, ...(res.data || {}), steps };
      setConnection(_connection);
      setLoadingSteps(ConnectionLoadingSteps.Visual);
    }
  };

  const addStepsAndTasksBehaviour = async () => {
    const _connection = await finalizeConnectionBehaviour(connection, user.id!);
    setConnection(_connection);
    setLoadingSteps(ConnectionLoadingSteps.End);
    setFullyLoaded(true);
    setTaskReloading('');
  };

  useEffect(() => {
    if (user.id) {
      initConnection({ connectionId });
    }
  }, [user.id]);

  // Waiting for connection
  useEffect(() => {
    if (connection && loadingSteps === ConnectionLoadingSteps.Init) {
      setProviderId(connection.provider_id);
      initProvider(
        { identifier: connection.provider_id },
        { fields: ['identification_number', 'name'] },
      );
      getUsersWithRights();
    }
  }, [connection]);

  // Control connection loading steps
  useEffect(() => {
    switch (loadingSteps) {
      case ConnectionLoadingSteps.Reload:
        reloadConnection();
        break;
      case ConnectionLoadingSteps.Titles:
        fillWithTitles();
        break;
      case ConnectionLoadingSteps.Visual:
        setConnection(
          mapConnectionTasks(
            connection,
            (task: ConnectionTask) => ({
              ...task,
              ...buildTaskStatus(task),
            }),
          ),
        );
        setLoadingSteps(ConnectionLoadingSteps.Data);
        break;
      case ConnectionLoadingSteps.Data:
        addStepsAndTasksBehaviour();
        break;
      default:
        break;
    }
  }, [loadingSteps]);

  useEffect(() => {
    let timeout;
    if (alertMessage.show) {
      timeout = setTimeout(() => {
        setAlertMessage({ ...alertMessage, show: false });
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [alertMessage]);

  useEffect(() => {
    if (connection?.steps && connection.validators && loadingSteps === ConnectionLoadingSteps.End) {
      const activeSteps = connection.steps.filter((step) => step.visible);
      const stepsOk = activeSteps.every((
        step,
      ) => step.status === ConnectionStepStatus.Validated);
      if (stepsOk) {
        const tasksOk = activeSteps.every((
          step,
        ) => step.tasks.every((task) => task.validated_at !== null));
        if (tasksOk && connection.status === ConnectionStatus.IN_PROGRESS
          && connection.validators?.find((u) => u.id === user.id)) {
          setConfirmButtonActive(true);
        }
      }
    }
  }, [connection?.validators, loadingSteps]);

  const closeModal = () => {
    setModal({ isOpen: false });
    setLoadingSteps(ConnectionLoadingSteps.Reload);
  };

  const updateConnectionStep = (
    action: ValueOf<typeof ConnectionStepStatus>,
    stepNumber: number,
  ) => async () => {
    const updateResponse = await waitWithLoad(
      updateConnectionStepService(connectionId, stepNumber, action),
    );
    if (updateResponse.success) {
      setAlertMessage({
        show: true,
        message: t('Connection.stepValidated', "L'étape a été validée"),
        variant: 'success',
      });
    } else {
      setAlertMessage({
        show: true,
        message: t('Connection.validationStepError', 'Une erreur est survenue'),
        variant: 'danger',
      });
    }
    closeModal();
  };

  const validateConnection = async () => {
    const validateRes = await updateConnection({
      connectionId,
      body: {
        status: ConnectionStatus.VALIDATED,
      },
    });
    if (validateRes?.success) {
      setAlertMessage({
        show: true,
        message: t('Connection.connexionValidated', 'La mise en relation a bien été validée'),
        variant: 'success',
      });
    } else {
      setAlertMessage({
        show: true,
        message: t(
          'Connection.errorValidating',
          'Une erreur est survenue',
        ),
        variant: 'danger',
      });
    }
    closeModal();
  };

  const handleCardClick = async (step: ConnectionStep, task: ConnectionTask) => {
    let modalTitle = '';
    setTaskReloading(task.id!);
    switch (task.content) {
      case ConnectionTaskTypes.Contact:
        setModal({
          isOpen: true,
          isCloseButton: true,
          title: t('ModalContact.title', 'Coordonnées des contacts'),
          body: <ModalContact
            connection={connection}
            task={task}
          />,
        });
        break;

      case ConnectionTaskTypes.Fields:
        setModal({
          isOpen: true,
          isCloseButton: true,
          title: t('FieldsModal.title', "Informations de l'entreprise"),
          body: <FieldsModal
            providerId={connection?.provider_id || ''}
            fields={task.customer_data?.split(';') || []}
          />,
        });
        break;

      case ConnectionTaskTypes.Activity:
        setModal({
          isOpen: true,
          isCloseButton: true,
          title: t('ModalActivity.title', "Activités de l'entreprise"),
          body: <ModalActivity
            providerId={connection?.provider_id}
          />,
        });
        break;

      case ConnectionTaskTypes.DocumentType:
        setModal({
          isOpen: true,
          isCloseButton: true,
          title: t('TaskModal.title', 'Documents soumis'),
          body: <FrameModal
            path={`/addworking/enterprise/${connection?.provider_id}/document/${task.provider_data}`}
          />,
        });
        break;

      case ConnectionTaskTypes.CustomField:
        if (task.customer_data) {
          const fieldRes = await getField(task.customer_data);
          if (fieldRes.success && fieldRes.data.title) {
            modalTitle = fieldRes.data.title;
          }
        }
        setModal({
          isOpen: true,
          isCloseButton: true,
          title: modalTitle,
          body: <ModalCustomField
            fieldId={task.customer_data}
            providerId={providerId}
            onClose={closeModal}
            isDisabled={step.tasks.every((_t) => _t.validated_at)}
          />,
        });
        break;

      case ConnectionTaskTypes.Form:
        if (task.customer_data) {
          const surveyRes = await getSurvey(task.customer_data);
          if (surveyRes.success) {
            setModal({
              isOpen: true,
              isCloseButton: true,
              title: surveyRes.data?.label,
              body: <SurveyModal
                survey={surveyRes.data}
                enterpriseId={providerId}
                onClose={closeModal}
                isDisabled={step.tasks.every((_t) => _t.validated_at)}
              />,
            });
          }
        }
        break;

      case ConnectionTaskTypes.BusinessCompliance:
        push(`/customer/v1/addworking/enterprise/${connection?.provider_id}/document#nav-documents-metier-tab`);
        break;

      case ConnectionTaskTypes.LegalCompliance:
        push(`/customer/v1/addworking/enterprise/${connection?.provider_id}/document#nav-documents-legaux-tab`);
        break;

      case ConnectionTaskTypes.Check:
        setModal({
          isOpen: true,
          isCloseButton: true,
          title: t('Connection.validateStep', "Valider l'étape"),
          body: <ValidateChoice
            description={t(
              'ValidateStep.options',
              `Vous êtes sur le point de valider l'étape numéro {{stepNumber}}
                de votre mise en relation avec {{providerName}}`,
              { stepNumber: step.step, providerName: connection?.provider_name },
            )}
            isLoading={isLoading}
            onCancel={closeModal}
            onConfirm={updateConnectionStep(ConnectionStepStatus.Validated, step.step)}
          />,
        });
        break;

      default:
        break;
    }
  };

  const handleConfirmConnection = () => {
    setModal({
      isOpen: true,
      isCloseButton: true,
      title: t('Connection.validateConnection', 'Valider la mise en relation'),
      body: <ValidateChoice
        description={t(
          'ValidateConnection.options',
          "Vous êtes sur le point d'accepter la mise en relation avec {{providerName}}",
          { providerName: connection?.provider_name },
        )}
        isLoading={isLoading}
        onCancel={closeModal}
        onConfirm={validateConnection}
      />,
    });
  };

  const handleProviderModal = (isOpen: boolean) => () => {
    setIsProviderOpen(isOpen);
  };

  const canDisplayTask = (task?: ConnectionTask) => (
    (
      fullyLoaded
      && (
        task?.id !== taskReloading
        || (task?.id === taskReloading && loadingSteps === ConnectionLoadingSteps.End)
      ) && (
        task?.content !== ConnectionTaskTypes.Check
        || (
          task?.content === ConnectionTaskTypes.Check
          && loadingSteps === ConnectionLoadingSteps.End
        )
      )
    )
    || (
      !fullyLoaded
      && ![ConnectionLoadingSteps.Init, ConnectionLoadingSteps.Titles].includes(loadingSteps)
    )
  );

  return (
    <>
      <ModalProvider
        providerId={providerId}
        isOpen={isProviderOpen}
        onClose={handleProviderModal(false)}
      />
      <AWContainer isLoading={isLoading}>
        <AWContainer.Main
          title={loadingProvider && !provider ? <Skeleton /> : t(
            'Connection.title',
            'Mise en relation avec {{provider}}',
            { provider: provider?.name },
          )}
          titleSide={!loadingProvider ? (
            <AWButton onClick={handleProviderModal(true)}>
              {t('Common.see', 'Voir')}
            </AWButton>
          ) : ''}
          titleClassName={loadingProvider ? 'w-60' : ''}
        >
          {
            !canDisplayTask() && !connection?.steps ? (
              <ConnectionSkeleton />
            ) : ''
          }
          {
            connection?.steps && connection?.steps.map((_step) => (
              <div key={_step.step}>
                <h2 className="text-dark font-size-21 my-4">
                  {`${t('Connection.step', 'Etape')} ${_step.step} : `}
                  <span
                    className="font-style-semibold"
                    style={{ color: getStepStatus(_step).color }}
                  >
                    {_step.visible
                      ? getStepStatus(_step).label
                      : t('Connection.stepNotActive', 'Non active')}
                  </span>
                </h2>
                {
                  _step.step_description && (
                    <p className="text-grey font-style-semibold mb-3">
                      {_step.step_description}
                    </p>
                  )
                }
                {_step.tasks.map((task) => (
                  <div key={task.id}>
                    {
                      canDisplayTask(task) ? (
                        <>
                          {task.disabled && connection.status !== ConnectionStatus.UNSEEN ? (
                            <ConnectionTaskTooltip
                              checkers={_step.checkers}
                              task={task}
                              stepNumber={_step.step}
                            />
                          ) : <> </>}
                          <CardConnectionTask
                            task={task}
                            className="mb-3"
                            onClick={() => handleCardClick(_step, task)}
                            actionText={task.content === ConnectionTaskTypes.CustomField
                              ? t('Action.update')
                              : t('Action.preview')}
                          />
                        </>
                      ) : (
                        <Skeleton height="100px" className="mb-15" />
                      )
                    }
                  </div>
                ))}
              </div>
            ))
          }
          {connection?.status !== ConnectionStatus.VALIDATED
          && connection?.status !== ConnectionStatus.UNSEEN ? (
            <div
              data-for="tooltip-btn-partnership"
              data-tip
              className="w-40 mx-auto mt-4 "
            >
              <AWButton
                type="button"
                onClick={handleConfirmConnection}
                disabled={!confirmButtonActive}

              >
                {t('Connection.confirmConnection', 'Confirmer la mise en relation')}
              </AWButton>
              {
                connection?.validators?.find((u) => u.id !== user.id) && (
                  <Tooltip id="tooltip-btn-partnership">
                    <div className="text-dark font-size-12">
                      {
                        connection?.validators?.length === 1 ? (
                          `${t(
                            'Connection.validator',
                            'Le compte suivant peut confirmer la mise en relation',
                          )}: ${connection?.validators[0].email}`
                        ) : (
                          <>
                            {`${t(
                              'Connection.validators',
                              'Les comptes suivants peuvent confirmer la mise en relation',
                            )}: `}
                            <ul className="mt-2 ps-3">
                              {connection?.validators?.map((_user) => (
                                <li key={_user.id}>
                                  {_user.email}
                                </li>
                              ))}
                            </ul>
                          </>
                        )
                      }
                    </div>
                  </Tooltip>
                )
              }
            </div>
            ) : ''}
        </AWContainer.Main>
        <AWContainer.Side />
      </AWContainer>
      <Modal
        show={modal.isOpen}
        onHide={closeModal}
        size="lg"
        centered
      >
        {(modal.title || modal.isCloseButton) && (
          <Modal.Header closeButton={modal.isCloseButton}>
            <Modal.Title className="text-dark">
              {modal.title}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          {modal.body}
        </Modal.Body>
      </Modal>
      <Alert
        show={alertMessage?.show}
        variant={alertMessage?.variant}
        className="position-absolute bottom-0 end-0 m-4"
      >
        {alertMessage.message}
      </Alert>
    </>
  );
};

export default Connection;
