import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@root/contexts/user.context';

const FrameModal = ({ path }: { path: string }) => {
  const [isFrameLoaded, setIsFrameLoaded] = useState(false);
  const { user } = useContext(UserContext);
  const { i18n } = useTranslation();

  const buildFrameUrl = (url: string) => {
    const baseUrl = new URL(`${process.env.REACT_APP_LARAVEL_URL}${url}`);
    baseUrl.searchParams.set('personal_token', user.v1token!);
    baseUrl.searchParams.set('locale', i18n.language.split('-')[0]);
    baseUrl.searchParams.set('modale', 'true');
    return baseUrl.toString();
  };

  return (
    <div className="w-100 vh-75 d-flex justify-content-center align-items-center">
      {!isFrameLoaded && <div className="spinner-border text-dark" />}
      <iframe
        title="document"
        className={isFrameLoaded ? 'w-100 h-100' : 'd-none'}
        src={buildFrameUrl(path)}
        // eslint-disable-next-line react/no-unknown-property
        onLoad={() => setIsFrameLoaded(true)}
      />
    </div>
  );
};

export default FrameModal;
