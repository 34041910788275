import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import AWButton from '@root/components/AWButtons/AWButton';
import check from '@root/assets/check.svg';
import support from '@root/assets/support_help.svg';
import logout from '@root/assets/logout.svg';
import flag from '@root/assets/flag.svg';
import { Enterprise } from '@root/interfaces/enterprise.interface';
import { UserContext } from '@root/contexts/user.context';
import useCurrentApp, { Apps } from '@root/hooks/useCurrentApp';
import { CUSTOMER_BASE_PATH, PROVIDER_BASE_PATH } from '@root/helpers/constants.helper';
import { randString } from '@root/helpers/utils';
import ChooseLanguage from '@root/components/ChooseLanguage/ChooseLanguage';

interface Props {
  active: boolean;
  closePopup: () => void;
}

const UserPopup = ({ active, closePopup }: Props) => {
  const { t } = useTranslation();
  const { isApp } = useCurrentApp();
  const { user, setUser } = useContext(UserContext);
  const { push } = useHistory();

  const [langOpen, setLangOpen] = useState(false);

  const basePath = isApp(Apps.Customer) ? CUSTOMER_BASE_PATH : PROVIDER_BASE_PATH;

  const handleEnterpriseChange = (enterprise: Enterprise) => {
    if (user.currentEnterprise?.identification_number !== enterprise.identification_number) {
      closePopup();
      setUser({ ...user, currentEnterprise: enterprise });
    }
  };

  const handleNewEnterprise = () => {
    closePopup();
    push(`${PROVIDER_BASE_PATH}/enterprises/add`);
  };

  const handleUpdateProfile = () => {
    closePopup();
    push(`${basePath}/profile/name`);
  };

  const isActiveEnterprise = (enterprise: Enterprise): boolean => (
    user.currentEnterprise?.identification_number === enterprise.identification_number
  );

  return (
    <div className={`user-popup${active ? '--active' : ''}`}>
      <div className="user-popup-section user-popup-details">
        <img
          src={user.picture}
          alt={`${user.firstname} ${user.lastname}`}
        />
        <p className="subtitle opacity-50">
          {`${user.firstname} ${user.lastname}`}
        </p>
        <p className="subtitle">{user.email}</p>
        <AWButton
          className="btn-transparent subtitle opacity-45"
          backgroundColor="transparent"
          color="black"
          type="button"
          onClick={handleUpdateProfile}
        >
          {t('SideBar.updateProfile', 'Modifier le profil')}
        </AWButton>
      </div>
      <div className="user-popup-section user-popup-companies">
        <div className="user-popup-companies-head">
          <p className="title">{t('SideBar.companies', 'Vos entreprises')}</p>
          {
            isApp(Apps.Provider) ? (
              <p
                aria-hidden="true"
                className="title pointer opacity-45"
                onClick={handleNewEnterprise}
              >
                {`+ ${t('Action.add')}`}
              </p>
            ) : ''
          }
        </div>
        <ul className="scrollbar-visible user-popup-companies__list">
          {user.currentAppEnterprises?.map((enterprise) => (
            <Link key={enterprise.id} to={{ state: { key: randString() } }}>
              <li
                className={
                  `opacity-45 user-popup-link${isActiveEnterprise(enterprise) ? '--active' : ''}`
                }
                onClick={() => handleEnterpriseChange(enterprise)}
                aria-hidden="true"
              >
                <div className="icon-center">
                  {isActiveEnterprise(enterprise) && (
                    <img
                      src={check}
                      alt={enterprise.name}
                    />
                  )}
                </div>
                <p className="title ms-3">
                  {enterprise.name}
                </p>
              </li>
            </Link>
          ))}
        </ul>
      </div>
      <div className="user-popup-section">
        <ul>
          <li
            className={`user-popup-link${langOpen ? '' : ' opacity-45'}`}
            aria-hidden="true"
          >
            <div className="icon-center">
              <img
                src={flag}
                alt="Language"
                width="80%"
              />
            </div>
            <div className="flex-1 ms-3">
              <ChooseLanguage
                menuPlacement="top"
                onMenuOpen={() => setLangOpen(true)}
                onMenuClose={() => setLangOpen(false)}
                noMargin
              />
            </div>
          </li>
          <li
            className="user-popup-link opacity-45"
          >
            <div className="icon-center">
              <img
                src={support}
                alt="Support"
              />
            </div>
            <a
              href="https://intercom.help/addworking/fr/"
              target="_blank"
              rel="noreferrer"
              className="title ms-3"
            >
              {t('UserPopup.support', 'Support & Aide')}
            </a>
          </li>
          <li
            className="user-popup-link opacity-45"
            onClick={() => push(`${basePath}/v1/logout`)}
            aria-hidden="true"
          >
            <div className="icon-center">
              <img
                src={logout}
                alt="Logout"
                width="100%"
              />
            </div>
            <p className="title ms-3">
              {t('UserPopup.logout', 'Se déconnecter')}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserPopup;
