import React, {
  useState,
  useContext,
  useEffect,
} from 'react';

import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { UserContext } from '@root/contexts/user.context';
import { Field, FieldReference } from '@root/interfaces/field.interface';
import {
  createFieldData,
} from '@customer/services/provider.service';
import AWButton from '@root/components/AWButtons/AWButton';
import { AlertContext } from '@root/contexts/alert.context';
import { getField } from '@customer/services/enterprise.service';
import { addUserToTask } from '@customer/services/connection.service';
import { Option } from '@root/interfaces/utils.interface';
import AWSelect from '@root/components/AWSelect/AWSelect';
import useSafeFetch from '@root/hooks/useSafeFetch';
import { getProviderFieldsConfig } from '@root/api-configs/enterprise.api.config';

interface Props {
  onClose: () => void
  fieldId?: string,
  providerId?: string,
  isDisabled: boolean,
}

const ModalCustomField = ({
  onClose,
  fieldId,
  providerId,
  isDisabled,
}: Props) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { setNotif } = useContext(AlertContext);
  const [values, setValues] = useState<Option[] | Option>();
  const [field, setField] = useState<Field>();
  const getProviderFields = useSafeFetch<Field[]>(getProviderFieldsConfig, []).callApi;

  const getCustomField = async (id) => {
    const fieldRes = await getField(id);
    if (fieldRes.success && fieldRes.data) {
      setField(fieldRes.data);
    }
  };

  useEffect(() => {
    if (fieldId) {
      getCustomField(fieldId);
    }
  }, [fieldId]);

  const getProviderField = async () => {
    if (providerId && fieldId) {
      const providerFieldRes = await getProviderFields({ providerId });
      if (providerFieldRes?.success && providerFieldRes?.data) {
        setValues(providerFieldRes.data.reduce((acc: Option[], { id, content, content_id }) => {
          if (id === fieldId) {
            acc.push({ value: content_id, label: content || '' });
          }
          return acc;
        }, []));
      }
    }
  };

  useEffect(() => {
    getProviderField();
  }, [providerId, fieldId]);

  const submitFieldChange = async (e) => {
    e.preventDefault();
    if (values && fieldId && providerId && user?.id) {
      let contentIds: string[] = [];
      if (Array.isArray(values)) {
        contentIds = values.reduce((acc: string[], { value }) => {
          if (value?.length) {
            acc.push(value);
          }
          return acc;
        }, []);
      } else if (values?.value?.length) {
        contentIds.push(values.value);
      }
      const createFieldRes = await createFieldData(
        fieldId,
        {
          provider_id: providerId,
          content_ids: contentIds,
        },
      );
      if (createFieldRes.success) {
        await addUserToTask(fieldId, user.id);
        setNotif(t('Provider.fieldchangedsuccessfully', 'Votre demande a bien été enregistrée'));
        onClose();
      }
    }
  };

  return (
    <Form onSubmit={submitFieldChange}>
      <Form.Group key={field?.id} className="mb-4">
        <AWSelect
          isDisabled={isDisabled}
          isMulti={field?.is_multiple}
          options={field?.references?.map((ref: FieldReference) => (
            { value: ref.id, label: ref.content }
          ))}
          onChange={setValues}
          placeholder={field?.references?.[0]?.content || ''}
          value={values}

        />
      </Form.Group>
      <AWButton disabled={isDisabled}>
        {t('Action.validate')}
      </AWButton>
    </Form>
  );
};

ModalCustomField.defaultProps = {
  fieldId: '',
  providerId: '',
};

export default ModalCustomField;
