import React, { useState } from 'react';

import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import logo from '@root/assets/logo_green_addworking.svg';
import downArrow from '@root/assets/arrow-dropdown.svg';

/**
 * Header is a component that contains Addworking logo on the left and a nav
 *  bar on the right. Navbar contains log in page link and language choices dropdown menu.
 * @version 1
 * @since 7/10/2021
 */

const Header = ({ headerMenu }: { headerMenu?: boolean }) => {
  // Needed for translation
  const { t, i18n } = useTranslation();
  const locales = {
    fr: 'Français',
    en: 'English',
    // de: "Deutsch",
  };

  // Change dropdown title on language selection
  const [dropdownTitle, setdropdownTitle] = useState(
    t('Component.header.language', 'Langue'),
  );

  return (
    <Navbar expand="lg" className="header-container navbar-dark">
      <Navbar.Brand href="/">
        <img src={logo} alt="logo Addworking" className="logo-header" />
      </Navbar.Brand>
      {headerMenu
        && (
          <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav ">
              <Nav className="ms-auto">
                <NavDropdown
                  title={dropdownTitle}
                  id="basic-nav-dropdown"
                  className="color-white "
                >
                  {Object.keys(locales).map((locale) => (
                    <NavDropdown.Item
                      key={locale}
                      style={{
                        fontWeight:
                          i18n.resolvedLanguage === locale ? 'bold' : 'normal',
                      }}
                      type="submit"
                      onClick={() => {
                        i18n.changeLanguage(locale);
                        setdropdownTitle(locales[locale]);
                      }}
                    >
                      {locales[locale]}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
                <img src={downArrow} alt="arrow down" />
              </Nav>
            </Navbar.Collapse>
          </>
        )}
    </Navbar>
  );
};

Header.defaultProps = {
  headerMenu: true,
};

export default Header;
