import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fontawesome from '@fortawesome/fontawesome';
import { faCheck, faTimes } from '@fortawesome/fontawesome-free-solid';
import FileButton from '@customer/components/FileButton/FileButton';
import { CustomSurveyQuestion, Formats, Types } from '@root/interfaces/survey.interface';
import { formatDate } from '@root/helpers/utils';

interface Props {
  question: CustomSurveyQuestion,
}
fontawesome.library.add(faCheck, faTimes);

const SurveyAnswer = ({ question: { type, format, answer } }: Props) => {
  const { t } = useTranslation();

  const Value = useCallback(
    () => {
      if (type === Types.Array && Array.isArray(answer)
        && (format === Formats.Multi || format === Formats.Checkbox)) {
        return (
          <span className="d-flex">
            {answer?.map((val: string) => (
              <span key={val} className="margin-right-10 badge-array">
                {val}
              </span>
            ))}
          </span>
        );
      } if (type === Types.String && format === Formats.Date) {
        return (
          <span>
            {formatDate(answer)}
          </span>
        );
      } if (type === Types.String && format === Formats.File) {
        return (
          <FileButton fileId={answer!.toString()} />
        );
      } if (type === Types.Boolean) {
        return (
          <span>
            <FontAwesomeIcon icon={`${answer ? 'check' : 'times'}`} color={answer ? 'green' : 'red'} />
            &nbsp;
            {answer ? t('Common.yes', 'Oui') : t('Common.no', 'Non')}
          </span>
        );
      }
      return (
        <span>
          {answer}
        </span>
      );
    },
    [type, format, answer],
  );

  return (
    <Value />
  );
};

export default SurveyAnswer;
