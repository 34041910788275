import React from 'react';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';

interface Props {
  /** Precise landing page path when clicking on return button */
  onClick: () => void,
  className?: string,
}

/**
 * Return is a button who allows to go to previous page.
 * @version 1
 * @since 8/10/2021
 */

const ReturnButton = ({ onClick, className }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex ">
      <button type="button" onClick={onClick} className={`return-button ${className}`}>
        <FontAwesomeIcon icon={faCaretLeft} />
        <div className="pl-2 d-inline-block return-button__text">
          {t('Action.return')}
        </div>
      </button>
    </div>
  );
};

ReturnButton.defaultProps = {
  className: '',
};

export default ReturnButton;
