import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@root/contexts/user.context';
import { AlertContext } from '@root/contexts/alert.context';
import { newDate } from '@root/helpers/utils';
import usePrevious from '@root/hooks/usePrevious';
import { PUNCHOUT } from '@root/helpers/constants.helper';

import { getPunchoutSession } from '@customer/services/punchout.service';
import AWButton from '@root/components/AWButtons/AWButton';

const BackPunchout = () => {
  const { t } = useTranslation();
  const { tokenPO, setTokenPO } = useContext(UserContext);
  const { setNotif } = useContext(AlertContext);
  const [loop, setLoop] = useState<number>(0);
  const prevLoop = usePrevious(loop);

  const getSession = async () => {
    if (tokenPO) {
      const apiResponse = await getPunchoutSession(tokenPO);
      if (apiResponse.success) {
        return apiResponse.data;
      }
    }
    return null;
  };

  const handleClick = async () => {
    const session = await getSession();
    if (session) {
      window.location.replace(session.postUrl);
    }
  };

  // Old way to handle promise because of the setTimeout callback
  const handleSession = (ms = 60000): Promise<void> => (
    new Promise((resolve) => {
      setTimeout(() => {
        (async () => {
          const session = await getSession();
          if (session && newDate() > newDate(session.expiresAt)) {
            setTokenPO('');
            sessionStorage.removeItem(PUNCHOUT);
            setNotif({
              message: t('Punchout.expired', 'Votre session a expirée'),
              variant: 'danger',
            });
          }
          resolve();
        })();
      }, ms);
    })
  );

  useEffect(() => {
    if (tokenPO && prevLoop !== loop) {
      (async () => {
        await handleSession(loop === 0 ? 1000 : 60000);
        setLoop(loop + 1);
      })();
    }
  }, [loop, tokenPO]);

  if (tokenPO) {
    return (
      <AWButton
        className="back-punchout"
        label="back-coupa"
        onClick={handleClick}
      >
        {t('Punchout.backToCoupa', 'Retour à Star')}
      </AWButton>
    );
  }
  return <div />;
};

export default BackPunchout;
