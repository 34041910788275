/* eslint-disable @typescript-eslint/no-explicit-any */
export type Status = 'sketch' | 'launched' | 'stopped';

export enum AnswererTypes {
  Provider = 'provider',
  Customer = 'customer',
  all = 'all',
}

interface PageTitle {
  position: number,
  value: string,
}

export interface Survey {
  id?: string,
  country?: string,
  description?: string,
  enterpriseId?: string,
  label?: string,
  content?: SurveyQuestion[][],
  status?: Status,
  isUpdate?: boolean,
  answerer?: string;
  pageTitles?: PageTitle[],
}

export interface SurveyQuestion {
  key: string,
  title: string,
  type: string,
  description?: string,
  format?: string,
  visible?: boolean,
  items?: {
    type: string,
    value: string,
  }[]
  options?: {
    min?: number;
    max?: number;
    required?: boolean;
    match?: RegExp;
  },
  answer: SurveyAnswerValue,
}

export type CustomSurveyQuestion = SurveyQuestion & { answer: any };

export interface SurveyAnswerValue {
  question: string,
  key?: string,
  value?: any,
}

export interface SurveyAnswer {
  enterpriseId: string,
  values: SurveyAnswerValue[],
}

export interface SurveyAnswersCount {
  count: number;
  days?: {
    [key: string]: number;
  }
}

export enum Types {
  String = 'string',
  Array = 'array',
  Boolean = 'boolean',
  Number = 'number',
}

export enum Formats {
  Multi = 'multi',
  Select = 'select',
  Date = 'date',
  Text = 'text',
  Range = 'range',
  File = 'file',
  Checkbox = 'checkbox',
}
