import { Service } from '@root/interfaces/contact.interface';
import { Survey } from '@root/interfaces/survey.interface';
import {
  Activity as IActivity,
  BusinessTurnover,
  EmployeeNumber,
} from '@root/interfaces/enterprise.interface';
import { User } from '@root/interfaces/user.interface';
import { AWColors, MergeOf, Option } from '@root/interfaces/utils.interface';

export type ConnectionTaskContent =
  'DOCUMENT_TYPE'
  | 'COMPLIANCE'
  | 'LEGAL_COMPLIANCE'
  | 'BUSINESS_COMPLIANCE'
  | 'CUSTOM_FIELD'
  | 'FIELDS'
  | 'CONTACT'
  | 'ACTIVITY'
  | 'CHECK'
  | 'FORM';

export type ConnectionModalLoadingOption = 'accept' | 'decline' | '';

export type ConnectionLoadingOption = 'submit' | 'search' | 'enterprise' | '';

export interface Checker {
  step_id: string;
  user_id: string;
}

export interface Owner {
  task_id: string;
  user_id: string;
}

export interface Validator {
  id: string;
  user_id: string;
  config_id: string;
}

export interface TaskBuilderParams {
  checkers: Checker[];
  owners: Owner[];
  userId?: string;
}

export enum ConnectionStatus {
  UNSEEN = 'unseen',
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  VALIDATED = 'validated',
  DECLINED = 'declined',
  REJECTED = 'rejected',
  ABORTED = 'aborted',
}

export enum ConnectionTaskField {
  COMMERCIAL_BROCHURE = 'commercial_brochure',
  DEPARTMENTS = 'departments',
  WEBSITE = 'website',
  TURNOVER = 'business_turnover',
  EMPLOYEES_NUMBER = 'employees_number',
}

export enum ConnectionStatusToInt {
  UNSEEN = 1,
  PENDING = 2,
  IN_PROGRESS = 3,
  DONE = 4,
  VALIDATED = 5,
  DECLINED = 6,
  REJECTED = 7,
  ABORTED = 8,
}

export enum ConnectionStepStatus {
  Pending = 'pending',
  InProgress = 'in_progress',
  Validated = 'validated',
  Rejected = 'rejected',
}

export enum ConnectionTaskTypes {
  DocumentType = 'DOCUMENT_TYPE',
  Compliance = 'COMPLIANCE',
  LegalCompliance = 'LEGAL_COMPLIANCE',
  BusinessCompliance = 'BUSINESS_COMPLIANCE',
  CustomField = 'CUSTOM_FIELD',
  Fields = 'FIELDS',
  Contact = 'CONTACT',
  Activity = 'ACTIVITY',
  Check = 'CHECK',
  Form = 'FORM',
}

export enum TooltipMessage {
  NotAuthorized = 'notAuthorized',
  MissingTasks = 'missingTasks',
  ConditionNotFulfilled = 'conditionNotFulfilled',
  PreviousStepNotValidated = 'previousStepNotValidated',
  TaskNotReady = 'taskNotReady',
  StepInactive = 'stepInactive',
}

export enum AnswererTypes {
  Provider = 'provider',
  Customer = 'customer',
  All = 'all',
}

export enum InvitationResError {
  UserAlreadyMember = 'USER_ALREADY_MEMBER',
}

export interface ConnectionContact {
  id: string,
  email: string,
  phone_number?: string,
}

export interface Connection {
  id?: string,
  status?: ConnectionStatus,
  email?: string,
  phone_number?: string,
  created_at?: string,
  updated_at?: string,
  provider_id?: string,
  provider_name?: string,
  config_id?: string,
  config_title?: string,
  config_description?: string,
  customer_id?: string,
  customer_name?: string,
  customer_logo?: string,
  steps?: ConnectionStep[],
  validators?: MergeOf<User, Validator>[],
  config_logo?: string,
  customer_identification_number?: string,
  provider_identification_number?: string,
}

export interface ConnectionStep {
  id: string;
  config_step_id: string;
  step: number;
  step_description?: string;
  tasks: ConnectionTask[];
  status?: ConnectionStepStatus,
  validated_at?: string;
  validated_by?: string;
  refused_at?: string;
  visible?: boolean;
  checkers?: MergeOf<User, Checker>[];
  disabled?: boolean;
}

export interface ConnectionTask {
  id?: string,
  config_task_id?: string;
  content?: ConnectionTaskContent,
  done_at?: string,
  validated_at?: string,
  rejected_at?: string,
  provider_data?: string,
  customer_data?: string,
  icon?: string,
  color?: `${AWColors}`,
  status?: string,
  title?: string,
  disabled?: boolean,
  updatedAt?: string,
  updatedBy?: string,
  validator?: User,
  done_by?: string,
  owners?: MergeOf<User, Owner>[],
  tooltipMessage?: TooltipMessage,
  is_private?: boolean;
  hidden?: boolean;
}

export interface ConnectionConfig {
  id: string,
  status?: string,
  title: string,
  customer_id?: string;
}

export interface ConnectionFilter {
  search?: string,
  configOptions?: string[],
  statuses?: string[],
}

export interface CreateConnectionParams {
  config_id?: string,
  emails: string[],
  phone_numbers?: string[],
  provider_id: string,
  customer_id: string,
}

export interface ConnectionModal extends Connection {
  enterpriseName?: string,
  logo?: string,
  message?: string,
  loadingOption?: ConnectionModalLoadingOption,
  withConfig?: boolean,
}

export interface TasksExternalData {
  services?: Service[],
  surveys?: Survey[],
  activities?: IActivity[],
}

export interface IConnectionTaskField {
  commercialBrochure?: { url?: string, none?: boolean },
  website?: { url?: string, none?: boolean },
  departments?: Department[],
  turnovers?: BusinessTurnover[];
  employeeNumbers?: EmployeeNumber[];
}

export interface Department extends Option {
  id: string,
  code?: string,
}

export type ConnectionUser = Checker | Owner | Validator;

export enum ConnectionUserType {
  Checker = 'checker', // Can validate a step
  Owner = 'owner', // Can fill a task
  Validator = 'validator', // Can validate a connection
}

export interface InvitationRes {
  created_at: string;
  email: string;
  id: string;
  enterprise_guest_id?: string;
  enterprise_host_id?: string;
  status?: string;
  type?: string;
  updated_at?: string;
  user_host_id?: string;
}
