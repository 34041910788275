import React from 'react';
import {
  useRouteMatch,
  Switch,
} from 'react-router-dom';
import Survey from '@customer/views/Surveys/Survey';
import SurveyProvider from '@customer/views/Surveys/SurveyProvider/SurveyProvider';
import Surveys from '@customer/views/Surveys/Surveys';
import SurveysProviders from '@customer/views/Surveys/SurveysProviders/SurveysProviders';
import ProtectedRoute from '@root/routes/ProtectedRoute';

const SurveyRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={`${path}/:surveyId/provider/:providerId`} exact>
        <SurveyProvider />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/providers`} exact>
        <SurveysProviders />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/:id`} exact>
        <Survey />
      </ProtectedRoute>
      <ProtectedRoute path={path} exact>
        <Surveys />
      </ProtectedRoute>
    </Switch>
  );
};

export default SurveyRoutes;
