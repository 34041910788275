import React from 'react';
import { useRouteMatch, Switch } from 'react-router-dom';
import ProtectedRoute from '@root/routes/ProtectedRoute';
import { TabApprovalProviderKeywordRoutes } from '@customer/views/Approvals/ApprovalContainer/tab-approval.list';
import ApprovalContextProvider from '@customer/contexts/approval.context';
import ApprovalContainer from '@customer/views/Approvals/ApprovalContainer/ApprovalContainer';
import ApprovalPreview from '@customer/views/Approvals/ApprovalContainer/ApprovalPreview/ApprovalPreview';
import ApprovalInformations from '@customer/views/Approvals/ApprovalContainer/ApprovalInformations/ApprovalInformations';
import ApprovalSignature from '@customer/views/Approvals/ApprovalContainer/ApprovalSignature/ApprovalSignature';
import ApprovalSignataires from '@customer/views/Approvals/ApprovalContainer/ApprovalSignataires/ApprovalSignataires';

const ApprovalContainerRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ApprovalContextProvider>
        <ProtectedRoute path={`${path}/${TabApprovalProviderKeywordRoutes.INFOS}`} exact>
          <ApprovalContainer>
            <ApprovalInformations />
          </ApprovalContainer>
        </ProtectedRoute>
        <ProtectedRoute path={`${path}/${TabApprovalProviderKeywordRoutes.PREVIEW}`} exact>
          <ApprovalContainer>
            <ApprovalPreview />
          </ApprovalContainer>
        </ProtectedRoute>
        <ProtectedRoute path={`${path}/${TabApprovalProviderKeywordRoutes.SIGNATAIRES}`} exact>
          <ApprovalContainer>
            <ApprovalSignataires />
          </ApprovalContainer>
        </ProtectedRoute>
        <ProtectedRoute path={`${path}/${TabApprovalProviderKeywordRoutes.SIGNATURE}`} exact>
          <ApprovalContainer>
            <ApprovalSignature />
          </ApprovalContainer>
        </ProtectedRoute>
      </ApprovalContextProvider>
    </Switch>
  );
};

export default ApprovalContainerRoutes;
