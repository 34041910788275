import React, { useContext } from 'react';
import { ApprovalContext } from '@customer/contexts/approval.context';
import { ApprovalStatusEnum } from '@root/interfaces/approval.interface';
import { t } from 'i18next';

const ApprovalSignature = () => {
  const { approvalToDisplay } = useContext(ApprovalContext);

  return (
    <div>
      {/* temporary text */}
      {approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_PROVIDER ? (
        <p>IFrame Yousign</p>
      ) : (
        <p>{t('Approvals.waitingInformations', 'En attente d’informations complémentaires.')}</p>
      )}
    </div>
  );
};

export default ApprovalSignature;
