import React, {
  Children, useContext, useEffect, useState,
} from 'react';
import { ApprovalContext } from '@customer/contexts/approval.context';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import AWContainer from '@root/components/AWContainer/AWContainer';
import ResizableRectangle from '@root/components/ResizableRectangle/ResizableRectangle';
import { getFile } from '@root/services/file.service';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { mimeType } from '@root/helpers/utils';
import { useTranslation } from 'react-i18next';

const ApprovalSignataires = () => {
  const [isDocumentPDF, setIsDocumentPDF] = useState<boolean>();
  const [samplePDF, setSamplePDF] = useState<string>('');
  const { isLoading, waitWithLoad } = useLoadingPromise();
  const {
    propertySignatures, setTotalPages, currentPage, setCurrentPage, approvalToDisplay,
  } = useContext(ApprovalContext);
  const { t } = useTranslation();

  const getPdfFile = async () => {
    if (approvalToDisplay.document_id) {
      if (approvalToDisplay.mime_type === mimeType.pdf) {
        const res = await waitWithLoad(getFile(approvalToDisplay.document_id));
        setSamplePDF(res.data.signedUrl);
        setIsDocumentPDF(true);
      } else {
        setIsDocumentPDF(false);
      }
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
    setCurrentPage(1);
  };

  useEffect(() => {
    getPdfFile();
  }, []);

  return (
    <AWContainer className="pdf-overlay" isLoading={isLoading}>
      {isDocumentPDF
        ? (
          <>
            <Document
              file={samplePDF}
            /* eslint-disable-next-line react/jsx-no-bind */
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={currentPage} />
            </Document>
            <div className="overlay-element">
              {Children.toArray(propertySignatures.map((signature) => (
                <ResizableRectangle
                  signatureId={signature.id}
                  signatureName={signature.signatureName}
                />
              )))}
            </div>
          </>
        )
        : <p>{t('Approvals.docAvailable', 'Pas de document disponible')}</p>}
    </AWContainer>
  );
};

export default ApprovalSignataires;
