import React, { useMemo } from 'react';
import chroma from 'chroma-js';
import { AWColors } from '@root/interfaces/utils.interface';

interface Props {
  // The percentage of information filled.
  percentage: number;
}

/**
 * CircularProgress is a component that shows a progression, from 0% to 100%.
 *  It is represented by a circular colored gauge
 * @version 1
 * @since 18/03/2022
 */

// Set of colors (gradient) from red to green.
const scale = chroma.scale([AWColors.Red, AWColors.Orange, AWColors.Green]);

const CircularProgress = ({ percentage }: Props) => {
  // Transform input to integer
  const progressPercent = useMemo(() => parseInt(percentage.toFixed(), 10), [percentage]);

  // Profile fully completed or not.
  const isCompleted = useMemo(() => progressPercent === 100, [progressPercent]);

  // Progress color corresponding to the percentage of fill.
  const color = useMemo(() => scale((progressPercent || 0) / 100).hex(), [progressPercent]);

  // Green color if fully completed, conic gradient otherwise.
  const progressColor = useMemo(() => {
    if (isCompleted) {
      return AWColors.Green;
    }
    if (Number.isNaN(progressPercent)) {
      return 'white';
    }
    // Start the gradient 10% before the end.
    const startGradientAt = progressPercent - ((1 / 5) * progressPercent);

    return (
      `conic-gradient(from 270deg at 50% 50%, ${color} 0%,
        ${color} ${startGradientAt}%,
        white ${progressPercent || 0}%)`
    );
  }, [isCompleted, color, progressPercent]);

  return (
    <div className="circular-progress-wrapper">
      <svg viewBox="0 0 100 100" className="circular-progress" xmlns="http://www.w3.org/2000/svg">

        <clipPath id="clip">
          <path d="M 50 0 a 50 50 0 0 1 0 100 50 50 0 0 1 0 -100 v 8 a 42 42 0 0 0 0 84 42 42 0 0 0 0 -84" />
        </clipPath>

        <foreignObject x="0" y="0" width="100" height="100" clipPath="url(#clip)">
          <div
            className="gradient"
            style={{ background: progressColor }}
          />
        </foreignObject>

        <text x="50%" y="50%" dominantBaseline="middle">
          {`${!Number.isNaN(progressPercent) ? `${progressPercent}%` : '-'}`}
        </text>
      </svg>
    </div>
  );
};

export default CircularProgress;
