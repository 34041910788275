import React, { useMemo } from 'react';
import {
  Form,
} from 'react-bootstrap';
import { FieldReference } from '@root/interfaces/field.interface';
import { Option } from '@root/interfaces/utils.interface';
import AWSelect from '@root/components/AWSelect/AWSelect';

interface Props {
  id: string;
  title: string;
  references: FieldReference[];
  onChange: (id: string, values: Option[]) => void;
}

const CustomFieldsFilter = ({
  id,
  title,
  references,
  onChange,
}: Props) => {
  const options = useMemo(() => (
    references
      .map((ref) => ({ value: ref.id, label: ref.content }))
  ), [references]);
  const handleChange = (fields) => {
    onChange(id, fields);
  };

  return (
    <Form.Group className="mb-4">
      <Form.Label htmlFor="userIdentity">
        {title}
      </Form.Label>
      <AWSelect
        options={options}
        onChange={handleChange}
        isMulti
      />
    </Form.Group>
  );
};

export default CustomFieldsFilter;
