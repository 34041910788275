import React from 'react';
import { useTranslation } from 'react-i18next';

const Unauthorized = () => {
  const { t } = useTranslation();
  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <div>
        <h1 className="text-dark text-center mb-5">
          {t('StaticPage.Unauthorized.noAccess')}
          &nbsp;
          :
        </h1>
        <div>
          <ul className="mb-5">
            <li className="font-size-18">{t('StaticPage.Unauthorized.noRelation')}</li>
            <li className="font-size-18">{t('StaticPage.Unauthorized.noPremium')}</li>
            <li className="font-size-18">{t('StaticPage.Unauthorized.noAdminAccess')}</li>
          </ul>
          <p>
            {t('StaticPage.Unauthorized.support')}
            &nbsp;
            :
            &nbsp;
            <a href="https://intercom.help/addworking/fr/" target="_blank" rel="noreferrer">
              https://intercom.help/addworking/fr/
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
