import React, { useContext } from 'react';
import { SubMenu } from '@root/interfaces/menu.interface';
import SideBarMenuItem from '@root/components/SideBar/SideBarMenuItem';
import { NotificationContext } from '@root/contexts/notifications.context';

interface Props {
  title?: string,
  subMenus: SubMenu[],
}

const SideBarMenu = ({
  title,
  subMenus,
}: Props) => {
  const { notification } = useContext(NotificationContext);

  return (
    <li key={title} className="section">
      {title && (
        <p className="section-head title">
          {title}
        </p>
      )}
      <ul>
        {subMenus.map((sub) => (
          <SideBarMenuItem
            key={sub.title}
            icon={sub.icon}
            title={sub.title}
            linkTo={sub.linkTo}
            disabled={!!sub.disabled}
            count={sub.notificationKey && notification[sub.notificationKey]}
          />
        ))}
      </ul>
    </li>
  );
};

SideBarMenu.defaultProps = {
  title: '',
};

export default SideBarMenu;
