import React from 'react';

interface Props {
  color?: string;
  width?: string;
  height?: string;
}

const JobSVG = ({ color, width, height }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17.143 15">
    <path
      id="Icon_awesome-toolbox"
      data-name="Icon awesome-toolbox"
      d="M16.829,8.365,15.314,6.85a1.071,1.071,0,0,0-.758-.314h-1.7V3.857A1.607,1.607,0,0,0,11.25,2.25H5.893A1.607,1.607,0,0,0,4.286,3.857V6.536h-1.7a1.072,1.072,0,0,0-.758.314L.314,8.365A1.071,1.071,0,0,0,0,9.123v2.77H4.286v-.536a.536.536,0,0,1,.536-.536H5.893a.536.536,0,0,1,.536.536v.536h4.286v-.536a.536.536,0,0,1,.536-.536h1.071a.536.536,0,0,1,.536.536v.536h4.286V9.122A1.071,1.071,0,0,0,16.829,8.365ZM10.714,6.536H6.429V4.393h4.286ZM12.857,13.5a.536.536,0,0,1-.536.536H11.25a.536.536,0,0,1-.536-.536v-.536H6.429V13.5a.536.536,0,0,1-.536.536H4.821a.536.536,0,0,1-.536-.536v-.536H0v3.214A1.072,1.072,0,0,0,1.071,17.25h15a1.072,1.072,0,0,0,1.071-1.071V12.964H12.857Z"
      transform="translate(0 -2.25)"
      fill={color}
    />
  </svg>
);

JobSVG.defaultProps = {
  color: '#fff',
  width: '13.125',
  height: '15',
};

export default JobSVG;
