import { ApiResponse, handleResponse } from '@root/helpers/response-handler';
import { Item, PunchoutLog, PunchoutSession } from '@customer/interfaces/punchout.interface';

const baseUrl = `${process.env.REACT_APP_PUNCHOUT_API}/v1/punchout/customer`;

export const postPunchoutData = async (
  providerId: string,
  body: { items: Item[], token: string, quote?: string },
): Promise<ApiResponse<{ url: string, body: string; }>> => (
  handleResponse(
    fetch(
      `${baseUrl}/punchout/message/${providerId}`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    ),
  )
);

export const getPunchoutSession = async (
  sessionId: string,
): Promise<ApiResponse<PunchoutSession>> => (
  handleResponse(
    fetch(`${baseUrl}/punchout/sessions/${sessionId}`),
  )
);

export const createPunchoutLog = async (
  punchoutLog: PunchoutLog,
): Promise<ApiResponse> => (
  handleResponse(
    fetch(
      `${baseUrl}/punchout/logs`,
      {
        method: 'POST',
        body: JSON.stringify(punchoutLog),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    ),
  )
);
