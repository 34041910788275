import React, { ChangeEventHandler } from 'react';
import {
  Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AWButton from '@root/components/AWButtons/AWButton';
import { AWColors } from '@root/interfaces/utils.interface';
import AWSelect from '@root/components/AWSelect/AWSelect';
import { ApprovalFilter } from '@root/interfaces/approval.interface';
import {
  statusOptions,
  marketTypeOptions,
} from '@customer/views/Approvals/ApprovalsFilters/options.list';

interface Props {
  approvalFilters: ApprovalFilter;
  setApprovalFilters: (approvalFilters: ApprovalFilter) => void;
  handleFiltersSearch: () => void | Promise<void>;
  onChange: (v: keyof ApprovalFilter) => ChangeEventHandler;
  isBtnLoading: (btn: string) => boolean;
  handleLoadingBtn: (btn: string) => void;
}

const ApprovalsFilters = ({
  approvalFilters,
  setApprovalFilters,
  handleFiltersSearch,
  isBtnLoading,
  handleLoadingBtn,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    handleFiltersSearch();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-4">
        <Form.Label htmlFor="approvals-enterpriseIdentity">
          {t('ApprovalsFilters.enterpriseIdentity', "Nom d'entreprise, SIRET/SIREN")}
        </Form.Label>
        <Form.Control
          id="approvals-enterpriseIdentity"
          value={approvalFilters.identity || ''}
          onChange={onChange('identity')}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label htmlFor="approvals-userIdentity">
          {t('ApprovalsFilters.userIdentity', "Nom ou email d'un contact")}
        </Form.Label>
        <Form.Control
          id="approvals-userIdentity"
          value={approvalFilters.contact || ''}
          onChange={onChange('contact')}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label htmlFor="approvals-keyword">
          {t('ApprovalsFilters.keyword', 'Mot clé (chantier, mission, description)')}
        </Form.Label>
        <Form.Control
          id="approvals-keyword"
          value={approvalFilters.keyword || ''}
          onChange={onChange('keyword')}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label htmlFor="approvals-workfieldType">
          {t('ApprovalsFilters.workfieldType', 'Type de chantier')}
        </Form.Label>
        <AWSelect options={marketTypeOptions} onChange={onChange('marketType')} />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label htmlFor="approvals-status">{t('ApprovalsFilters.status', 'Statut')}</Form.Label>
        <AWSelect options={statusOptions} onChange={onChange('status')} />
      </Form.Group>
      <Row>
        <Col sm="12" className="d-flex">
          <Col sm="6">
            <AWButton onClick={() => handleLoadingBtn('filter')} disabled={isBtnLoading('filter')}>
              {isBtnLoading('filter') ? (
                <Spinner size="sm" animation="border" />
              ) : (
                t('ProvidersFilters.filter', 'Filtrer')
              )}
            </AWButton>
          </Col>
          <Col sm="6">
            <AWButton
              color={AWColors.Green}
              backgroundColor="white"
              onClick={() => setApprovalFilters({})}
            >
              {t('ApprovalsFilters.reset', 'Réinitialiser')}
            </AWButton>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};

export default ApprovalsFilters;
