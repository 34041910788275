import React from 'react';
import { TabsMenu } from '@root/interfaces/menu.interface';

const Tabs = ({ tabs, activeTab, setActiveTab }: TabsMenu) => (
  <div className="my-4">
    <ul className="nav aw-nav-tabs">
      {tabs.map((tab) => (
        <li className="nav-item" key={tab.key}>
          <span
            className={`aw-nav-link${activeTab.key === tab.key ? '--active' : ''}`}
            onClick={() => {
              setActiveTab(tab);
            }}
          >
            {tab.label}
          </span>
        </li>
      ))}
    </ul>
  </div>
);

export default Tabs;
