import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AWContainer from '@root/components/AWContainer/AWContainer';
import EnterpriseFormContainer from '@root/components/EnterpriseCreation/Forms/EnterpriseFormContainer';
import PappersSearchResult from '@root/components/EnterpriseCreation/Pappers/PappersSearchResult';
import { EnterpriseCreationContext } from '@root/contexts/enterpriseCreation.context';

interface Props {
  onSubmit: () => void;
  title: string;
  titleSide?: React.ReactNode;
  children?: React.ReactNode;
  height?: number;
}

const EnterpriseCreation = ({
  onSubmit,
  title,
  titleSide,
  children,
  height,
}: Props) => {
  const { t } = useTranslation();

  const { resetEnterprise } = useContext(EnterpriseCreationContext);

  useEffect(() => {
    resetEnterprise();
  }, []);

  const vhHeight = useMemo(() => (height ? `vh-${height}` : ''), [height]);

  return (
    <AWContainer
      className={vhHeight}
      noHeight={!!height}
    >
      <AWContainer.Main
        title={title}
        titleSide={titleSide || ''}
        className={`bg-light ${vhHeight}`}
        noHeight={!!height}
      >
        {children}
        <EnterpriseFormContainer
          onSubmit={onSubmit}
        />
      </AWContainer.Main>
      <AWContainer.Side noHeight={!!height} className="vh-70">
        <PappersSearchResult
          notFoundText={`${t(
            'CreateEnterprise.CannotFindMyEnterprise',
            'Vous ne trouvez pas votre entreprise',
          )} ? `}
        />
      </AWContainer.Side>
    </AWContainer>
  );
};

EnterpriseCreation.defaultProps = {
  children: '',
  titleSide: '',
  height: 0,
};

export default EnterpriseCreation;
