import React from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Form } from 'react-bootstrap';
import { Types, Formats, SurveyQuestion } from '@root/interfaces/survey.interface';
import {
  formatDate, localize, randString,
} from '@root/helpers/utils';
import AWSelect from '@root/components/AWSelect/AWSelect';

interface Props {
  question: SurveyQuestion,
  disabled: boolean,
  errors?: string[],
  onChange: (res: any, key: string) => void;
}

const Question = ({
  errors,
  disabled,
  onChange,
  question: {
    key,
    title,
    type,
    description,
    format,
    items,
    options,
    answer,
  },
}: Props) => {
  const selectItems = (rawItems) => rawItems.map((i) => ({ label: i.value, value: i.value }));
  const { t } = useTranslation();

  const isNumTruthy = (n?: number) => (n !== null && n !== undefined);

  const optionMessage = () => {
    if (format === Formats.File) {
      return t('Question.fileRequirements', 'Format .pdf, maximum 5 Mo');
    }

    const hasMin = isNumTruthy(options?.min);
    const hasMax = isNumTruthy(options?.max);

    if (hasMin || hasMax) {
      switch (type) {
        case Types.Number:
          if (options) {
            if (hasMin && !hasMax) {
              return t('Question.numberMin', 'Minimum {{min}}', { min: options.min });
            }
            if (hasMax && !hasMin) {
              return t('Question.numberMax', 'Maximum {{max}}', { max: options.max });
            }
            if (hasMin && hasMax) {
              return t(
                'Question.numberBetween',
                'Entre {{min}} et {{max}}',
                { min: options.min, max: options.max },
              );
            }
          }
          break;

        case Types.Array:
          if (options) {
            if (hasMin && !hasMax) {
              return t(
                'Question.arrayMin',
                'Sélectionnez au moins {{min}} réponse{{s}}',
                { min: options.min, s: options.min! > 1 ? 's' : '' },
              );
            }
            if (hasMax && !hasMin) {
              return t(
                'Question.arrayMax',
                'Sélectionnez maximum {{max}} réponse{{s}}',
                { max: options.max, s: options.max! > 1 ? 's' : '' },
              );
            }
            if (hasMin && hasMax) {
              return t(
                'Question.arrayBetween',
                'Sélectionnez entre {{min}} et {{max}} réponse{{s}}',
                { min: options.min, max: options.max, s: options.max! > 1 ? 's' : '' },
              );
            }
          }
          break;

        case (Types.String):
          if (format === Formats.Date && options) {
            if (hasMin && !hasMax) {
              return t(
                'Question.dateMin',
                'Au plus tôt le {{min}}',
                { min: formatDate(options.min) },
              );
            }
            if (hasMax && !hasMin) {
              return t(
                'Question.dateMax',
                'Au plus tard le {{max}}',
                { max: formatDate(options.max) },
              );
            }
            if (hasMin && hasMax) {
              return t(
                'Question.dateBetween',
                'Entre le {{min}} et le {{max}}',
                { min: formatDate(options.min), max: formatDate(options.max) },
              );
            }
          } else if (options) {
            if (hasMin && !hasMax) {
              return t(
                'Question.stringMin',
                'Minimum {{min}} caractère{{s}}',
                { min: options.min, s: options.min! > 1 ? 's' : '' },
              );
            }
            if (hasMax && !hasMin) {
              return t(
                'Question.stringMax',
                'Maximum {{max}} caractère{{s}}',
                { max: options.max, s: options.max! > 1 ? 's' : '' },
              );
            }
          }
          break;

        default:
          break;
      }
    }
    return '';
  };

  const input = (id: string) => {
    const viewFile = answer?.value && typeof answer.value === 'string' ? (
      <span
        aria-hidden
        className="d-inline-block mt-2 ms-1 pointer text-dark"
        onClick={() => window.open(answer.value, '_blank')}
      >
        {t('Question.previewFile', 'Visualiser le document soumis')}
      </span>
    ) : <span />;
    switch (type) {
      case Types.String:
        switch (format) {
          case Formats.Date:
            return (
              <DatePicker
                id={id}
                disabled={disabled}
                className="form-control smaller"
                dateFormat="dd/MM/yyyy"
                minDate={options?.min && new Date(options?.min?.toString())}
                maxDate={options?.max && new Date(options?.max?.toString())}
                locale={localize()}
                selected={(
                  typeof answer?.value === Types.String && Date.parse(answer?.value)
                    ? new Date(answer?.value) : ''
                )}
                onChange={(d: Date) => onChange(d ? dayjs(d).format('YYYY-MM-DD') : '', key)}
              />
            );
          case Formats.File:
            return (
              disabled ? viewFile : (
                <>
                  <Form.Control
                    type="file"
                    id={id}
                    accept="application/pdf"
                    onChange={(e) => onChange((e.target as HTMLInputElement).files, key)}
                  />
                  <div className="d-flex justify-content-center">
                    {viewFile}
                  </div>
                </>
              )
            );
          case Formats.Text:
            return (
              <Form.Control
                id={id}
                disabled={disabled}
                as="textarea"
                rows={3}
                value={answer?.value ? `${answer?.value}` : ''}
                maxLength={options?.max}
                onChange={(e) => onChange(e.target.value, key)}
              />
            );
          default:
            return (
              <Form.Control
                id={id}
                disabled={disabled}
                value={answer?.value ? `${answer?.value}` : ''}
                maxLength={options?.max}
                onChange={(e) => onChange(e.target.value, key)}
              />
            );
        }
      case Types.Array:
        switch (format) {
          case Formats.Select:
            return (
              <AWSelect
                options={items?.length && selectItems(items)}
                isDisabled={disabled}
                noNullOption
                value={answer?.value}
                onChange={(e) => onChange(e, key)}
              />
            );
          case Formats.Multi:
            return (
              <AWSelect
                noNullOption
                isDisabled={disabled}
                value={answer?.value}
                options={items?.length && selectItems(items)}
                isMulti
                onChange={(e) => (
                  (options?.max && e.length > options.max) ? null : onChange(e, key)
                )}
              />
            );
          case Formats.Checkbox:
            return items?.map((item) => (
              <Form.Check
                type="checkbox"
                disabled={disabled}
                id={item.value}
                key={item.value}
                label={item.value}
                value={item.value}
                checked={
                  Array.isArray(answer?.value) && answer?.value.some((ans) => ans === item.value)
                }
                onChange={(e) => onChange(e.target.value, key)}
              />
            ));
          default:
            return items?.map((item) => (
              <Form.Check
                type="radio"
                disabled={disabled}
                id={item.value}
                key={item.value}
                label={item.value}
                value={item.value}
                checked={Array.isArray(answer?.value) && answer?.value[0] === item.value}
                onChange={(e) => onChange(e.target.value, key)}
              />
            ));
        }
      case Types.Number:
        switch (format) {
          default:
            return (
              <Form.Control
                id={id}
                disabled={disabled}
                className="smaller"
                type="number"
                value={
                  typeof answer?.value === 'number' && !Number.isNaN(answer?.value)
                    ? answer?.value?.toString() : ''
                }
                onChange={(e) => onChange(parseInt(e.target.value, 10), key)}
              />
            );
        }
      case Types.Boolean:
        return (
          <>
            <Form.Check
              id={id}
              disabled={disabled}
              type="radio"
              value="true"
              label={t('Question.tre', 'Oui')}
              inline
              checked={answer?.value === true}
              onChange={(e) => onChange(e.target.value, key)}
            />
            <Form.Check
              id={id}
              disabled={disabled}
              type="radio"
              value="false"
              label={t('Question.no', 'Non')}
              inline
              checked={answer?.value === false}
              onChange={(e) => onChange(e.target.value, key)}
            />
          </>

        );
      default:
        return (
          <Form.Control
            id={id}
            disabled={disabled}
            value={answer?.value || ''}
            maxLength={options?.max}
          />
        );
    }
  };

  return (
    <Form.Group className="mb-2 question">
      <Form.Label htmlFor={key}>
        {`${title} : `}
        {options?.required && '*'}
        {format === Formats.Range && <span>{answer?.value}</span>}
      </Form.Label>
      {!!description && <p>{description}</p>}
      {!!optionMessage() && <p>{optionMessage()}</p>}
      <div className="mt-2">
        {input(key)}
      </div>
      {
        errors?.map((error: string) => (
          <p key={randString()} className="text-danger">
            <small>
              {`* ${error}`}
            </small>
          </p>
        ))
      }
    </Form.Group>
  );
};

Question.defaultProps = {
  errors: [],
};

export default Question;
