export const CACHE_ENTERPRISE_PROVIDER = 'cache_enterprise_provider';
export const CACHE_ENTERPRISE_CUSTOMER = 'cache_enterprise_customer';
export const REDIRECT_PARAM = 'redirect';
export const PUNCHOUT = 'punchout';
export const PROVIDER_BASE_PATH = '/provider';
export const CUSTOMER_BASE_PATH = '/customer';
export const ADDWORKING_SUPPORT = 'https://intercom.help/addworking/fr/';
export const V1_LOGOUT = '/v1/logout';
export const PROVIDER = 'provider';
export const CUSTOMER = 'customer';
export const BE_EXAMPLE_IDENTIFICATION_NUMBER = '1234.123.123';
export const FR_EXAMPLE_IDENTIFICATION_NUMBER = '12345678912345';
export const DE_EXAMPLE_IDENTIFICATION_NUMBER = 'HRB1234,HRA123,123456';
