import { toPng } from 'html-to-image';

export const convertModelDocumentFromStringToImg = async (
  model: string,
  elementId_temp: string,
  elementId: string,
) => {
  // creates a div node to render the HTML template
  const html = document.createElement('div');
  html.innerHTML = model;
  // we get 2 div:
  // 1 - the one which will host the preview image
  // 2 - the one which will temporary host the HTML template
  const preview = document.getElementById(elementId);
  const temp = document.getElementById(elementId_temp);
  temp?.appendChild(html);

  if (temp && preview) {
    // we clear the preview if a template was selected before
    preview.innerHTML = '';
    // converts html to a string
    const dataURL = await toPng(temp);
    const img = document.createElement('img');
    img.src = dataURL;
    preview!.appendChild(img);
    // remove all data inside temp
    temp.removeChild(html);
  }
};
