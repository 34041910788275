import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@root/contexts/user.context';
import useCognito from '@root/hooks/useCognito';
import InfoMessage from '@root/components/InfoMessage/InfoMessage';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import AWButton from '@root/components/AWButtons/AWButton';

import ContentBox from '@user/components/ContentBox/ContentBox';
import ReturnButton from '@user/components/ReturnButton/ReturnButton';
import { LoginRouterContext, Views } from '@user/contexts/loginRouter.context';

const EmailConfirm = () => {
  const { t } = useTranslation();

  const [code, setCode] = useState('');
  const { isLoading, waitWithLoad } = useLoadingPromise();

  const { user } = useContext(UserContext);
  const { setCurrentView } = useContext(LoginRouterContext);
  const { message, confirmUserEmail } = useCognito();
  const { push } = useHistory();

  useEffect(() => {
    if (!user.email) {
      push('/');
    }
  }, []);

  const handleConfirmation = async (e) => {
    e.preventDefault();
    waitWithLoad(confirmUserEmail(user, code));
  };

  return (
    <ContentBox title={t('EmailConfirm.emailConfirmation', 'Confirmez votre email')}>
      <Form onSubmit={handleConfirmation}>
        <Form.Group>
          <Form.Label className="text-justify" htmlFor="confirmEmail">
            {t(
              'EmailConfirm.description',
              `
              Afin de vous connecter, vous devez d'abord confirmer votre
              adresse email.
              Veuillez renseigner le code de vérification que nous venons de vous
              envoyer à 
            `,
            )}
            <span>{` ${user.email}`}</span>
          </Form.Label>
          <Form.Control
            id="confirmEmail"
            placeholder={t('EmailConfirm.code', 'Code de confirmation')}
            value={code}
            className="mt-4"
            onChange={(e) => setCode(e.target.value)}
          />
        </Form.Group>
        {
          message && <InfoMessage message={message.text} color={message.color} />
        }
        <div className="d-flex flex-column align-items-center">
          <AWButton
            type="submit"
            className="mt-4 w-50"
            disabled={!code || isLoading}
            isLoading={isLoading}
            noFlex
          >
            {t('Action.confirm')}
          </AWButton>
          <ReturnButton
            className="mt-2"
            onClick={() => {
              push('/');
              setCurrentView(Views.Login);
            }}
          />
        </div>
      </Form>
    </ContentBox>
  );
};

export default EmailConfirm;
