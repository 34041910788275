import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';

import AWLongCard from '@root/components/Cards/AWLongCard/AWLongCard';
import { Connection } from '@root/interfaces/connection.interface';

import icon from '@root/assets/connection.svg';
import { formatDate } from '@root/helpers/utils';

interface Props {
  connection: Connection,
  isConnectionRefused: boolean,
  onClick?: () => void,
}

const CardConnection = ({
  connection,
  isConnectionRefused,
  onClick,
}: Props) => {
  const { t } = useTranslation();

  return (
    <AWLongCard
      icon={icon}
      className="mb-3 text-black"
      onClick={onClick}
      disabled={isConnectionRefused}
    >
      <Row>
        <Col md="7" className="mb-2 mb-md-0 d-flex flex-column justify-content-evenly">
          <h2 className="font-size-15 mb-0 font-style-semibold text-truncate">
            {connection.customer_name}
          </h2>
          <p>
            {`${t('CardConnection.status', 'Statut')} : ${t(`ConnectionStatus.${connection.status}`)}`}
          </p>
        </Col>
        <Col md="5" className="d-flex flex-column justify-content-between">
          <p>
            {`
              ${t('CardConnection.receivedAt', 'Reçue le')} : 
              ${formatDate(connection.created_at)}
            `}
          </p>
          <p>
            {`
              ${t('CardConnection.updatedAt', 'Mise à jour le')} : 
              ${formatDate(connection.updated_at)}
            `}
          </p>
        </Col>
      </Row>
    </AWLongCard>
  );
};

CardConnection.defaultProps = {
  onClick: () => { },
};

export default CardConnection;
