import i18n from '@root/locales/i18n';

export const contractTabsViews = [
  {
    label: i18n.t('Contracts.all', 'Tout les contrats'),
    key: 'all',
  },
  {
    label: i18n.t('Contracts.contracts', 'Contrats'),
    key: 'contracts',
  },
  {
    label: i18n.t('Contracts.amendment', 'Avenants'),
    key: 'amendment',
  },
];
