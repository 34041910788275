import React from 'react';

interface Props {
  color: string,
  label: string
}

const Tag = ({ color, label }: Props) => (
  <div className="tag" style={{ backgroundColor: color }}>
    <p className="tag-label">{label}</p>
  </div>
);

export default Tag;
