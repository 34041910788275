import { useCallback, useState } from 'react';

const useLoadingPromise = () => {
  const [isLoading, setIsLoading] = useState(false);

  const waitWithLoad = useCallback(
    <T = unknown>(promise: Promise<T>) => {
      setIsLoading(true);
      return promise.finally(() => setIsLoading(false));
    },
    [],
  );

  return { waitWithLoad, isLoading };
};

export default useLoadingPromise;
