import React from 'react';
import ReactTooltip, { Effect, Place, Type } from 'react-tooltip';

interface Props {
  children: React.ReactNode;
  place?: Place;
  type?: Type;
  effect?: Effect;
  id?: string;
  className?: string;
}

const Tooltip = ({
  children,
  place,
  type,
  effect,
  id,
  className,
}: Props) => (
  <ReactTooltip
    place={place}
    type={type}
    effect={effect}
    id={id}
    className="tooltip opaque"
  >
    <span className={`tip ${className}`}>
      {children}
    </span>
  </ReactTooltip>
);

Tooltip.defaultProps = {
  place: 'right',
  type: 'light',
  effect: 'solid',
  id: '',
  className: '',
};

export default Tooltip;
