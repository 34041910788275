import React, {
  createContext,
  ReactNode,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import { SessionUser } from '@root/interfaces/cognito.interface';
import useCurrentApp, { Apps } from '@root/hooks/useCurrentApp';
import usePrevious from '@root/hooks/usePrevious';
import {
  CACHE_ENTERPRISE_CUSTOMER,
  CACHE_ENTERPRISE_PROVIDER,
  REDIRECT_PARAM,
} from '@root/helpers/constants.helper';
import { getCurrentEnterprises } from '@root/helpers/user.helper';
import useSafeFetch, { useSafeFetchCallback } from '@root/hooks/useSafeFetch';
import { swapCurrentEnterpriseV1Config } from '@root/api-configs/user.api.config';

interface IUserContext {
  user: SessionUser;
  tokenPO: string;
  recoveryEmail: string;
  redirect: string;
  isV1Redirect: boolean;
  setRedirect: (path: string, isV1?: boolean) => void;
  setTokenPO: React.Dispatch<React.SetStateAction<string>>;
  setUser: React.Dispatch<React.SetStateAction<SessionUser>>;
  setRecoveryEmail: React.Dispatch<React.SetStateAction<string>>;
  refreshEnterprises: (id?: string) => Promise<void>;
}

const LOGOUT = 'logout';

export const UserContext = createContext<IUserContext>({
  user: {
    email: '', password: '',
  },
  tokenPO: '',
  recoveryEmail: '',
  redirect: '',
  setUser: () => { },
  setRecoveryEmail: () => { },
  setRedirect: () => { },
  setTokenPO: () => { },
  isV1Redirect: false,
  refreshEnterprises: async () => { },
});

const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<SessionUser>({ email: '', password: '' });
  const { isApp } = useCurrentApp();
  const oldCurrentEnterprise = usePrevious(user.currentEnterprise);
  const [recoveryEmail, setRecoveryEmail] = useState('');
  const [redirect, setRedirectOnly] = useState('');
  const [isV1Redirect, setIsV1Redirection] = useState(false);
  const { search, pathname } = useLocation();
  const oldPathname = usePrevious(pathname);
  const [tokenPO, setTokenPO] = useState<string>('');
  const swapEnterprise = useSafeFetch(swapCurrentEnterpriseV1Config).callApi;
  const fetchCurrentEnterprises = useSafeFetchCallback(getCurrentEnterprises);

  const setRedirect = (path: string, isV1 = false) => {
    setIsV1Redirection(isV1);
    setRedirectOnly(path);
  };

  /**
   * Update user enterprises.
   * Optionnaly update current user enterprise if the identifier is indicated.
   * @param identifier Expect enterprise `id` or `identification_number`
   */
  const refreshEnterprises = async (identifier?: string) => {
    const enterprises = await fetchCurrentEnterprises(user.id as string);
    const currentEnterprise = enterprises.currentAppEnterprises?.find(
      (e) => e.id === identifier || e.identification_number === identifier,
    ) || user.currentEnterprise;
    if (enterprises.enterprises?.length) {
      setUser({
        ...user,
        ...enterprises,
        currentEnterprise,
      });
    }
  };

  const reloadCurrentEnterpriseDeps = async () => {
    if (
      user.id
      && user.currentEnterprise?.id
      && user.currentEnterprise.id !== oldCurrentEnterprise?.id
      && oldCurrentEnterprise?.id !== undefined
    ) {
      if (isApp(Apps.Provider)) {
        localStorage.setItem(CACHE_ENTERPRISE_PROVIDER, user.currentEnterprise.id);
      } else if (isApp(Apps.Customer)) {
        localStorage.setItem(CACHE_ENTERPRISE_CUSTOMER, user.currentEnterprise.id);
      }
    }
    if (user.id && user.currentEnterprise?.id) {
      swapEnterprise({ enterpriseId: user.currentEnterprise.id });
    }
  };

  useEffect(() => {
    reloadCurrentEnterpriseDeps();
  }, [user.currentEnterprise?.id]);

  useEffect(() => {
    if (
      user.id
      && (
        (!isApp(Apps.Customer, oldPathname) && isApp(Apps.Customer))
        || (!isApp(Apps.Provider, oldPathname) && isApp(Apps.Provider))
      )
    ) {
      refreshEnterprises();
    }
  }, [pathname]);

  useEffect(() => {
    let isV1 = false;
    let redirection = new URLSearchParams(search).get(REDIRECT_PARAM);
    if (!redirection) {
      redirection = localStorage.getItem(REDIRECT_PARAM);
      if (redirection?.includes(LOGOUT)) {
        redirection = null;
      }
    } else {
      isV1 = true;
    }
    if (redirection) {
      localStorage.removeItem(REDIRECT_PARAM);
      setRedirect(redirection, isV1);
    }
  }, []);

  const value = useMemo(() => ({
    user,
    setUser,
    recoveryEmail,
    setRecoveryEmail,
    redirect,
    setRedirect,
    isV1Redirect,
    setTokenPO,
    tokenPO,
    refreshEnterprises,
  }), [user, recoveryEmail, redirect, isV1Redirect, tokenPO]);

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
