import React, { ReactNode } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

interface Props {
  /** Title displayed on top the component's content */
  title?: string;
  /** Text, component and image inside component */
  children?: ReactNode;
  /** Custom classes */
  className?: string;
}

/**
 * ContentBox is a component that contains written content centered in its middle.
 * @version 1
 * @since 7/10/2021
 */

const ContentBox = ({ title, children, className }: Props) => (
  <div className="contentbox-container">
    <Container className="centered-box ">
      <Row className=" h-100">
        <Col lg={{ span: 6, offset: 3 }} md={{ span: 10, offset: 1 }} xs={12} className="my-auto">
          <div className={`${className} h1 h1-white`}>{title}</div>
          {children}
        </Col>
      </Row>
    </Container>
  </div>
);

ContentBox.defaultProps = {
  title: '',
  children: '',
  className: '',
};

export default ContentBox;
