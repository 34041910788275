import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Row,
  Form,
  Spinner,
} from 'react-bootstrap';
import AWButton from '@root/components/AWButtons/AWButton';
import { EnterpriseCreationContext } from '@root/contexts/enterpriseCreation.context';
import ErrorInput from '@provider/components/ErrorInput/ErrorInput';
import { EnterpriseFields, Establishment } from '@root/interfaces/enterprise.interface';
import { formatSiret } from '@root/helpers/utils';

import triangle from '@root/assets/triangle.svg';
import { BooleanErrors } from '@root/interfaces/form.interface';
import { getIdentificationLabel } from '@root/helpers/enterprise.helper';
import { CountryCode } from '@root/interfaces/utils.interface';
import { BE_EXAMPLE_IDENTIFICATION_NUMBER, DE_EXAMPLE_IDENTIFICATION_NUMBER, FR_EXAMPLE_IDENTIFICATION_NUMBER } from '@root/helpers/constants.helper';

interface Props {
  isLoading?: boolean;
  onSubmit?: (e: any) => void;
  errors?: BooleanErrors<EnterpriseFields>;
}

const EnterpriseForm = ({ onSubmit, isLoading, errors }: Props) => {
  const { t } = useTranslation();
  const {
    isManual,
    enterprise,
    handleEnterpriseChange,
    handleEstablishmentChange,
    legalForms,
    establishments,
    handleReset,
    establishmentsLoading,
  } = useContext(EnterpriseCreationContext);

  const example = useMemo(() => {
    if (enterprise?.country === CountryCode.Be) {
      return BE_EXAMPLE_IDENTIFICATION_NUMBER;
    }
    if (enterprise?.country === CountryCode.De) {
      return DE_EXAMPLE_IDENTIFICATION_NUMBER;
    }
    return FR_EXAMPLE_IDENTIFICATION_NUMBER;
  }, [enterprise?.country]);

  return (
    <Form onSubmit={onSubmit}>
      {
        !establishmentsLoading ? (
          <>
            <Row>
              <Col xl="3">
                <Form.Group className="mb-3">
                  <Form.Label
                    htmlFor={EnterpriseFields.LegalFormId}
                  >
                    {t('Enterprise.legalForm', 'Forme juridique')}
                  </Form.Label>
                  {
                    !isManual ? (
                      <Form.Control
                        id={EnterpriseFields.LegalFormId}
                        type="input"
                        value={enterprise.legal_form}
                        disabled
                      />
                    ) : (
                      <Form.Select
                        id={EnterpriseFields.LegalFormId}
                        onChange={handleEnterpriseChange(EnterpriseFields.LegalFormId)}
                        value={enterprise.legal_form_id || ''}
                      >
                        <option value="">-</option>
                        {enterprise.country && legalForms[enterprise?.country]?.length
                          ? legalForms[enterprise?.country]?.map((lf) => (
                            <option key={lf.id} value={lf.id}>
                              {lf.display_name}
                            </option>
                          )) : ''}
                      </Form.Select>
                    )
                  }
                  <ErrorInput
                    error={errors![EnterpriseFields.LegalFormId]}
                    message={t(
                      'Enterprise.Form.Error.selectLegalForm',
                      'Veuillez renseigner une forme légale',
                    )}
                    type="form"
                  />
                </Form.Group>
              </Col>
              <Col xl="9">
                <Form.Group className="mb-3">
                  <Form.Label
                    htmlFor={EnterpriseFields.Name}
                  >
                    {t('Enterprise.name', "Nom de l'entreprise")}
                  </Form.Label>
                  <Form.Control
                    disabled={!isManual}
                    id={EnterpriseFields.Name}
                    value={enterprise.name || ''}
                    onChange={handleEnterpriseChange(EnterpriseFields.Name)}
                  />
                  <ErrorInput
                    error={errors![EnterpriseFields.Name]}
                    message={t(
                      'Enterprise.Form.Error.selectEnterpriseName',
                      "Veuillez renseigner un nom d'entreprise",
                    )}
                    type="form"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label
                    htmlFor={EnterpriseFields.IdentificationNumber}
                  >
                    {getIdentificationLabel(enterprise.country)}
                  </Form.Label>
                  {
                    !isManual && establishments && establishments.length > 1 ? (
                      <Form.Select
                        id={EnterpriseFields.IdentificationNumber}
                        onChange={handleEstablishmentChange}
                        defaultValue={enterprise.identification_number}
                      >
                        {establishments.map((establishment: Establishment) => (
                          <option key={establishment.siret} value={establishment.siret}>
                            {formatSiret(establishment.siret)}
                          </option>
                        ))}
                      </Form.Select>
                    ) : (
                      <Form.Control
                        disabled={!isManual}
                        id={EnterpriseFields.IdentificationNumber}
                        maxLength={enterprise.country === 'fr' ? 16 : undefined}
                        value={(enterprise.country === 'fr'
                          ? formatSiret(enterprise.identification_number || '')
                          : enterprise.identification_number) || ''}
                        onChange={handleEnterpriseChange(EnterpriseFields.IdentificationNumber)}
                      />
                    )
                  }
                  <ErrorInput
                    error={errors![EnterpriseFields.IdentificationNumber]}
                    message={`${
                      t(
                        'Enterprise.Form.Error.selectIdentificationNumber',
                      )
                    } (${t('Common.example').toLowerCase()}: ${example})`}
                    type="form"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label
                htmlFor={EnterpriseFields.AddressAddress}
              >
                {t('Enterprise.address', 'Adresse postale')}
              </Form.Label>
              <Form.Control
                disabled={!isManual}
                id={EnterpriseFields.AddressAddress}
                value={enterprise.address?.address || ''}
                onChange={handleEnterpriseChange(EnterpriseFields.AddressAddress)}
              />
              <ErrorInput
                error={errors![EnterpriseFields.AddressAddress]}
                message={t(
                  'Enterprise.Form.Error.selectAddress',
                  'Veuillez renseigner une adresse',
                )}
                type="form"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label
                htmlFor={EnterpriseFields.AddressAdditionnal}
              >
                {t('Enterprise.address2', 'Adresse (ligne 2)')}
              </Form.Label>
              <Form.Control
                disabled={!isManual}
                id={EnterpriseFields.AddressAdditionnal}
                value={enterprise.address?.additionnal_address || ''}
                onChange={handleEnterpriseChange(EnterpriseFields.AddressAdditionnal)}
              />
            </Form.Group>
            <Row>
              <Col xl="3">
                <Form.Group className="mb-3">
                  <Form.Label
                    htmlFor={EnterpriseFields.AddressZipcode}
                  >
                    {t('Enterprise.zipcode', 'Code postal')}
                  </Form.Label>
                  <Form.Control
                    disabled={!isManual}
                    id={EnterpriseFields.AddressZipcode}
                    value={enterprise.address?.zipcode || ''}
                    onChange={handleEnterpriseChange(EnterpriseFields.AddressZipcode)}
                  />
                  <ErrorInput
                    error={errors![EnterpriseFields.AddressZipcode]}
                    message={t(
                      'Enterprise.Form.Error.selectZipcode',
                      'Veuillez renseigner un code postal',
                    )}
                    type="form"
                  />
                </Form.Group>
              </Col>
              <Col xl="9">
                <Form.Group className="mb-3">
                  <Form.Label
                    htmlFor={EnterpriseFields.AddressTown}
                  >
                    {t('Enterprise.city', 'Ville')}
                  </Form.Label>
                  <Form.Control
                    disabled={!isManual}
                    id={EnterpriseFields.AddressTown}
                    value={enterprise.address?.town || ''}
                    onChange={handleEnterpriseChange(EnterpriseFields.AddressTown)}
                  />
                  <ErrorInput
                    error={errors![EnterpriseFields.AddressTown]}
                    message={t(
                      'Enterprise.Form.Error.selectCity',
                      'Veuillez renseigner une ville',
                    )}
                    type="form"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3 justify-content-between align-items-center font-style-semibold">
              <Col xl="4">
                {
                  enterprise.country === 'fr' && (
                    <div
                      onClick={handleReset}
                      className={
                        `text-dark pointer d-flex align-items-center
                              mb-3 mb-xl-0 justify-content-center justify-content-xl-start`
                      }
                      aria-hidden="true"
                    >
                      <img
                        src={triangle}
                        alt="back"
                        className="opacity-50 rotate-180"
                        height="14px"
                        width="14px"
                      />
                      <u className="ms-2">{t('Action.search')}</u>
                    </div>
                  )
                }
              </Col>
              <Col xl="5">
                {/* <AWButton disabled={invalidForm || isLoading}> */}
                <AWButton>
                  {isLoading ? (
                    <div className="spinner-border spinner-border-sm" />
                  ) : (
                    t('Action.confirm')
                  )}
                </AWButton>
              </Col>
            </Row>
          </>
        ) : (
          <div className="hpx-300 d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        )
      }
    </Form>
  );
};

EnterpriseForm.defaultProps = {
  isLoading: false,
  onSubmit: () => {},
  errors: {},
};

export default EnterpriseForm;
