import React from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from '@root/locales/i18n';

import ProtectedRoute from '@root/routes/ProtectedRoute';

import EmailConfirm from '@user/components/EmailConfirm/EmailConfirm';
import Login from '@user/views/Login/Login';
import Signup from '@user/views/Signup/Signup';
import { Apps } from '@root/hooks/useCurrentApp';
import { capitalize } from '@root/helpers/utils';
import Unauthorized from '@root/components/Unauthorized/Unauthorized';
import NotFound from '@root/components/NotFound/NotFound';

const AppUserRoutes = () => {
  i18n.setDefaultNamespace(capitalize(Apps.User));
  return (
    <I18nextProvider i18n={i18n}>
      <Switch>
        <Route path="/" exact>
          <Login />
        </Route>
        <Route path="/signup" exact>
          <Signup />
        </Route>
        <Route path="/signup/email-confirmation" exact>
          <EmailConfirm />
        </Route>
        <Route path="/not-found" exact>
          <NotFound />
        </Route>
        <ProtectedRoute hasSidebar={false} path="/unauthorized" exact>
          <Unauthorized />
        </ProtectedRoute>
        <Route path="*">
          <Redirect to="/not-found" />
        </Route>
      </Switch>
    </I18nextProvider>
  );
};

export default AppUserRoutes;
