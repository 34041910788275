import React, { Children, useContext } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ApprovalContext } from '@customer/contexts/approval.context';
import AWButton from '@root/components/AWButtons/AWButton';
import trash from '@root/assets/trash.svg';

const SignaturesBloc = () => {
  const { t } = useTranslation();

  const { propertySignatures, setPropertySignatures } = useContext(ApprovalContext);

  const addSignature = () => {
    setPropertySignatures([
      ...propertySignatures,
      {
        id: propertySignatures.length + 1,
        signatureName: '',
        width: 200,
        height: 100,
        pageNumberSignature: 1,
        x: 0,
        y: 0,
      },
    ]);
  };

  const removeSignature = (id: number) => {
    setPropertySignatures(propertySignatures.filter((signature) => signature.id !== id));
  };

  const handleChange = (e, id: number) => {
    const updatedPropertySignature = propertySignatures.map((sign) => {
      if (sign.id === id) {
        return { ...sign, signatureName: e.target.value };
      }
      return sign;
    });
    setPropertySignatures(updatedPropertySignature);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Form onSubmit={handleSubmit} className="signatures-bloc">
      {Children.toArray(
        propertySignatures.map((signature) => (
          <Form.Group className="mb-4 signatures-bloc__row">
            <Form.Label className="signatures-bloc__label">
              {t('ApprovalsContainer.signatory', 'Signataire')}
              {' '}
              {signature.id}
              :
            </Form.Label>
            <Form.Control
              className="signatures-bloc__input"
              placeholder="Insérez le nom du signataire"
              value={signature.signatureName}
              onChange={(e) => handleChange(e, signature.id)}
            />
            <Col md="2" className="d-flex justify-content-center align-items-end">
              <AWButton onClick={() => removeSignature(signature.id)}>
                <img
                  src={trash}
                  alt="delete signataire bloc"
                  className="return-icon opacity-100 mr-10"
                  height="25px"
                  width="25px"
                />
              </AWButton>
            </Col>
          </Form.Group>
        )),
      )}
      <Col sm="12">
        <AWButton className="signatures-bloc__add-button" onClick={addSignature}>
          {t('ApprovalsContainer.addSignatory', 'Ajouter un signataire')}
        </AWButton>
      </Col>
    </Form>
  );
};

export default SignaturesBloc;
