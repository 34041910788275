import React, { useMemo } from 'react';
import { Row, Form, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { CountryCode } from '@root/interfaces/utils.interface';
import CountryInput from '@root/components/EnterpriseCreation/Forms/CountryInput';
import france from '@root/assets/france.svg';
import germany from '@root/assets/germany.svg';
import belgium from '@root/assets/belgium.svg';

interface Props {
  country: CountryCode;
  onChange: (country: CountryCode) => void;
  countriesEnabled?: Record<CountryCode, boolean>;
}

const Countries = ({ country, onChange, countriesEnabled }: Props) => {
  const { t } = useTranslation();

  const handleCountry = (c: CountryCode) => () => {
    onChange(c);
  };

  const isEnabled = useMemo(() => {
    if (!countriesEnabled) return true;
    const count = Object.values(countriesEnabled).filter((val) => val).length;
    return count !== 1;
  }, [countriesEnabled]);

  return (
    <Row>
      {
        isEnabled ? (
          <>
            <Form.Label>
              {t('Enterprise.country', "Pays de domiciliation de l'entreprise")}
            </Form.Label>
            <Col xl="4">
              <CountryInput
                checked={country === CountryCode.Fr}
                country={t('Country.france', 'France')}
                flag={france}
                onClick={handleCountry(CountryCode.Fr)}
                disabled={!!countriesEnabled && !countriesEnabled[CountryCode.Fr]}
              />
            </Col>
            <Col xl="4">
              <CountryInput
                checked={country === CountryCode.De}
                country={t('Country.germany', 'Allemagne')}
                flag={germany}
                onClick={handleCountry(CountryCode.De)}
                disabled={!!countriesEnabled && !countriesEnabled[CountryCode.De]}
              />
            </Col>
            <Col xl="4">
              <CountryInput
                checked={country === CountryCode.Be}
                country={t('Country.belgium', 'Belgique')}
                flag={belgium}
                onClick={handleCountry(CountryCode.Be)}
                disabled={!!countriesEnabled && !countriesEnabled[CountryCode.Be]}
              />
            </Col>
          </>
        ) : <span />
      }
    </Row>
  );
};

Countries.defaultProps = {
  countriesEnabled: undefined,
};

export default Countries;
