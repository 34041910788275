import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { Department, Activity, Certification } from '@root/interfaces/enterprise.interface';

interface Props {
  departments?: Department[],
  activities?: Activity[],
  certifications?: Certification[],
}

const EnterpriseActivity = ({ departments, activities, certifications }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="enterprise-info">
      <Form>
        <Form.Group>
          <Form.Label className="text-dark">
            {t('Enterprise.geographicCover', 'Couverture géographique')}
          </Form.Label>
          <textarea
            className="form-control enterprise__departments info__content"
            disabled
            value={departments && departments.map((department) => `${department.insee_code} (${department.display_name})`).join(', ')}
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label className="text-dark">
            {t('Enterprise.activities', 'Activités')}
          </Form.Label>
          <textarea
            className="form-control enterprise__departments info__content"
            disabled
            value={activities && activities.map((activity) => activity.activity).join(', ')}
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label className="text-dark">
            {t('Enterprise.certificate', 'Certifications et habilitations')}
          </Form.Label>
          <textarea
            disabled
            className="form-control enterprise__departments info__content"
            value={certifications && certifications.map((certification) => certification.label).join(', ')}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

EnterpriseActivity.defaultProps = {
  departments: [],
  activities: [],
  certifications: [],
};

export default EnterpriseActivity;
