import { ApiResponse } from '@root/helpers/response-handler';
import { getHigherObjectBy } from '@root/helpers/utils';
import { EmployeeNumber, BusinessTurnover } from '@root/interfaces/enterprise.interface';
import {
  getDepartments,
  getEmployeeNumbers,
  getEnterprises,
  getBusinessTurnovers,
} from '@customer/services/enterprise.service';
import { getFile } from '@root/services/file.service';

const QUERYBOY_FIELDS = [
  'commercial_brochure',
  'has_commercial_brochure',
  'website',
  'has_website',
  'departments',
  'identification_number',
];

interface IDepartment {
  id: string,
  display_name: string,
  insee_code: string,
}

export interface IEnterpriseFields {
  commercialBrochure?: string,
  website?: string,
  departments?: IDepartment[],
  turnovers?: BusinessTurnover[],
  employeeNumbers?: EmployeeNumber[],
}

export enum ConnectionTaskField {
  COMMERCIAL_BROCHURE = 'commercial_brochure',
  DEPARTMENTS = 'departments',
  WEBSITE = 'website',
  TURNOVER = 'business_turnover',
  EMPLOYEES_NUMBER = 'employees_number',
}

export default async (fields: string[], providerId: string) => {
  const data: {
    enterpriseFields?: IEnterpriseFields,
    latestTurnover?: BusinessTurnover,
    latestEmployeeNumber?: EmployeeNumber,
  } = {};
  const enterpriseFieldRes = await getEnterprises({
    fields: QUERYBOY_FIELDS,
    search: { id: providerId },
  });
  if (enterpriseFieldRes.success && enterpriseFieldRes.data.length) {
    const tmpEnterpriseFields: IEnterpriseFields = {};
    const enterprise = enterpriseFieldRes.data[0];
    if (fields.includes(ConnectionTaskField.COMMERCIAL_BROCHURE)
      && enterprise.commercial_brochure) {
      const fileRes = await getFile(enterprise.commercial_brochure);
      if (fileRes.success) {
        tmpEnterpriseFields.commercialBrochure = fileRes.data.signedUrl;
      }
    }
    if (fields.includes(ConnectionTaskField.WEBSITE)
      && enterprise.website) {
      tmpEnterpriseFields.website = enterprise.website;
    }
    if (fields.includes(ConnectionTaskField.DEPARTMENTS)) {
      const departmentsRes = await getDepartments(
        enterprise.identification_number!,
        { fields: 'id,display_name,insee_code', page_size: 150 },
      ) as ApiResponse<IDepartment[]>;
      if (departmentsRes.success && departmentsRes.data.length) {
        tmpEnterpriseFields.departments = departmentsRes.data.sort(
          (a, b) => parseInt(a.insee_code, 10) - parseInt(b.insee_code, 10),
        );
      }
    }
    if (fields.includes(ConnectionTaskField.TURNOVER)) {
      const turnoversRes = await getBusinessTurnovers(
        providerId,
        { fields: 'id,year,declarative_amount,currency' },
      );
      if (turnoversRes.success && turnoversRes.data.length) {
        tmpEnterpriseFields.turnovers = turnoversRes.data;
        data.latestTurnover = getHigherObjectBy('year', turnoversRes.data);
      }
    }
    if (fields.includes(ConnectionTaskField.EMPLOYEES_NUMBER)) {
      const employeesRes = await getEmployeeNumbers(
        providerId,
        { fields: ['id', 'year', 'declarative_min', 'declarative_max', 'declarative_range_id'] },
      );
      if (employeesRes.success && employeesRes.data.length) {
        tmpEnterpriseFields.employeeNumbers = employeesRes.data;
        data.latestEmployeeNumber = getHigherObjectBy('year', employeesRes.data);
      }
    }
    data.enterpriseFields = tmpEnterpriseFields;
  }
  return data;
};
