import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col } from 'react-bootstrap';
import { getContacts as getContactsService, getService } from '@customer/services/contact.service';
import { Connection, ConnectionTask } from '@root/interfaces/connection.interface';
import { capitalize } from '@root/helpers/utils';
import { Contact } from '@root/interfaces/contact.interface';

interface Props {
  connection?: Connection,
  task?: ConnectionTask,
}

const ModalContact = ({
  connection,
  task,
}: Props) => {
  const { t } = useTranslation();
  const [contacts, setContacts] = useState<Contact[]>();
  const [serviceLabel, setServiceLabel] = useState('');

  const getContacts = async (providerId, serviceId, customerId) => {
    const contactRes = await getContactsService({
      fields: 'id,first_name,last_name,email,phone_number',
      search: {
        provider_id: providerId,
        service_id: serviceId,
        customer_id: customerId,
      },
    });
    if (contactRes.success && contactRes.data) {
      setContacts(contactRes.data);
    }
  };

  const getServiceLabel = async (serviceId) => {
    const serviceRes = await getService(serviceId, {
      fields: 'label',
    });
    if (serviceRes.success && serviceRes.data?.label) {
      setServiceLabel(serviceRes.data.label);
    }
  };

  useEffect(() => {
    if (connection?.provider_id && task?.customer_data && connection?.customer_id) {
      getContacts(connection.provider_id, task.customer_data, connection.customer_id);
      getServiceLabel(task.customer_data);
    }
  }, [connection, task]);

  return (
    <Form>
      <Form.Group>
        {
          contacts?.length ? (
            <>
              <Form.Label htmlFor="contacts">
                {t(
                  'ModalContact.providerContacts',
                  `Contacts ${(serviceLabel?.length) ? '{{service}}' : ''}`,
                  { service: `${(serviceLabel?.length) ? `"${serviceLabel}"` : ''}` },
                )}
              </Form.Label>
              <div className="existing-contacts">
                {
                  contacts.map((contact) => (
                    <Row key={contact.id} className="g-0 mb-1">
                      <Col xs={2} className="text-truncate">
                        {contact.first_name && capitalize(contact.first_name)}
                      </Col>
                      <Col xs={3} className="text-truncate">
                        {contact.last_name && capitalize(contact.last_name)}
                      </Col>
                      <Col xs={3} className="text-truncate">
                        {contact?.phone_number ? `+${contact.phone_number}` : ''}
                      </Col>
                      <Col xs={4} className="text-truncate">
                        {contact?.email}
                      </Col>
                    </Row>
                  ))
                }
              </div>
            </>
          ) : (
            <p className="text-dark">
              {t(
                'ModalContact.noContacts',
                `Aucun contact ${(serviceLabel?.length) ? '{{service}}' : ''}`,
                { service: `${(serviceLabel?.length) ? `"${serviceLabel}"` : ''}` },
              )}
            </p>
          )
        }
      </Form.Group>
    </Form>
  );
};

ModalContact.defaultProps = {
  connection: '',
  task: '',
};

export default ModalContact;
