import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import AWContainer from '@root/components/AWContainer/AWContainer';
import CardEnterprise from '@root/components/Cards/CardEnterprise/CardEnterprise';
import AWButton from '@root/components/AWButtons/AWButton';
import { ActivePartner } from '@root/interfaces/enterprise.interface';
import { useHistory } from 'react-router-dom';
import { PROVIDER, PROVIDER_BASE_PATH } from '@root/helpers/constants.helper';
import useSafeFetch from '@root/hooks/useSafeFetch';
import { getEnterpriseActivePartnersConfig } from '@root/api-configs/enterprise.api.config';
import { UserContext } from '@root/contexts/user.context';
import { randString } from '@root/helpers/utils';
import { enterprisesPageSize } from '@root/helpers/enterprise.helper';

const Customers = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { user } = useContext(UserContext);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [hasMorePages, setHasMorePages] = useState(false);

  const {
    data: customers,
    setData: setCustomers,
    callApi: fetchCustomers,
    loading,
  } = useSafeFetch<ActivePartner[]>(getEnterpriseActivePartnersConfig, []).toObject;

  const getCustomers = async () => {
    const res = await fetchCustomers({
      enterpriseId: user.currentEnterprise?.id,
      search: new URLSearchParams({
        page: page.toString(),
        search,
        type: PROVIDER,
      }).toString(),
    });
    if (res?.success) {
      setCustomers(page === 1 ? res.data : [...customers, ...res.data]);
      setHasMorePages(res.data?.length === enterprisesPageSize);
    }
  };

  useEffect(() => {
    getCustomers();
  }, [page, search, user.currentEnterprise?.id]);

  const searchOnChange = useCallback(debounce(({ target: { value } }) => {
    if (page > 1) setPage(1);
    if (page === 1) setSearch(value);
  }, 300), [page]);

  return (
    <AWContainer>
      <AWContainer.Main
        isLoading={loading}
        title={(
          <div className="d-flex align-items-center">
            <span>
              {t('Customers.title', 'Mes clients')}
            </span>
            <Form onSubmit={(e) => e.preventDefault()}>
              <Form.Group>
                <Form.Control
                  id="enterpriseIdentity"
                  onChange={searchOnChange}
                  size="sm"
                  className="ms-5"
                />
              </Form.Group>
            </Form>
          </div>
        )}
      >
        {
          customers.map((customer) => (
            <CardEnterprise
              key={randString()}
              enterpriseType="customer"
              enterprise={customer}
              onClick={() => push(`${PROVIDER_BASE_PATH}/customers/${customer.customer_id}`)}
            />
          ))
        }
        {
          hasMorePages ? (
            <AWButton
              type="button"
              className="rounded"
              backgroundColor="white"
              color="black"
              loaderColor="secondary"
              onClick={() => setPage((p) => p + 1)}
            >
              {t('Customers.displayMore', 'Afficher plus de clients')}
            </AWButton>
          ) : null
        }
      </AWContainer.Main>
      <AWContainer.Side />
    </AWContainer>
  );
};

export default Customers;
