import React from 'react';
import { Container, Row } from 'react-bootstrap';

interface Props {
  noPadding?: boolean;
  className?: string;
}

const Separator = ({ noPadding, className }: Props) => (
  <Container className={noPadding ? 'p-0' : ''}>
    <Row>
      <div className={noPadding ? `separator ${className} m-0` : `separator ${className}`} />
    </Row>
  </Container>
);

Separator.defaultProps = {
  noPadding: false,
  className: '',
};

export default Separator;
