import React, { useContext } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '@root/routes/ProtectedRoute';
import Welcome from '@provider/views/Welcome/Welcome';
import AddEnterpriseSwitch from '@provider/views/AddEnterprise/AddEnterpriseSwitch';
import { UserContext } from '@root/contexts/user.context';
import { PROVIDER_BASE_PATH } from '@root/helpers/constants.helper';

const WelcomeRoutes = () => {
  const { path } = useRouteMatch();
  const { user } = useContext(UserContext);

  return (
    !user.currentAppEnterprises?.length ? (
      <Switch>
        <ProtectedRoute hasSidebar={false} path={path} exact>
          <Welcome />
        </ProtectedRoute>
        <ProtectedRoute hasSidebar={false} path={`${path}/enterprise`} exact>
          <AddEnterpriseSwitch />
        </ProtectedRoute>
      </Switch>
    ) : <Redirect to={PROVIDER_BASE_PATH} />
  );
};

export default WelcomeRoutes;
