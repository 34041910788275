import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AWButton from '@root/components/AWButtons/AWButton';

interface Props {
  description: string,
  isLoading: boolean,
  onConfirm: () => void,
  onCancel: () => void,
}

const ValidateChoice = ({
  description,
  isLoading,
  onConfirm,
  onCancel,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="text-dark mt-3 mb-4">
        {description}
      </p>
      <Row>
        <Col md="6">
          <AWButton
            type="button"
            className="border"
            backgroundColor="transparent"
            color="black"
            onClick={onCancel}
          >
            {t('Action.return')}
          </AWButton>
        </Col>
        <Col md="6">
          <AWButton
            type="button"
            onClick={onConfirm}
          >
            {
              isLoading ? (
                <Spinner animation="border" variant="white" size="sm" />
              ) : (
                t('Action.confirm')
              )
            }
          </AWButton>
        </Col>
      </Row>
    </>
  );
};

export default ValidateChoice;
