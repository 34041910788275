import React, { MouseEventHandler, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@root/contexts/user.context';
import logo from '@root/assets/addworking_logo_black.svg';
import citybuilding from '@root/assets/citybuilding.png';
import addCollaboratorBW from '@root/assets/add-collaborator-bw.png';
import inviteCustomerBW from '@root/assets/invite-customer-bw.png';
import scrabbleBW from '@root/assets/scrabble-bw.png';
import AWCard from '@provider/components/AWCard/AWCard';
import { PROVIDER_BASE_PATH } from '@root/helpers/constants.helper';
import AWContainer from '@root/components/AWContainer/AWContainer';

interface Card {
  image: string,
  title: string,
  text: string,
  action?: MouseEventHandler<HTMLDivElement>,
}

interface Content {
  message: JSX.Element,
  activeCard: Card,
  cards: Card[],
}

const Welcome = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { user } = useContext(UserContext);

  const content: Content = useMemo(() => {
    const addEnterpriseCard: Card = {
      action: () => push(`${PROVIDER_BASE_PATH}/welcome/enterprise`),
      image: citybuilding,
      title: t('Welcome.addEnterprise', 'Ajouter mon entreprise'),
      text: t(
        'Welcome.addEnterpriseText',
        `Vous devez rattacher à votre compte utilisateur au moins une entreprise.
         Vous pourrez plus tard en ajouter d'autres ou gérer celle-ci`,
      ),
    };

    const inviteClientCard: Card = {
      image: inviteCustomerBW,
      title: t('Welcome.inviteClient', 'Inviter un client'),
      text: t(
        'Welcome.inviteClientText',
        `Invitez vos clients à rejoindre la plateforme afin de profiter
         de la mutualisation des services. Votre conformité sera visibile
         immédiatement par tous vos clients.`,
      ),
    };

    const inviteCollabCard: Card = {
      image: addCollaboratorBW,
      title: t('Welcome.addCollaborator', 'Inviter un collaborateur'),
      text: t(
        'Welcome.addCollaboratorText',
        `Vous n'êtes peut-être seul à pouvoir tout gérer.
         N'hésitez pas à inviter des collaborateurs en leur donnant des droits qui leur correspondent.`,
      ),
    };

    const answerClientCard: Card = {
      image: scrabbleBW,
      title: t('Welcome.answerClientInvitation', "Répondre à une invitation de la part d'un client"),
      text: t(
        'Welcome.answerClientInvitationText',
        `Retrouvez ici les invitations de clients qui souhaitent vous proposer
         des missions et signer des contrats.`,
      ),
    };

    return {
      message: (
        <p>
          {t('Welcome.basicWelcome', 'Bienvenue dans votre environnement AddWorking')}
        </p>
      ),
      activeCard: addEnterpriseCard,
      cards: [
        inviteClientCard,
        answerClientCard,
        inviteCollabCard,
      ],
    };
  }, []);

  return (
    <AWContainer>
      <AWContainer.Main
        fullWidth
        className="welcome"
        title={`${t('Welcome.hello', 'Bonjour')} ${user.firstname}`}
        titleSide={(
          <img
            src={logo}
            height="32px"
            width="32px"
            alt="Addworking"
          />
        )}
      >
        {content.message}
        <p className="my-2">
          {t(
            'Welcome.welcomeIntroduction',
            `AddWorking est une plateforme gratuite qui vous permet de fluidifier vos échanges avec vos clients. 
          Vous allez pouvoir gérer le dossier administratif de votre entreprise et ainsi la mettre en conformité 
          au regard de l'administration mais également des exigences particulières que peuvent avoir chacun de vos clients.`,
          )}
        </p>
        <p className="mb-1">
          {`${t(
            'Welcome.welcomeExamples',
            'Cela vous permet par exemple, et selon le niveau fonctionnel souscrit par vos clients, de',
          )} :`}
        </p>
        <ul>
          <li>
            {t(
              'Welcome.example1',
              'Répondre à des propositions de missions',
            )}
          </li>
          <li>
            {t(
              'Welcome.example2',
              'Signer des contrats en ligne',
            )}
          </li>
          <li>
            {t(
              'Welcome.example3',
              'Déposer vos factures',
            )}
          </li>
          <li>
            {t(
              'Welcome.example4',
              'Suivre les paiements',
            )}
          </li>
        </ul>
        <div className="steps-container mt-4">
          <div>
            <h2>{`${t('Welcome.forNow', 'Et maintenant')} ?`}</h2>
            <div
              className="pointer"
              onClick={content.activeCard.action}
              aria-hidden="true"
            >
              <AWCard
                image={content.activeCard.image}
                title={content.activeCard.title}
                text={content.activeCard.text}
              />
            </div>
          </div>
          <div>
            <h2>{`${t('Welcome.nextSteps', 'Vous pourrez ensuite')}...`}</h2>
            <div className="cards-container">
              {content.cards.map((card) => (
                <AWCard
                  key={card.title}
                  image={card.image}
                  title={card.title}
                  text={card.text}
                  isInactive
                />
              ))}
            </div>
          </div>
        </div>
        <p className="mb-0">
          {t(
            'Welcome.footer',
            `Même si notre plateforme vous propose une expérience autonome, 
           notre équipe Support se tient toujours à votre disposition.`,
          )}
        </p>
      </AWContainer.Main>
    </AWContainer>
  );
};

export default Welcome;
