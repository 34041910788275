import React, {
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { CountryCode } from '@root/interfaces/utils.interface';
import EnterpriseForm from '@root/components/EnterpriseCreation/Forms/EnterpriseForm';
import { EnterpriseCreationContext } from '@root/contexts/enterpriseCreation.context';
import ErrorInput from '@customer/components/ErrorInput/ErrorInput';
import { BooleanErrors } from '@root/interfaces/form.interface';
import { EnterpriseFields } from '@root/interfaces/enterprise.interface';
import { checkIdentificationNumberFormat } from '@root/helpers/enterprise.helper';
import Countries from '@root/components/EnterpriseCreation/Forms/Countries';

const EnterpriseFormContainer = ({ onSubmit }: { onSubmit: () => void }) => {
  const { t } = useTranslation();
  const {
    isManual,
    setIsManual,
    enterprise,
    resetEnterprise,
    establishments,
    setSearch,
    countries,
  } = useContext(EnterpriseCreationContext);
  const [errors, setErrors] = useState<BooleanErrors<EnterpriseFields>>({});

  const handleSubmit = (e) => {
    e.preventDefault();
    const _errors: BooleanErrors<EnterpriseFields> = {};
    if (!enterprise.legal_form_id) {
      _errors[EnterpriseFields.LegalFormId] = true;
    }
    if (!enterprise.country) {
      _errors[EnterpriseFields.Country] = true;
    }
    if (!enterprise.name) {
      _errors[EnterpriseFields.Name] = true;
    }
    if (
      !checkIdentificationNumberFormat(
        enterprise.identification_number,
        enterprise.country,
      )
    ) {
      _errors[EnterpriseFields.IdentificationNumber] = true;
    }
    if (!enterprise.address?.address) {
      _errors[EnterpriseFields.AddressAddress] = true;
    }
    if (!enterprise.address?.town) {
      _errors[EnterpriseFields.AddressTown] = true;
    }
    if (!enterprise.address?.zipcode) {
      _errors[EnterpriseFields.AddressZipcode] = true;
    }
    if (Object.keys(_errors)?.length) {
      setErrors(_errors);
    } else if (onSubmit) {
      setErrors({});
      onSubmit();
    }
  };

  const searchEnterprise = ({ target: { value } }: { target: { value: string } }) => {
    setSearch(value);
  };

  const displayEnterpriseForm = () => {
    if (enterprise.identification_number || isManual) {
      return <EnterpriseForm errors={errors} onSubmit={handleSubmit} />;
    }
    return (
      <>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="enterprise-identification">
              {t(
                'Enterprise.nameOrSiret',
                "Nom de l'enterprise ou numéro SIRET/SIREN",
              )}
            </Form.Label>
            <Form.Control
              id="enterprise-identification"
              onChange={searchEnterprise}
            />
          </Form.Group>
        </Form>
        {
          establishments && !establishments?.length ? (
            <p className="text-dark mt-3">
              {t(
                'Enterprise.Form.Error.noEstablishment',
                "Aucun établissement actif n'est référencé pour l'entreprise {{enterprise}}",
                { enterprise: enterprise.name },
              )}
            </p>
          ) : ''
        }
      </>
    );
  };

  const handleCountry = (country: CountryCode) => {
    setIsManual(true);
    resetEnterprise({ country });
  };

  return (
    <div
      className="d-flex flex-column flex-grow-1 overflow-x-hidden"
    >
      <Countries
        onChange={handleCountry}
        country={enterprise.country || CountryCode.Fr}
        countriesEnabled={countries}
      />
      <ErrorInput
        error={errors![EnterpriseFields.Country]}
        message={t('Enterprise.Form.Error.selectCountry', 'Veuillez renseigner un pays')}
        type="form"
      />
      {!!enterprise.country && displayEnterpriseForm()}
    </div>
  );
};

export default EnterpriseFormContainer;
