import React, { ChangeEventHandler, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
  label: string;
  onChangeMin: ChangeEventHandler<HTMLInputElement>;
  onChangeMax: ChangeEventHandler<HTMLInputElement>;
}

enum Limits {
  Min = 'min',
  Max = 'max',
}

const ManualRange = ({ label, onChangeMin, onChangeMax }: Props) => {
  const { t } = useTranslation();
  const [min, setMin] = useState<number>();
  const [max, setMax] = useState<number>();
  const [error, setError] = useState<string>('');

  const handleChange = (type: Limits) => (e) => {
    const value = parseInt(e.target?.value, 10);
    if (Number.isNaN(value)) {
      if (type === Limits.Max) {
        setMax(undefined);
        onChangeMax(e);
      }
      if (type === Limits.Min) {
        setMin(undefined);
        onChangeMin(e);
      }
      setError('');
    }
    if (value > -1) {
      if (type === Limits.Min) {
        if (max && max < value) {
          setError(t('RangeError.minGreatherThanMax'));
        } else {
          setError('');
          setMin(value);
          onChangeMin(e);
        }
      }
      if (type === Limits.Max) {
        if (min && min > value) {
          setError(t('RangeError.minGreatherThanMax'));
        } else {
          setError('');
          setMax(value);
          onChangeMax(e);
        }
      }
    }
  };

  return (
    <Form.Group>
      <Form.Label>
        {label}
      </Form.Label>
      <div className="d-flex justify-content-between align-content-center">
        <div className="my-auto mx-10">
          {t('Common.between')}
        </div>
        <Form.Control
          type="number"
          min="0"
          placeholder={t('Form.min', 'Minimum')}
          onChange={handleChange(Limits.Min)}
        />
        <div className="my-auto mx-10">
          {t('Common.and').toLowerCase()}
        </div>
        <Form.Control
          type="number"
          min="0"
          placeholder={t('Form.max', 'Maximum')}
          onChange={handleChange(Limits.Max)}
        />
      </div>
      <span className="form-error mb-4">
        {error}
      </span>
    </Form.Group>
  );
};

export default ManualRange;
