import React, { ReactNode } from 'react';
import MenuTabsContextProvider from '@root/contexts/menuTabs.context';

import ListContextProvider from '@customer/contexts/list.context';
import LegendreProviderContextProvider from '@customer/contexts/legendreProvider.context';
import EnterpriseCreationContextProvider from '@root/contexts/enterpriseCreation.context';

const CustomerAppContextsProvider = ({ children }: { children: ReactNode }) => (
  <MenuTabsContextProvider>
    <LegendreProviderContextProvider>
      <ListContextProvider>
        <EnterpriseCreationContextProvider>
          {children}
        </EnterpriseCreationContextProvider>
      </ListContextProvider>
    </LegendreProviderContextProvider>
  </MenuTabsContextProvider>
);

export default CustomerAppContextsProvider;
