import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import { cloneDeep } from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';

import Input from '@root/components/Input/Input';
import { UserContext } from '@root/contexts/user.context';
import { AWColors, Option } from '@root/interfaces/utils.interface';
import AWButton from '@root/components/AWButtons/AWButton';
import { genders } from '@root/helpers/user.helper';
import InfoMessage from '@root/components/InfoMessage/InfoMessage';

import ContentBox from '@user/components/ContentBox/ContentBox';
import useCognito from '@root/hooks/useCognito';
import { LoginRouterContext, Views } from '@user/contexts/loginRouter.context';
import AWSelect from '@root/components/AWSelect/AWSelect';
import { CognitoErrors } from '@root/interfaces/cognito.interface';
import { PHONE_NUMBER_REGEX } from '@root/helpers/patterns';

interface UserExist {
  firstname: string;
  lastname: string;
  gender: string;
  password: string,
  confirmationPassword: string,
  phoneNumber: string,
  captchaToken?: string;
}

const initErrors = {
  firstname: '',
  lastname: '',
  gender: '',
  password: '',
  confirmationPassword: '',
  phoneNumber: '',
  captchaToken: '',
};

interface Props {
  firstConnection?: boolean;
}

const requiredFields = [
  'firstname',
  'lastname',
  'gender',
  'confirmationPassword',
  'phoneNumber',
];

const CognitoRegister = ({ firstConnection }: Props) => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const { setCurrentView } = useContext(LoginRouterContext);
  const [errors, setErrors] = useState<UserExist>(initErrors);
  const [captchaToken, setCaptchaToken] = useState('');
  const [acceptedConditions, setAcceptedConditions] = useState(false);
  const { message, signUp } = useCognito();
  const [userExist, setUserExist] = useState<UserExist>({
    firstname: user.firstname || '',
    lastname: user.lastname || '',
    gender: user.gender || '',
    password: '',
    confirmationPassword: '',
    phoneNumber: user.phoneNumber || '',
  });

  const goToLogin = () => { setCurrentView(Views.Login); };

  const handleErrors = () => {
    const _errors: UserExist = cloneDeep(initErrors);
    for (let i = 0; i < requiredFields.length; i += 1) {
      const field = requiredFields[i];
      if (!userExist[field]) {
        _errors[field] = t('Error.missingField');
      }
    }
    if (userExist.phoneNumber && !userExist.phoneNumber?.match(PHONE_NUMBER_REGEX)) {
      _errors.phoneNumber = t('Errors.formatNotValid');
    }
    if (!userExist.password) {
      _errors.password = t('PasswordReset.passwordFormat');
    }
    if (
      userExist.password
      && userExist.confirmationPassword
      && userExist.password !== userExist.confirmationPassword
    ) {
      _errors.confirmationPassword = t('PasswordReset.passwordMatch');
    }
    if (!captchaToken) {
      _errors.captchaToken = t('Errors.captcha');
    }
    setErrors(_errors);
    return Object.values(_errors).every((msg: string) => (!msg));
  };

  const handleChange = (field: keyof UserExist) => (event) => {
    const value = event?.target?.value === undefined ? event : event.target.value;
    setUserExist({ ...userExist, [field]: value });
  };

  const handleGenderChange = (gender: Option) => {
    setUserExist({ ...userExist, gender: gender.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = handleErrors();
    if (isValid) {
      signUp({ ...user, ...userExist }, captchaToken);
    }
  };

  const phoneNumberErrors = () => {
    if (errors?.phoneNumber) {
      return <InfoMessage message={errors.phoneNumber} />;
    }
    if (message?.opt === CognitoErrors.PhoneNumberFormat) {
      return <InfoMessage message={message.text} color={message.color} />;
    }
    return null;
  };

  return (
    <ContentBox title={firstConnection ? t('FirstConnection.title') : t('Login.cognitoRegister')}>
      <div className="line-break font-size-14 mb-4 text-italic">
        {firstConnection
          ? t('FirstConnection.welcome')
          : (
            <div className="color-red">
              {t('Login.cognitoRegisterDescription')}
            </div>
          )}
      </div>
      <Form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between flex-wrap">
          <div className="d-flex flex-column align-items-start mr-15">
            <Form.Group>
              <Form.Label>
                {t('Signup.gender')}
              </Form.Label>
              <AWSelect
                options={genders}
                value={
                  genders.find((g) => g.value === userExist.gender) || { label: '-', value: '' }
                }
                onChange={handleGenderChange}
              />
            </Form.Group>
            <InfoMessage message={errors.gender} />
          </div>
          <Input
            label={t('Signup.firstName')}
            className="flex-1 mr-15"
            value={userExist.firstname}
            onChange={handleChange('firstname')}
            error={errors.firstname}
          />
          <Input
            label={t('Signup.lastName')}
            className="flex-1"
            value={userExist.lastname}
            onChange={handleChange('lastname')}
            error={errors.lastname}
          />
        </div>
        <Input
          label={t('Signup.email')}
          className="mt-4"
          disabled
          value={user.email}
        />
        <Form.Group className="mt-4">
          <Form.Label>
            {t('Signup.phoneNumber')}
          </Form.Label>
          <PhoneInput
            country="fr"
            placeholder="+33 1 02 03 04 05"
            inputProps={{
              name: 'phone',
              required: true,
            }}
            onChange={handleChange('phoneNumber')}
            value={userExist.phoneNumber}
            inputClass="phone-number-input font-style-regular"
            buttonClass="phone-number-button"
            dropdownClass="font-style-light"
          />
          {phoneNumberErrors()}
        </Form.Group>
        <div className="d-flex justify-content-between flex-wrap mt-4">
          <Input
            label={t('Signup.password')}
            className="flex-1 mr-15"
            type="password"
            value={userExist.password}
            placeholder="••••••••"
            onChange={handleChange('password')}
            error={errors.password}
            tooltip={t('PasswordReset.passwordFormat')}
          />
          <Input
            label={t('PasswordReset.newPasswordConfirmation')}
            className="flex-1"
            type="password"
            value={userExist.confirmationPassword}
            placeholder="••••••••"
            error={errors.confirmationPassword}
            onChange={handleChange('confirmationPassword')}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column mt-4">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={setCaptchaToken}
            hl={i18n.language.split('-')[0]}
          />
          {errors.captchaToken ? (<InfoMessage message={errors.captchaToken} />) : ''}
        </div>
        <Form.Group className="mt-3 d-flex">
          <Form.Check
            id="terms"
            type="checkbox"
            onChange={(e) => setAcceptedConditions(e.target.checked)}
          />
          <Form.Label htmlFor="terms" className="mx-2">
            {t('Signup.acceptTerms1')}
            &nbsp;
            <a href="https://www.addworking.com/legal/cgu" target="_blank" rel="noreferrer">
              <u>
                {t(
                  'Signup.acceptTerms2',
                  "conditions générales d'utilisation",
                )}
              </u>
            </a>
          </Form.Label>
        </Form.Group>
        {message && message.opt !== CognitoErrors.PhoneNumberFormat ? (
          <InfoMessage message={message.text} color={message.color} />
        ) : null}
        <div className="d-flex mt-5 justify-content-end flex-wrap">
          <AWButton
            type="button"
            onClick={goToLogin}
            backgroundColor={AWColors.LightGrey}
            className="mr-15"
            color="black"
          >
            {t('Action.cancel')}
          </AWButton>
          <AWButton type="submit" disabled={!acceptedConditions}>
            {t('Action.validate')}
          </AWButton>
        </div>
      </Form>
    </ContentBox>
  );
};

CognitoRegister.defaultProps = {
  firstConnection: false,
};

export default CognitoRegister;
