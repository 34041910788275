/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import { useForm, Controller } from 'react-hook-form';
import AWButton from '@root/components/AWButtons/AWButton';
import InfoMessage from '@root/components/InfoMessage/InfoMessage';
import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from '@root/helpers/patterns';
import {
  createContact,
  deleteContact,
  getContacts as getContactsService,
} from '@provider/services/contact.service';
import { Contact } from '@root/interfaces/contact.interface';
import { Connection, ConnectionTask } from '@root/interfaces/connection.interface';
import { capitalize } from '@root/helpers/utils';
import { ApiResponse } from '@root/helpers/response-handler';
import { AWColors } from '@root/interfaces/utils.interface';

interface Props {
  onClose: () => void,
  connection?: Connection,
  task?: ConnectionTask,
}

const ModalContact = ({
  onClose,
  connection,
  task,
}: Props) => {
  const { t } = useTranslation();
  const [contacts, setContacts] = useState<Contact[]>();
  const [contactsToDelete, setContactsToDelete] = useState<Contact[]>([]);
  const {
    register,
    watch,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const contactFields = watch();

  const isSubmitDisabled = !contactFields.firstName
    || !contactFields.lastName
    || !(contactFields.email || contactFields.phoneNumber);

  const getContacts = async (providerId, serviceId, customerId) => {
    const contactRes = await getContactsService({
      fields: 'id,first_name,last_name,email,phone_number',
      search: {
        provider_id: providerId,
        service_id: serviceId,
        customer_id: customerId,
      },
    });
    if (contactRes.success && contactRes.data) {
      setContacts(contactRes.data);
    }
  };

  useEffect(() => {
    if (connection?.provider_id && task?.customer_data && connection?.customer_id) {
      getContacts(connection.provider_id, task.customer_data, connection.customer_id);
    }
  }, [connection, task]);

  const findErrors = (value: string) => {
    let error;
    if ((errors as any)[value]?.type === 'required') {
      error = t('Errors.missingField', 'Merci de remplir ce champ');
    } else if ((errors as any)[value]?.type === 'custom') {
      error = errors[value]?.message;
    }
    return error ? <InfoMessage message={error} color={AWColors.Red} className="mb-2" /> : null;
  };

  const onSubmit = async (data) => {
    if (data.email && !data.email.match(EMAIL_REGEX)) {
      setError(
        'email',
        {
          type: 'custom',
          message: t('Errors.formatNotValid', 'Le format du champ est invalide'),
        },
      );
    } else if (data.phoneNumber && !data.phoneNumber.match(PHONE_NUMBER_REGEX)) {
      setError(
        'phoneNumber',
        {
          type: 'custom',
          message: t('Errors.formatNotValid', 'Le format du champ est invalide'),
        },
      );
    } else if (!data.phoneNumber && !data.email) {
      setError(
        'phoneNumber',
        {
          type: 'custom',
          message: t('Errors.atLeastOne', 'Merci de renseigner votre email ou votre numéro de téléphone'),
        },
      );
    } else {
      const contact: Contact = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        phone_number: data.phoneNumber,
        provider_id: connection?.provider_id,
        service_id: task?.customer_data,
        customer_id: connection?.customer_id,
      };
      await createContact(contact);
      onClose();
    }
  };

  const handleContactToDelete = (e, contact) => {
    if (e.target.checked) {
      setContactsToDelete([...contactsToDelete, contact]);
    } else {
      setContactsToDelete(contactsToDelete.filter((c) => c.id !== contact.id));
    }
  };

  const deleteContacts = async () => {
    const deletePromises = contactsToDelete.reduce(
      (acc, current) => (
        current.id ? [...acc, deleteContact(current.id)] : acc
      ),
      [] as Promise<ApiResponse>[],
    );
    const deleteRes = await Promise.all(deletePromises);
    if (deleteRes.some((dr) => dr.success)) {
      onClose();
    }
  };

  return (
    <>
      {
        contacts?.length ? (
          <>
            <p className="font-style-bold mb-3 ms-1">
              {t('ModalContact.alreadyexistingcontacts', 'Contacts existants')}
            </p>
            {
              contacts.map((contact) => (
                <Row key={contact.id} className="g-0 mb-1">
                  <Col xs={1} className="d-flex justify-content-center align-items-center">
                    <Form.Check
                      checked={contactsToDelete?.some((c) => c.id === contact.id)}
                      type="checkbox"
                      onChange={(e) => handleContactToDelete(e, contact)}
                    />
                  </Col>
                  <Col xs={11} className="existing-contacts">
                    <Row>
                      <Col xs={2} className="text-truncate">
                        {contact.first_name && capitalize(contact.first_name)}
                      </Col>
                      <Col xs={3} className="text-truncate">
                        {contact.last_name && capitalize(contact.last_name)}
                      </Col>
                      <Col xs={3} className="text-truncate">
                        {contact?.phone_number ? `+${contact.phone_number}` : ''}
                      </Col>
                      <Col xs={4} className="text-truncate">
                        {contact?.email}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))
            }
            <AWButton
              type="button"
              disabled={!contactsToDelete.length}
              className="mt-4 w-50"
              onClick={deleteContacts}
            >
              {t('Action.delete')}
            </AWButton>
          </>
        )
          : ''
      }
      <Form onSubmit={handleSubmit(onSubmit)}>
        <p className="font-style-bold my-3 ms-1">
          {t('Action.addContact')}
        </p>
        <Form.Group className="my-2">
          <Form.Label htmlFor="firstName">
            {t('ModifyProfile.firstName', 'Prénom')}
          </Form.Label>
          <Form.Control
            id="firstName"
            autoComplete="new-password"
            placeholder="John"
            {...register('firstName', { required: true })}
          />
          {findErrors('firstName')}
        </Form.Group>
        <Form.Group className="my-2">
          <Form.Label htmlFor="lastName">
            {t('ModifyProfile.lastName', 'Nom')}
          </Form.Label>
          <Form.Control
            id="lastName"
            autoComplete="new-password"
            placeholder="Doe"
            {...register('lastName', { required: true })}
          />
          {findErrors('lastName')}
        </Form.Group>
        <Form.Group className="my-2">
          <Form.Label htmlFor="phoneNumber">
            {t('Signup.phoneNumber', 'Numéro de téléphone')}
          </Form.Label>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <PhoneInput
                {...field}
                country="fr"
                placeholder="+33 1 02 03 04 05"
                inputClass="phone-number-input font-style-regular"
                buttonClass="phone-number-button"
                dropdownClass="font-style-light"
                inputProps={{
                  name: 'phone',
                }}
              />
            )}
          />
          {findErrors('phoneNumber')}
        </Form.Group>
        <Form.Group className="my-2">
          <Form.Label htmlFor="email">
            {t('ModifyProfile.email', 'Adresse mail')}
          </Form.Label>
          <Form.Control
            id="email"
            autoComplete="new-password"
            placeholder="john.doe@domain.com"
            {...register('email')}
          />
          {findErrors('email')}
        </Form.Group>
        <AWButton
          disabled={isSubmitDisabled}
          className="mt-4 w-50"
        >
          {t('Action.add')}
        </AWButton>
      </Form>
    </>
  );
};

ModalContact.defaultProps = {
  connection: '',
  task: '',
};

export default ModalContact;
