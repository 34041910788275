import React from 'react';
import { ILabelValueCard } from '@root/interfaces/labelValueCard.interface';
import LabelValue from '@customer/components/LabelValue/LabelValue';

interface Props {
  labelValues: ILabelValueCard[];
}

const LabelValueCard = ({ labelValues }: Props) => (
  <div className="label-value-card enterprise-info">
    {labelValues ? (React.Children.toArray(labelValues.map((labelValue: ILabelValueCard) => (
      <LabelValue keyName={`${labelValue?.title} :`}>
        <span className="label-value-card__value">{labelValue?.content}</span>
      </LabelValue>
    )))) : ''}
  </div>
);

export default LabelValueCard;
