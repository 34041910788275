import React, { ReactNode } from 'react';
import MenuTabsContextProvider from '@root/contexts/menuTabs.context';
import EnterpriseCreationContextProvider from '@root/contexts/enterpriseCreation.context';

const ProviderAppContextProvider = ({ children }: { children: ReactNode }) => (
  <MenuTabsContextProvider>
    <EnterpriseCreationContextProvider>
      {children}
    </EnterpriseCreationContextProvider>
  </MenuTabsContextProvider>
);

export default ProviderAppContextProvider;
