export interface Item {
  key?: string,
  type: string,
  description: string,
  price: number,
  unitPrice?: number,
  deliveryDate?: string;
  quantity?: number,
}

export interface PunchoutSession {
  token: string;
  postUrl: string;
  enterpriseId: string;
  expiresAt: Date;
  status: 'active' | 'expired' | 'closed',
  user?: string;
  email?: string;
  buyerCookie: string;
  punchoutPayloadId?: string;
  operation: string;
}

export interface PunchoutLog {
  userId: string;
  userEmail: string;
  customerId: string;
  customerName: string;
  providerId: string;
  providerName: string;
  sessionToken: string;
  label: string;
  createdAt?: Date;
}

export enum PunchoutLogLabel {
  NotConform = 'modale_punchout_odm_stNonConforme',
  RequireConfo = 'modale_punchout_odm_stNonConforme_demandeConfo',
  RefuseConfo = 'modale_punchout_odm_stNonConforme_refuseConfo',
}
