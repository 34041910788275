import React from 'react';
import {
  useRouteMatch,
  Switch,
} from 'react-router-dom';
import ProtectedRoute from '@root/routes/ProtectedRoute';
import Provider from '@customer/views/Providers/Provider/Provider';
import Providers from '@customer/views/Providers/Providers';

const ProviderRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={path} exact>
        <Providers />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/:enterpriseId/:offerId?`}>
        <Provider />
      </ProtectedRoute>
    </Switch>
  );
};

export default ProviderRoutes;
