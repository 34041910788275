import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import { queryBoySearch, queryToString } from '@root/helpers/utils';
import { QueryboyOptions } from '@root/interfaces/queryboy.interface';
import { Survey, SurveyAnswer } from '@root/interfaces/survey.interface';

const baseUrl = `${process.env.REACT_APP_COMPANY_API}/v1/company/customer/surveys`;

export const getSurveys = async (options: QueryboyOptions): Promise<ApiResponse<Survey[]>> => (
  safeFetch(`${baseUrl}${queryBoySearch(options)}`)
);

export const getSurvey = async (id: string): Promise<ApiResponse<Survey>> => (
  safeFetch(`${baseUrl}/${id}`)
);

export const getAnswers = async (surveyId: string): Promise<ApiResponse> => (
  safeFetch(`${baseUrl}/${surveyId}/answers`)
);

export const getSurveyAnswers = async (
  enterpriseId: string,
  surveyId: string,
): Promise<ApiResponse<SurveyAnswer>> => (
  safeFetch(
    `${baseUrl}/${surveyId}/enterprises/${enterpriseId}/answers`,
  )
);

export const getAnswersCount = async (surveyId: string, days = 10): Promise<ApiResponse> => (
  safeFetch(`${baseUrl}/answers/count${queryToString({
    survey: surveyId,
    days,
  })}`)
);

export const createSurveyAnswer = async (
  surveyId: string,
  body: SurveyAnswer,
): Promise<ApiResponse<SurveyAnswer>> => (
  safeFetch(
    `${baseUrl}/${surveyId}/answers`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  )
);

export const updateEnterpriseAnswers = async (
  surveyId: string,
  body: SurveyAnswer,
): Promise<ApiResponse<SurveyAnswer>> => (
  safeFetch(
    `${baseUrl}/${surveyId}/answers`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  )
);
