import React from 'react';
import { useRouteMatch, Switch } from 'react-router-dom';
import ProtectedRoute from '@root/routes/ProtectedRoute';
import NewApproval from '@customer/views/Approvals/NewApproval/NewApproval';
import CheckStep from '@customer/views/Approvals/NewApproval/NewApprovalSteps/CheckStep';
import InfosStep from '@customer/views/Approvals/NewApproval/NewApprovalSteps/InfosStep';
import MissionStep from '@customer/views/Approvals/NewApproval/NewApprovalSteps/MissionStep';
import ModelStep from '@customer/views/Approvals/NewApproval/NewApprovalSteps/ModelStep';
import PreviewStep from '@customer/views/Approvals/NewApproval/NewApprovalSteps/PreviewStep';
import ValidationStep from '@customer/views/Approvals/NewApproval/NewApprovalSteps/ValidationStep';
import { StepPathKeyword } from '@customer/views/Approvals/NewApproval/steps.list';
import ApprovalContextProvider from '@customer/contexts/approval.context';

const NewApprovalRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <ApprovalContextProvider>
      <Switch>
        <ProtectedRoute path={`${path}/${StepPathKeyword.MISSION}`} exact>
          <NewApproval><MissionStep /></NewApproval>
        </ProtectedRoute>
        <ProtectedRoute path={`${path}/${StepPathKeyword.MODEL}`} exact>
          <NewApproval><ModelStep /></NewApproval>
        </ProtectedRoute>
        <ProtectedRoute path={`${path}/${StepPathKeyword.INFOS}`} exact>
          <NewApproval><InfosStep /></NewApproval>
        </ProtectedRoute>
        <ProtectedRoute path={`${path}/${StepPathKeyword.CHECK}`} exact>
          <NewApproval><CheckStep /></NewApproval>
        </ProtectedRoute>
        <ProtectedRoute path={`${path}/${StepPathKeyword.PREVIEW}`} exact>
          <NewApproval><PreviewStep /></NewApproval>
        </ProtectedRoute>
        <ProtectedRoute path={`${path}/${StepPathKeyword.VALIDATION}`} exact>
          <NewApproval><ValidationStep /></NewApproval>
        </ProtectedRoute>
      </Switch>
    </ApprovalContextProvider>
  );
};

export default NewApprovalRoutes;
