import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { IntercomProvider } from 'react-use-intercom';

import ProtectedRoute from '@root/routes/ProtectedRoute';
import Frame from '@root/components/Frame/Frame';

import i18n from '@root/locales/i18n';
import Dashboard from '@provider/views/Dashboard/Dashboard';
import Forbidden from '@provider/views/Forbidden/Forbidden';
import ContactSupport from '@provider/views/ContactSupport/ContactSupport';
import Unauthorized from '@root/components//Unauthorized/Unauthorized';
import ProfileSettings from '@root/views/ProfileSettings/ProfileSettings';
import ConnectionRoutes from '@provider/routes/connection.route';
import { capitalize } from '@root/helpers/utils';
import { Apps } from '@root/hooks/useCurrentApp';
import WelcomeRoutes from '@provider/routes/welcome.route';
import { V1_LOGOUT } from '@root/helpers/constants.helper';
import EnterpriseRoutes from '@provider/routes/enterprise.route';
import CustomerRoutes from '@provider/routes/customer.route';
import NotFound from '@root/components/NotFound/NotFound';
import ApprovalProviderRoutes from './approvalProviderRoutes';
import ContractRoutes from './contract.route';

const AppProviderRoutes = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  i18n.setDefaultNamespace(capitalize(Apps.Provider));
  return (
    <I18nextProvider i18n={i18n}>
      <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID || ''}>
        <Switch>
          <Route path={`${path}/forbidden`} exact>
            <Forbidden />
          </Route>
          <Route path={`${path}/contact`} exact>
            <ContactSupport />
          </Route>
          <Route path={`${path}/not-found`} exact>
            <NotFound />
          </Route>
          <Route path={`${path}/connections`}>
            <ConnectionRoutes />
          </Route>
          <Route path={`${path}/enterprises`}>
            <EnterpriseRoutes />
          </Route>
          {process.env.REACT_APP_CONTRACT_PAGE_V2 === 'true'
          && (
          <Route path={`${path}/contracts`}>
            <ContractRoutes />
          </Route>
          )}
          <Route path={`${path}/welcome`}>
            <WelcomeRoutes />
          </Route>
          <Route path={`${path}/customers`}>
            <CustomerRoutes />
          </Route>
          <Route path={`${path}/approvals`}>
            <ApprovalProviderRoutes />
          </Route>
          <ProtectedRoute path={`${path}/unauthorized`} exact>
            <Unauthorized />
          </ProtectedRoute>
          <ProtectedRoute path={`${path}/profile/:info`}>
            <ProfileSettings />
          </ProtectedRoute>
          <ProtectedRoute exact path={`${path}/`}>
            <Dashboard />
          </ProtectedRoute>
          <ProtectedRoute path={`${path}/v1/*`} hasSidebar={!pathname.includes(V1_LOGOUT)}>
            <Frame title="v1" />
          </ProtectedRoute>
          <Route path="*">
            <Redirect to={`${path}/not-found`} />
          </Route>
        </Switch>
      </IntercomProvider>
    </I18nextProvider>
  );
};

export default AppProviderRoutes;
