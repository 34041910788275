import React from 'react';
import { Form } from 'react-bootstrap';
import { Field, FieldReference } from '@root/interfaces/field.interface';
import AWSelect from '@root/components/AWSelect/AWSelect';

interface Props {
  fields?: Field[],
  customerFields?: Field[],
  handleFieldChange: (fieldId: string) => void,
}

const FieldsDataForms = ({
  fields,
  customerFields,
  handleFieldChange,
}: Props) => (
  <Form className="enterprise-info">
    {customerFields && customerFields.map((customerField, i) => {
      const fieldData = fields?.filter((field) => field.id === customerField.id);
      const value = fieldData?.map(({ content_id, content }) => ({ value: content_id || '', label: content || '' }));
      return (
        <Form.Group key={customerField.id} className={i > 0 ? 'mt-3' : ''}>
          <Form.Label className="text-dark">
            {customerField.title}
          </Form.Label>
          <AWSelect
            options={customerField.references?.map((ref: FieldReference) => (
              { value: ref.id, label: ref.content }
            ))}
            onChange={handleFieldChange(customerField.id)}
            isMulti={customerField.is_multiple}
            value={customerField.is_multiple ? value : value?.[0]}
          />
        </Form.Group>
      );
    })}
  </Form>
);

FieldsDataForms.defaultProps = {
  fields: [],
  customerFields: [],
};

export default FieldsDataForms;
