import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Badge,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getPreviousYears, toStringRange } from '@root/helpers/utils';
import { EmployeeNumber, BusinessTurnover } from '@root/interfaces/enterprise.interface';
import buildEnterpriseFields, { ConnectionTaskField, IEnterpriseFields } from '@customer/components/TaskModals/FieldsModal/fieldsModal.builder';
import AWSelect from '@root/components/AWSelect/AWSelect';

interface Props {
  fields: string[],
  providerId: string,
}

// 3 previous years if current month is before April.
const previousYears = new Date().getMonth() < 4
  ? getPreviousYears({ numberOfYears: 3, startingYear: new Date().getFullYear() - 1 })
  : getPreviousYears({ numberOfYears: 3 });
const previousYearOptions = previousYears.map((py) => ({
  value: py.toString(),
  label: py.toString(),
}));

const FieldsModal = ({ fields, providerId }: Props) => {
  const { t } = useTranslation();

  const [enterpriseFields, setEnterpriseFields] = useState<IEnterpriseFields>({});
  const [turnover, setTurnover] = useState<BusinessTurnover>();
  const [employeeNumber, setEmployeeNumber] = useState<EmployeeNumber>();

  const fetchData = async () => {
    const {
      enterpriseFields: _enterpriseFields,
      latestTurnover: _latestTurnover,
      latestEmployeeNumber: _latestEmployeeNumber,
    } = await buildEnterpriseFields(fields, providerId);
    if (_enterpriseFields) setEnterpriseFields(_enterpriseFields);
    if (_latestTurnover) setTurnover(_latestTurnover);
    if (_latestEmployeeNumber) setEmployeeNumber(_latestEmployeeNumber);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const declarativeEmployeeNumber = useMemo(() => {
    if (employeeNumber?.declarative_range_id) {
      return toStringRange(employeeNumber.declarative_min!, employeeNumber.declarative_max);
    }
    return '-';
  }, [employeeNumber?.declarative_range_id]);

  return (
    fields.length ? (
      <Form>
        {fields.includes(ConnectionTaskField.TURNOVER) && (
          <Form.Group className="mb-3">
            <Form.Label>
              {t('FieldsModal.turnover', "Chiffre d'affaires")}
            </Form.Label>
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label className="text-dark">
                    {t('FieldsModal.turnoverYear', 'Année')}
                  </Form.Label>
                  <AWSelect
                    height="35px"
                    noNullOption
                    value={previousYearOptions.find(
                      (option) => option.value === turnover?.year?.toString(),
                    )}
                    options={previousYearOptions}
                    onChange={(e) => setTurnover(
                      enterpriseFields.turnovers?.find(
                        (_t) => _t.year?.toString() === e.value,
                      ),
                    )}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label className="text-dark">
                    {t('FieldsModal.turnoverAmount', 'Montant déclaré')}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    disabled
                    defaultValue={turnover?.declarative_amount || ''}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label className="text-dark">
                    {t('FieldsModal.currency', 'Devise')}
                  </Form.Label>
                  <Form.Control
                    defaultValue={turnover?.currency || ''}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form.Group>
        )}
        {fields.includes(ConnectionTaskField.EMPLOYEES_NUMBER) && (
          <Form.Group className="mb-3">
            <Form.Label>
              {t('FieldsModal.employeeNumber', 'Masse salariale')}
            </Form.Label>
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label className="text-dark">
                    {t('FieldsModal.employeeNumberYear', 'Année')}
                  </Form.Label>
                  <AWSelect
                    height="35px"
                    noNullOption
                    value={previousYearOptions.find(
                      (option) => option.value === employeeNumber?.year?.toString(),
                    )}
                    options={previousYearOptions}
                    onChange={(e) => {
                      setEmployeeNumber(
                        enterpriseFields.employeeNumbers?.find(
                          (_en) => _en.year === e.value,
                        ),
                      );
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label className="text-dark">
                    {t('FieldsModal.employeeNumberCount', "Nombre d'employés")}
                  </Form.Label>
                  <Form.Control
                    value={declarativeEmployeeNumber || ''}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form.Group>
        )}
        {fields.includes(ConnectionTaskField.DEPARTMENTS) && (
          <Form.Group>
            <Form.Label>
              {t(
                'FieldsModal.geographicCover',
                "Couverture géographique (zone(s) d'intervention de l'entreprise)",
              )}
            </Form.Label>
            {
              enterpriseFields.departments?.length ? (

                enterpriseFields.departments?.map((dpt) => (
                  <Badge key={dpt.id} bg="secondary" className="me-1">
                    {`${dpt.insee_code} - ${dpt.display_name}`}
                  </Badge>
                ))
              ) : (
                <p className="text-dark">
                  {t('FieldsModal.noDepartments', "L'entreprise n'a pas renseigné sa couverture géographique")}
                </p>
              )
            }
          </Form.Group>
        )}
        {fields.includes(ConnectionTaskField.WEBSITE) && (
          <Form.Group className="mt-3">
            <Form.Label>
              {t('FieldsModal.website', 'Site internet')}
            </Form.Label>
            <p className="text-dark">
              {enterpriseFields.website ? (
                <a
                  href={enterpriseFields.website}
                  target="_blank"
                  rel="noreferrer"
                  className="border rounded py-1 px-2"
                >
                  {enterpriseFields.website}
                </a>
              ) : (
                t('FieldsModal.noWebsite', "L'entreprise n'a pas de site internet")
              )}
            </p>
          </Form.Group>
        )}
        {fields.includes(ConnectionTaskField.COMMERCIAL_BROCHURE) && (
          <Form.Group className="mt-3">
            <Form.Label>
              {t('FieldsModal.brochure', 'Plaquette commerciale')}
            </Form.Label>
            <p className="text-dark">
              {enterpriseFields.commercialBrochure ? (
                <a
                  href={enterpriseFields.commercialBrochure}
                  target="_blank"
                  rel="noreferrer"
                  className="border rounded py-1 px-2"
                >
                  {t('FieldsModal.previewDocument', 'Visualiser le document')}
                </a>
              ) : (
                t('FieldsModal.noBrochure', "L'entreprise n'a pas de plaquette commerciale")
              )}
            </p>
          </Form.Group>
        )}
      </Form>
    ) : (
      <p className="text-dark">
        {t('FieldsModal.noInfo', 'Aucune information')}
      </p>
    )

  );
};

export default FieldsModal;
