import React from 'react';
import { ApprovalStep as IApprovalStep } from '@customer/interfaces/approval-step.interface';
import ApprovalStep from '@customer/components/Stepper/ApprovalStep';

interface Props {
  stepList: IApprovalStep[];
  title: string;
}

const Stepper = ({ stepList, title }: Props) => (
  <>
    <h2 className="text-dark text-uppercase font-size-20 mt-5 mb-4">
      {title}
    </h2>
    {stepList ? stepList.map((step) => <ApprovalStep key={step.id} step={step} />) : null}
  </>
);

export default Stepper;
