import React, { useContext, useEffect, useState } from 'react';
import { ApprovalProviderContext } from '@provider/contexts/approvalProvider.context';
import { ILabelValueCard } from '@root/interfaces/labelValueCard.interface';
import { useTranslation } from 'react-i18next';
import LabelValueCard from '@root/components/LabelValueCard/LabelValueCard';
import { ApprovalStatusEnum } from '@root/interfaces/approval.interface';
import { mimeType } from '@root/helpers/utils';
import AWButton from '@root/components/AWButtons/AWButton';
import { updateApproval } from '@root/services/approval.service';
import { Modal } from 'react-bootstrap';

const ApprovalInformations = () => {
  const { approvalToDisplay, setApprovalToDisplay } = useContext(ApprovalProviderContext);
  const { t } = useTranslation();
  const [labelValue, setLabelValue] = useState<ILabelValueCard[]>([]);
  const [modal, setModal] = useState<{
    isOpen: boolean;
    title?: string;
  }>({ isOpen: false });

  const signatureStatus = {
    allSignaturesMissing: t('CardApproval.allSignaturesMissing', 'aucune signature'),
    providerValidationMissing: t(
      'CardApproval.providerValidationMissing',
      'en attente de validation ST',
    ),
    customerValidationMissing: t(
      'CardApproval.customerValidationMissing',
      'en attente de validation client',
    ),
    providerSignatureMissing: t(
      'CardApproval.providerSignatureMissing',
      'en attente de signature ST',
    ),
    customerSignatureMissing: t(
      'CardApproval.customerSignatureMissing',
      'en attente de signature client',
    ),
    sponsorSignatureMissing: t(
      'CardApproval.sponsorSignatureMissing',
      'en attente de signature MOA',
    ),
    signed: t('CardApproval.signed', 'signée'),
  };

  const setApprovalToDisplayStatus = (str: string) => setApprovalToDisplay(
    { ...approvalToDisplay, status: str.toUpperCase() },
  );

  const setSignatureProperties = () => {
    if (
      approvalToDisplay.status === ApprovalStatusEnum.DRAFT
      || approvalToDisplay.status === ApprovalStatusEnum.READY
    ) {
      setApprovalToDisplayStatus(signatureStatus.allSignaturesMissing);
    }
    if (approvalToDisplay.status === ApprovalStatusEnum.WAITING_PROVIDER_VALIDATION) {
      setApprovalToDisplayStatus(signatureStatus.providerValidationMissing);
    }
    if (approvalToDisplay.status === ApprovalStatusEnum.WAITING_CUSTOMER_VALIDATION) {
      setApprovalToDisplayStatus(signatureStatus.customerValidationMissing);
    }
    if (approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_PROVIDER) {
      setApprovalToDisplayStatus(signatureStatus.providerSignatureMissing);
    }
    if (approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_CUSTOMER) {
      setApprovalToDisplayStatus(signatureStatus.customerSignatureMissing);
    }
    if (approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_SPONSOR) {
      setApprovalToDisplayStatus(signatureStatus.sponsorSignatureMissing);
    }
    if (approvalToDisplay.status === ApprovalStatusEnum.ACCEPTED) {
      setApprovalToDisplayStatus(signatureStatus.signed);
    }
  };

  const setLabelValueDatas = async () => {
    setSignatureProperties();
    const labelValueDatas: ILabelValueCard[] = [
      {
        title: t('Approvals.sponsor', 'MOA'),
        content: `${approvalToDisplay?.sponsor_email ? approvalToDisplay?.sponsor_email : '-'}` || `${approvalToDisplay?.sponsor_legal_form ? approvalToDisplay?.sponsor_legal_form : ''} ${approvalToDisplay?.sponsor_name ? approvalToDisplay?.sponsor_name : '-'}`,
      },
      {
        title: t('Approvals.provider', 'Sous-traitant'),
        content: `${approvalToDisplay?.provider_legal_form ? approvalToDisplay?.provider_legal_form : ''} ${approvalToDisplay?.provider_name ? approvalToDisplay?.provider_name : '-'}`,
      },
      {
        title: t('Approvals.workfield', 'Chantier'),
        content: approvalToDisplay?.workfield_label ? approvalToDisplay?.workfield_label : '',
      },
      {
        title: t('Approvals.description', 'Description'),
        content: approvalToDisplay?.workfield_description ? approvalToDisplay?.workfield_description : '-',
      },
      {
        title: t('Approvals.status', 'Statut'),
        content: approvalToDisplay?.status ? approvalToDisplay?.status : '',
      },
    ];
    setLabelValue(labelValueDatas);
  };

  // modal handling functions
  const closeModal = () => setModal({ isOpen: false });

  useEffect(() => {
    setLabelValueDatas();
    return () => {
    };
  }, [approvalToDisplay]);

  const activateApproval = () => {
    updateApproval({
      id: approvalToDisplay.id,
      status: ApprovalStatusEnum.WAITING_CUSTOMER_VALIDATION,
    }, approvalToDisplay.id)
      .then((res) => {
        if (res.success) {
          setModal({ isOpen: true, title: 'Validation' });
        } else {
          setModal({ isOpen: true, title: 'Erreur' });
        }
      });
  };

  return (
    <>
      <div>
        <LabelValueCard labelValues={labelValue} />
      </div>
      {approvalToDisplay.mime_type === mimeType.pdf
        ? (
          <div className="approval-information">
            <AWButton
              className="approval-information__validation-button"
              onClick={() => activateApproval()}
            >
              {t('validationButton', "Valider la demande d'agrément")}
            </AWButton>
          </div>
        ) : ''}
      <Modal show={modal.isOpen} onHide={closeModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="approvals-information__modal">
            {modal.title === 'Validation' ? (
              <>
                {t('Approvals.informationValidModal', "La demande d'agrément a bien été validée")}
              </>
            )
              : (
                <>
                  {t('Approvals.informationErrorModal', 'Une erreur est survenue')}
                </>
              )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApprovalInformations;
