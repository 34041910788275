import React from 'react';
import { AWColors } from '@root/interfaces/utils.interface';
import { useTranslation } from 'react-i18next';

interface Props {
  message?: string,
  color?: AWColors,
  className?: string,
  toSupport?: boolean,
}

const InfoMessage = ({
  message,
  color,
  className,
  toSupport,
}: Props) => {
  const { t } = useTranslation();
  return (
    <p className={className} style={{ color }}>
      {
        toSupport ? (
          <>
            {`${t(
              'Support.weFaceDifficulties',
              'Nous rencontrons actuellement des difficultés, merci de contacter le',
            )} `}
            <a href="https://www.addworking.com/contact" target="_blank" rel="noreferrer">
              <u>
                {`${t('Support.support', 'support')}`}
              </u>
            </a>
            {` ${t('Support.ifYourProblemRemains', 'si votre problème persiste.')}`}
          </>
        ) : (
          <small>{message}</small>
        )
      }
    </p>
  );
};

InfoMessage.defaultProps = {
  message: '',
  color: AWColors.Red,
  className: '',
  toSupport: false,
};

export default InfoMessage;
