import React, {
  useState, useMemo, useContext, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form, Row, Col,
} from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import { randString } from '@root/helpers/utils';
import { EMAIL_REGEX } from '@root/helpers/patterns';
import AWButton from '@root/components/AWButtons/AWButton';
import add from '@root/assets/add.svg';
import trash from '@root/assets/trash.svg';
import { updateConnectionConfig } from '@root/api-configs/connection.api.config';
import useSafeFetch, { useSafeFetchCallback } from '@root/hooks/useSafeFetch';
import { sendConnectionInvitations } from '@customer/helpers/connection.helper';
import { UserContext } from '@root/contexts/user.context';
import { sendMailConfig } from '@root/api-configs/user.api.config';
import { TemplateCodeMail } from '@root/interfaces/mail-interface';
import { Connection } from '@root/interfaces/connection.interface';
import { buildNotifFromInvitations } from '@customer/views/Connections/connections.builder';
import { AlertContext, Notification } from '@root/contexts/alert.context';

interface AlertMessage {
  text?: string,
  secondaryText?: string,
  variant?: 'success' | 'danger',
  show: boolean,
}
interface Props {
  connection: Connection
}

const RecipientsInvitation = ({
  connection: {
    email, id, provider_id, customer_name,
  },
}: Props) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { REACT_APP_HOST_URL } = process.env;
  const [checkedEmails, setCheckedEmails] = useState<string[]>([]);
  const [areSentEmails, setAreSentEmails] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>({ show: false });
  const { setNotif } = useContext(AlertContext);

  const sendConnectionInvitationsService = useSafeFetchCallback(sendConnectionInvitations);

  const [
    createdEmails,
    setCreatedEmails,
  ] = useState<Record<string, string>[]>([{ key: randString(), email: '' }]);
  const updateConnection = useSafeFetch(updateConnectionConfig).callApi;
  const sendMail = useSafeFetch(sendMailConfig).callApi;

  useEffect(() => {
    let timeout;
    if (alertMessage.show) {
      timeout = setTimeout(() => {
        setAlertMessage({ ...alertMessage, show: false });
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [alertMessage]);

  useEffect(() => {
    setCreatedEmails([{ key: randString(), email: '' }]);
    setCheckedEmails([]);
    setAreSentEmails(false);
  }, [id]);

  const handleChange = (_email: string) => {
    const clone = [...checkedEmails];
    setCheckedEmails(clone.includes(_email)
      ? clone.filter((value) => value !== _email)
      : [...clone, _email]);
  };

  const updateEmail = (key: string, _email: string) => {
    const clone = cloneDeep(createdEmails);
    const element = clone.find((e) => e.key === key);
    element.email = _email;
    setCreatedEmails(clone);
  };

  const addEmail = () => {
    setCreatedEmails([...createdEmails, { key: randString(), email: '' }]);
  };

  const deleteEmail = (key: string) => {
    setCreatedEmails(createdEmails.filter((e) => e.key !== key));
  };

  const checkValidationMail = (key: string, _email: string) => (
    _email?.length
      ? EMAIL_REGEX.test(_email) && !createdEmails.some(
        (e) => e.key !== key && e.email.trim() === _email.trim(),
      ) && !email?.split(';').includes(_email)
      : false
  );
  const isSubmitDisabled = useMemo(
    () => {
      const isInvalidCreatedEmails = createdEmails.some((e) => {
        if (!e.email.trim().length){
          return false;
        }
        return !checkValidationMail(e.key, e.email);
      });
      const allEmptyCreatedEmails = createdEmails.filter((e) => !e.email.trim().length);

      if (!checkedEmails.length && allEmptyCreatedEmails.length === createdEmails.length){
        return true;
      }
      if (!!checkedEmails && isInvalidCreatedEmails){
        return true;
      }
      return false;
    },
    [checkedEmails, createdEmails, email],
  );

  const handleSubmit = async () => {
    let alert: Notification = {
      message: t('NewConnection.error', 'Une erreur est survenue'),
      variant: 'danger',
    };
    const listCreatedEmail = createdEmails
      .filter((e) => !!e.email.trim().length)
      .map((e) => e.email);

    const connectionRes = await updateConnection({
      connectionId: id,
      body: {
        email: [...email?.split(';') || [], ...listCreatedEmail].join(';'),
      },
    });

    if (connectionRes?.success){
      const emails = [...checkedEmails, ...listCreatedEmail];
      const [invitationsRes, mailRes] = await Promise.all([
        sendConnectionInvitationsService(
          {
            emails,
            user,
            enterpriseId: provider_id || '',
          },
        ),
        sendMail(
          {
            idTemplate: TemplateCodeMail.NewConnection,
            body: {
              emails,
              content: {
                customer_name,
                app_url: `${REACT_APP_HOST_URL}/provider/connections`,
              },
            },
          },
        ),
      ]);
      if (connectionRes?.success && mailRes?.success) {
        alert = buildNotifFromInvitations(invitationsRes);
      }
    }

    setNotif(alert);
    setAreSentEmails(true);
  };

  return (
    <div>
      <div className={`${areSentEmails ? 'content-disabled' : ''}`}>
        { email?.split(';').map((_email) => (
          <Form.Check
            type="checkbox"
            id={_email}
            key={_email}
            label={_email}
            value={_email}
            checked={checkedEmails.includes(_email)}
            onChange={() => handleChange(_email)}
          />
        ))}
        <Row>
          <p className="text-grey font-size-16 my-2 ">
            {t('InviteRecipientForm.addEmails')}
          </p>
          {createdEmails.map((element, index) => (
            <Row key={element.key}>
              <Col md="8">
                <Form.Control
                  id="email"
                  onChange={(e) => updateEmail(element.key, e?.target?.value || '')}
                  value={element.email}
                  isValid={checkValidationMail(element.key, element.email)}
                  isInvalid={element.email?.length
                    ? !checkValidationMail(element.key, element.email) : false}
                  required
                />
              </Col>
              <Col md="4" className="d-flex justify-content-center align-items-end">
                <AWButton
                  backgroundColor="transparent"
                  onClick={() => (!index ? addEmail() : deleteEmail(element.key))}
                  type="button"
                >
                  <img
                    src={!index ? add : trash}
                    alt={!index ? 'add' : 'trash'}
                    className="opacity-50"
                    height="25px"
                    width="25px"
                  />
                </AWButton>
              </Col>
            </Row>
          ))}
        </Row>
        <Row className="justify-content-end mt-4">
          <Col xl="4">
            <AWButton
              disabled={isSubmitDisabled}
              type="submit"
              onClick={handleSubmit}
            >
              {
              t('Action.send')
            }
            </AWButton>
          </Col>
        </Row>
      </div>
      {/* <Alert
        show={alertMessage?.show}
        variant={alertMessage?.variant}
        className="position-absolute bottom-0 end-0 m-4"
      >
        <p>
          {alertMessage?.text}
        </p>
        {
          alertMessage?.secondaryText ? (
            <>
              <hr />
              <p>{alertMessage.secondaryText}</p>
            </>
          ) : <> </>
        }
      </Alert> */}
    </div>
  );
};

export default RecipientsInvitation;
