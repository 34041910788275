import React, { useRef, useEffect, useState } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';

interface Props {
  data: number[];
  color: string;
}

const LineChart = ({
  data,
  color,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, setChart] = useState<ChartJS>();

  useEffect(() => {
    if (ref.current) {
      ref.current.replaceChildren();
      const canvas = document.createElement('canvas');
      canvas.removeAttribute('height');
      canvas.removeAttribute('width');
      canvas.setAttribute('style', 'width: 100%;');
      ref.current.append(canvas);
      const ctx = canvas.getContext('2d');
      if (ctx && data.length) {
        ChartJS.register(...registerables);
        setChart(
          new ChartJS(ctx, {
            type: 'line',
            data: {
              labels: data.map((_, i) => (`${i}`)),
              datasets: [
                {
                  data,
                  backgroundColor: (context) => {
                    const { chartArea } = context.chart;
                    if (chartArea) {
                      const gradient = ctx
                        .createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                      gradient.addColorStop(0, 'white');
                      gradient.addColorStop(1, color);
                      return gradient;
                    }
                    return color;
                  },
                  pointBorderWidth: 0,
                  pointBorderColor: color,
                  pointStyle: 'cross',
                  borderColor: color,
                  fill: true,
                },
              ],
            },
            options: {
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                y: {
                  min: 0,
                  display: false,
                },
                x: {
                  display: false,
                },
              },
            },
          }),
        );
      }
    }
  }, [ref.current, data]);

  useEffect(() => () => {
    ref.current?.replaceChildren();
  }, []);

  return (
    <div ref={ref} />
  );
};

export default LineChart;
