import i18n from '@root/locales/i18n';
import { Option } from '@root/interfaces/utils.interface';
import { Apps } from '@root/hooks/useCurrentApp';
import { capitalize } from '@root/helpers/utils';

i18n.setDefaultNamespace(capitalize(Apps.Customer));

export const marketTypeOptions: Option[] = [
  { value: 'all', label: i18n.t('ApprovalsFilters.all', 'Tous') },
  { value: 'public', label: i18n.t('ApprovalsFilters.publicMarketType', 'Marché public') },
  { value: 'private', label: i18n.t('ApprovalsFilters.privateMarketType', 'Marché privé') },
];

export const statusOptions: Option[] = [
  { value: 'unsigned', label: i18n.t('ApprovalsFilters.unsignedStatusOption', 'Aucune signature') },
  {
    value: 'customerSignatureMissing',
    label: i18n.t('ApprovalsFilters.customerMissingStatusOption', 'En attente du client'),
  },
  {
    value: 'providerSignatureMissing',
    label: i18n.t('ApprovalsFilters.providerMissingStatusOption', 'En attente du ST'),
  },
  {
    value: 'sponsorSignatureMissing',
    label: i18n.t('ApprovalsFilters.sponsorMissingStatusOption', 'En attente du MOA'),
  },
  { value: 'signed', label: i18n.t('ApprovalsFilters.signedStatusOption', 'Signée') },
];
