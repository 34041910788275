import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card } from 'react-bootstrap';
import customer from '@root/assets/customer.png';
import provider from '@root/assets/provider.png';
import LoginContainer from '@user/components/LoginContainer/LoginContainer';
import ReturnButton from '@user/components/ReturnButton/ReturnButton';
import { LoginRouterContext, Views } from '@user/contexts/loginRouter.context';
import { goTo } from '@root/helpers/utils';
import { CUSTOMER_BASE_PATH, PROVIDER_BASE_PATH } from '@root/helpers/constants.helper';

const ChooseProfile = () => {
  const { t } = useTranslation();
  const { setCurrentView } = useContext(LoginRouterContext);

  return (
    <LoginContainer isLogged>
      <h1>{t('ChooseProfile.connectAs', 'Se connecter en tant que')}</h1>
      <Row className="justify-content-between my-3">
        <Col md={6} className="mb-4 mb-md-0 mb-xl-4 mb-xxl-0">
          <Card className="choosecard p-0" onClick={() => goTo(CUSTOMER_BASE_PATH)}>
            <Card.Img
              className="choosecard__image"
              variant="top"
              src={customer}
              alt="customer"
            />
            <Card.Body className="h-50 d-flex flex-column justify-content-evenly">
              <Card.Title className="choosecard__title">
                {t('ChooseProfile.ordergiver', "Donneur d'ordre")}
              </Card.Title>
              <Card.Text className="choosecard__text">
                {t(
                  'ChooseProfile.descriptionCustomer',
                  'Retrouvez vos sous-traitants, leur conformité, les contrats, les missions confiées',
                )}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="choosecard p-0" onClick={() => goTo(PROVIDER_BASE_PATH)}>
            <Card.Img
              className="choosecard__image"
              variant="top"
              src={provider}
              alt="provider"
            />
            <Card.Body className="h-50 d-flex flex-column justify-content-evenly">
              <Card.Title className="choosecard__title">
                {t('ChooseProfile.provider', 'Sous-traitant')}
              </Card.Title>
              <Card.Text className="choosecard__text">
                {t(
                  'ChooseProfile.descriptionProvider',
                  'Gérez la relation avec vos clients, de la contractualisation au réglement',
                )}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ReturnButton onClick={() => setCurrentView(Views.Password)} />
    </LoginContainer>
  );
};

export default ChooseProfile;
