import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import { ApprovalNotification } from '@root/interfaces/approvalNotification.interface';

const baseUrl = `${process.env.REACT_APP_COMPLIANCE_API}/v1/compliance/customer`;

export const sendApprovalEmail = (
  body: ApprovalNotification,
): Promise<ApiResponse<void>> => safeFetch(`${baseUrl}/approvals/approval-notification`, {
  method: 'POST',
  body: JSON.stringify(body),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
