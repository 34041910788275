import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import {
  ApprovalVariable, NewApproval, ApprovalDetails,
} from '@root/interfaces/approval.interface';

const { REACT_APP_COMPLIANCE_API } = process.env;

// Routes
export const getCustomerApprovals = async (customerId: string): Promise<ApiResponse<any[]>> => safeFetch(`${REACT_APP_COMPLIANCE_API}/v1/compliance/customer/customers/${customerId}/approvals`);

export const getProviderApprovals = async (providerId: string): Promise<ApiResponse<any[]>> => safeFetch(`${REACT_APP_COMPLIANCE_API}/v1/compliance/provider/providers/${providerId}/approvals`);

export const getApprovalById = async (approvalId: string): Promise<ApiResponse<ApprovalDetails>> => safeFetch(`${REACT_APP_COMPLIANCE_API}/v1/compliance/customer/approvals/${approvalId}`);

export const createNewApproval = async (body: NewApproval): Promise<ApiResponse<NewApproval>> => safeFetch(`${REACT_APP_COMPLIANCE_API}/v1/compliance/customer/approvals`, {
  method: 'POST',
  body: JSON.stringify(body),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const updateApproval = async (body: NewApproval, approvalId: string): Promise<ApiResponse<NewApproval>> => safeFetch(`${REACT_APP_COMPLIANCE_API}/v1/compliance/customer/approvals/${approvalId}`, {
  method: 'PUT',
  body: JSON.stringify(body),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const createApprovalVariables = async (body: ApprovalVariable[], approvalId: string): Promise<ApiResponse<any>> => safeFetch(`${REACT_APP_COMPLIANCE_API}/v1/compliance/customer/approvals/${approvalId}/variables`, {
  method: 'POST',
  body: JSON.stringify(body),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const updateApprovalVariables = async (body: ApprovalVariable[], approvalId: string): Promise<ApiResponse<any>> => safeFetch(`${REACT_APP_COMPLIANCE_API}/v1/compliance/customer/approvals/${approvalId}/variables`, {
  method: 'PUT',
  body: JSON.stringify(body),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
