import { updateHasUserConfig } from '@root/api-configs/enterprise.api.config';
import { AlertContext } from '@root/contexts/alert.context';
import { UserContext } from '@root/contexts/user.context';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import useSafeFetch from '@root/hooks/useSafeFetch';
import React, { useContext, useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import JobForm from '../JobForm/JobForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const JobTitleModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const [job, setJob] = useState<string>('');
  const { waitWithLoad, isLoading } = useLoadingPromise();
  const updateHasUser = useSafeFetch(updateHasUserConfig).callApi;
  const { user, setUser } = useContext(UserContext);
  const { setNotif } = useContext(AlertContext);

  const handleChange = (value: string) => {
    setJob(value);
  };

  const handleSubmit = async () => {
    const res = await waitWithLoad(updateHasUser({
      enterpriseId: user.currentEnterprise!.id!,
      userId: user.id,
      body: {
        job_title: job,
      },
    }));
    if (res?.success) {
      setUser({
        ...user,
        currentEnterprise: {
          ...user.currentEnterprise,
          job_title: job,
        },
      });
      onClose();
    } else {
      setNotif({
        message: t('Error.UnknownError'),
        variant: 'danger',
      });
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      backdrop="static"
      centered
      className="modal-xl"
    >
      <ModalBody>
        <JobForm
          onSubmit={handleSubmit}
          onChange={handleChange}
          enterpriseName={user.currentEnterprise?.name || ''}
          loading={isLoading}
          disabled={isLoading || !job}
        />
      </ModalBody>
    </Modal>
  );
};

export default JobTitleModal;
