import {
  Formats,
  SurveyAnswer,
  SurveyQuestion,
  Types,
} from '@root/interfaces/survey.interface';
import { getFile } from '@root/services/file.service';

export const buildAnswer = async (question: SurveyQuestion, answers?: SurveyAnswer) => {
  if (!answers) return { question: question.key };
  const answer = answers.values.find((ans) => ans.question === question.key);
  if (answer
    && Array.isArray(answer.value)
    && question.type === Types.Array
    && (question.format === Formats.Multi || question.format === Formats.Select)) {
    return {
      ...answer,
      value: answer.value.map((a) => ({ label: a, value: a })),
    };
  }
  if (answer?.value
    && question.format === Formats.File
    && !answer.value.includes('https')
  ) {
    const fileUrlRes = await getFile(answer.value);
    if (fileUrlRes.success) {
      return {
        ...answer,
        value: fileUrlRes.data.signedUrl,
      };
    }
  }
  return answer || { question: question.key };
};
