import React, { useState } from 'react';
import {
  Switch,
  Route,
  useParams,
  useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tab from '@provider/components/Tab/Tab';
import useCurrentApp, { Apps } from '@root/hooks/useCurrentApp';
import { CUSTOMER_BASE_PATH, PROVIDER_BASE_PATH } from '@root/helpers/constants.helper';
import UserNameForm from '@root/components/Profile/UserNameForm/UserNameForm';
import PasswordForm from '@root/components/Profile/PasswordForm/PasswordForm';
// Adresse email inchangeable pour l'instant
// import UserEmailForm from '@provider/components/Profile/UserEmailForm/UserEmailForm';

const Tabs = {
  Name: 'name',
  Email: 'email',
  Password: 'password',
};

const ProfileSettings = () => {
  const { t } = useTranslation();
  const { isApp } = useCurrentApp();
  const { info } = useParams<{ info: string }>();
  const { push } = useHistory();
  const [tab, setTab] = useState(info);

  const basePath = isApp(Apps.Customer) ? CUSTOMER_BASE_PATH : PROVIDER_BASE_PATH;

  const changePage = () => {
    push(basePath);
  };

  return (
    <div className="p-5">
      <h1 className="dashboard__title">
        {t('ModifyProfile.title', 'Modifier votre profil')}
      </h1>
      <ul className="d-flex p-0 mb-5">
        <Tab
          className="flex-1"
          to={`${basePath}/profile/${Tabs.Name}`}
          active={(tab === Tabs.Name)}
          action={() => setTab(Tabs.Name)}
        >
          {t('ModifyProfile.name', 'Nom')}
        </Tab>
        {/* Adresse email inchangeable pour l'instant */}
        {/* <Tab
            className="flex-1"
            to={`${baseUrl}/${Tabs.Email}`}
            active={(tab === Tabs.Email)}
            action={() => setTab(Tabs.Email)}
          >
            {t('ModifyProfile.email', 'Email')}
          </Tab> */}
        <Tab
          className="flex-1"
          to={`${basePath}/profile/${Tabs.Password}`}
          active={(tab === Tabs.Password)}
          action={() => setTab(Tabs.Password)}
        >
          {t('ModifyProfile.password', 'Mot de passe')}
        </Tab>
      </ul>

      <Switch>
        <Route exact path={`${basePath}/profile/name`}>
          <UserNameForm onCancel={changePage} />
        </Route>
        {/* Adresse email inchangeable pour l'instant */}
        {/* <Route exact path="/provider/profile/email">
            <UserEmailForm onCancel={changePage} />
          </Route> */}
        <Route exact path={`${basePath}/profile/password`}>
          <PasswordForm onCancel={changePage} />
        </Route>
      </Switch>
    </div>
  );
};

export default ProfileSettings;
