import React, {
  createContext,
  ReactNode,
  useMemo,
  useState,
} from 'react';

interface IListContext {
  activeList: number;
  setActiveList: (active: number) => void;
}

export const ListContext = createContext<IListContext>({
  activeList: 1,
  setActiveList: () => {},
});

const ListContextProvider = ({ children }: { children: ReactNode }) => {
  const [activeList, setActiveList] = useState<number>(1);

  const value = useMemo(() => ({
    activeList,
    setActiveList,
  }), [activeList]);

  return (
    <ListContext.Provider value={value}>
      {children}
    </ListContext.Provider>
  );
};

export default ListContextProvider;
