import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import { SurveyAnswer, Survey } from '@root/interfaces/survey.interface';

const { REACT_APP_URL_COMPANY_API } = process.env;

export const getSurvey = (identifier: string): Promise<ApiResponse<Survey>> => (
  safeFetch(
    `${REACT_APP_URL_COMPANY_API}/v1/company/provider/surveys/${identifier}`,
  )
);

export const createSurveyAnswer = async (
  surveyId: string,
  body: SurveyAnswer,
): Promise<ApiResponse<SurveyAnswer>> => (
  safeFetch(
    `${REACT_APP_URL_COMPANY_API}/v1/company/provider/surveys/${surveyId}/answers`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  )
);

export const getSurveyAnswers = async (
  enterpriseId: string,
  surveyId: string,
): Promise<ApiResponse<SurveyAnswer>> => (
  safeFetch(
    `${REACT_APP_URL_COMPANY_API}/v1/company/provider/surveys/${surveyId}/enterprises/${enterpriseId}/answers`,
  )
);

export const updateEnterpriseAnswers = async (
  surveyId: string,
  body: SurveyAnswer,
): Promise<ApiResponse<SurveyAnswer>> => (
  safeFetch(
    `${REACT_APP_URL_COMPANY_API}/v1/company/provider/surveys/${surveyId}/answers`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  )
);
