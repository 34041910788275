import React from 'react';

interface Props {
  /** If error = true, ErrorInput appears */
  error: boolean;
  /** Content message */
  message?: string;
  /** Type of alert, default: alert */
  type?: 'alert' | 'form';
}

const ErrorInput = ({ error, message, type }: Props) => {
  if (!error) {
    return <> </>;
  }
  return <div className={type === 'form' ? 'form-error' : 'alert-message'}>{message || ''}</div>;
};

export default ErrorInput;

ErrorInput.defaultProps = {
  message: '',
  type: '',
};
