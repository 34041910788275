import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { getFrameBaseUrl } from '@root/helpers/utils';
import { UserContext } from '@root/contexts/user.context';
import { GUID_ID } from '@root/helpers/patterns';
import { Enterprise } from '@root/interfaces/enterprise.interface';
import useCurrentApp, { Apps } from '@root/hooks/useCurrentApp';
import { logOut } from '@root/helpers/session';
import { getV2Redirection } from '@root/routes/redirections';
import Unauthorized from '@root/components/Unauthorized/Unauthorized';
import i18n from '@root/locales/i18n';

interface Props {
  title: string;
  className?: string;
}

const { REACT_APP_LARAVEL_URL, REACT_APP_LARAVEL_URL_WITH_MENU } = process.env;
const PARAMS_TO_HIDE = ['personal_token', 'locale'];

const V1ErrorPages = {
  NOT_FOUND: "Cette page n'existe pas !",
  UNAUTHORIZED: i18n.t('Error.v1.unauthorized'),
};

const Frame = ({ title, className }: Props) => {
  // eslint-dsisable-next-line @typescript-eslint/no-explicit-any
  const frameRef = useRef<any>(null);
  const { app, isApp } = useCurrentApp();

  const {
    listen,
    goBack,
    replace,
    push,
  } = useHistory();

  const { user } = useContext(UserContext);

  const [unauthorized, setUnauthorized] = useState(false);
  const [frameUrl, setFrameUrl] = useState('');
  const [displayNone, setDisplayNone] = useState(true);
  const [redirect, setRedirect] = useState<JSX.Element>();

  const hostUrl = useMemo(() => (
    !isApp(Apps.User) ? REACT_APP_LARAVEL_URL : REACT_APP_LARAVEL_URL_WITH_MENU
  ), [app]);

  const {
    pathname: v2path,
    search: v2search,
    hash: v2hash,
  } = window.location;

  const v1path = useMemo(
    () => v2path.replace('/v1/', '/') + v2search + v2hash,
    [v2path, v2search, v2hash],
  );

  const prefix = useMemo(() => (isApp(Apps.User) ? '' : `/${app}`), [app]);

  const updateEnterpriseId = (currentId: string) => {
    const ids = user.currentAppEnterprises?.map((enterprise: Enterprise) => enterprise.id) || [];
    if (!ids.includes(currentId)) return currentId;
    return user.currentEnterprise?.id || currentId;
  };

  const rebuildUrlWithIds = (path: string, params?: string[]): string => {
    if (!params || !params.length) return path;
    return params?.reduce((url: string, id: string) => (
      url.replace(id, updateEnterpriseId(id))
    ), path);
  };

  const authenticateAndRender = () => {
    let baseUrl;
    if (isApp(Apps.User) && hostUrl) {
      baseUrl = new URL(`${getFrameBaseUrl(hostUrl)}${v1path}`);
      baseUrl.searchParams.set('personal_token', localStorage.getItem('v1token') || user.v1token);
    } else {
      baseUrl = new URL(`${hostUrl}${v1path}`);
      baseUrl.searchParams.set('personal_token', user.v1token!);
    }
    baseUrl.searchParams.set('locale', i18n.language.split('-')[0]);
    baseUrl = baseUrl.toString();
    baseUrl = !isApp(Apps.User) ? baseUrl.replace(/\/(provider|customer)/, '') : baseUrl;
    setFrameUrl(baseUrl);
  };

  const isErrorPage = (selector: string, innerText: string) => {
    let error = false;
    frameRef.current.contentWindow.document
      .querySelectorAll(selector).forEach((el) => {
        if (el.innerText === innerText) {
          error = true;
        }
      });
    return error;
  };

  useEffect(() => {
    authenticateAndRender();
    const unlisten = listen((_, action) => {
      if (action === 'POP') {
        goBack();
      }
    });
    return unlisten;
  }, []);

  useEffect(() => {
    const params = v2path.match(new RegExp(GUID_ID, 'gi'));
    const _redirect = getV2Redirection(v2path, user.currentEnterprise?.id, params);
    if (!isApp(Apps.User) && _redirect) {
      replace(_redirect);
    } else if (params && frameUrl) {
      const url = rebuildUrlWithIds(frameUrl, params);
      if (url !== frameUrl) {
        setFrameUrl(url.replace(/\/(provider|customer)/, ''));
      }
    }
  }, [v2path, user.currentEnterprise, frameUrl]);

  useEffect(() => {
    if (unauthorized && user?.currentEnterprise?.id) {
      setUnauthorized(false);
    }
  }, [user?.currentEnterprise?.id]);

  useEffect(() => {
    window.addEventListener('blur', () => {
      if (document.activeElement?.tagName === 'IFRAME') {
        window.dispatchEvent(new Event('frameClicked'));
      }
    });
    return () => {
      window.removeEventListener('blur', () => { });
    };
  }, []);

  useEffect(() => {
    const links: HTMLAnchorElement[] = Array.from(frameRef?.current
      ?.contentWindow?.document?.querySelectorAll('a') || []);
    const navLinks = links.filter((link) => {
      const { pathname } = link;
      return (pathname && pathname !== v2path.replace(`/${app}/v1/`, '/'));
    });

    navLinks.forEach(
      (nl) => nl.addEventListener('click', () => setDisplayNone(true)),
    );

    return frameRef?.current?.contentWindow?.document.removeEventListener('click', () => { });
  }, [frameRef?.current?.contentWindow?.document, v2path, app]);

  const getV2Params = (search: string) => {
    const searchParams = new URLSearchParams(search);
    PARAMS_TO_HIDE.forEach((p) => {
      if (searchParams.has(p)) {
        searchParams.delete(p);
      }
    });
    return searchParams.toString() ? `?${searchParams.toString()}` : '';
  };

  const buildV2Url = (pathname, search, hash) => {
    const searchParams = getV2Params(search);
    return `${prefix}/v1${pathname}${searchParams}${hash}`;
  };

  const handleFrameLoaded = async () => {
    const {
      pathname,
      search,
      hash,
    } = frameRef.current.contentWindow.location;
    const isLogin = pathname.includes('login');
    if (isLogin) {
      await logOut();
    } else if (isErrorPage('h1', V1ErrorPages.NOT_FOUND)) {
      setRedirect(<Redirect to={isApp(Apps.Customer) || isApp(Apps.Provider)
        ? `/${app}/not-found` : '/not-found'}
      />);
    } else if (isErrorPage('h1', V1ErrorPages.UNAUTHORIZED)) {
      setUnauthorized(true);
    } else if (new URLSearchParams(search).has('redirect') || isLogin) {
      setRedirect(<Redirect to="/" />);
    } else if (
      (pathname + getV2Params(search) + hash) !== v1path.replace(prefix, '')
    ) {
      const url = buildV2Url(pathname, search, hash);
      push(url);
    }
    if (displayNone && !isLogin) setDisplayNone(false);
  };

  if (redirect) {
    return redirect;
  }

  return (
    <div className="h-100 w-100 d-flex justify-content-center align-items-center p-4">
      {displayNone ? (
        <div className="spinner-border text-dark" />
      ) : ''}
      {
        unauthorized ? (
          <Unauthorized />
        ) : (
          <iframe
            className={`${(displayNone ? 'd-none' : 'h-100 w-100')} ${className}`}
            title={title}
            ref={frameRef}
            src={frameUrl}
            // eslint-disable-next-line react/no-unknown-property
            onLoad={handleFrameLoaded}
          />
        )
      }
    </div>
  );
};

Frame.defaultProps = {
  className: '',
};

export default Frame;
