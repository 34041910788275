import React, { ReactNode } from 'react';
import UserContextProvider from '@root/contexts/user.context';
import AlertContextProvider from '@root/contexts/alert.context';
import NotificationContextProvider from '@root/contexts/notifications.context';
import LanguageContextProvider from './language.context';

const GlobalContextProvider = ({ children }: { children: ReactNode }) => (
  <AlertContextProvider>
    <UserContextProvider>
      <LanguageContextProvider>
        <NotificationContextProvider>
          {children}
        </NotificationContextProvider>
      </LanguageContextProvider>
    </UserContextProvider>
  </AlertContextProvider>
);

export default GlobalContextProvider;
