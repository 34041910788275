import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import List from '@customer/components/List/List';
import DropdownMenu from '@customer/components/DropdownMenu/DropdownMenu';
import TEMPLATES from '@customer/views/Surveys/SurveysProviders/surveysProviders.list';
import { Template } from '@customer/interfaces/list.interface';
import { ListContext } from '@customer/contexts/list.context';
import { LegendreProvider } from '@customer/interfaces/legendre.interface';
import { LegendreProviderContext } from '@customer/contexts/legendreProvider.context';
import { fetchProviders } from '@customer/views/Legendre/providerLegendre.builder';

const SurveysProviders = () => {
  const { t } = useTranslation();
  const [providers, setProviders] = useState<LegendreProvider[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMoreElements, setHasMoreElements] = useState<boolean>(true);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const { activeList, setActiveList } = useContext(ListContext);
  const history = useHistory();
  const { setProvider } = useContext(LegendreProviderContext);

  const template = useMemo<Template>(() => {
    const result: Template = TEMPLATES[activeList];
    result.columns = result.columns.map((col) => ({
      ...col,
      label: t(col.translate[0], col.translate[1]),
    }));
    return result;
  }, [activeList]);

  const fetchList = async (list: number, opts?: { reset?: boolean }) => {
    const providersList: LegendreProvider[] = await fetchProviders(
      list,
      page,
      TEMPLATES[list].buildCallback,
    );
    setHasMoreElements(providersList.length >= 25);
    setProviders((elements: LegendreProvider[]) => (
      opts?.reset ? providersList : ([...elements, ...providersList])
    ));
    setPageLoading(false);
  };

  useEffect(() => {
    fetchList(activeList);
  }, [page]);

  useEffect(() => {
    setListLoading(false);
  }, [providers]);

  const goToProvider = (entity: LegendreProvider): void => {
    setProvider(entity);
    history.push(`/customer/provider/${entity.enterprise_identification_number}`);
  };

  const handleChange = (option: { value: number }) => {
    setActiveList(option.value);
    setPage(1);
    setListLoading(true);
    fetchList(option.value, { reset: true });
  };

  const handleNextPage = () => {
    // eslint-disable-next-line no-plusplus, no-param-reassign
    setPage((p: number) => (++p));
    setPageLoading(true);
  };

  const options = useMemo(() => (
    [
      {
        value: 1, label: t('List.photovoltaic', 'Photovoltaïque 36KW'),
      },
      {
        value: 2, label: t('List.carpenters', 'Charpentiers - 34 - Mars 22'),
      },
      {
        value: 3, label: t('List.nice_carpenters', 'Charpentiers - 06 - Mai 22'),
      },
      {
        value: 4, label: t('List.meynes_carpenters', 'Charpentiers - 30 - Mai 22'),
      },
      {
        value: 5, label: t('List.nievre_carpenters', ' Charpentiers - 58 - Mars 22'),
      },
      {
        value: 6, label: t('List.calvados_waterproofer', ' Charpentiers - 58 - Mars 22'),
      },
    ]
  ), []);

  const defaultOption = useMemo(() => (
    options.find((opt) => opt.value === activeList) || options[0]
  ), []);

  const getLabel = (value: number) => (
    options.find((option: { value: number, label: string }) => option.value === value)?.label
    || options[0].label
  );

  return (
    <div className="h-100 d-flex flex-column">
      <div className="d-flex justify-content-between">
        <div className="h1 text-dark">
          {
            t('Table.title', 'Questionnaire {{title}}', { title: getLabel(activeList) })
          }
        </div>
        <div className="list-dropdown">
          <DropdownMenu
            onChange={handleChange}
            items={options}
            value={defaultOption}
          />
        </div>
      </div>
      <div className="flex-1">
        <List
          columns={template.columns}
          data={providers}
          onClick={goToProvider}
          onNextPage={handleNextPage}
          hasMorePages={hasMoreElements}
          pageLoading={pageLoading}
          listLoading={listLoading}
        />
      </div>
    </div>
  );
};

export default SurveysProviders;
