import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tabs from '@customer/components/Tabs/Tabs';
import AWContainer from '@root/components/AWContainer/AWContainer';
import ReturnButton from '@root/components/ReturnButton/ReturnButton';
import EnterpriseContact from '@root/components/Enterprise/EnterpriseContact';
import EnterpriseGeneral from '@root/components/Enterprise/EnterpriseGeneral';
import useSafeFetch, { useSafeFetchCallback } from '@root/hooks/useSafeFetch';
import { enterpriseTabsView, fetchEnterprise } from '@root/helpers/enterprise.helper';
import { UserContext } from '@root/contexts/user.context';
import useSafeState from '@root/hooks/useSafeState';
import {
  BusinessTurnover,
  EmployeeNumber,
  Enterprise,
  Partner,
} from '@root/interfaces/enterprise.interface';
import {
  getBusinessTurnoversConfig,
  getEmployeeNumbersConfig,
  getProviderCustomersConfig,
} from '@root/api-configs/enterprise.api.config';
import { getHigherObjectBy } from '@root/helpers/utils';
import { Connection } from '@root/interfaces/connection.interface';
import { getConnectionsByEnterpriseIdConfig } from '@root/api-configs/connection.api.config';
import { PROVIDER_BASE_PATH } from '@root/helpers/constants.helper';
import BurgerMenu from '@root/components/BurgerMenu/BurgerMenu';
import { BurgerMenu as IBurgerMenu } from '@root/interfaces/menu.interface';

const Customer = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { enterpriseId } = useParams<{ enterpriseId: string }>();
  const [activeTab, setActiveTab] = useState(enterpriseTabsView[0]);
  const [customer, setCustomer] = useSafeState<Enterprise>();
  const { replace } = useHistory();

  const getEnterpriseService = useSafeFetchCallback(fetchEnterprise);

  const [
    initBusinessTurnover,
    businessTurnover,
  ] = useSafeFetch<BusinessTurnover>(
    getBusinessTurnoversConfig,
    undefined,
    (turnovers: BusinessTurnover[]) => getHigherObjectBy('year', turnovers),
  );

  const [
    initEmployeeNumber,
    employeeNumber,
  ] = useSafeFetch<EmployeeNumber>(
    getEmployeeNumbersConfig,
    undefined,
    (_employees: EmployeeNumber[]) => getHigherObjectBy('year', _employees),
  );

  const [
    initConnections,
    connections,
  ] = useSafeFetch<Connection[]>(getConnectionsByEnterpriseIdConfig, []);

  const {
    callApi: getCustomers,
  } = useSafeFetch<Partner[]>(getProviderCustomersConfig, []).toObject;

  const connection = useMemo(() => (
    connections.find(
      (c) => c.provider_id === user.currentEnterprise?.id && c.customer_id === enterpriseId,
    )
  ), [connections]);

  const burgerMenu: IBurgerMenu = useMemo(() => {
    let options = [
      {
        label: t('CustomerBurgerMenu.seeContracts', 'Voir les contrats'),
        linkTo: `${PROVIDER_BASE_PATH}/v1/contract?filter[parties]=${enterpriseId}`,
      },
    ];

    if (connection?.id) {
      options = [
        ...options,
        {
          label: t('CustomerBurgerMenu.seeConnection', 'Voir la mise en relation'),
          linkTo: `${PROVIDER_BASE_PATH}/connections/${connection.id}`,
        }];
    }
    return {
      sections: [{ options }],
    };
  }, [enterpriseId, connection]);

  const fetchData = async () => {
    if (user.currentEnterprise?.id) {
      const partnersRes = await getCustomers(
        { providerId: user.currentEnterprise.id },
        { fields: 'customer_id', search: { customer_id: enterpriseId } },
      );
      if (partnersRes && partnersRes.data?.length) {
        const _customer = await getEnterpriseService(enterpriseId);
        setCustomer(_customer);
        if (_customer.id) {
          initBusinessTurnover(
            { enterpriseId: _customer.id },
            { fields: 'year,declarative_amount,official_amount' },
          );
          initEmployeeNumber(
            { enterpriseId: _customer.id },
            {
              fields: [
                'year',
                'declarative_range_id',
                'official_range_id',
                'declarative_min',
                'official_min',
                'declarative_max',
                'official_max',
              ],
            },
          );
          initConnections({ enterpriseId: user.currentEnterprise?.id });
        }
      } else {
        replace(`${PROVIDER_BASE_PATH}/customers`);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [user.currentEnterprise?.id]);

  const mainContent = () => {
    switch (activeTab.key) {
      case 'info':
        return (
          <EnterpriseGeneral
            enterprise={customer}
            businessTurnover={businessTurnover}
            employeeNumber={employeeNumber}
          />
        );

      default:
        return (
          <EnterpriseContact
            representatives={customer.representatives || []}
            phoneNumbers={customer.phone_numbers || []}
            connection={connection || {}}
            limitedContacts
          />
        );
    }
  };

  return (
    <AWContainer isLoading={!customer}>
      <AWContainer.Main
        title={(
          <div className="d-flex">
            <ReturnButton />
            <span>{`${customer?.legal_form} ${customer?.name}`}</span>
          </div>
        )}
        titleSide={<BurgerMenu sections={burgerMenu.sections} />}
      >
        <Tabs
          tabs={enterpriseTabsView}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {mainContent()}
      </AWContainer.Main>
      <AWContainer.Side />
    </AWContainer>
  );
};

export default Customer;
