import React from 'react';

interface Props {
  color?: string;
  width?: string;
  height?: string;
}

const InformationSVG = ({ color, width, height }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13.125 15">
    <path
      id="Icon_awesome-building"
      data-name="Icon awesome-building"
      d="M12.773,14.063h-.586V.7a.7.7,0,0,0-.7-.7H1.641a.7.7,0,0,0-.7.7V14.063H.352A.352.352,0,0,0,0,14.414V15H13.125v-.586A.352.352,0,0,0,12.773,14.063ZM3.75,2.227A.352.352,0,0,1,4.1,1.875H5.273a.352.352,0,0,1,.352.352V3.4a.352.352,0,0,1-.352.352H4.1A.352.352,0,0,1,3.75,3.4Zm0,2.813A.352.352,0,0,1,4.1,4.688H5.273a.352.352,0,0,1,.352.352V6.211a.352.352,0,0,1-.352.352H4.1a.352.352,0,0,1-.352-.352ZM5.273,9.375H4.1a.352.352,0,0,1-.352-.352V7.852A.352.352,0,0,1,4.1,7.5H5.273a.352.352,0,0,1,.352.352V9.023A.352.352,0,0,1,5.273,9.375ZM7.5,14.063H5.625V11.6a.352.352,0,0,1,.352-.352H7.148A.352.352,0,0,1,7.5,11.6ZM9.375,9.023a.352.352,0,0,1-.352.352H7.852A.352.352,0,0,1,7.5,9.023V7.852A.352.352,0,0,1,7.852,7.5H9.023a.352.352,0,0,1,.352.352Zm0-2.812a.352.352,0,0,1-.352.352H7.852A.352.352,0,0,1,7.5,6.211V5.039a.352.352,0,0,1,.352-.352H9.023a.352.352,0,0,1,.352.352Zm0-2.812a.352.352,0,0,1-.352.352H7.852A.352.352,0,0,1,7.5,3.4V2.227a.352.352,0,0,1,.352-.352H9.023a.352.352,0,0,1,.352.352Z"
      fill={color}
    />
  </svg>
);

InformationSVG.defaultProps = {
  color: '#fff',
  width: '13.125',
  height: '15',
};

export default InformationSVG;
