import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import Provider from '@customer/views/Providers/Provider/Provider';

interface Props {
  providerId?: string;
  isOpen: boolean;
  onClose: () => void;
}

const ModalProvider = ({ providerId, isOpen, onClose }: Props) => (
  <Modal
    show={isOpen}
    animation={false}
    backdrop="static"
    centered
    className="modal-xl "
    onHide={onClose}
    close
  >
    <ModalHeader closeButton />
    <ModalBody>
      <Provider enterpriseId={providerId} />
    </ModalBody>
  </Modal>
);

ModalProvider.defaultProps = {
  providerId: '',
};

export default ModalProvider;
