import { cloneDeep } from 'lodash';
import i18n from '@root/locales/i18n';

import { randString, capitalize } from '@root/helpers/utils';
import { getFile } from '@root/services/file.service';
import {
  Formats,
  SurveyAnswer,
  SurveyQuestion,
  Types,
} from '@root/interfaces/survey.interface';
import { AWColors } from '@root/interfaces/utils.interface';
import { getActivities, getEnterprises } from '@provider/services/enterprise.service';
import { getField } from '@root/services/field.service';
import { getSurvey, getSurveyAnswers } from '@root/services/survey.service';

import pending from '@root/assets/pending.svg';
import usersCheck from '@root/assets/users-check.svg';
import valid from '@root/assets/valid.svg';
import invalid from '@root/assets/invalid.svg';

import {
  AnswererTypes,
  Connection,
  ConnectionStep,
  ConnectionTask,
  ConnectionTaskContent,
  ConnectionTaskTypes,
  TasksExternalData,
} from '@root/interfaces/connection.interface';
import { getService } from '@provider/services/contact.service';
import { getDocument } from '@provider/services/documentType.service';
import { getConnection } from '@provider/services/connection.service';
import { getUser } from '@root/services/user.service';
import { Apps } from '@root/hooks/useCurrentApp';

i18n.setDefaultNamespace(capitalize(Apps.Provider));

const TASK_ADDWORKING_VALIDATION: ConnectionTaskContent[] = [
  ConnectionTaskTypes.DocumentType,
  ConnectionTaskTypes.Compliance,
  ConnectionTaskTypes.LegalCompliance,
  ConnectionTaskTypes.BusinessCompliance,
];

const TASK_CUSTOMER: ConnectionTaskContent[] = [
  ConnectionTaskTypes.CustomField,
];

const isCustomerTask = (content?: ConnectionTaskContent) => (
  content && TASK_CUSTOMER.includes(content)
);

const buildTaskCard = async (_task: ConnectionTask, _step: ConnectionStep) => {
  const task: ConnectionTask = cloneDeep(_task);
  const updates = [task.done_at, task.validated_at, task.rejected_at];
  const lastUpdate = Math.max(...updates.map((update) => (
    update ? new Date(update).getTime() : 0)));
  const toDoByCustomer = i18n.t(
    'Connection.customerPendingAction',
    'En attente de complétion par le client',
  );
  if (task?.done_by) {
    const userRes = await getUser(task.done_by);
    if (userRes && userRes.success) {
      task.updatedBy = `${userRes.data.firstname} ${userRes.data.lastname}`;
      task.disabled = true;
    }
  }
  if (lastUpdate) task.updatedAt = new Date(lastUpdate).toString();
  task.disabled = !!_step.validated_at || !_step.visible;
  if (task.rejected_at) {
    task.icon = invalid;
    task.color = AWColors.Red;
    task.status = i18n.t('Status.rejected');
  } else if (task.done_at && !task.validated_at) {
    task.icon = usersCheck;
    task.color = AWColors.Orange;
    if (task.content && TASK_ADDWORKING_VALIDATION.includes(task.content)) {
      task.status = i18n.t(
        'Connection.addworkingPendingValidation',
        'En attente de validation par Addworking',
      );
    } else {
      task.status = isCustomerTask(task.content) ? toDoByCustomer
        : i18n.t('Connection.pendingValidation', 'En attente de validation client');
    }
  } else if (_task.validated_at) {
    task.icon = valid;
    task.color = AWColors.Green;
    task.status = i18n.t('Status.validated');
  } else {
    task.icon = pending;
    task.color = AWColors.Grey;
    task.status = isCustomerTask(task.content) ? toDoByCustomer
      : i18n.t('Status.pending');
  }
  if (task.content === ConnectionTaskTypes.Fields) {
    task.title = i18n.t('Connection.enterpriseInfo', "Informations de l'entreprise");
  } else if (task.content === ConnectionTaskTypes.LegalCompliance) {
    task.title = i18n.t('Connection.legalCompliance', 'Conformité légale');
  } else if (task.content === ConnectionTaskTypes.BusinessCompliance) {
    task.title = i18n.t('Connection.businessCompliance', 'Conformité métier');
  } else if (task.content === ConnectionTaskTypes.Compliance) {
    task.title = i18n.t('Connection.compliance', 'Conformité');
  } else if (task.content === ConnectionTaskTypes.Activity) {
    task.title = i18n.t('Connection.activities', 'Activités');
  } else if (task.content === ConnectionTaskTypes.Form && task.customer_data) {
    const surveyRes = await getSurvey(task.customer_data);
    if (surveyRes.success) {
      task.title = `${i18n.t('Connection.survey', 'Questionnaire')} : ${surveyRes.data.label}`;
      task.hidden = surveyRes.data.answerer === AnswererTypes.Customer;
    }
  } else if (task.content === ConnectionTaskTypes.CustomField && task.customer_data) {
    const fieldRes = await getField(task.customer_data);
    if (fieldRes.success && fieldRes.data) {
      task.title = fieldRes.data.title;
    }
  } else if (task.content === ConnectionTaskTypes.Contact) {
    const serviceRes = await getService(task.customer_data, {
      fields: 'label',
    });
    if (serviceRes.success && serviceRes.data?.label) {
      const name = serviceRes.data.label;
      task.title = `${i18n.t('Connection.contact', 'Contact')} "${name}"`;
    } else {
      task.title = i18n.t('Connection.contact', 'Contact');
    }
  } else if (task.content === ConnectionTaskTypes.DocumentType && task.customer_data) {
    const documentRes = await getDocument(task.customer_data, {
      fields: 'display_name',
    });
    if (documentRes.success) {
      const name = documentRes.data.display_name;
      const activeLanguage = i18n.resolvedLanguage;
      task.title = (name) ? JSON.parse(name)[activeLanguage || 'fr'] : '';
    }
  } else {
    task.title = task.content;
  }
  if (isCustomerTask(task.content)) {
    task.title += ` - ${i18n.t('Connection.customerAction', 'Action Client')}`;
    task.disabled = true;
  }
  return task;
};

const buildTaskCards = async (_connection: Connection): Promise<Connection> => {
  const taskDetailPromises: Promise<ConnectionTask>[] = _connection.steps
    ?.flatMap((step) => (
      step.tasks.map((task) => buildTaskCard(task, step))
    )) || [];
  const taskDetails = await Promise.all(taskDetailPromises);
  return {
    ..._connection,
    steps: _connection.steps?.reduce((acc: ConnectionStep[], step: ConnectionStep) => {
      const tasks = taskDetails.filter((task) => (
        !!step.tasks.find((_task) => (
          _task.id === task.id && !_task.is_private && !task.hidden
        ))
      ));
      if (tasks.length) {
        if (step.validated_at) {
          tasks.push({
            id: randString(),
            title: i18n.t('Connection.stepVerify', 'Vérification côté client'),
            icon: valid,
            color: AWColors.Green,
            status: i18n.t('Connection.validated', 'Validé'),
            updatedAt: step.validated_at,
            disabled: true,
          });
        } else {
          tasks.push({
            id: randString(),
            title: i18n.t('Connection.stepVerify', 'Vérification côté client'),
            icon: usersCheck,
            color: AWColors.Orange,
            status: i18n.t('Connection.pendingValidation', 'En attente de validation client'),
            disabled: true,
          });
        }
        return [...acc, { ...step, tasks }];
      }
      return acc;
    }, []),
  };
};

export const buildConnection = async (connectionId: string) => {
  let connection: Connection = { id: connectionId };
  const connectionRes = await getConnection(connectionId);
  if (connectionRes.success) {
    const filteredConnections = {
      ...connectionRes.data,
      steps: connectionRes.data.steps?.map((step) => ({
        ...step,
        tasks: step.tasks.filter((task) => task.content !== ConnectionTaskTypes.CustomField),
      })),
    };
    connection = await buildTaskCards(filteredConnections);
    const customerNameRes = await getEnterprises({
      fields: 'name,logo_id',
      search: {
        id: connectionRes.data.customer_id,
      },
    });
    if (customerNameRes.success && customerNameRes.data.length) {
      connection.customer_name = customerNameRes.data[0].name;
      if (customerNameRes.data[0].logo_id) {
        const fileRes = await getFile(customerNameRes.data[0].logo_id);
        if (fileRes.success) {
          connection.customer_logo = fileRes.data.signedUrl;
        }
      }
    }
    return connection;
  }
  return null;
};

export const buildAnswer = async (question: SurveyQuestion, answers: SurveyAnswer) => {
  const answer = answers.values.find((ans) => ans.question === question.key);
  if (answer
    && Array.isArray(answer.value)
    && question.type === Types.Array
    && (question.format === Formats.Multi || question.format === Formats.Select)) {
    return {
      ...answer,
      value: answer.value.map((a) => ({ label: a, value: a })),
    };
  }
  if (answer?.value
    && question.format === Formats.File
  ) {
    const fileUrlRes = await getFile(answer.value);
    if (fileUrlRes.success) {
      return {
        ...answer,
        value: fileUrlRes.data.signedUrl,
      };
    }
  }
  return answer || { question: question.key };
};

const buildSurvey = async (surveyId: string, enterpriseId: string) => {
  const surveyRes = await getSurvey(surveyId);
  if (surveyRes.success && surveyRes.data.id && enterpriseId) {
    const answersRes = await getSurveyAnswers(enterpriseId, surveyRes.data.id);
    if (answersRes.success && answersRes.data) {
      surveyRes.data.isUpdate = true;
      if (surveyRes.data.content) {
        const answerPromises = surveyRes.data.content
          .flatMap((questions) => (
            questions.map((q) => buildAnswer(q, answersRes.data))
          ));
        const resolvedAnswers = await Promise.all(answerPromises);
        surveyRes.data.content = surveyRes.data.content?.map(
          (questions) => questions.map((q) => ({
            ...q,
            answer: resolvedAnswers.find((ra) => ra.question === q.key) || q.answer,
          })),
        );
      }
    }
    return surveyRes.data;
  }
  return null;
};

const tasksIncludeContent = (tasks: ConnectionTask[], content: ConnectionTaskContent) => (
  tasks.filter((t) => t.content === content)
);

const getTasksRequireExternalData = (steps: ConnectionStep[]) => {
  const tasksRequireExternalData = steps
    ?.reduce((acc: ConnectionTask[], step: ConnectionStep) => (
      [...acc, ...step.tasks.filter((_t) => (
        _t.content === ConnectionTaskTypes.Contact
        || _t.content === ConnectionTaskTypes.Form
        || _t.content === ConnectionTaskTypes.Activity
      ))]
    ), []);
  return {
    contactTasks: tasksIncludeContent(tasksRequireExternalData, ConnectionTaskTypes.Contact),
    formTasks: tasksIncludeContent(tasksRequireExternalData, ConnectionTaskTypes.Form),
    activityTasks: tasksIncludeContent(tasksRequireExternalData, ConnectionTaskTypes.Activity),
  };
};

export const buildTasksExternalData = async (steps: ConnectionStep[], providerId?: string) => {
  const tasksExternalData: TasksExternalData = {};
  const { contactTasks, activityTasks, formTasks } = getTasksRequireExternalData(steps);
  if (contactTasks.length) {
    const serviceRes = await Promise.all(contactTasks.map(
      (ct) => getService(ct.customer_data, { fields: 'id,label' }),
    ));
    serviceRes.forEach((sr) => {
      if (sr.success) {
        tasksExternalData.services = tasksExternalData.services
          ? [...tasksExternalData.services, sr.data] : [sr.data];
      }
    });
  }
  if (formTasks.length) {
    const surveyRes = await Promise.all(formTasks.map((ft) => {
      if (ft.customer_data && providerId) {
        return buildSurvey(ft.customer_data, providerId);
      }
      return null;
    }));
    surveyRes.forEach((sr) => {
      if (sr) {
        tasksExternalData.surveys = tasksExternalData.surveys
          ? [...tasksExternalData.surveys, sr] : [sr];
      }
    });
  }
  if (activityTasks && providerId) {
    const activityRes = await getActivities(providerId, {
      fields: 'activity,activity_id',
      page_size: 100,
    });
    if (activityRes.success && activityRes.data.length) {
      tasksExternalData.activities = activityRes.data;
    }
  }
  return tasksExternalData;
};
