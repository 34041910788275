import { Skill } from '@root/interfaces/enterprise.interface';
import { LegendreProvider } from '@customer/interfaces/legendre.interface';
import { TemplateList } from '@customer/interfaces/list.interface';

export const TEMPLATE_CONSTANTS = {
  QUALIFPV: 'QUALIFPV',
  QUALIFELEC: 'QUALIFELEC',
  METALLURGY: 'Métallurgie',
  CARPENTERS: 'Charpentiers',
  HERAULT_CARPENTERS: 'Charpentiers - 34 - Mars 22',
  PHOTOVOLTAIC: 'Photovoltaic',
  NICE_CARPENTERS: 'Charpentiers - 06 - Mai 22',
  MEYNES_CARPENTERS: 'Charpentiers - 30 - Mai 22',
  NIEVRE_CARPENTERS: 'Charpentiers - 58 - Mars 22',
  CALVADOS_WATERPROOFERS: 'Etancheurs - 14 - Mars 22',
};

const TEMPLATES: TemplateList = {
  1: {
    name: TEMPLATE_CONSTANTS.PHOTOVOLTAIC,
    columns: [
      {
        translate: ['Provider.legalForm', 'Forme Légale'],
        width: 7,
        key: 'legalForm',
        isBold: true,
      },
      {
        translate: ['Provider.name', 'Raison sociale'],
        width: 25,
        key: 'enterprise_name',
        isBold: true,
      },
      {
        translate: ['Provider.qualipv', 'QUALIFPV'],
        width: 15,
        key: 'qualifpv',
        isBoolean: true,
      },
      {
        translate: ['Provider.qualifelec', 'QUALIFELEC'],
        width: 15,
        key: 'qualifelec',
        isBoolean: true,
      },
      {
        translate: ['Provider.disponibility', 'Disponibilité mensuelle'],
        key: 'availability',
      },
      {
        translate: ['Provider.status', 'Statut'],
        key: 'statusLabel',
      },
    ],
    buildCallback: (provider: LegendreProvider): LegendreProvider => {
      if (provider.skills) {
        const skillNames = provider.skills.map((skill: Skill) => skill.name);
        return {
          ...provider,
          qualifpv: skillNames.includes(TEMPLATE_CONSTANTS.QUALIFPV),
          qualifelec: skillNames.includes(TEMPLATE_CONSTANTS.QUALIFELEC),
        };
      }
      return provider;
    },
  },
  2: {
    name: TEMPLATE_CONSTANTS.HERAULT_CARPENTERS,
    columns: [
      {
        translate: ['Provider.legalForm', 'Forme Légale'],
        width: 7,
        key: 'legalForm',
        isBold: true,
      },
      {
        translate: ['Provider.name', 'Raison sociale'],
        key: 'enterprise_name',
        isBold: true,
      },
      {
        translate: ['Provider.sector', 'Secteur'],
        key: 'sector',
        width: 20,
        isArray: true,
      },
      {
        translate: ['Provider.status', 'Statut'],
        width: 10,
        key: 'statusLabel',
      },
    ],
    buildCallback: (provider: LegendreProvider): LegendreProvider => {
      if (provider.skills) {
        return {
          ...provider,
          sector: provider.skills
            .filter((skill: Skill) => [
              TEMPLATE_CONSTANTS.METALLURGY,
              TEMPLATE_CONSTANTS.CARPENTERS,
            ].includes(skill.name || ''))
            .map((skill: Skill) => skill.name) as string[],
        };
      }
      return provider;
    },
  },
};

const classics: { id: number; name: string; }[] = [
  {
    id: 3,
    name: TEMPLATE_CONSTANTS.NICE_CARPENTERS,
  },
  {
    id: 4,
    name: TEMPLATE_CONSTANTS.MEYNES_CARPENTERS,
  },
  {
    id: 5,
    name: TEMPLATE_CONSTANTS.NIEVRE_CARPENTERS,
  },
  {
    id: 6,
    name: TEMPLATE_CONSTANTS.CALVADOS_WATERPROOFERS,
  },
];

for (let i = 0; i < classics.length; i += 1) {
  TEMPLATES[classics[i].id] = {
    name: classics[i].name,
    columns: [
      {
        translate: ['Provider.legalForm', 'Forme Légale'],
        width: 7,
        key: 'legalForm',
        isBold: true,
      },
      {
        translate: ['Provider.name', 'Raison sociale'],
        key: 'enterprise_name',
        isBold: true,
      },
      {
        translate: ['Provider.availability', 'Disponibilité'],
        key: 'availability',
        isBoolean: true,
      },
      {
        translate: ['Provider.status', 'Statut'],
        width: 10,
        key: 'statusLabel',
      },
    ],
    buildCallback: (provider: LegendreProvider): LegendreProvider => provider,
  };
}

export default TEMPLATES;
