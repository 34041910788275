import React, { MouseEventHandler } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  checked: boolean,
  country: string,
  flag: string,
  onClick: MouseEventHandler<HTMLElement>,
  disabled?: boolean,
}

const CountryInput = ({
  checked,
  country,
  flag,
  onClick,
  disabled,
}: Props) => (
  <Form.Group
    className={`mb-3 country-input ${disabled ? 'content-disabled' : ''}`}
    onClick={disabled ? () => {} : onClick}
  >
    <img src={flag} alt={country} height="14px" width="18px" />
    <Form.Label htmlFor={country} className="mb-0 pointer">
      {country}
    </Form.Label>
    <Form.Check
      readOnly
      id="france"
      type="radio"
      checked={checked}
      disabled={!!disabled}
    />
  </Form.Group>
);

CountryInput.defaultProps = {
  disabled: false,
};

export default CountryInput;
