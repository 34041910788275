import React, { useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import trash from '@root/assets/trash.svg';
import { randString, toCamelCase } from '@root/helpers/utils';
import { KeyboardValues } from '@root/interfaces/utils.interface';

interface Props {
  onChange: (words) => void;
  fireKey: KeyboardValues;
  defaultWords?: Word[];
  label?: string;
  itemLabel: string;
}

interface Word {
  id: string;
  value: string;
  current: boolean;
}

const MultiStringInput = ({
  label,
  onChange,
  fireKey,
  defaultWords,
  itemLabel,
}: Props) => {
  const { t } = useTranslation();
  const [words, setWords] = useState<Word[]>(defaultWords || []);

  const name = useMemo(() => label?.toLowerCase()?.replace(/\s/g, '-') || 'multi-string', [label]);
  const fireKeyTrad = useMemo(() => {
    const indexOfFireKey = Object.values(KeyboardValues).indexOf(fireKey);
    return t(
      `Keyboard.${toCamelCase(
        Object.keys(KeyboardValues)[indexOfFireKey],
        { ignoreUpper: true },
      )}`,
    );
  }, [fireKey]);

  const handleValue = (e: { target: { value: string } }) => {
    const current = words.find((w: Word) => (w.current));
    const word = current ? cloneDeep(current) : { value: '', current: true, id: randString() };
    word.value = e?.target?.value?.trim() || '';
    setWords([...words.filter((w: Word) => !w.current), word]);
  };

  const handleWords = (e) => {
    if (e.keyCode === fireKey) {
      const current = words.find((w: Word) => w.current);
      if (current) {
        setWords(
          [...words.filter((w: Word) => !w.current), { ...cloneDeep(current), current: false }],
        );
      }
    }
  };

  const handleDelete = (id: string) => () => {
    setWords(words.filter((w: Word) => w.id !== id && !!w.value));
  };

  useEffect(() => {
    onChange(words);
  }, [words]);

  const value = useMemo(() => (
    words.find((w: Word) => w.current)?.value || ''
  ), [words]);

  return (
    <Form.Group className="multi-string-input">
      {
        label ? (
          <Form.Label htmlFor={name}>
            {label}
          </Form.Label>
        ) : ''
      }
      <InputGroup>
        {
          words.filter((w: Word) => !w.current).map((w: Word) => (
            <InputGroup.Text key={w.id}>
              {w.value}
              &nbsp;
              <span onClick={handleDelete(w.id)} className="pointer">
                <img src={trash} alt="delete" height="16" />
              </span>
            </InputGroup.Text>
          ))
        }
        <Form.Control
          type="text"
          className="multi-string-input__input"
          value={value}
          onChange={handleValue}
          onKeyDown={handleWords}
          aria-describedby={name}
        />
      </InputGroup>
      <Form.Text id={name}>
        {t(
          'Form.clickToAddWord',
          'Appuyer sur {{key}} pour ajouter {{itemLabel}}',
          { key: fireKeyTrad, itemLabel },
        )}
      </Form.Text>
    </Form.Group>
  );
};

MultiStringInput.defaultProps = {
  label: '',
  defaultWords: [],
};

export default MultiStringInput;
