import {
  useState,
  useEffect,
  useRef,
  useCallback,
  SetStateAction,
} from 'react';

const useSafeState = <T>(
  initialState?: T,
): [T, React.Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState(initialState);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const setSafeState = useCallback((data) => {
    if (mounted.current) setState(data);
  }, [setState, mounted]);

  return [state as T, setSafeState];
};

export default useSafeState;
