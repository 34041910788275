import { map } from 'lodash';
import { SessionUser } from '@root/interfaces/cognito.interface';
import { CACHE_ENTERPRISE_CUSTOMER, CACHE_ENTERPRISE_PROVIDER } from '@root/helpers/constants.helper';
import { getUsers } from '@root/services/user.service';
import { getEnterprises as getEnterprisesService } from '@provider/services/enterprise.service';
import { Enterprise } from '@root/interfaces/enterprise.interface';
import { Apps, IApp } from '@root/hooks/useCurrentApp';
import { Member, User } from '@root/interfaces/user.interface';
import i18n from '@root/locales/i18n';
import { capitalize } from '@root/helpers/utils';
import { CallbackSafeFetch } from '@root/hooks/useSafeFetch';
import { getMyEnterprisesConfig } from '@root/api-configs/user.api.config';
import { ApiResponse } from '@root/helpers/response-handler';

i18n.setDefaultNamespace(capitalize(Apps.User));

const getEnterprises = async (options): Promise<Enterprise[]> => {
  const res = await getEnterprisesService(options);
  if (
    res.success
    && Array.isArray(res.data)
    && res.data.length
  ) {
    return res.data;
  }
  return [];
};

export const getUserId = async (email: string): Promise<string> => {
  const res = await getUsers({
    fields: 'id',
    search: { email },
  });
  if (
    res.data
    && Array.isArray(res.data)
    && res.data[0].id
    && !res.hasError()
  ) {
    return res.data[0].id;
  }
  return '';
};

/** Get all user enterprises.
 * @param identifier User email or id
 */
export const getUserEnterprises = async (
  callbackApi: CallbackSafeFetch,
): Promise<Enterprise[]> => {
  const res: ApiResponse<Member[]> = await callbackApi(getMyEnterprisesConfig, {});
  if (res.success && res.data?.length) {
    const enterprises = await getEnterprises({
      fields: 'id,name,identification_number,is_vendor,is_customer,is_v2_compatible,country,job_title',
      page_size: 1000,
      search: {
        id: {
          $or: map(res.data, 'enterprise_id'),
        },
      },
    });
    return enterprises.map((enterprise) => {
      const job_title = res.data.find((_ent) => _ent.enterprise_id === enterprise.id)?.job_title;
      return { ...enterprise, job_title };
    });
  }
  return [];
};

export const getUser = async (email: string): Promise<User> => {
  const res = await getUsers({
    fields: 'id,firstname,lastname,personal_token',
    search: { email },
  });
  if (
    res.data
    && Array.isArray(res.data)
    && res.data[0].id
    && res.success
  ) {
    return { ...res.data[0] };
  }
  return { id: '', firstname: '', lastname: '' };
};

export const getCurrentEnterprises = async (
  callbackApi: CallbackSafeFetch,
  app?: IApp,
) => {
  const enterpriseFiltering = (ent) => (
    app === Apps.Customer ? ent.is_customer : ent.is_vendor
  );
  const enterprises = await getUserEnterprises(callbackApi);
  if (!enterprises || !enterprises.length) return { enterprises: [] };
  let cacheId;
  if (app === Apps.Customer) {
    cacheId = localStorage.getItem(CACHE_ENTERPRISE_CUSTOMER);
  } else if (app === Apps.Provider) {
    cacheId = localStorage.getItem(CACHE_ENTERPRISE_PROVIDER);
  }
  const currentAppEnterprises = enterprises.filter(enterpriseFiltering);
  let currentEnterprise = cacheId && currentAppEnterprises.find((enterprise: Enterprise) => (
    enterprise.id === cacheId
  ));
  if (!currentEnterprise) {
    [currentEnterprise] = currentAppEnterprises;
  }
  return { enterprises, currentAppEnterprises, currentEnterprise };
};

export const initCurrentUser = async (
  callbackApi: CallbackSafeFetch,
  user: SessionUser,
  app?: IApp,
) => {
  const _user = await getUser(user.email!);
  if (!_user.id) return { ..._user, enterprises: [] };
  const enterprises = await getCurrentEnterprises(callbackApi, app);
  return { ...user, ..._user, ...enterprises };
};

export const genders = [
  {
    label: i18n.t('Form.miss'),
    value: 'female',
  },
  {
    label: i18n.t('Form.mister'),
    value: 'male',
  },
];

export const getFullName = (user: SessionUser) => `${user.firstname} ${user.lastname}`;
