import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';

import AWLongCard from '@root/components/Cards/AWLongCard/AWLongCard';

import icon from '@root/assets/connection.svg';
import { Connection } from '@root/interfaces/connection.interface';
import { formatDate } from '@root/helpers/utils';

interface Props {
  connection: Connection,
  isConnectionInactive: boolean,
  onClick?: () => void,
  onClickArrow?: () => void,
  isActivedArrow?: boolean,
}

const CardConnection = ({
  connection,
  isConnectionInactive,
  onClick,
  onClickArrow,
  isActivedArrow,
}: Props) => {
  const { t } = useTranslation();

  return (
    <AWLongCard
      icon={icon}
      className="mb-3 text-black"
      onClick={onClick}
      onClickArrow={onClickArrow}
      isActivedArrow={isActivedArrow}
      disabled={isConnectionInactive}
    >
      <Row>
        <Col md="7" className="mb-2 mb-md-0 d-flex flex-column justify-content-evenly">
          <h2 className="font-size-15 mb-0 font-style-semibold text-truncate">
            {connection.provider_name}
          </h2>
          <p>
            {`${t('CardConnection.process', 'Processus')} : ${connection?.config_title ? connection.config_title : '-'}`}
          </p>
        </Col>
        <Col md="5" className="d-flex flex-column justify-content-between">
          <p>
            {`${t('CardConnection.status', 'Statut')} : ${t(`ConnectionStatus.${connection.status}`)}`}
          </p>
          <p>
            {`
              ${t('CardConnection.createdAt', 'Créée le')} : 
              ${formatDate(connection.created_at)}
            `}
          </p>
          <p>
            {`
              ${t('CardConnection.updatedAt', 'Mis à jour le')} : 
              ${formatDate(connection.updated_at)}
            `}
          </p>
        </Col>
      </Row>
    </AWLongCard>
  );
};

CardConnection.defaultProps = {
  onClick: () => { },
  onClickArrow: () => { },
  isActivedArrow: false,
};

export default CardConnection;
