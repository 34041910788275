import React from 'react';
import Tab from '@provider/components/Tabs/Tab';

interface Props {
  tabs: string[];
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
}

const Tabs = ({ tabs, activeTab, setActiveTab }: Props) => (
  <div className="my-4">
    <ul className="nav aw-nav-tabs">
      {tabs.map((tab) => (
        <li className="nav-item" key={tab}>
          <Tab tabName={tab} activeTab={activeTab} setActiveTab={setActiveTab} />
        </li>
      ))}
    </ul>
  </div>
);

export default Tabs;
