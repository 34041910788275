/* eslint-disable max-len */
import React, { useRef, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';

import { toKey } from '@root/helpers/utils';
import { Column } from '@customer/interfaces/list.interface';
import Tooltip from '@root/components/Tooltip/Tooltip';

interface Props {
  columns: Column[];
  data: unknown[];
  hasMorePages?: boolean;
  onClick: (entity: any) => void;
  onNextPage?: () => void;
  pageLoading?: boolean;
  listLoading?: boolean;
}

const List = ({
  columns,
  data,
  onClick,
  onNextPage,
  hasMorePages,
  pageLoading,
  listLoading,
}: Props) => {
  const { t } = useTranslation();
  const listRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const handleClick = (entity: unknown) => () => {
    onClick(entity);
  };

  const displayField = (item: unknown, column: Column) => {
    if (item && typeof item === 'object' && !isUndefined(item[column.key])) {
      if (column.isArray) {
        const id = toKey(column);
        return (
          <div>
            <div data-for={id} data-tip="react-tooltip">
              {item[column.key].length === 1 ? item[column.key][0] : `${item[column.key].length} ${column?.units || ''}`}
            </div>
            {
              (item[column.key].length > 1) && (
                <Tooltip
                  place="left"
                  id={id}
                >
                  {
                    item[column.key].map((el) => (
                      <div>{el}</div>
                    ))
                  }
                </Tooltip>
              )
            }
          </div>
        );
      }
      if (column.isBoolean) {
        return item[column.key] ? t('Common.yes', 'Oui') : t('Common.no', 'Non');
      }
      return item[column.key];
    }
    return '';
  };

  const calculWidth = (header: Column): string => {
    if (header.width) return `${header.width}%`;
    const noWidth = columns
      .filter((h: Column) => !h.width)
      .length;
    const sumWidth = columns
      .map((h: Column) => (h.width || 0))
      .reduce((a, b) => (a + b));
    return `${(100 - sumWidth) / noWidth}%`;
  };

  const resizeList = () => {
    if (listRef.current) {
      setHeight(window.innerHeight - listRef.current.getBoundingClientRect().top - 40);
    }
  };

  const listenScroll = () => {
    if (listRef.current) {
      setScrollPosition(listRef.current.scrollTop);
    }
  };

  useEffect(() => {
    resizeList();
    window.onresize = resizeList;
    if (listRef.current) {
      listRef.current.onscroll = listenScroll;
    }
    return () => {
      window.onresize = null;
      if (listRef.current) {
        listRef.current.onscroll = null;
      }
    };
  }, []);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo(0, scrollPosition);
    }
  }, [data]);

  return (
    <div ref={listRef} className="list" style={{ height }}>
      <div className="list-headers">
        {
          columns.map((column: Column) => (
            <div
              key={toKey(column.label)}
              className="list-headers__item"
              style={{ width: calculWidth(column) }}
            >
              {column.label}
            </div>
          ))
        }
      </div>
      {
        listLoading ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <Spinner animation="border" />
          </div>
        ) : (
          data.map((item: unknown) => (
            <div
              key={toKey(item)}
              className="list-items"
              onClick={handleClick(item)}
            >
              {
                columns.map((column: Column) => (
                  <div
                    key={toKey(item)}
                    className={`list-items__item ${column.isBold ? 'list-items__item--bold' : ''}`}
                    style={{ width: calculWidth(column) }}
                  >
                    {displayField(item, column)}
                  </div>
                ))
              }
            </div>
          ))
        )
      }
      {
        !!(!listLoading && hasMorePages && data.length) && (
          <div className="list-pagination" onClick={onNextPage}>
            <button className="list-pagination__button" type="button">
              {
                pageLoading
                  ? <Spinner animation="border" size="sm" />
                  : t('Pagination.more', 'Afficher plus')
              }
            </button>
          </div>
        )
      }
    </div>
  );
};

List.defaultProps = {
  onNextPage: null,
  hasMorePages: false,
  pageLoading: false,
  listLoading: false,
};

export default List;
