import { get as _get } from 'lodash';
import { IApiResponse, FetchConfig } from '@root/interfaces/api-response.interface';
import { ErrorDetail } from '@root/interfaces/error.interface';
import * as Errors from '@root/helpers/catalog.error';
import { currentSession, logOut } from '@root/helpers/session';

const { REACT_APP_HOST_URL } = process.env;

/**
 * @class ApiResponse Class representing a Api Response with code response handled
 */
export class ApiResponse<T = unknown> {
  private _originalCode: string;

  private _hasCodeError: boolean;

  public data: T;

  public code: string;

  public message: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public details: any;

  public error: unknown;

  /**
   *
   * @param data Useful data from api response
   */
  constructor({ code, data }: IApiResponse) {
    this._originalCode = code.toString();
    this.data = data;
    this._hasCodeError = this.checkStatus();
    this.code = (data?.code || code)?.toString();
    this.message = data?.message || '';
    this.details = data?.details || '';
    this.error = data?.error;
    this.logErrors();
  }

  get success() {
    return !this.hasError();
  }

  /**
 * Display notif
 * @param {ApiResponse} apiResponse The object used to know what to display
 * @param {Function} setNotifCallback Context callback to display notif
 */
  public displayNotif(setNotifCallback) {
    if (this.hasError()) {
      for (let i = 0; i < Object.keys(Errors).length; i += 1) {
        const err = Object.keys(Errors)[i];
        if (this.hasError(Errors[err])) {
          setNotifCallback({
            message: err,
          });
        }
      }
    }
  }

  public hasError(errorDetail?: ErrorDetail) {
    if (!errorDetail) return this._hasCodeError;
    /* If errorDetail is a standard http code, the result should be true
     if the current code is a sub-error of the standard */
    if (
      this.code.includes('.')
      && !errorDetail.code.toString().includes('.')) {
      return this.code.includes(errorDetail.code.toString());
    }
    return this.code === errorDetail.code.toString();
  }

  public transform<U>(callback: (data: T) => U): ApiResponse<U> {
    return new ApiResponse<U>({
      code: this.code,
      data: this.success ? callback(this.data) : this.data,
    });
  }

  private checkStatus() {
    return (this._originalCode === '200' && _get(this.data, ['error']))
      || (!this._originalCode?.match(/^2\d{2}(\.(\d|\.)*)?$/));
  }

  private logErrors() {
    if (this._hasCodeError || this.error) {
      // eslint-disable-next-line no-console
      console.error([
        'Fetch Error',
        this.code,
        '\n',
        this.error,
        this.message,
        `\nDetails : ${typeof this.details === 'object'
          ? JSON.stringify(this.details) : this.details
        }`,
      ].join(' '));
    }
  }
}

const parseResponse = async (fetchPromise: Promise<Response>) => {
  let data;
  let res: Response | { status?: string, details?: string; };
  try {
    res = await fetchPromise;
    if (res.status === 204) {
      data = {};
    } else {
      try {
        data = await res.json();
      } catch (error) {
        if ((error as Error).name === 'SyntaxError') {
          data = {};
        } else {
          throw error;
        }
      }
    }
  } catch (e) {
    data = {};
    res = { status: '500', details: (e as Error).message };
  }
  return { data, res };
};

const buildConfigFetch = (token: string, baseConfig: FetchConfig = {}): FetchConfig => {
  const configFetch: FetchConfig = {
    ...baseConfig,
    method: baseConfig.method || 'GET',
    headers: {
      ...(baseConfig.headers || {}),
      Accept: (baseConfig.headers && baseConfig.headers.Accept) || '*/*',
      'Content-Type': (
        baseConfig.headers && baseConfig.headers['Content-Type']
      ) || 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  if (
    configFetch.body instanceof FormData
    && (
      configFetch.headers && configFetch.headers['Content-Type']
    )
  ) {
    delete configFetch.headers['Content-Type'];
  }
  return configFetch;
};

const handleResError = (apiResponse: ApiResponse): void => {
  if (apiResponse.hasError(Errors.Unauthorized)) {
    logOut(true);
  } else if (apiResponse.hasError(Errors.ForbiddenApp)) {
    window.open(`${REACT_APP_HOST_URL}/forbidden`, '_self');
  }
};

/**
 * Get fetch api and return an ApiResponse instance who will handle the error response
 * @param fetchPromise The request promise (via fetch)
 * @param callBackData An optional call if the service have to pre-process data
 * @returns An ApiResponse instance with usefull methods
 */
export const handleResponse = async <T = unknown>(
  fetchPromise: Promise<Response>,
): Promise<ApiResponse<T>> => {
  try {
    const { data, res } = await parseResponse(fetchPromise);
    return new ApiResponse<T>({ data, code: res.status as string });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw e;
  }
};

/**
 * A safe fetch who will be call our back-end services with auth if all is ok and redirect
 * if something wrong
 * @param {string} url The url to call (like fetch)
 * @param {object} config The http call options (like fetch)
 * @returns An ApiResponse instance with usefull methods
 */
export const safeFetch = async <T = unknown>(
  url: string,
  config?: FetchConfig,
): Promise<ApiResponse<T>> => {
  const session = await currentSession();
  if (session.isLogged && session.token) {
    const apiResponse: ApiResponse<T> = await handleResponse(
      fetch(
        url,
        buildConfigFetch(session.token, config) as Request,
      ),
    );
    if (apiResponse.hasError()) {
      handleResError(apiResponse);
    }
    return apiResponse;
    // eslint-disable-next-line
  }
  logOut(true);
  return new ApiResponse({ code: '500', data: {} });
};
