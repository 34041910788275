import { QueryboyOptions } from '@root/interfaces/queryboy.interface';
import { Skill } from '@root/interfaces/enterprise.interface';
import { ApiResponse } from '@root/helpers/response-handler';

import { SendEmailOptions } from '@customer/interfaces/provider.interface';
import {
  getSkills,
  updateStatus,
  getInvitations,
  updateInvitation,
  updateProviderCHA,
  getEnterpriseLegalForm,
} from '@customer/services/provider.service';

export const updateProvider = async (
  identification_number: string,
  status: string,
  opts?: SendEmailOptions,
): Promise<string> => {
  const apiResponse: ApiResponse = await updateStatus(identification_number, status, opts);
  if (apiResponse.success) {
    return status;
  }
  return '';
};

export const fetchLegalForm = async (legal_form_id: string, options: QueryboyOptions) => {
  if (legal_form_id) {
    const legalFormRes = await getEnterpriseLegalForm(legal_form_id, options);
    if (legalFormRes.success) {
      return legalFormRes.data;
    }
  }
  return null;
};

export const fetchSkills = async (
  identification_number: string,
  options: QueryboyOptions,
): Promise<Skill[]> => {
  const skillResponse: ApiResponse = await getSkills(identification_number, options);
  if (skillResponse.success) {
    return skillResponse.data as Skill[];
  }
  return [];
};

export const fetchProviderInvitation = async (
  customerId: string,
  providerId: string,
) => {
  const invitationsRes = await getInvitations({
    search: {
      host_id: customerId,
      guest_enterprise_id: providerId,
    },
    fields: 'id,status,additional_data',
  });
  if (invitationsRes.success && invitationsRes.data?.length) {
    return invitationsRes.data[0];
  }
  return null;
};

export const updateProviderStatus = async (
  {
    invitationId,
    providerId,
    customerId,
    status,
    CHA,
  }: {
    invitationId: string,
    providerId: string,
    customerId: string,
    status: string,
    CHA: string,
  },
): Promise<ApiResponse> => {
  const resUpdate = await updateProviderCHA(customerId, providerId, { vendor_external_id: CHA });
  if (resUpdate.success) {
    const res = await updateInvitation(invitationId, { status, provider_id: providerId });
    return res;
  }
  return new ApiResponse({ code: '500', data: {} });
};
