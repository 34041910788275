import { safeFetch, handleResponse, ApiResponse } from '@root/helpers/response-handler';
import { queryBoySearch } from '@root/helpers/utils';
import { IUserStatus, SessionUser } from '@root/interfaces/cognito.interface';
import { User } from '@root/interfaces/user.interface';

const {
  REACT_APP_USER_API,
  REACT_APP_USER_API_PUBLIC,
  REACT_APP_API_KEY,
} = process.env;

export const getUsers = (options = {}): Promise<ApiResponse<User[]>> => (
  handleResponse(
    fetch(
      `${REACT_APP_USER_API_PUBLIC}/v1/user/user/users${queryBoySearch(options)}`,
      {
        headers: {
          'X-API-KEY': REACT_APP_API_KEY as string,
        },
      },
    ),
  )
);

export const getUserStatus = (
  email: string,
): Promise<ApiResponse<IUserStatus>> => (
  handleResponse(
    fetch(
      `${REACT_APP_USER_API_PUBLIC}/v1/user/user/users/${email}/status`,
      {
        headers: {
          'X-API-KEY': REACT_APP_API_KEY as string,
        },
      },
    ),
  )
);

export const getEnterpriseUsers = (userId): Promise<ApiResponse> => (
  safeFetch(`${REACT_APP_USER_API}/v1/user/user/users/${userId}/enterprises?fields=enterprise_id`)
);

export const createACL = async (email: string): Promise<ApiResponse> => (
  handleResponse(
    fetch(
      `${REACT_APP_USER_API_PUBLIC}/v1/user/user/users/acl`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': REACT_APP_API_KEY as string,
        },
        body: JSON.stringify({ email }),
      },
    ),
  )
);

export const createUser = async (body: SessionUser): Promise<ApiResponse> => (
  handleResponse(
    fetch(
      `${REACT_APP_USER_API_PUBLIC}/v1/user/user/users`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': REACT_APP_API_KEY as string,
        },
        body: JSON.stringify(body),
      },
    ),
  )
);

export const updateUser = async (userId: string, body: SessionUser): Promise<ApiResponse> => (
  handleResponse(
    fetch(
      `${REACT_APP_USER_API_PUBLIC}/v1/user/user/users/${userId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': REACT_APP_API_KEY as string,
        },
        body: JSON.stringify(body),
      },
    ),
  )
);

export const resetUser = async (email: string): Promise<ApiResponse<void>> => (
  handleResponse(
    fetch(
      `${REACT_APP_USER_API_PUBLIC}/v1/user/user/users/${email}/reset`,
      {
        method: 'POST',
        headers: {
          'X-API-KEY': REACT_APP_API_KEY as string,
        },
      },
    ),
  )
);
