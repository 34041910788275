export enum TabApprovalProviderKeywordRoutes {
  INFOS = 'infos',
  PREVIEW = 'previsualisation',
  VARIABLES = 'variables',
  SIGNATURE = 'signature',
}

export enum TabApprovalProviderKeyword {
  INFOS = 'INFOS',
  PREVIEW = 'PRÉVISUALISATION',
  VARIABLES = 'VARIABLES',
  SIGNATURE = 'EN ATTENTE DE MA SIGNATURE',
}
