import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ApprovalContext } from '@customer/contexts/approval.context';

const InfosStep = () => {
  const { t } = useTranslation();
  const { sponsorEmail, setSponsorEmail } = useContext(ApprovalContext);

  return (
    <>
      <h2 className="my-4">
        {t('ApprovalFurtherInfo.selectSponsor', "Vérification du maître d'ouvrage")}
        {t('NewApprovalSteps.sponsorInfo', "Informations du maître d'ouvrage")}
        {' '}
        :
      </h2>
      <Form>
        <Form.Group className="mb-4">
          <Form.Label htmlFor="contact-email">
            {t('NewApprovalSteps.sponsorEmail', "Email du maître d'ouvrage")}
            {' '}
            :
          </Form.Label>
          <Form.Control
            defaultValue={sponsorEmail}
            type="email"
            id="contact-email"
            placeholder="maitreouvrage@email.com"
            onChange={(e) => setSponsorEmail(e.target.value)}
          />
        </Form.Group>
      </Form>
    </>
  );
};

export default InfosStep;
