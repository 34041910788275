import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import menu from '@root/assets/burger-menu.svg';
import i18n from '@root/locales/i18n';
import { BurgerMenu as IBurgerMenu } from '@root/interfaces/menu.interface';
import { randString } from '@root/helpers/utils';

const BurgerMenu = ({ sections }: IBurgerMenu) => {
  const [active, setActive] = useState(false);

  return (
    <div className="position-relative">
      <img
        src={menu}
        width="25px"
        onClick={() => setActive(!active)}
        className="pointer"
        alt="burger menu"
      />
      <div className={`burger-menu${active ? '--active' : ''}`}>
        {sections?.map((section) => (
          <div key={randString()} className="burger-menu__section">
            {section.options?.map((option) => (
              option.linkTo ? (
                <Link key={option.label} to={option.linkTo}>
                  <p className="burger-menu__option">{option.label}</p>
                </Link>
              ) : (
                <p key={option.label} className="burger-menu__option">
                  {option.label}
                </p>
              )
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

BurgerMenu.defaultProps = {
  sections: [
    { options: [{ linkTo: '', label: i18n.t('BurgerMenu.noOptionAvailable', 'Aucune option disponible') }] },
  ],
};

export default BurgerMenu;
