import React, { SetStateAction, Dispatch, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import AWContainer from '@root/components/AWContainer/AWContainer';
import AWLinkButton from '@root/components/AWButtons/AWLinkButton';
import InviteRecipientForm from '@customer/components/InviteRecipientForm/InviteRecipientForm';
import { ConnectionContact } from '@root/interfaces/connection.interface';
import { randString } from '@root/helpers/utils';
import { PHONE_NUMBER_REGEX, EMAIL_REGEX } from '@root/helpers/patterns';
import { Spinner } from 'react-bootstrap';

interface Props {
  contacts: ConnectionContact[];
  setContacts: Dispatch<SetStateAction<ConnectionContact[]>>;
  onSubmit: (e?: any) => void | Promise<void>;
  loading?: boolean;
}

export const getBlanckContact: () => ConnectionContact = () => ({
  email: '',
  phone_number: '',
  id: randString(),
});

const AddContact = ({
  contacts,
  setContacts,
  loading,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();

  const checkValidationMail = (mail: string, index: number) => {
    const mails = contacts.reduce((acc: string[], value: ConnectionContact, i: number) => {
      if (i !== index && value?.email) {
        acc.push(value.email);
      }
      return acc;
    }, []);

    return mail?.length ? !!mail.match(EMAIL_REGEX)
      && !mails.includes(mail.trim()) : false;
  };

  const checkValidationPhoneNumber = (phoneNumber: string | undefined) => (
    phoneNumber && phoneNumber?.length >= 2
      ? !!phoneNumber.match(PHONE_NUMBER_REGEX) : true
  );

  const updateContact = (id: string, value: string, field: string) => {
    const clone = cloneDeep(contacts);
    const contact = clone.find((c) => c.id === id);
    if (contact) {
      contact[field] = value;
      setContacts(clone);
    }
  };

  const addContact = () => {
    setContacts([...contacts, getBlanckContact()]);
  };

  const deleteContact = (id: string) => {
    setContacts(contacts.filter((c) => c.id !== id));
  };

  const disabled = useMemo(() => (
    contacts?.every((c) => !(
      c.email.match(EMAIL_REGEX) && (!c.phone_number || c.phone_number.match(PHONE_NUMBER_REGEX))
    ))
  ), [contacts]);

  return (
    <AWContainer>
      <AWContainer.Main
        title={t('NewConnection.title', 'Inviter un sous-traitant')}
        titleSide={(
          <AWLinkButton
            href="/customer/connections"
            className="bg-white text-dark"
          >
            {t('NewConnection.backToConnections', 'Retour aux mises en relation')}
          </AWLinkButton>
        )}
      >
        {
          loading ? (
            <div className="hpx-300 d-flex justify-content-center align-items-center">
              <Spinner />
            </div>
          ) : (
            <InviteRecipientForm
              contacts={contacts}
              isSubmitDisabled={disabled}
              onSubmit={onSubmit}
              updateContact={updateContact}
              deleteContact={deleteContact}
              addContact={addContact}
              checkValidationMail={checkValidationMail}
              checkValidationPhoneNumber={checkValidationPhoneNumber}
            />
          )
        }
      </AWContainer.Main>
      <AWContainer.Side />
    </AWContainer>
  );
};

AddContact.defaultProps = {
  loading: false,
};

export default AddContact;
