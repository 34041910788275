import { HookApiConfig } from '@root/interfaces/api-response.interface';

const baseUrl = `${process.env.REACT_APP_COMPLIANCE_API}/v1/compliance/#userType`;

export const getComplianceCountConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#providerId/compliance/count`,
};

export const getSkillsCountConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#enterpriseId/skills/count`,
};

export const getDocumentTypeConfig: HookApiConfig = {
  url: `${baseUrl}/document-types/#documentTypeId`,
};
