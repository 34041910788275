import React, { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@root/contexts/user.context';
import useCognito from '@root/hooks/useCognito';
import InfoMessage from '@root/components/InfoMessage/InfoMessage';
import ContentBox from '@user/components/ContentBox/ContentBox';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import AWButton from '@root/components/AWButtons/AWButton';
import ReturnButton from '@user/components/ReturnButton/ReturnButton';
import { LoginRouterContext, Views } from '@user/contexts/loginRouter.context';

/**
 * Display a form to reset the user password.
 */

const PasswordReset = () => {
  const { t } = useTranslation();
  const { isLoading, waitWithLoad } = useLoadingPromise();
  const {
    resetPassword,
    message,
    setMessage,
    signIn: cognitoSignIn,
  } = useCognito();
  const { recoveryEmail } = useContext(UserContext);
  const { setCurrentView } = useContext(LoginRouterContext);

  const [info, setInfo] = useState({ code: '', pwd: '', pwdConfirm: '' });
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [success, setSuccess] = useState(false);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setMessage(null);
    if (info.pwd !== info.pwdConfirm) {
      setIsPasswordMatch(false);
    } else {
      try {
        setIsPasswordMatch(true);
        await waitWithLoad(resetPassword(recoveryEmail, info.code, info.pwd));
        setSuccess(true);
      } catch (error) {
        setSuccess(false);
      }
    }
  };

  const redirect = async (e) => {
    e.preventDefault();
    await waitWithLoad(cognitoSignIn(recoveryEmail, info.pwd));
  };

  return (
    <ContentBox title={t('PasswordReset.changepassword', 'Changement de mot de passe')}>
      <Form onSubmit={handlePasswordReset}>
        <Form.Group>
          <Form.Label htmlFor="confirmationCode">
            {`${t('PasswordReset.confirmationCode', 'Code de confirmation')} :`}
          </Form.Label>
          <Form.Control
            id="confirmationCode"
            placeholder={t(
              'PasswordReset.codePlaceholder',
              'Veuillez renseigner le code de confirmation reçu',
            )}
            value={info.code}
            onChange={(e) => setInfo({ ...info, code: e.target.value })}
            autoComplete="disabled"
            type="text"
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label htmlFor="newPassword" className="mb-0">
            {`${t('PasswordReset.newPassword', 'Nouveau mot de passe')} :`}
          </Form.Label>
          <p className="input-helper-text">
            {t(
              'PasswordReset.passwordRules',
              `
              Contient au moins 8 caractères dont
              une minuscule, une majuscule, un caractère spécial et un chiffre
            `,
            )}
          </p>
          <Form.Control
            id="newPassword"
            type="password"
            placeholder={t(
              'PasswordReset.newPasswordPlaceholder',
              'Veuillez saisir votre nouveau mot de passe',
            )}
            value={info.pwd}
            onChange={(e) => setInfo({ ...info, pwd: e.target.value })}
            autoComplete="new-password"
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label htmlFor="passwordConfirmation">
            {`${t('PasswordReset.newPasswordConfirmation', 'Confirmation de votre mot de passe')} :`}
          </Form.Label>
          <Form.Control
            id="passwordConfirmation"
            type="password"
            placeholder={t(
              'PasswordReset.passwordConfirmPlaceholder',
              'Veuillez confirmer votre nouveau mot de passe',
            )}
            value={info.pwdConfirm}
            onChange={(e) => setInfo({ ...info, pwdConfirm: e.target.value })}
            autoComplete="new-password"
          />
        </Form.Group>
        {
          !isPasswordMatch && (
            <InfoMessage
              message={t(
                'PasswordReset.passwordMatch',
                'Le mot de passe et sa confirmation ne sont pas identiques',
              )}
            />
          )
        }
        {message && <InfoMessage message={message.text} color={message.color} />}
        <div className="d-flex flex-column align-items-center">
          {
            success ? (
              <AWButton
                onClick={redirect}
                className="mt-4 w-50"
                disabled={isLoading}
                isLoading={isLoading}
                noFlex
              >
                {t('Action.login')}
              </AWButton>
            ) : (
              <AWButton
                disabled={!(!!info.pwd && !!info.pwdConfirm && !!info.code) || isLoading}
                className="mt-4 w-50"
                isLoading={isLoading}
                type="submit"
                noFlex
              >
                {t('Action.confirm')}
              </AWButton>
            )
          }
          {!success && (
            <ReturnButton className="mt-2" onClick={() => setCurrentView(Views.Recovery)} />
          )}
        </div>
      </Form>
    </ContentBox>
  );
};

export default PasswordReset;
