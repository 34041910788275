import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import logo from '@root/assets/addworking-logo.svg';
import ChooseLanguage from '@root/components/ChooseLanguage/ChooseLanguage';

interface Props {
  children: ReactNode,
  isLogged?: boolean,
}

const LoginContainer = ({ children, isLogged }: Props) => {
  const { t } = useTranslation();

  return (
    <Container fluid className="login-container">
      <Row className="p-0">
        <Col xl="6" className="login-container__help">
          <img
            alt="AddWorking"
            src={logo}
            width="60%"
          />
          <div className="my-5">
            <h1>
              {t('Login.helpTitle', 'Bienvenue !')}
            </h1>
            <p className="font-size-16">
              {t(
                'Login.helpDescription',
                `Que vous soyez donneur d'ordres, sous-traitant ou les deux,
                pour retrouver l'ensemble de vos informations
                (états de conformité, missions, contrats, factures) :
                une seule interface, une seule connexion et c'est ici.`,
              )}
            </p>
          </div>
          {
            !isLogged && (
              <Link className="login-container__help__link" to="/signup">
                {t('Login.linkText', 'Pas encore de compte ?')}
              </Link>
            )
          }
        </Col>
        <Col xl="6" className="login-container__form">
          <div className="login-container__language">
            <ChooseLanguage menuPlacement="bottom" />
          </div>
          {children}
        </Col>
      </Row>
    </Container>
  );
};

LoginContainer.defaultProps = {
  isLogged: false,
};

export default LoginContainer;
