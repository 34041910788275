import React, { useContext, useEffect, useState } from 'react';
import {
  Col, Form, Modal, Row,
} from 'react-bootstrap';
import { UserContext } from '@root/contexts/user.context';
import AWContainer from '@root/components/AWContainer/AWContainer';
import AWSelect from '@root/components/AWSelect/AWSelect';
import LabelValueList from '@root/components/LabelValueList/LabelValueList';
import { ILabelValueList } from '@root/interfaces/labelValueList.interface';
import { convertModelDocumentFromStringToImg } from '@customer/views/Approvals/NewApproval/NewApprovalSteps/utils/convertHtmlToImg.service';
import { useTranslation } from 'react-i18next';
import { ApprovalDocumentModel } from '@root/interfaces/approvalDocument.interface';
import {
  getAllApprovalDocumentModels,
  getApprovalDocumentModelByS3Id,
} from '@root/services/approvalDocument.service';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { Option } from '@root/interfaces/utils.interface';
import { formatDate } from '@root/helpers/utils';
import { ApprovalContext } from '@customer/contexts/approval.context';

const ModelStep = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const {
    modelSelected,
    setModelSelected,
    setPdfFile,
    uploadedPdfUrl,
    setUploadedPdfUrl,
    setDocumentCompletedWithCustomerVariables,
  } = useContext(ApprovalContext);
  const { waitWithLoad, isLoading } = useLoadingPromise();

  const [modelDocumentList, setModelDocumentList] = useState<ApprovalDocumentModel[]>([]);
  const [documentModelOptions, setDocumentModelOptions] = useState<Option[]>([]);

  const [modal, setModal] = useState<{
    isOpen: boolean;
    title?: string;
  }>({ isOpen: false });

  // document models handling functions
  const buildDocumentModelOptions = async (modelList: ApprovalDocumentModel[]) => {
    const options: Option[] = await modelList.map((model) => ({
      value: model.s3_file_id,
      label: model.label ? model.label : '-',
    }));
    setDocumentModelOptions(options);
  };

  const getDocumentModels = async (enterpriseId: string) => {
    const models = await waitWithLoad(getAllApprovalDocumentModels(enterpriseId));
    setModelDocumentList(models.data);
    buildDocumentModelOptions(models.data);
  };

  const getSelectedDocumentModelByS3Id = async (id: string) => {
    const modelString = await waitWithLoad(getApprovalDocumentModelByS3Id(id));
    return modelString.data;
  };

  const buildModelSelected = async (modelOption: Option) => {
    const model = modelDocumentList.find((m) => m.s3_file_id === modelOption.value);
    if (model) {
      const modelValue = await getSelectedDocumentModelByS3Id(model.s3_file_id);
      setModelSelected({ ...model, value: modelValue });
    }
  };

  // modal handling functions
  const closeModal = () => setModal({ isOpen: false });

  const handleClickOnPreview = async () => {
    await setModal({
      isOpen: true,
      title: modelSelected.label,
    });
    convertModelDocumentFromStringToImg(
      modelSelected.value!,
      'id-model-step__temp-modal-preview',
      'id-model-step__modal-preview',
    );
  };

  // uploaded pdf file handling functions
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const uploadedFile: File = e.target.files[0];
      setPdfFile(uploadedFile);
      const fileUrl = URL.createObjectURL(uploadedFile);
      setUploadedPdfUrl(fileUrl);
      // reset model selected to hide model preview and info
      setModelSelected({
        id: '',
        s3_file_id: '',
        value: '',
      });
      // reset variables-filled stored model
      setDocumentCompletedWithCustomerVariables('');
    }
  };

  useEffect(() => {
    if (user.currentEnterprise?.id) {
      getDocumentModels(user.currentEnterprise.id);
    }
  }, [user.currentEnterprise?.id]);

  useEffect(() => {
    if (modelSelected.value) {
      convertModelDocumentFromStringToImg(
        modelSelected.value,
        'id-model-step__temp-preview',
        'id-model-step__preview',
      );
      // reset pdf url to hide PDF iframe
      setUploadedPdfUrl('');
      setPdfFile(new File([], ''));
    }
  }, [modelSelected]);

  const handleDisplayOfModelInfo = (key?: string) => key || '-';

  const labelValues: ILabelValueList[] = [
    {
      title: t(
        'NewApprovalSteps.templateName',
        'Nom du modèle',
      ),
      content: [handleDisplayOfModelInfo(modelSelected.label)],
    },
    {
      title: t(
        'NewApprovalSteps.creationDate',
        'Date de création',
      ),
      content: [handleDisplayOfModelInfo(formatDate(modelSelected.created_at))],
      subTitle: t(
        'NewApprovalSteps.editionDate',
        "Date d'édition",
      ),
      subContent: [handleDisplayOfModelInfo(formatDate(modelSelected.updated_at))],
    },
    {
      title: t(
        'NewApprovalSteps.templateCreator',
        'Créateur du modèle',
      ),
      content: [handleDisplayOfModelInfo(modelSelected.owner_name)],
    },
  ];
  /* eslint-disable */
  return (
    <>
      <AWContainer className="model-step" isLoading={isLoading}>
        <Col xs="6" className="mb-4 mx-3">
          <Form.Group className="mb-2">
            <Form.Label>{`${t(
              'NewApprovalSteps.documentTemplate',
              'Modèle de document'
            )} :`}</Form.Label>
            <AWSelect options={documentModelOptions} onChange={(e) => buildModelSelected(e)} />
          </Form.Group>
          <p style={{ fontWeight: 'bolder' }} className="mb-2">
            OU
          </p>
          <Form.Group className="mb-2">
            <Form.Label>{`${t(
              'NewApprovalSteps.localFile',
              'Fichier local (document sans variables possibles)'
            )} :`}</Form.Label>
            <Form.Control type="file" onChange={handleFile} accept={'application/pdf'} />
          </Form.Group>
        </Col>
        <div className="model-step__data">
          <Row className="mb-4">
            {!modelSelected.value && !uploadedPdfUrl ? (
              <div className="model-step__select-model d-flex justify-content-center">
                {`${t(
                  'NewApprovalSteps.selectDocumentTemplate',
                  'Sélectionnez un modèle de document ou téléchargez un document PDF pour poursuivre'
                )}.`}
              </div>
            ) : (
              ''
            )}
            {uploadedPdfUrl && !modelSelected.value ? (
              <iframe src={uploadedPdfUrl} width="100%" height="1000px"></iframe>
            ) : (
              ''
            )}
            {modelSelected.value ? (
              <>
                <Col xs="6">
                  <div
                    id="id-model-step__preview"
                    className="model-step__preview d-flex justify-content-center"
                    style={modelSelected.value ? { visibility: 'visible' } : {}}
                    onClick={() => handleClickOnPreview()}
                  ></div>
                </Col>

                <div className="model-step__label-value-list">
                  <LabelValueList labelValues={labelValues} />
                </div>
              </>
            ) : ''}
          </Row>
        <div id="id-model-step__temp-preview"></div>
        </div>
      </AWContainer>
      <Modal show={modal.isOpen} onHide={closeModal} dialogClassName="model-step__modal-dialog">
        <Modal.Header closeButton>
          <Modal.Title>{modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div id="id-model-step__modal-preview" className="model-step__modal-preview"></div>
            <div id="id-model-step__temp-modal-preview"></div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModelStep;
