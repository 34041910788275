import i18n from '@root/locales/i18n';
import { Option } from '@root/interfaces/utils.interface';
import { Apps } from '@root/hooks/useCurrentApp';
import { capitalize } from '@root/helpers/utils';

i18n.setDefaultNamespace(capitalize(Apps.Customer));

export const marketTypeOptions: Option[] = [
  { value: 'all', label: i18n.t('ApprovalsFilters.all', 'Tous') },
  { value: 'public', label: i18n.t('ApprovalsFilters.publicMarketType', 'Marché public') },
  { value: 'private', label: i18n.t('ApprovalsFilters.privateMarketType', 'Marché privé') },
];

export const statusOptions: Option[] = [
  { value: 'draft', label: i18n.t('ContractState.draft', 'Brouillon') },
  {
    value: 'in_preparation',
    label: i18n.t('ContractState.in_preparation', 'En préparation'),
  },
  {
    value: 'missing_documents',
    label: i18n.t('ContractState.missing_documents', 'Documents à fournir'),
  },
  {
    value: 'generated',
    label: i18n.t('ContractState.generated', 'Bon pour mise en signature'),
  },
  { value: 'to_validate', label: i18n.t('ContractState.to_validate', 'A valider') },
  {
    value: 'internal_validation',
    label: i18n.t('ContractState.internal_validation', 'En validation interne'),
  },
  {
    value: 'under_validation',
    label: i18n.t('ContractState.under_validation', 'En cours de validation'),
  },
  {
    value: 'to_sign',
    label: i18n.t('ContractState.to_sign', 'A signer'),
  },
  {
    value: 'waiting_for_signature',
    label: i18n.t('ContractState.waiting_for_signature', 'En attente de signature'),
  },
  {
    value: 'signed',
    label: i18n.t('ContractState.signed', 'Signé'),
  },
  {
    value: 'active',
    label: i18n.t('ContractState.active', 'Actif'),
  },
  {
    value: 'declined',
    label: i18n.t('ContractState.declined', 'Décliné'),
  },
  {
    value: 'due',
    label: i18n.t('ContractState.due', 'Echu'),
  },
  {
    value: 'inactive',
    label: i18n.t('ContractState.inactive', 'Inactif'),
  },
  {
    value: 'archived',
    label: i18n.t('ContractState.archived', 'Archivé'),
  },
  {
    value: 'unknown',
    label: i18n.t('ContractState.unknown', 'Inconnu'),
  },
  {
    value: 'is_ready_to_generate',
    label: i18n.t('ContractState.is_ready_to_generate', 'A générer'),
  },
  {
    value: 'canceled',
    label: i18n.t('ContractState.canceled', 'Annulé'),
  },
];
