import React from 'react';
import { useTranslation } from 'react-i18next';

const POWERBI_URL = 'https://app.powerbi.com/view?r=eyJrIjoiNmI3MTdmYTgtZjMxOC00ZWRkLTgyN2YtMTA4Zjk4MzU5YjNiIiwidCI6IjY1YzM1Y2I0LTQyZTEtNGQ1MS1iMTNjLTlkZmVhNGU0NDI3YiJ9&pageName=ReportSection4f7516d89d15263b768a';

const ReportingIframe = () => {
  const { t } = useTranslation();
  return (
    <div className="p-4 h-100 w-100 reporting">
      <h1 className="mb-2">{t('MenuTabs.reporting')}</h1>
      <iframe
        title={t('MenuTabs.reporting')}
        className="reporting__iframe"
        src={POWERBI_URL}
        allowFullScreen
      />
    </div>
  );
};

export default ReportingIframe;
