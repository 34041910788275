import React from 'react';
import {
  Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AWButton from '@root/components/AWButtons/AWButton';
import AWSelect from '@root/components/AWSelect/AWSelect';
import { AWColors } from '@root/interfaces/utils.interface';
import {
  statusOptions,
} from '@root/views/Contracts/ContractFilters/options.list';
import { IContractFilters } from '@root/interfaces/contract.interface';

const ContractFilters = ({
  partiesList,
  contractFilters,
  setContractFilters,
  handleFiltersSearch,
  isBtnLoading,
  handleLoadingBtn,
  onChange,
}: IContractFilters) => {
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    handleFiltersSearch();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-4">
        <Form.Label htmlFor="approvals-enterpriseIdentity">
          {t('ContractFilters.contractName', 'Nom du contrat')}
        </Form.Label>
        <Form.Control
          id="approvals-enterpriseIdentity"
          value={contractFilters.name || ''}
          onChange={onChange('name')}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label htmlFor="approvals-status">{t('ContractFilters.state', 'État')}</Form.Label>
        <AWSelect options={statusOptions} isSearchable onChange={onChange('states')} />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label htmlFor="approvals-status">{t('ContractFilters.party', 'Partie prenant')}</Form.Label>
        <AWSelect options={partiesList} isSearchable onChange={onChange('party_id')} />
      </Form.Group>
      <Row>
        <Col sm="12" className="d-flex">
          <Col sm="6">
            <AWButton onClick={() => handleLoadingBtn('filter')} disabled={isBtnLoading('filter')}>
              {isBtnLoading('filter') ? (
                <Spinner size="sm" animation="border" />
              ) : (
                t('ContractFilters.filter', 'Filtrer')
              )}
            </AWButton>
          </Col>
          <Col sm="6">
            <AWButton
              color={AWColors.Green}
              backgroundColor="white"
              onClick={() => setContractFilters({})}
            >
              {t('ContractFilters.reset', 'Réinitialiser')}
            </AWButton>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};

ContractFilters.defaultProps = {
  partiesList: [],
};

export default ContractFilters;
