import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Rnd } from 'react-rnd';
import { ApprovalContext } from '@customer/contexts/approval.context';

interface Props {
  signatureId: number;
  signatureName: string;
}

const ResizableRectangle = ({ signatureId, signatureName }: Props) => {
  const { t } = useTranslation();

  const { propertySignatures, setPropertySignatures, currentPage } = useContext(ApprovalContext);
  const [size, setSize] = useState<any>({
    pageNumberSignature: currentPage,
    x: 0,
    y: 0,
    width: '99px',
    height: '39px',
    id: signatureId,
    signatureName,
  });

  const handleChange = (id: number) => {
    if (propertySignatures.length) {
      setPropertySignatures(propertySignatures.map((prop) => (prop.id === id ? size : prop)));
    } else {
      setPropertySignatures([size]);
    }
  };

  useEffect(() => {
    handleChange(signatureId);
  }, [size]);

  return (
    <Rnd
      className="rectangle overlay-element"
      size={{ width: size.width, height: size.height }}
      position={{ x: size.x, y: size.y }}
      id={signatureId}
      onDragStop={(e, d) => {
        setSize({
          x: d.x,
          y: d.y,
          width: size.width ?? 0,
          height: size.height ?? 0,
          id: signatureId,
          pageNumberSignature: currentPage,
          signatureName,
        });
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        setSize({
          width: ref.style.width,
          height: ref.style.height,
          id: signatureId,
          pageNumberSignature: currentPage,
          signatureName,
          ...position,
        });
      }}
    >
      {t('ApprovalsContainer.signatory', 'Signataire')}
      {' '}
      {signatureId !== 0 ? signatureId : ''}
      <p>{signatureName}</p>
    </Rnd>
  );
};

export default ResizableRectangle;
