import React, {
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createActivity, deleteActivity } from '@provider/services/enterprise.service';
import AWButton from '@root/components/AWButtons/AWButton';
import { Activity } from '@root/interfaces/enterprise.interface';
import { KeyboardValues, MultiStringInput as IMultiStringInput } from '@root/interfaces/utils.interface';
import MultiStringInput from '@root/components/MultiStringInput/MultiStringInput';

interface Props {
  id: string,
  initialActivities: Activity[],
  onClose: () => void,
}

const ModalActivity = ({
  id,
  initialActivities,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [activities, setActivities] = useState<Activity[]>(initialActivities);

  const updateProviderActivities = async () => {
    let activitiesToDelete: Activity[] = initialActivities;
    if (activities.length) {
      activitiesToDelete = initialActivities.filter(
        (ia) => !activities.some((a) => a.activity_id === ia.activity_id),
      );
    }

    const activitiesToCreate = activities.filter(
      (a) => !initialActivities.some(
        (ia) => ia.activity_id === a.activity_id,
      ),
    ).map((_a) => _a.activity);

    if (activitiesToDelete.length) {
      await Promise.all(activitiesToDelete.map((activity) => {
        if (activity.activity_id) {
          return deleteActivity(activity.activity_id);
        }
        return null;
      }));
    }

    if (activitiesToCreate.length) {
      await Promise.all(activitiesToCreate.map((activity) => createActivity(id, {
        activity,
        source: 'provider',
        published: true,
      })));
    }

    onClose();
  };

  const handleActivityChange = (words: IMultiStringInput[]) => {
    setActivities(words.map((word) => ({
      activity_id: word.id,
      activity: word.value,
    })));
  };

  return (
    <>
      <MultiStringInput
        onChange={handleActivityChange}
        defaultWords={initialActivities.map((ia) => ({
          id: ia.activity_id || '', value: ia.activity, current: false,
        }))}
        label={t('Provider.activityToAdd', 'Activités')}
        fireKey={KeyboardValues.EnterKey}
        itemLabel={t('Common.aWord', 'un mot')}
      />
      <AWButton
        type="button"
        className="mt-4 w-50"
        onClick={updateProviderActivities}
      >
        {t('Action.validate')}
      </AWButton>
    </>
  );
};

export default ModalActivity;
