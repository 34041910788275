import { getUploadSignedURL, uploadToS3 } from '@root/services/file.service';

export const uploadFileToS3 = async (file: File) => {
  const uploadURLRes = await getUploadSignedURL(file.type);
  if (uploadURLRes.success) {
    const uploadRes = await uploadToS3(uploadURLRes.data.signedUrl, file);
    if (uploadRes.success) {
      return uploadURLRes.data.key;
    }
  }
  return '';
};
