import { ApiResponse, safeFetch } from '@root/helpers/response-handler';

export const getUserProfile = (
  userId: string,
): Promise<
ApiResponse<{
  email: string;
  gender: string;
  firstname: string;
  lastname: string;
}>
> => safeFetch(
  `${process.env.REACT_APP_USER_API}/v1/user/provider/users/${userId}?fields=email,gender,firstname,lastname`,
);
