import React from 'react';
import { Container } from 'react-bootstrap';

interface Props {
  id: string;
  title: string;
  subTitle: string;
  content: string;
  subContent: string;
  status?: string;
  company?: { legalForm: string, companyName: string };
  activeCardId?: string;
  setActiveCardId: (activeCardId: string) => void;
}

const AWShortCard = ({
  id, title, subTitle, content, subContent, status, company, activeCardId, setActiveCardId,
}: Props) => (
  <Container className={`short-card d-flex flex-column rounded ${activeCardId === id ? 'selected' : ''}`} onClick={() => setActiveCardId(id)}>
    <div className="short-card__title">
      {title}
    </div>
    <div className="short-card__sub-title">
      {subTitle}
    </div>
    <div className="short-card__content">
      {content}
    </div>
    <div className="short-card__sub-content">
      {subContent}
      {company && (
        <div className="short-card__sub-content__company">
          {company.legalForm}
          {' '}
          <strong>{company.companyName.toUpperCase()}</strong>
        </div>
      )}
      {status ? (<div className={status === 'active' ? 'short-card__sub-content__active' : 'short-card__sub-content__inactive'}>{status}</div>) : ''}
    </div>
  </Container>
);

AWShortCard.defaultProps = {
  status: '',
  company: { legalForm: '', companyName: '' },
  activeCardId: '',
};

export default AWShortCard;
