/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect } from 'react';
import i18n from '@root/locales/i18n';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AWButton from '@root/components/AWButtons/AWButton';
import InfoMessage from '@root/components/InfoMessage/InfoMessage';
import useCognito from '@root/hooks/useCognito';
import { UserContext } from '@root/contexts/user.context';
import { getUserProfile } from '@provider/services/user.service';
import { AWColors } from '@root/interfaces/utils.interface';
import AWSelect from '@root/components/AWSelect/AWSelect';

interface Props {
  onCancel: () => void;
}

const genderOptions = [
  {
    label: i18n.t('Signup.man', 'Mr'),
    value: 'male',
  },
  {
    label: i18n.t('Signup.woman', 'Mme'),
    value: 'female',
  },
];

const UserNameForm = ({ onCancel }: Props) => {
  const { t } = useTranslation();
  const { updateUser, message, setMessage } = useCognito();
  const { user, setUser } = useContext(UserContext);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    (async () => {
      const response = await getUserProfile(user.id!);
      setUser({
        ...user,
        gender: response.data.gender,
        firstname: response.data.firstname,
        lastname: response.data.lastname,
      });
    })();
  }, []);

  useEffect(() => {
    if (user.gender) {
      setValue('gender', user.gender);
    }
  }, [user.gender]);

  const findErrors = (value) => {
    let error = null;
    if ((errors as any)[value]?.type === 'required') {
      error = t('Errors.missingField', 'Merci de remplir ce champ');
    }
    return error ? <InfoMessage message={error} color={AWColors.Red} /> : null;
  };

  const onSubmit = async (data) => {
    const newUser = { ...data, gender: data.gender.value };
    updateUser(newUser);
    setUser({ ...user, ...newUser });
    setMessage({ text: t('Success.saveChanges'), color: AWColors.Green });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Row className="mb-4">
        <Col
          lg={{ span: 6, offset: 3 }}
          md={{ span: 10, offset: 1 }}
          className="mb-2"
        >
          <Form.Group>
            <Form.Label htmlFor="gender">
              {t('Signup.gender', 'Civilité')}
            </Form.Label>
            <Controller
              name="gender"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <AWSelect
                  noNullOption
                  value={genderOptions.find((e) => e.value === value)}
                  options={genderOptions}
                  onChange={onChange}
                />
              )}
            />

            {findErrors('gender')}
          </Form.Group>
        </Col>
        <Col
          lg={{ span: 6, offset: 3 }}
          md={{ span: 10, offset: 1 }}
          className="mb-2"
        >
          <Form.Group>
            <Form.Label htmlFor="firstname">
              {t('Signup.firstName', 'Prénom')}
            </Form.Label>
            <Form.Control
              id="firstname"
              defaultValue={user.firstname}
              {...register('firstname', { required: true })}
            />
            {findErrors('firstname')}
          </Form.Group>
        </Col>
        <Col lg={{ span: 6, offset: 3 }} md={{ span: 10, offset: 1 }}>
          <Form.Group>
            <Form.Label htmlFor="lastname">
              {t('Signup.lastName', 'Nom')}
            </Form.Label>
            <Form.Control
              id="lastname"
              defaultValue={user.lastname}
              {...register('lastname', { required: true })}
            />
            {findErrors('lastname')}
          </Form.Group>
        </Col>
        <Col
          lg={{ span: 6, offset: 3 }}
          md={{ span: 10, offset: 1 }}
          className="mb-2"
        >
          <div className="mt-4 d-flex flex-column align-items-center buttons-container">
            <AWButton
              type="submit"
              aria-label="submit-form"
              disabled={!isValid}
              className="mb-4"
              containerClassname="w-100"
            >
              {t('Action.modify')}
            </AWButton>
            <AWButton
              aria-label="submit-form"
              backgroundColor={AWColors.Grey}
              containerClassname="w-100"
              onClick={onCancel}
            >
              {t('Action.cancel')}
            </AWButton>
          </div>
          {message && <InfoMessage message={message.text} color={message.color} />}
        </Col>
      </Row>
    </Form>
  );
};

export default UserNameForm;
