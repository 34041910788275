// Regex used during creation of a new approval
// it will detect variables between {{}}
// and replace them with user's input values

const modelVariableRegex = {
  // get all variables that are in form of {{variable}}
  modelVariable: /\{\{(.*?)\}\}/gm,
  // among the isolated {{variables}}, get those that start with {{1.
  selectedCustomerVariable: /^{{1./gm,
  // same but with {{2.
  selectedProviderVariable: /^{{2./gm,
  // used to replace {{variable}} to variable
  replaceVariable: /\{{|}}|\d+./g,
  disabledVariable: /todo/,
};

export default modelVariableRegex;
