import { HookApiConfig } from '@root/interfaces/api-response.interface';

const baseUrl = `${process.env.REACT_APP_USER_API}/v1/user/#userType/invitations`;

export const getInvitationsConfig: HookApiConfig = { url: baseUrl };

export const updateInvitationConfig: HookApiConfig = {
  url: `${baseUrl}/#id`,
  method: 'PATCH',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const createInvitationConfig: HookApiConfig = {
  url: baseUrl,
  method: 'POST',
};
