import React, { ChangeEventHandler, useState, useEffect } from 'react';
import {
  Col,
  Form,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AWButton from '@root/components/AWButtons/AWButton';
import CustomFieldsFilter from '@customer/views/Providers/ProvidersFilters/CustomFieldsFilter';
import {
  ProviderFilters,
  ProvidersLoadingBtn,
} from '@customer/interfaces/provider.interface';
import { KeyboardValues, Option, MultiStringInput as IMultiStringInput } from '@root/interfaces/utils.interface';
import { Certification } from '@root/interfaces/enterprise.interface';
// import custom from '@customer/views/Providers/ProvidersFilters/custom.list';
import {
  getAllDepartments,
  getCustomerCertifications,
} from '@customer/helpers/customer.helper';
import { Field, FieldReference } from '@root/interfaces/field.interface';
import MultiStringInput from '@root/components/MultiStringInput/MultiStringInput';
import ManualRange from '@root/components/ManualRange/ManualRange';
import AWSelect from '@root/components/AWSelect/AWSelect';
import useSafeFetch from '@root/hooks/useSafeFetch';
import { getCustomerFieldsConfig } from '@root/api-configs/enterprise.api.config';

interface Props {
  customerId?: string,
  handleFilters: () => void | Promise<void>,
  isBtnLoading: (btn: ProvidersLoadingBtn) => boolean,
  handleLoadingBtn: (btn: ProvidersLoadingBtn) => void,
  onChange: (v: keyof ProviderFilters) => ChangeEventHandler,
  onCustomFieldsChange: (id: string, values: Option[]) => void,
  onArrayChange: (field: keyof ProviderFilters, value: string[] | string) => void,
}

const ProvidersFilters = ({
  customerId,
  handleFilters,
  isBtnLoading,
  handleLoadingBtn,
  onChange,
  onArrayChange,
  onCustomFieldsChange,
}: Props) => {
  const { t } = useTranslation();
  const [departments, setDepartments] = useState<Option[]>([]);
  const [initFields, fields] = useSafeFetch<Field[]>(
    getCustomerFieldsConfig,
    [],
    (customFields) => (
      customFields.filter((f: Field) => !!f.references?.length && f.is_filter)
    ),
  );
  const [certifications, setCertifications] = useState<Option[]>([]);

  const optionsVendorExternalId: Option[] = [
    {
      label: t('Common.yes'),
      value: 'true',
    },
    {
      label: t('Common.no'),
      value: 'false',
    },
  ];

  useEffect(() => {
    let mounted = true;
    (async () => {
      const _departments = await getAllDepartments();
      if (mounted) {
        setDepartments(
          _departments.map((department) => ({
            label: `${department.insee_code} - ${department.display_name} (${department.prefecture})`,
            value: department.insee_code,
          })),
        );
      }
    })();
    return () => { mounted = false; };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (customerId) {
      (async () => {
        const [
          _departments,
          _certifications,
        ] = await Promise.all([
          getAllDepartments(),
          getCustomerCertifications(customerId),
          initFields({ customerId }),
        ]);
        if (mounted) {
          setDepartments(
            _departments.map((department) => ({
              label: `${department.insee_code} - ${department.display_name} (${department.prefecture})`,
              value: department.insee_code,
            })),
          );
          setCertifications(
            _certifications
              .map((certif: Certification) => ({
                label: certif.label || '',
                value: certif.certification_id || '',
              })),
          );
        }
      })();
    }
    return () => { mounted = false; };
  }, [customerId]);

  const handleArrayChange = (fieldName: keyof ProviderFilters) => (
    data: IMultiStringInput[] | IMultiStringInput,
  ) => {
    onArrayChange(
      fieldName,
      Array.isArray(data)
        ? data.map((opt: IMultiStringInput) => (opt.value))
        : data.value,
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleFilters();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h2 className="text-dark text-uppercase font-size-20 mt-5 mb-4">
        {t('ProvidersFilters.filters', 'Filtres')}
      </h2>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="geographicCover">
          {t('ProvidersFilters.geographicCover', 'Couverture géographique')}
        </Form.Label>
        <AWSelect
          options={departments}
          onChange={handleArrayChange('departments')}
          isMulti
        />
      </Form.Group>
      <ManualRange
        label={t('ProvidersFilters.businessTurnover', 'Chiffre d\'affaires')}
        onChangeMin={onChange('businessTurnoverMin')}
        onChangeMax={onChange('businessTurnoverMax')}
      />
      <Form.Group className="mb-4">
        <MultiStringInput
          onChange={handleArrayChange('activities')}
          label={t('ProvidersFilters.activities', 'Activités')}
          fireKey={KeyboardValues.SpaceKey}
          itemLabel={t('Common.aWord', 'un mot')}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label htmlFor="certifications">
          {t('ProvidersFilters.certifications', 'Certifications et habilitations')}
        </Form.Label>
        <AWSelect
          options={certifications}
          isMulti
          onChange={handleArrayChange('certifications')}
        />
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label htmlFor="hasVendorExternalId">
          {t('ProvidersFilters.hasPersonalId')}
        </Form.Label>
        <AWSelect
          options={optionsVendorExternalId}
          onChange={handleArrayChange('hasVendorExternalId')}
        />
      </Form.Group>
      {(fields && fields.length > 0) && (
        fields.map((field) => (
          <CustomFieldsFilter
            key={field.id}
            id={field.id}
            title={field.title as string}
            references={field.references as FieldReference[]}
            onChange={onCustomFieldsChange}
          />
        ))
      )}
      <Row>
        <Col sm="6">
          <AWButton
            onClick={() => handleLoadingBtn(ProvidersLoadingBtn.FILTER)}
            disabled={isBtnLoading(ProvidersLoadingBtn.FILTER)}
          >
            {isBtnLoading(ProvidersLoadingBtn.FILTER) ? (
              <Spinner size="sm" animation="border" />
            ) : (
              t('ProvidersFilters.filter', 'Filtrer')
            )}
          </AWButton>
        </Col>
      </Row>
    </Form>
  );
};

ProvidersFilters.defaultProps = {
  customerId: '',
};

export default ProvidersFilters;
