import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { capitalize } from '@root/helpers/utils';
import { Enterprise, EnterprisePappers } from '@root/interfaces/enterprise.interface';
import triangle from '@root/assets/triangle.svg';

import { EnterpriseCreationContext } from '@root/contexts/enterpriseCreation.context';
import SelectionBox from '@customer/components/SelectionBox/SelectionBox';
import AWButton from '@root/components/AWButtons/AWButton';
import { CountryCode } from '@root/interfaces/utils.interface';

interface Props {
  enterprises: EnterprisePappers[];
  onClick: (enterprise: Enterprise) => void;
}

const EnterpriseList = ({ enterprises, onClick }: Props) => {
  const { t } = useTranslation();
  const [displayedEnterprises, setDisplayedEnterprises] = useState(enterprises.slice(0, 10));
  const { resetEnterprise } = useContext(EnterpriseCreationContext);
  const getAddress = (enterprise): string => (
    `${enterprise.siege.numero_voie || ''} ${enterprise.siege.type_voie || ''} ${enterprise.siege.libelle_voie || ''}`
      .split(' ')
      .map((word) => capitalize(word))
      .join(' ')
  );

  useEffect(() => {
    setDisplayedEnterprises(enterprises.slice(0, 10));
  }, [enterprises]);

  const buildEnterprise = (enterprise: EnterprisePappers): Enterprise => (
    {
      name: enterprise.nom_entreprise,
      legal_form: enterprise.forme_juridique,
      identification_number: enterprise.siege.siret,
      is_vendor: true,
      country: CountryCode.Fr,
      address: {
        address: getAddress(enterprise),
        additionnal_address: enterprise.siege.adresse_ligne_2,
        zipcode: enterprise.siege.code_postal,
        town: enterprise.siege.ville,
        country: CountryCode.Fr,
      },
    }
  );

  const handleClick = (enterprise) => async () => {
    if (enterprise?.siege?.siret) {
      onClick(buildEnterprise(enterprise));
    } else {
      resetEnterprise();
    }
  };

  const getCityAndCodePostal = (enterprise) => {
    if (enterprise?.siege?.code_postal && enterprise?.siege?.ville) {
      return `${enterprise.siege.code_postal} ${enterprise.siege.ville}`;
    }
    return enterprise?.siege?.code_postal || enterprise?.siege?.ville || '';
  };

  return (
    <>
      <h2 className="text-center mb-2">
        {`${t('CreateEnterprise.enterpriseHere', "L'entreprise est ici")} ?`}
      </h2>
      <div className="d-flex justify-content-center">
        <img
          src={triangle}
          alt="enterprises"
          className="rotate-90 mb-1"
          height="14px"
          width="14px"
        />
      </div>
      <div className="enterprise-list">
        {displayedEnterprises.map((
          enterprise,
        ) => (
          <SelectionBox
            key={`denomination${enterprise?.siren_formate}`}
            onClick={handleClick(enterprise)}
            className="text-start font-size-14"
            marginTop="16px"
            marginBottom="16px"
          >
            <div className="font-style-bold mb-2">
              {enterprise?.nom_entreprise || ''}
            </div>
            <div className="mb-2">
              <span className="text-grey text-uppercase">
                {`${t('Enterprise.siren', 'SIREN')} : `}
              </span>
              {enterprise.siren_formate}
            </div>
            <div>
              {`${getAddress(enterprise)} - ${getCityAndCodePostal(enterprise)}`}
            </div>
          </SelectionBox>
        ))}
        {
          enterprises.length > displayedEnterprises.length && (
            <AWButton
              type="button"
              color="black"
              backgroundColor="white"
              className="enterprise-list__load-more"
              onClick={() => setDisplayedEnterprises(
                [
                  ...displayedEnterprises,
                  ...enterprises.slice(
                    displayedEnterprises.length,
                    displayedEnterprises.length + 10,
                  ),
                ],
              )}
            >
              {t('Common.displayMore', 'Afficher plus')}
            </AWButton>
          )
        }
      </div>
    </>
  );
};

export default EnterpriseList;
