import React, { useEffect, useState } from 'react';
import CardSurvey from '@customer/components/CardSurvey/CardSurvey';
import { Survey } from '@root/interfaces/survey.interface';
import { getSurveys as getSurveysService } from '@customer/services/survey.service';

const Surveys = () => {
  const [surveys, setSurveys] = useState<Survey[]>([]);

  const getSurveys = async () => {
    const result = await getSurveysService({
      fields: 'label,slug,status,description,startAt',
      page_size: 25,
    });
    if (result.success) {
      setSurveys(result.data);
    }
  };

  useEffect(() => {
    getSurveys();
  }, []);

  return (
    <div className="h-100 d-flex flex-column">
      <div className="flex-1">
        {
          surveys.map((survey) => (
            <CardSurvey
              key={survey.id}
              survey={survey}
              linkTo={survey.status !== 'sketch' ? `/surveys/${survey.id}` : ''}
            />
          ))
        }
      </div>
    </div>
  );
};

export default Surveys;
