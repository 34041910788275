import { HookApiConfig } from '@root/interfaces/api-response.interface';

const baseUrl = `${process.env.REACT_APP_MISSION_API_URL}/v1/mission/#userType`;

export const getMissionFromEnterpriseIdConfig: HookApiConfig = { url: `${baseUrl}/missions` };

export const getMissionOfferConfig: HookApiConfig = {
  url: `${baseUrl}/mission-offer/#offerId`,
};

export const getMissionProvidersConfig: HookApiConfig = {
  url: `${baseUrl}/mission-offer/#offerId/providers`,
};

export const addToCartConfig: HookApiConfig = {
  url: `${baseUrl}/mission-offer/#offerId/providers`,
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const deleteFromCartConfig: HookApiConfig = {
  url: `${baseUrl}/mission-offer/#offerId/providers/#providerId`,
  method: 'DELETE',
};

export const sendInvitationsConfig: HookApiConfig = {
  url: `${baseUrl}/mission-offer/#offerId/sendInvitations`,
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};
