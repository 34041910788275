import React from 'react';
import { useTranslation } from 'react-i18next';
import blue from '@root/assets/blue.png';
import ProcessContainer from '@provider/components/ProcessContainer/ProcessContainer';

const ContactSupport = () => {
  const { t } = useTranslation();

  return (
    <ProcessContainer
      title={t('ContactSupport.title', "Besoin d'aide ?")}
      image={blue}
    >
      <p className="color-white">
        {t('ContactSupport.description', 'Veuillez cliquer sur le lien ci-dessous pour contacter le support')}
      </p>
      <br />
      <a href="https://intercom.help/addworking/fr/" className="support-link">
        {t('ContactSupport.contact', 'Nous contacter')}
      </a>
    </ProcessContainer>
  );
};

export default ContactSupport;
