import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Container, Row, Col, ListGroup, Button,
} from 'react-bootstrap';
import ReturnButton from '@root/components/ReturnButton/ReturnButton';
import { getAnswers, getSurvey } from '@customer/services/survey.service';
import {
  Survey,
  CustomSurveyQuestion,
  SurveyAnswer as ISurveyAnswer,
  SurveyQuestion,
  SurveyAnswerValue,
} from '@root/interfaces/survey.interface';
import { getProviders } from '@customer/services/provider.service';
import { ApiResponse } from '@root/helpers/response-handler';

import { Provider, ProviderSourcing } from '@customer/interfaces/provider.interface';
import { getLegalForm } from '@root/helpers/utils';
import SurveyAnswer from '@customer/components/SurveyAnswer/SurveyAnswer';

const { REACT_APP_GMAPS_KEY } = process.env;

const SurveyProvider = () => {
  const { t } = useTranslation();
  const { surveyId, providerId }: { surveyId: string, providerId: string } = useParams();
  const [provider, setProvider] = useState<ProviderSourcing>();
  const [survey, setSurvey] = useState<Survey>({});
  const [questions, setQuestions] = useState<CustomSurveyQuestion[]>([]);
  const [answer, setAnswer] = useState<ISurveyAnswer>();

  const fetchSurvey = async (): Promise<void> => {
    const response = await getSurvey(surveyId);
    if (response.success) {
      setSurvey(response.data as Survey);
    }
  };

  const fetchAnswers = async (): Promise<void> => {
    const response: ApiResponse = await getAnswers(surveyId as string);
    if (response.success && (response?.data as ISurveyAnswer[]).length) {
      const answerResponse = (response.data as ISurveyAnswer[]).find(
        (ans) => ans.enterpriseId === providerId,
      );
      setAnswer(answerResponse as ISurveyAnswer);
    }
  };

  const formatQuestions = (): void => {
    setQuestions(
      survey?.content?.flat()?.map((question: SurveyQuestion) => {
        const foundAnswer = answer?.values
          ?.find((val: SurveyAnswerValue) => val.question === question.key)?.value;
        return {
          ...question,
          answer: foundAnswer,
        };
      }) || [],
    );
  };

  const getProvider = async (): Promise<void> => {
    const response: ApiResponse = await getProviders({
      fields: 'name,identificationNumber,legalForm,address,users',
      search: { _id: providerId },
    });
    if (response?.success && (response?.data as Provider[]).length) {
      const providerResponse = (response.data as Provider[])[0];
      setProvider({
        ...providerResponse,
        legalForm: getLegalForm(providerResponse.legalForm),
      } as ProviderSourcing);
    }
  };

  useEffect(() => {
    formatQuestions();
  }, [answer, survey, provider]);

  useEffect(() => {
    getProvider();
  }, [providerId]);

  useEffect(() => {
    fetchSurvey();
    fetchAnswers();
  }, [surveyId]);

  return (
    <div className="provider-answers">
      <Container fluid className="p-0">
        <Row className="m-0 provider-survey-title bg-white">
          <Col className="d-flex justify-content-between align-items-center provider-answers__header">
            <div className="part table-border">
              <div className="d-flex align-items-center">
                <div className="return-button-container">
                  <ReturnButton />
                </div>
                <h5 className="text-dark provider-title">
                  {`${provider?.legalForm} - ${provider?.name}`}
                </h5>
              </div>
            </div>
            <div>
              <Button variant="outline-success">
                {t('Status.change', 'Changer le statut')}
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="m-0">
          <Col className="informations-col">
            <Row>
              <div className="p-0">
                <h2 className="text-dark provider-answers__subtitle">
                  {t('Provider.personal', 'Informations personnelles')}
                </h2>
                <ListGroup className="card-answers">
                  <ListGroup.Item className="list-answers">
                    <Row>
                      <Col xs={3} className="list-answers__question">
                        {t('Provider.name', 'Raison Sociale')}
                      </Col>
                      <Col className="list-answers__answer">
                        {provider?.name}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item className="list-answers">
                    <Row>
                      <Col xs={3} className="list-answers__question">
                        {t('Provider.siret', 'SIRET')}
                      </Col>
                      <Col className="list-answers__answer">
                        {provider?.identificationNumber}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item className="list-answers">
                    <Row>
                      <Col xs={3} className="list-answers__question">
                        {t('Provider.representative', 'Représentant')}
                      </Col>
                      <Col className="list-answers__answer">
                        &nbsp;
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item className="list-answers">
                    <Row>
                      <Col xs={3} className="list-answers__question">
                        {t('Provider.address', 'Adresse')}
                      </Col>
                      <Col className="list-answers__answer">
                        {provider?.address}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item className="list-answers">
                    <Row>
                      <Col xs={3} className="list-answers__question">
                        {t('Provider.phoneNumber', 'Téléphone')}
                      </Col>
                      <Col className="list-answers__answer">
                        &nbsp;
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Row>
            <Row>
              <div className="p-0">
                <h2 className="text-dark provider-answers__subtitle">
                  {t('Provider.quiz', 'Informations de questionnaire')}
                </h2>
                <ListGroup className="card-answers">
                  {questions?.map((question: CustomSurveyQuestion) => (
                    <ListGroup.Item key={question.key} className="list-answers">
                      <div className="list-answers__question">
                        {question.title}
                      </div>
                      <div className="list-answers__answer survey">
                        <SurveyAnswer
                          question={question}
                        />
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            </Row>
          </Col>
          <Col xs={5} className="p-0 bg-grey map-col">
            <div>
              <h2 className="text-dark provider-answers__subtitle">
                {t('Provider.headOffice', 'Emplacement de siège social')}
              </h2>
              <iframe
                id="google-map"
                className="google-map"
                title="map"
                width="437"
                height="292"
                loading="lazy"
                src={`https://www.google.com/maps/embed/v1/place?key=${REACT_APP_GMAPS_KEY}&q=${provider?.address}`}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SurveyProvider;
