import React, {
  useState,
  useMemo,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Row,
  Col,
} from 'react-bootstrap';
import { randString } from '@root/helpers/utils';
import { AlertContext } from '@root/contexts/alert.context';
import { UserContext } from '@root/contexts/user.context';
import AWButton from '@root/components/AWButtons/AWButton';
import trash from '@root/assets/trash.svg';
import plus from '@root/assets/plus.svg';

import { Item } from '@customer/interfaces/punchout.interface';
import { postPunchoutData } from '@customer/services/punchout.service';
import { Provider } from '@customer/interfaces/provider.interface';
import AWSelect from '@root/components/AWSelect/AWSelect';

interface Props {
  onSubmit: (items: Item[]) => Promise<Item[]>;
  onCancel: () => void;
  provider: Provider;
}

const Jobs = {
  Equipments: 'Fourniture',
  Service: 'Service',
  EquipmentsService: 'Fourniture et Service',
};

const Fields: Record<string, string> = {
  UnitPrice: 'unitPrice',
  Description: 'description',
  Type: 'type',
  Quantity: 'quantity',
  Price: 'price',
  Quote: 'quote',
};

const initialItem = {
  key: randString(),
  type: '',
  description: '',
  price: 0,
};

const MIN = 0;

const PunchoutQuoteForm = ({ onSubmit, onCancel, provider }: Props) => {
  const { t } = useTranslation();
  const { tokenPO } = useContext(UserContext);
  const { setNotif } = useContext(AlertContext);
  const [items, setItems] = useState<Item[]>([initialItem]);
  const [quote, setQuote] = useState<string>('');

  const FLOAT_REGEXP = /^\d+(\.|,)?\d{0,2}/;

  const deleteLine = (key) => () => {
    setItems(items.filter((item) => item.key !== key));
  };

  const addItem = () => {
    setItems([...items, { ...initialItem, key: randString() }]);
  };

  const toFixedNotRounded = (n: number) => {
    if (n.toString().endsWith('.')) return n;
    const floatValue = n.toString().match(FLOAT_REGEXP);
    return floatValue ? parseFloat(floatValue[0]) : n;
  };

  const handleQuoteChange = ({ target: { value } }: { target: { value: string } }) => {
    setQuote(value);
  };

  const handleItemsChange = (field, key) => (e) => {
    const oldItems = [...items];
    const newItems = oldItems.map((item) => {
      if (item.key === key && field === Fields.Type) {
        return { ...item, [field]: e.value };
      } if (item.key === key && field !== Fields.Type) {
        const { target: { value } } = e;
        if (field === Fields.Price) {
          return {
            ...item,
            price: toFixedNotRounded(value),
          };
        }
        if (field === Fields.UnitPrice && item.quantity) {
          return {
            ...item,
            unitPrice: toFixedNotRounded(value),
            price: parseFloat(
              (toFixedNotRounded(value) * toFixedNotRounded(item.quantity)).toFixed(2),
            ),
          };
        }
        if (field === Fields.UnitPrice && !item.quantity) {
          return {
            ...item,
            unitPrice: toFixedNotRounded(value),
          };
        }
        if (field === Fields.Quantity && item.unitPrice) {
          return {
            ...item,
            quantity: toFixedNotRounded(value),
            price: parseFloat(
              (toFixedNotRounded(value) * toFixedNotRounded(item.unitPrice)).toFixed(2),
            ),
          };
        }
        if (field === Fields.Quantity && !item.unitPrice) {
          return {
            ...item,
            quantity: toFixedNotRounded(value),
          };
        }
        return { ...item, [field]: value };
      }
      return item;
    });
    setItems(newItems);
  };

  const total = useMemo(() => (
    items.reduce((acc, obj) => acc + (
      typeof (obj.price) === 'string' ? Number(obj.price) : obj.price
    ), 0).toFixed(2)
  ), [items]);

  const isEachLineFull = (arr: Item[]) => (
    arr.every((item: Item) => item.type && item.description)
  );

  const postCXML = (data?: { url: string, body: string }) => {
    if (data) {
      const form = document.createElement('form');
      form.setAttribute('action', data.url);
      form.setAttribute('method', 'post');
      const input = document.createElement('input');
      input.setAttribute('name', 'cXML-urlencoded');
      input.setAttribute('type', 'hidden');
      input.value = data.body || '';
      form.appendChild(input);
      document.body.appendChild(form);
      form.submit();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEachLineFull(items)) {
      const newItems = await onSubmit(items);
      if (newItems.length && provider.id) {
        const punchoutRes = await postPunchoutData(
          provider.id,
          {
            items: newItems.map((item) => ({
              type: item.type,
              description: item.description,
              price: item.price,
              quantity: item.quantity,
              deliveryDate: item.deliveryDate,
            })),
            token: tokenPO,
            quote,
          },
        );
        if (punchoutRes.hasError() || !punchoutRes?.data?.url) {
          setNotif({
            message: t('Punchout.unknownerror', 'Une erreur est survenue'),
            variant: 'danger',
          });
        } else {
          postCXML(punchoutRes?.data);
        }
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h2 className="text-dark font-style-regular mt-2 mb-4 d-flex justify-content-between">
        <span className="text-dark">{t('Provider.modalTitle', 'Détails des prestations')}</span>
        <span className="punchout-form__title-detail text-dark">
          {t('Provider.total', 'Total : {{total}} €HT', { total })}
        </span>
      </h2>
      <div className="punchout-form__form-container">
        <Row className="mb-3">
          <Col md={3} xs={12}>
            <Form.Group>
              <Form.Label className="punchout-form__label-input" htmlFor={Fields.Quote}>
                <div className="d-flex">
                  <span>
                    {t('Punchout.quoteNumber')}
                  </span>
                  <span className="punchout-form__label-input--optional ml-5">
                    (
                    {t('Form.optional').toLowerCase()}
                    )
                  </span>
                </div>
              </Form.Label>
              <Form.Control id={Fields.Quote} value={quote} onChange={handleQuoteChange} />
            </Form.Group>
          </Col>
        </Row>
        {items.map((item) => (
          <Row className="mb-3" key={item.key}>
            <Col xs={12} md={3}>
              <Form.Group className="punchout-form__label-input">
                <Form.Label htmlFor="new-job">
                  {t('Provider.typeofjob', 'Type de prestation')}
                </Form.Label>
                <AWSelect
                  noNullOption
                  options={Object.values(Jobs).map((type) => ({
                    label: type,
                    value: type,
                  }))}
                  onChange={handleItemsChange(Fields.Type, item.key)}
                />
              </Form.Group>
            </Col>
            <Col md={4} xs={12}>
              <Form.Group className="punchout-form__label-input">
                <Form.Label htmlFor={Fields.Description}>
                  {t('Provider.description', 'Description')}
                </Form.Label>
                <Form.Control
                  id={Fields.Description}
                  key={item.key}
                  value={item.description || ''}
                  onChange={handleItemsChange(Fields.Description, item.key)}
                />
              </Form.Group>
            </Col>
            {
              item.type === Jobs.Equipments ? (
                <Col xs={12} md={4} className="d-flex">
                  <Form.Group className="mx-2 punchout-form__label-input">
                    <Form.Label htmlFor={Fields.Quantity}>
                      {t('Provider.quantity', 'Quantité')}
                    </Form.Label>
                    <input
                      className="form-control"
                      name={Fields.Quantity}
                      id={Fields.Quantity}
                      key={item.key}
                      value={item.quantity || ''}
                      onChange={handleItemsChange(Fields.Quantity, item.key)}
                      type="number"
                      step=".01"
                      min={MIN}
                    />
                  </Form.Group>
                  <Form.Group className="punchout-form__label-input">
                    <Form.Label htmlFor={Fields.UnitPrice}>
                      {t('Provider.price', 'Montant unitaire HT')}
                    </Form.Label>
                    <input
                      className="form-control"
                      name={Fields.UnitPrice}
                      id={Fields.UnitPrice}
                      key={item.key}
                      value={item.unitPrice || ''}
                      onChange={handleItemsChange(Fields.UnitPrice, item.key)}
                      type="number"
                      step=".01"
                      min={MIN}
                    />
                  </Form.Group>
                </Col>
              ) : (
                <Col md={{ span: 2, offset: 2 }} xs={12}>
                  <Form.Group className="punchout-form__label-input">
                    <Form.Label htmlFor={Fields.Price}>
                      {t('Provider.price', 'Montant HT')}
                    </Form.Label>
                    <input
                      className="form-control"
                      name={Fields.Price}
                      id={Fields.Price}
                      key={item.key}
                      value={item.price || ''}
                      onChange={handleItemsChange(Fields.Price, item.key)}
                      type="number"
                      step=".01"
                      min={MIN}
                    />
                  </Form.Group>
                </Col>
              )
            }

            <Col xs={1} className="punchout-form__trash-container">
              <div className="punchout-form__trash" onClick={deleteLine(item.key)}>
                <img src={trash} alt="delete" />
              </div>
            </Col>
          </Row>
        ))}

        <div className="mt-2 punchout-form__trash-container">
          <AWButton
            type="button"
            onClick={addItem}
            className="punchout-form__trash punchout-form__plus-container"
            backgroundColor="#D6D6D6"
          >
            <img src={plus} alt="+" className="punchout-form__plus" />
          </AWButton>
        </div>
      </div>
      <Row className="mt-4">
        <Col xs={12}>
          <div className="d-flex justify-content-end">
            <AWButton
              type="button"
              className="border punchout-form__buttons"
              backgroundColor="#ACACAC"
              color="white"
              onClick={onCancel}
              noFlex
            >
              {t('Provider.modalCancel', 'Annuler')}
            </AWButton>
            <AWButton
              className="punchout-form__buttons"
              disabled={!isEachLineFull(items)}
              noFlex
            >
              {t('Provider.modalSend', 'Valider et retourner dans Star')}
            </AWButton>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default PunchoutQuoteForm;
