import React from 'react';
import {
  useRouteMatch,
  Switch,
} from 'react-router-dom';
import Contacts from '@customer/views/Contacts/Contacts';
import ProtectedRoute from '@root/routes/ProtectedRoute';

const ContactRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={path} exact>
        <Contacts />
      </ProtectedRoute>
    </Switch>
  );
};

export default ContactRoutes;
