import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fontawesome from '@fortawesome/fontawesome';
import { faQuestionCircle } from '@fortawesome/fontawesome-free-solid';
import InfoMessage from '@root/components/InfoMessage/InfoMessage';
import Tooltip from '@root/components/Tooltip/Tooltip';

fontawesome.library.add(faQuestionCircle);

interface Props {
  label: string;
  type?: 'text' | 'number' | 'password';
  min?: number;
  className?: string;
  onChange?: (e: { target: { value: string } }) => void;
  placeholder?: string;
  disabled?: boolean;
  value?: string | number;
  error?: string;
  tooltip?: string;
}

const Input = ({
  label,
  type,
  className,
  min,
  onChange,
  placeholder,
  disabled,
  value,
  tooltip,
  error,
}: Props) => {
  const id = useMemo(() => (
    label.toLowerCase().replace(/\s/g, '-')
  ), [label]);

  return (
    <Form.Group className={className}>
      <div className="d-flex align-items-center">
        <Form.Label className="mr-5 cursor-pointer">
          {label}
        </Form.Label>
        {tooltip ? (
          <div>
            <div data-for={id} data-tip className="font-size-13">
              <FontAwesomeIcon icon="question-circle" color="black" />
            </div>
            <Tooltip place="right" id={id}>
              {tooltip}
            </Tooltip>
          </div>
        ) : ''}
      </div>
      <Form.Control
        type={type}
        min={min}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
      {error ? <InfoMessage message={error} /> : ''}
    </Form.Group>
  );
};

Input.defaultProps = {
  type: 'text',
  min: undefined,
  className: '',
  onChange: () => { },
  placeholder: '',
  value: '',
  disabled: false,
  tooltip: '',
  error: '',
};

export default Input;
