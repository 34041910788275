import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '@root/routes/ProtectedRoute';
import AddEnterpriseSwitch from '@provider/views/AddEnterprise/AddEnterpriseSwitch';
import Enterprise from '@provider/views/Enterprise/Enterprise';

const EnterpriseRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={`${path}/add`} exact>
        <AddEnterpriseSwitch />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/:enterpriseId`} exact>
        <Enterprise />
      </ProtectedRoute>
    </Switch>
  );
};

export default EnterpriseRoutes;
