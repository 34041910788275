import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import EnterpriseCreation from '@root/components/EnterpriseCreation/EnterpriseCreation';
import { ApiResponse } from '@root/helpers/response-handler';
import closeButton from '@root/assets/close.svg';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void | Promise<ApiResponse<unknown> | null>;
}

const NewEnterpriseModal = ({
  isOpen,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={isOpen}
      size="xl"
      centered
      animation={false}
      contentClassName="rounded-0 h-100"
    >
      <EnterpriseCreation
        title={t('CreateEnterprise.inviteEnterprise', 'Inviter une entreprise')}
        titleSide={(
          <img
            aria-hidden="true"
            src={closeButton}
            alt="close"
            className="pointer"
            height="16px"
            width="16px"
            onClick={onClose}
          />
        )}
        onSubmit={onSubmit}
        height={70}
      />
    </Modal>
  );
};

export default NewEnterpriseModal;
