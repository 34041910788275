/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

interface Props {
  /** Precise landing page path when clicking on return button */
  keyName?: string;
  children?: any;
}

const LabelValue = ({ keyName, children }: Props) => (
  <Container>
    <Row className="info__item">
      <Col xs={6} className="info__title">
        {keyName}
      </Col>
      <Col xs={6} className="info__content">
        {children}
      </Col>
    </Row>
  </Container>
);

LabelValue.defaultProps = {
  keyName: '',
  children: '',
};

export default LabelValue;
