import React, { ReactNode, useMemo } from 'react';
import { AWColors } from '@root/interfaces/utils.interface';

type IconComponent = React.FunctionComponent<{
  color?: string;
  width?: string;
  height?: string;
}>;

interface Props {
  title: string;
  color?: string;
  progress?: number;
  icon: IconComponent;
  className?: string;
  children: ReactNode;
}

const AWMiniCard = ({
  color,
  title,
  icon: Icon,
  className,
  progress,
  children,
}: Props) => {
  const hasProgress = useMemo(() => typeof progress === 'number', [progress]);

  return (
    <div className={`aw-mini-card ${className}`} style={{ border: `1px solid ${color}` }}>
      <div className="aw-mini-card__title">
        {title}
      </div>
      <div className="aw-mini-card__content">
        {children}
      </div>
      <div className={`d-flex align-items-center mt-3 ${hasProgress ? 'justify-content-between' : ''}`}>
        <div className="aw-mini-card__icon">
          <Icon color={color} height="22" width="22" />
        </div>
        {
          hasProgress ? (
            <div className="aw-mini-card__progress" style={{ color }}>
              {progress}
              %
            </div>
          ) : ''
        }
      </div>
    </div>
  );
};

AWMiniCard.defaultProps = {
  color: AWColors.Green,
  className: '',
  progress: undefined,
};

export default AWMiniCard;
