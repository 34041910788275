import React from 'react';
import {
  useRouteMatch,
  Switch,
} from 'react-router-dom';
import Connections from '@customer/views/Connections/Connections';
import NewConnection from '@customer/views/Connections/NewConnection';
import Connection from '@customer/views/Connections/Connection/Connection';
import ProtectedRoute from '@root/routes/ProtectedRoute';

const ConnectionRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={path} exact>
        <Connections />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/new`} exact>
        <NewConnection />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/:connectionId`} exact>
        <Connection />
      </ProtectedRoute>
    </Switch>
  );
};

export default ConnectionRoutes;
