import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@root/contexts/user.context';
import { EMAIL_REGEX } from '@root/helpers/patterns';
import useCognito from '@root/hooks/useCognito';
import InfoMessage from '@root/components/InfoMessage/InfoMessage';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import AWButton from '@root/components/AWButtons/AWButton';
import ContentBox from '@user/components/ContentBox/ContentBox';
import ReturnButton from '@user/components/ReturnButton/ReturnButton';
import { LoginRouterContext, Views } from '@user/contexts/loginRouter.context';

/**
 * Display a form asking the user a recovery email address.
 * A validation code is sent on submit.
 */

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { isLoading, waitWithLoad } = useLoadingPromise();

  const { message, sendPwdCodeConfirmation } = useCognito();

  const { recoveryEmail, setRecoveryEmail } = useContext(UserContext);
  const { setCurrentView } = useContext(LoginRouterContext);

  const handlePasswordRecovery = (e) => {
    e.preventDefault();
    waitWithLoad(sendPwdCodeConfirmation(recoveryEmail));
  };

  return (
    <ContentBox title={t('ForgotPassword.forgotPassword', 'Mot de passe oublié')}>
      <Form onSubmit={handlePasswordRecovery}>
        <Form.Group>
          <Form.Label className="text-justify">
            {t(
              'ForgotPassword.provideEmail',
              `
              Pour renouveler votre mot de passe,
              merci de renseigner votre adresse email dans le champ ci-dessous.
              Si votre email existe, vous recevrez un code de validation.
            `,
            )}
          </Form.Label>
          <Form.Control
            className="mt-4"
            placeholder={t('ForgotPassword.emailPlaceholder', 'Adresse email de récupération')}
            value={recoveryEmail}
            onChange={(e) => setRecoveryEmail(e.target.value)}
          />
        </Form.Group>
        {message && <InfoMessage message={message.text} color={message.color} />}
        <div className="d-flex flex-column align-items-center">
          <AWButton
            type="submit"
            isLoading={isLoading}
            className="mt-4 w-50"
            disabled={!(EMAIL_REGEX.test(recoveryEmail)) || isLoading}
            noFlex
          >
            {t('Action.send')}
          </AWButton>
          <ReturnButton className="mt-2" onClick={() => setCurrentView(Views.Password)} />
        </div>
      </Form>
    </ContentBox>
  );
};

export default ForgotPassword;
