import { ApprovalStatusEnum, ApprovalProvider } from '@root/interfaces/approval.interface';
import { Contact } from '@root/interfaces/contact.interface';

// Filters
const lowerStartsWith = (str?: string, search?: string) => (
  str && search && str.toLowerCase().startsWith(search.toLowerCase())
);

const filterByIdentityOrKeywordConditions = (query: string, str?: string) => {
  // if the data has spaces, we check all words that are space-separated
  if (str && str.includes(' ')) {
    return str.split(' ').some((word) => lowerStartsWith(word, query));
  }
  if (str && !str.includes(' ')) {
    return lowerStartsWith(str, query);
  }
  return false;
};

export const filterByIdentity = (
  query: string,
  list: ApprovalProvider[],
) => list.filter((approval) => (
  filterByIdentityOrKeywordConditions(query, approval.customer.customer_name)
  || filterByIdentityOrKeywordConditions(query, approval.customer.customer_identification_number)
));

export const filterByKeyword = (
  query: string,
  list: ApprovalProvider[],
) => list.filter((approval) => (
  filterByIdentityOrKeywordConditions(query, approval.workfield.label)
  || filterByIdentityOrKeywordConditions(query, approval.workfield.description)
  || filterByIdentityOrKeywordConditions(query, approval.mission.label)
  || filterByIdentityOrKeywordConditions(query, approval.mission.description)
));

const filterByContactConditions = (query: string, contact: Contact) => (
  lowerStartsWith(contact?.first_name, query)
  || lowerStartsWith(contact?.last_name, query)
  || lowerStartsWith(contact?.email, query)
);

export const filterByContact = (query: string, list: any[]) => list.filter((approval) => {
  const { sponsorContact, providerContact } = approval;

  if (sponsorContact && providerContact) {
    return (
      filterByContactConditions(query, sponsorContact)
      || filterByContactConditions(query, providerContact)
    );
  }
  if (sponsorContact) {
    filterByContactConditions(query, sponsorContact);
  } else if (providerContact) {
    filterByContactConditions(query, providerContact);
  }
  // if no contact exist, return no result found
  return false;
});

export const filterByStatus = (status: string, list: ApprovalProvider[]) => {
  switch (status) {
    case 'unsigned':
      return list.filter(
        (approval) => approval.status === ApprovalStatusEnum.WAITING_PROVIDER_VALIDATION
          || approval.status === ApprovalStatusEnum.WAITING_CUSTOMER_VALIDATION
          || approval.status === ApprovalStatusEnum.WAITING_FOR_PROVIDER,
      );
    case 'waitingMyValidation':
      return list.filter(
        (approval) => approval.status === ApprovalStatusEnum.WAITING_PROVIDER_VALIDATION,
      );
    case 'providerSignatureMissing':
      return list.filter(
        (approval) => approval.status === ApprovalStatusEnum.WAITING_FOR_PROVIDER,
      );
    default:
      return list.filter(
        (approval) => approval.status === ApprovalStatusEnum.ACCEPTED,
      );
  }
};
