import React, { useContext, useEffect } from 'react';
import { ApprovalContext } from '@customer/contexts/approval.context';
import { convertModelDocumentFromStringToImg } from '@customer/views/Approvals/NewApproval/NewApprovalSteps/utils/convertHtmlToImg.service';
import modelVariableRegex from '@customer/views/Approvals/NewApproval/NewApprovalSteps/utils/modelVariablesRegex';

const PreviewStep = () => {
  const {
    modelSelected,
    modelVariableDictionary,
    setDocumentCompletedWithCustomerVariables,
  } = useContext(ApprovalContext);
  let document = modelSelected.value;
  const documentVariables = document.match(modelVariableRegex.modelVariable);
  documentVariables?.forEach((el) => {
    document = modelVariableDictionary && document.replace(el, modelVariableDictionary[el.replace(/\{{|}}|\d+./g, '')]?.value);
  });

  useEffect(() => {
    if (modelSelected.value) {
      convertModelDocumentFromStringToImg(document, 'id-preview-step__temp-preview', 'id-preview-step__preview');
      setDocumentCompletedWithCustomerVariables(document);
    }
  }, []);

  return (
    <>
      <div
        id="id-preview-step__preview"
        className="approvalsPreview"
      />
      <div id="id-preview-step__temp-preview" />
    </>
  );
};

export default PreviewStep;
