import React, {
  createContext,
  ReactNode,
  useState,
  useMemo,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { UserContext } from '@root/contexts/user.context';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { buildMenuCustomer, buildMenuProvider } from '@root/components/SideBar/menu';
import { Menu } from '@root/interfaces/menu.interface';
import useCurrentApp, { Apps } from '@root/hooks/useCurrentApp';

interface IMenuTabsContext {
  menuTabs: Menu[],
  activeTab: IActiveTab,
  isMenuLoading: boolean,
  isV1Path: boolean,
  autoActiveTab: () => void,
  setActiveTab: (activeTab: IActiveTab) => void,
}

interface IActiveTab {
  title: string;
  linkTo?: string;
}

export const MenuTabsContext = createContext<IMenuTabsContext>({
  menuTabs: [],
  activeTab: { title: '' },
  isMenuLoading: false,
  isV1Path: false,
  autoActiveTab: () => { },
  setActiveTab: () => { },
});

const MenuTabsContextProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useContext(UserContext);
  const { pathname } = useLocation();
  const { isApp, app } = useCurrentApp();
  const { i18n } = useTranslation();
  const [menuTabs, setMenuTabs] = useState<Menu[]>([]);
  const [activeTab, setActiveTab] = useState<IActiveTab>({ title: '' });
  const { isLoading } = useLoadingPromise();

  const buildMenu = () => {
    if (isApp(Apps.Provider)) {
      return buildMenuProvider(user);
    } if (isApp(Apps.Customer)) {
      return buildMenuCustomer(user);
    }
    return [];
  };

  useEffect(() => {
    if (user.currentEnterprise) {
      const tabs = buildMenu();
      setMenuTabs(tabs);
    }
  }, [user.currentEnterprise, i18n.language]);

  const isV1Path = pathname.includes('/v1/');

  const autoActiveTab = () => {
    const trimPath = (_path: string) => _path
      .replace(new RegExp(`/?${app}/?(v1)?/?`), '')
      .replace(/#.*/, '')
      .replace(/\/$/, '');
    const findMatchingPath = (_paths: string[]) => (
      _paths.some((path) => new RegExp(`^${trimPath(path)}$`).test(trimPath(pathname)))
    );

    const menu = buildMenu();
    const subMenus = menu.flatMap((m) => m.subMenus);
    let tab: IActiveTab = { title: '' };
    const exactSubMenu = subMenus.find((s) => (
      findMatchingPath(s.matchingPaths ? [s.linkTo, ...s.matchingPaths] : [s.linkTo])
    ));
    if (exactSubMenu) {
      tab = { title: exactSubMenu.title, linkTo: exactSubMenu.linkTo };
    }
    setActiveTab(tab);
  };

  const value = useMemo(
    () => ({
      isMenuLoading: isLoading,
      menuTabs,
      activeTab,
      isV1Path,
      autoActiveTab,
      setActiveTab,
    }),
    [menuTabs, user, activeTab, isLoading, pathname],
  );

  return (
    <MenuTabsContext.Provider
      value={value}
    >
      {children}
    </MenuTabsContext.Provider>
  );
};

export default MenuTabsContextProvider;
