import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AlertContext } from '@root/contexts/alert.context';
import { capitalize } from '@root/helpers/utils';
import {
  AnswererTypes,
  Survey,
  Types,
  Formats,
  SurveyQuestion,
  SurveyAnswerValue,
} from '@root/interfaces/survey.interface';
import { getUploadSignedURL, uploadToS3 } from '@root/services/file.service';
import {
  getSurveyAnswers,
  updateEnterpriseAnswers,
  createSurveyAnswer,
} from '@customer/services/survey.service';
import AWButton from '@root/components/AWButtons/AWButton';
import Question from '@customer/components/Question/Question';
import { buildAnswer } from '@customer/components/TaskModals/SurveyModal/surveyModal.builder';

interface IError {
  key: string;
  errors: string[];
}

interface Props {
  survey: Survey,
  onClose: () => void;
  enterpriseId?: string,
  isDisabled: boolean,
}

const MAX_FILE_SIZE = (1024 * 1000) * 5;

const SurveyModal = ({
  survey: _survey,
  enterpriseId,
  isDisabled,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { setNotif } = useContext(AlertContext);
  const [page, setPage] = useState(0);
  const [survey, setSurvey] = useState<Survey>(_survey);
  const [errors, setErrors] = useState<IError[]>();
  const [isNew, setIsNew] = useState(true);

  const fetchAnswers = async () => {
    const res = await getSurveyAnswers(enterpriseId!, _survey.id!);
    if (res.success) setIsNew(false);
    const content: SurveyQuestion[][] = await Promise.all(
      _survey.content?.map(async (_page) => {
        const questions = await Promise.all(
          _page.map(async (question): Promise<SurveyQuestion> => ({
            ...question,
            answer: await buildAnswer(question, res.success ? res.data : undefined),
          })),
        );
        return questions;
      }) || [],
    );
    setSurvey({
      ..._survey,
      content: content || [],
    });
  };

  useEffect(() => {
    if (_survey.id && enterpriseId) {
      fetchAnswers();
    }
  }, [_survey.id, enterpriseId]);

  const isEditable = useMemo(() => (
    _survey.answerer !== AnswererTypes.Provider
  ), [_survey.answerer]);

  const checkAnswer = (question: SurveyQuestion): string[] => {
    const {
      answer,
      options,
      type,
      format,
    } = question;
    const result = [];
    // Required
    if (
      options?.required
      && (
        answer?.value === ''
        || answer?.value === undefined
        || (
          Array.isArray(answer?.value)
          && !answer?.value.length
        )
      )
    ) {
      result.push(t('SurveyModal.pleaseProvideAnswer', 'Cette question est obligatoire.'));
    }

    // File
    if (format === Formats.File
      && answer?.value instanceof File
    ) {
      if (answer?.value.size > MAX_FILE_SIZE) {
        result.push(t('SurveyModal.fileTooLarge', 'Le fichier sélectionné est trop volumineux'));
      }
      if (answer?.value.type !== 'application/pdf' && !answer?.value.name.includes('.pdf')) {
        result.push(
          t(
            'SurveyModal.fileWrongFormat',
            'Le fichier sélectionné ne possède pas le bon format',
          ),
        );
      }
    }

    // Date
    if (type === Formats.Date) {
      if ((options?.min || options?.max) && answer?.value) {
        const selectedDate = Date.parse(answer?.value.toString());
        let minDate;
        let maxDate;
        if (options.min) minDate = Date.parse(options.min.toString());
        if (options.max) maxDate = Date.parse(options.max.toString());
        if (
          (minDate && (selectedDate < minDate))
          || (maxDate && (selectedDate > maxDate))
          || (
            (minDate && maxDate)
            && (selectedDate < minDate || selectedDate > maxDate)
          )
        ) {
          result.push(
            t(
              'SurveyModal.wrongDate',
              'Veuillez sélectionner une date comprise dans la fourchette indiquée',
            ),
          );
        }
      }
    }

    // Array
    if (Array.isArray(answer?.value)) {
      if (
        options?.min
        && answer?.value
        && answer.value.length < options.min
      ) {
        result.push(
          t(
            'SurveyModal.notEnoughItems',
            'Veuillez sélectionner minimum {{minItems}} option{{s}}',
            { minItems: options.min, s: options.min > 1 ? 's' : '' },
          ),
        );
      }
      if (
        options?.max
        && answer?.value
        && answer.value.length > options.max
      ) {
        result.push(
          t(
            'SurveyModal.tooMuchItems',
            'Veuillez sélectionner maximum {{maxItems}} option{{s}}',
            { maxItems: options.max, s: options.max > 1 ? 's' : '' },
          ),
        );
      }
    }

    // Number
    if (type === Types.Number) {
      if (
        options?.min
        && options?.max
        && answer?.value
        && (
          answer.value < options.min
          || answer.value > options.max
        )
      ) {
        result.push(
          t(
            'SurveyModal.betweenLowAndHigh',
            'Veuillez saisir un nombre compris entre {{minimum}} et {{maximum}}',
            { minimum: options.min, maximum: options.max },
          ),
        );
      }
      if (options?.min
        && !options?.max
        && answer?.value
        && answer.value < options.min
      ) {
        result.push(
          t(
            'SurveyModal.tooLow',
            'Veuillez saisir un nombre supérieur ou égal à {{minimum}}',
            { minimum: options.min },
          ),
        );
      }
      if (
        options?.max
        && !options?.min
        && answer?.value
        && answer.value > options.max
      ) {
        result.push(
          t(
            'SurveyModal.tooHigh',
            'Veuillez saisir un nombre inférieur ou égal à {{maximum}}',
            { maximum: options.max },
          ),
        );
      }
    }

    // String
    if (
      answer?.value
      && type === Types.String
      && typeof answer.value === Types.String
    ) {
      if (
        options?.max
        && answer?.value
        && answer.value.length > options?.max
      ) {
        result.push(
          t(
            'SurveyModal.tooMuchLetters',
            'Vous ne pouvez écrire que {{maximum}} caractère{{s}} maximum (actuel: {{current}})',
            { current: answer.value.length, maximum: options.max, s: options.max > 1 ? 's' : '' },
          ),
        );
      }
    }
    return result;
  };

  const handleInputChange = (res: any, key: string) => {
    let answer = res;
    if (typeof res === Types.String) answer = capitalize(res);
    if (res instanceof FileList && res.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      answer = res[0];
    }
    if (survey.content?.length) {
      const question = survey.content[page].find((q) => q.key === key);
      if (question) {
        if (question.type === Types.Array) {
          switch (question.format) {
            case Formats.Checkbox:
              if (question.answer?.value && Array.isArray(question.answer.value)) {
                if (question.answer.value.some((ans) => ans === res)) {
                  question.answer.value = question.answer.value.filter((ans) => ans !== res);
                } else if (!question.options?.max
                  || (question.answer.value.length < question.options?.max)) {
                  question.answer.value.push(res);
                }
              } else {
                question.answer = {
                  ...question.answer,
                  value: [res],
                };
              }
              break;

            case Formats.Multi || Formats.Select:
              question.answer = {
                ...question.answer,
                value: answer,
              };
              break;

            default:
              question.answer = {
                ...question.answer,
                value: [answer],
              };
              break;
          }
        } else if (question.type === Types.Boolean) {
          question.answer = {
            ...question.answer,
            value: answer.toLowerCase() === 'true',
          };
        } else {
          question.answer = {
            ...question.answer,
            value: answer,
          };
        }
        if (errors?.length) {
          let allErrors = [...errors];
          const errorsMessage = checkAnswer(question);
          if (errorsMessage.length) {
            allErrors.push({ key: question.key, errors: errorsMessage });
          } else if (errors.some((a) => a.key === question.key)) {
            allErrors = allErrors.filter((q) => q.key !== question.key);
          }
          setErrors(allErrors);
        }
        setSurvey({ ...survey });
      }
    }
  };

  const uploadFile = async (file, questionKey, answerKey) => {
    if (!file || typeof file === Types.String) return '';
    if (!(file instanceof File)) return file;
    const uploadURLRes = await getUploadSignedURL(file.type);
    if (uploadURLRes.success) {
      const uploadRes = await uploadToS3(uploadURLRes.data.signedUrl, file);
      if (uploadRes.success) {
        return { question: questionKey, value: uploadURLRes.data.key, key: answerKey };
      }
    }
    return { question: questionKey, value: '', key: answerKey };
  };

  const buildAnswersValues = async () => {
    const files: Promise<SurveyAnswerValue>[] = [];
    // Map to answers
    if (survey.content?.length) {
      const values = survey.content.flatMap((p) => (
        p.reduce((acc: SurveyAnswerValue[], q: SurveyQuestion) => {
          if (Array.isArray(q.answer?.value)) {
            const res: string[] | { label: string, value: string } = [];
            q.answer?.value.forEach((r: string | { label: string, value: string }) => (
              typeof r === 'string' ? res.push(r) : res.push(r.value)
            ));
            acc.push({ question: q.key, value: res, key: q.answer?.key });
          } else if (q.answer?.value instanceof File) {
            files.push(uploadFile(q.answer.value, q.key, q.answer.key));
          } else {
            acc.push({ question: q.key, value: q.answer?.value, key: q.answer?.key });
          }
          return acc;
        }, [])
      ));
      if (files.length) {
        const fileRes = await Promise.all(files);
        return [...values, ...fileRes];
      }
      return values;
    }
    return [];
  };

  const handleSubmit = async () => {
    if (enterpriseId && survey.id) {
      const values: SurveyAnswerValue[] = await buildAnswersValues();
      const upsert = isNew ? createSurveyAnswer : updateEnterpriseAnswers;
      const res = await upsert(survey.id, { enterpriseId, values });
      if (res.success) {
        onClose();
      } else {
        setNotif({
          message: t(
            'SurveyModal.upsertFailed',
            'Un problème est survenu, vos réponses n\'ont pas été prises en compte',
          ),
          variant: 'danger',
        });
      }
    }
  };

  const getErrorsFromPage = () => {
    if (survey.content?.length) {
      return survey.content[page].reduce((acc: IError[], question: SurveyQuestion) => {
        const errorsMessage = checkAnswer(question);
        return errorsMessage.length
          ? [...acc, { key: question.key, errors: errorsMessage }]
          : acc;
      }, []);
    }
    return [];
  };

  const handleNextPage = () => {
    const allErrors = getErrorsFromPage();
    if (allErrors.length) {
      setErrors(allErrors);
    } else {
      setPage((prev) => prev + 1);
    }
  };

  const getErrors = (key: string) => (
    errors?.find((e) => e.key === key)?.errors || []
  );

  const AWButtons = useCallback(() => {
    if (survey) {
      const previousButton = (
        <AWButton
          type="button"
          className="mb-md-0 mb-2"
          onClick={() => setPage((prev) => prev - 1)}
        >
          {t('Action.return')}
        </AWButton>
      );
      const nextButton = (
        <AWButton
          type="button"
          onClick={isEditable || !isDisabled ? handleNextPage : (() => setPage((prev) => prev + 1))}
        >
          {t('SurveyModal.next', 'Suivant')}
        </AWButton>
      );

      const validateButton = (
        <AWButton
          type="button"
          onClick={handleSubmit}
        >
          {t('Action.validate')}
        </AWButton>
      );

      const pageNumber = survey.content?.length || 0;
      const isLast = page + 1 === survey.content?.length;
      if (pageNumber > 1 && page > 0 && isLast && isEditable && !isDisabled) {
        return (
          <Row className="justify-content-center">
            <Col md="3">
              {previousButton}
            </Col>
            <Col md="3">
              {validateButton}
            </Col>
          </Row>
        );
      }
      if (pageNumber === 1 && isEditable && !isDisabled) {
        return (
          <Row className="justify-content-center">
            <Col md="6">
              {validateButton}
            </Col>
          </Row>
        );
      }
      if (page > 0 && (page - 1) < pageNumber && pageNumber > 1 && !isLast) {
        return (
          <Row className="justify-content-center">
            <Col md="3">
              {previousButton}
            </Col>
            <Col md="3">
              {nextButton}
            </Col>
          </Row>
        );
      }
      if (pageNumber > 1 && page > 0) {
        return (
          <Row className="justify-content-center">
            <Col md="6">
              {previousButton}
            </Col>
          </Row>
        );
      }
      if (pageNumber > 1 && (page - 1) < pageNumber) {
        return (
          <Row className="justify-content-center">
            <Col md="6">
              {nextButton}
            </Col>
          </Row>
        );
      }
    }
    return <span />;
  }, [survey, page, handleSubmit]);

  const pageTitle = useMemo(() => survey?.pageTitles?.find(
    (el) => el.position === page,
  )?.value, [page]);

  return (
    <Form>
      <div className="vh-40 overflow-auto my-auto">
        <p className="mb-3 font-style-bold">{pageTitle}</p>
        {
          survey.content?.length && survey.content[page]?.map((question) => (
            <Question
              key={question.key}
              question={question}
              disabled={!isEditable || isDisabled}
              onChange={handleInputChange}
              errors={getErrors(question.key)}
            />
          ))
        }
      </div>
      <p className="text-end">
        {`${t('Pagination.page', 'Page')}  ${page + 1}/${survey.content?.length}`}
      </p>
      {survey.content?.length ? <AWButtons /> : ''}
    </Form>
  );
};

SurveyModal.defaultProps = {
  enterpriseId: undefined,
};

export default SurveyModal;
