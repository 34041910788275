import React from 'react';
import { useRouteMatch, Switch } from 'react-router-dom';
import ApprovalInformations from '@provider/views/Approvals/ApprovalContainer/ApprovalInformations/ApprovalInformations';
import ApprovalContainer from '@provider/views/Approvals/ApprovalContainer/ApprovalContainer';
import { TabApprovalProviderKeywordRoutes } from '@provider/views/Approvals/tab-approval.list';

import ApprovalProviderContextProvider from '@provider/contexts/approvalProvider.context';
import ProtectedRoute from '@root/routes/ProtectedRoute';
import ApprovalPreview from '@provider/views/Approvals/ApprovalContainer/ApprovalPreview/ApprovalPreview';
import ApprovalVariables from '@provider/views/Approvals/ApprovalContainer/ApprovalVariables/ApprovalVariables';
import ApprovalSignature from '@provider/views/Approvals/ApprovalContainer/ApprovalSignature/ApprovalSignature';

const ApprovalContainerRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ApprovalProviderContextProvider>
        <ProtectedRoute path={`${path}/${TabApprovalProviderKeywordRoutes.INFOS}`} exact>
          <ApprovalContainer>
            {' '}
            <ApprovalInformations />
          </ApprovalContainer>
        </ProtectedRoute>
        <ProtectedRoute path={`${path}/${TabApprovalProviderKeywordRoutes.PREVIEW}`} exact>
          <ApprovalContainer>
            {' '}
            <ApprovalPreview />
          </ApprovalContainer>
        </ProtectedRoute>
        <ProtectedRoute path={`${path}/${TabApprovalProviderKeywordRoutes.VARIABLES}`} exact>
          <ApprovalContainer>
            {' '}
            <ApprovalVariables />
          </ApprovalContainer>
        </ProtectedRoute>
        <ProtectedRoute path={`${path}/${TabApprovalProviderKeywordRoutes.SIGNATURE}`} exact>
          <ApprovalContainer>
            {' '}
            <ApprovalSignature />
          </ApprovalContainer>
        </ProtectedRoute>
      </ApprovalProviderContextProvider>
    </Switch>
  );
};

export default ApprovalContainerRoutes;
