import { Certification } from '@root/interfaces/enterprise.interface';
import {
  getAllDepartments as getAllDepartmentsService,
  getCustomerCertifications as getCustomerCertificationsService,
} from '@customer/services/enterprise.service';

export const getAllDepartments = async () => {
  const res = await getAllDepartmentsService({
    fields: ['insee_code', 'display_name', 'prefecture'],
    search: { country: 'fr' },
    page_size: 102,
    sort: 'insee_code',
  });
  if (res.success && res.data?.length) {
    return res.data;
  }
  return [];
};

export const getCustomerCertifications = async (customerId: string): Promise<Certification[]> => {
  const res = await getCustomerCertificationsService(
    customerId,
    { fields: 'certification_id,label' },
  );
  if (res.success && res.data?.length) {
    return res.data;
  }
  return [];
};
