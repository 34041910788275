import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '@root/routes/ProtectedRoute';
import Customers from '@provider/views/Customers/Customers';
import Customer from '@provider/views/Customers/Customer';

const CustomerRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={path} exact>
        <Customers />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/:enterpriseId`} exact>
        <Customer />
      </ProtectedRoute>
    </Switch>
  );
};

export default CustomerRoutes;
