import React, { useContext, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { UserContext } from '@root/contexts/user.context';
import { capitalize } from '@root/helpers/utils';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { getUploadSignedURL, uploadToS3 } from '@root/services/file.service';
import {
  Formats,
  Survey,
  SurveyAnswerValue,
  SurveyQuestion,
  Types,
} from '@root/interfaces/survey.interface';
import { createSurveyAnswer, updateEnterpriseAnswers } from '@root/services/survey.service';

import AWButton from '@root/components/AWButtons/AWButton';
import Question from '@provider/components/Question/Question';

interface IError {
  key: string;
  errors: string[];
}

interface Props {
  initialSurvey: Survey,
  isSurveyEnabled: boolean,
  onClose: () => void,
}

const MAX_FILE_SIZE = (1024 * 1000) * 5;

const SurveyModal = ({
  initialSurvey,
  isSurveyEnabled,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<IError[]>();
  const [pageIndex, setPageIndex] = useState(0);
  const [survey, setSurvey] = useState(initialSurvey);
  const { user } = useContext(UserContext);
  const { isLoading, waitWithLoad } = useLoadingPromise();

  const checkAnswer = (question: SurveyQuestion): string[] => {
    const {
      answer,
      options,
      type,
      format,
    } = question;
    const result = [];
    // Required
    if (
      options?.required
      && (
        answer?.value === ''
        || answer?.value === undefined
        || (
          Array.isArray(answer?.value)
          && !answer?.value.length
        )
      )
    ) {
      result.push(t('SurveyModal.pleaseProvideAnswer', 'Cette question est obligatoire.'));
    }

    // File
    if (format === Formats.File
      && answer?.value instanceof File
    ) {
      if (answer?.value.size > MAX_FILE_SIZE) {
        result.push(t('SurveyModal.fileTooLarge', 'Le fichier sélectionné est trop volumineux'));
      }
      if (answer?.value.type !== 'application/pdf' && !answer?.value.name.includes('.pdf')) {
        result.push(
          t(
            'SurveyModal.fileWrongFormat',
            'Le fichier sélectionné ne possède pas le bon format',
          ),
        );
      }
    }

    // Date
    if (type === Formats.Date) {
      if ((options?.min || options?.max) && answer?.value) {
        const selectedDate = Date.parse(answer?.value.toString());
        let minDate;
        let maxDate;
        if (options.min) minDate = Date.parse(options.min.toString());
        if (options.max) maxDate = Date.parse(options.max.toString());
        if (
          (minDate && (selectedDate < minDate))
          || (maxDate && (selectedDate > maxDate))
          || (
            (minDate && maxDate)
            && (selectedDate < minDate || selectedDate > maxDate)
          )
        ) {
          result.push(
            t(
              'SurveyModal.wrongDate',
              'Veuillez sélectionner une date comprise dans la fourchette indiquée',
            ),
          );
        }
      }
    }

    // Array
    if (Array.isArray(answer?.value)) {
      if (
        options?.min
        && answer?.value
        && answer.value.length < options.min
      ) {
        result.push(
          t(
            'SurveyModal.notEnoughItems',
            'Veuillez sélectionner minimum {{minItems}} option{{s}}',
            { minItems: options.min, s: options.min > 1 ? 's' : '' },
          ),
        );
      }
      if (
        options?.max
        && answer?.value
        && answer.value.length > options.max
      ) {
        result.push(
          t(
            'SurveyModal.tooMuchItems',
            'Veuillez sélectionner maximum {{maxItems}} option{{s}}',
            { maxItems: options.max, s: options.max > 1 ? 's' : '' },
          ),
        );
      }
    }

    // Number
    if (type === Types.Number) {
      if (options?.min
        && options?.max
        && answer?.value
        && (
          answer.value < options.min
          || answer.value > options.max
        )
      ) {
        result.push(
          t(
            'SurveyModal.betweenLowAndHigh',
            'Veuillez saisir un nombre compris entre {{minimum}} et {{maximum}}',
            { minimum: options.min, maximum: options.max },
          ),
        );
      }
      if (options?.min
        && !options?.max
        && answer?.value
        && answer.value < options.min
      ) {
        result.push(
          t(
            'SurveyModal.tooLow',
            'Veuillez saisir un nombre supérieur ou égal à {{minimum}}',
            { minimum: options.min },
          ),
        );
      }
      if (
        options?.max
        && !options?.min
        && answer?.value
        && answer.value > options.max
      ) {
        result.push(
          t(
            'SurveyModal.tooHigh',
            'Veuillez saisir un nombre inférieur ou égal à {{maximum}}',
            { maximum: options.max },
          ),
        );
      }
    }

    // String
    if (
      answer?.value
      && type === Types.String
      && typeof answer.value === Types.String
    ) {
      if (
        options?.max
        && answer?.value
        && answer.value.length > options?.max
      ) {
        result.push(
          t(
            'SurveyModal.tooMuchLetters',
            'Vous ne pouvez écrire que {{maximum}} caractère{{s}} maximum (actuel: {{current}})',
            { current: answer.value.length, maximum: options.max, s: options.max > 1 ? 's' : '' },
          ),
        );
      }
    }
    return result;
  };

  const getErrors = () => {
    const allErrors: IError[] = [];
    if (survey.content?.length) {
      survey.content[pageIndex].forEach((question) => {
        const errorsMessage = checkAnswer(question);
        if (errorsMessage.length) {
          allErrors.push({ key: question.key, errors: errorsMessage });
        }
      });
    }
    return allErrors;
  };

  const handleNextPage = () => {
    const allErrors = getErrors();
    if (allErrors.length) {
      setErrors(allErrors);
    } else {
      setPageIndex((prev) => prev + 1);
    }
  };

  const uploadFile = async (file, questionKey, answerKey) => {
    if (!file || typeof file === Types.String) return '';
    if (!(file instanceof File)) return file;
    const uploadURLRes = await getUploadSignedURL(file.type);
    if (uploadURLRes.success) {
      const uploadRes = await uploadToS3(uploadURLRes.data.signedUrl, file);
      if (uploadRes.success) {
        return { question: questionKey, value: uploadURLRes.data.key, key: answerKey };
      }
    }
    return { question: questionKey, value: '', key: answerKey };
  };

  const buildAnswers = async () => {
    const files: Promise<SurveyAnswerValue>[] = [];
    let values: SurveyAnswerValue[] = [];
    // Map to answers
    if (survey.content?.length) {
      survey.content.forEach((p) => {
        p.forEach((q) => {
          if (Array.isArray(q.answer?.value)) {
            const res: string[] | { label: string, value: string } = [];
            q.answer?.value.forEach((r: string | { label: string, value: string }) => (
              typeof r === 'string' ? res.push(r) : res.push(r.value)
            ));
            values.push({ question: q.key, value: res, key: q.answer?.key });
          } else if (q.answer?.value instanceof File) {
            files.push(uploadFile(q.answer.value, q.key, q.answer.key));
          } else {
            values.push({ question: q.key, value: q.answer?.value, key: q.answer?.key });
          }
        });
      });
    }
    if (files.length) {
      const fileRes = await Promise.all(files);
      values = [...values, ...fileRes];
    }
    return values;
  };

  const handleResponse = (res, key) => {
    let answer = res;
    if (typeof res === Types.String) answer = capitalize(res);
    if (res instanceof FileList && res.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      answer = res[0];
    }
    if (survey.content?.length) {
      const question = survey.content[pageIndex].find((q) => q.key === key);
      if (question) {
        if (question.type === Types.Array) {
          switch (question.format) {
            case Formats.Checkbox:
              if (question.answer?.value && Array.isArray(question.answer.value)) {
                if (question.answer.value.some((ans) => ans === res)) {
                  question.answer.value = question.answer.value.filter((ans) => ans !== res);
                } else if (!question.options?.max
                  || (question.answer.value.length < question.options?.max)) {
                  question.answer.value.push(res);
                }
              } else {
                question.answer = {
                  ...question.answer,
                  value: [res],
                };
              }
              break;

            case Formats.Multi || Formats.Select:
              question.answer = {
                ...question.answer,
                value: answer,
              };
              break;

            default:
              question.answer = {
                ...question.answer,
                value: [answer],
              };
              break;
          }
        } else if (question.type === Types.Boolean) {
          question.answer = {
            ...question.answer,
            value: answer.toLowerCase() === 'true',
          };
        } else {
          question.answer = {
            ...question.answer,
            value: answer,
          };
        }
        if (errors?.length) {
          let allErrors = [...errors];
          const errorsMessage = checkAnswer(question);
          if (errorsMessage.length) {
            allErrors.push({ key: question.key, errors: errorsMessage });
          } else if (errors.some((a) => a.key === question.key)) {
            allErrors = allErrors.filter((q) => q.key !== question.key);
          }
          setErrors(allErrors);
        }
        setSurvey({ ...survey });
      }
    }
  };

  const submitSurvey = async () => {
    const values = await buildAnswers();
    if (survey.id && user.currentEnterprise?.id) {
      const body = {
        enterpriseId: user.currentEnterprise.id,
        values,
      };
      const upsert = survey.isUpdate ? updateEnterpriseAnswers : createSurveyAnswer;
      const surveyRes = await upsert(survey.id, body);
      if (surveyRes.success) {
        onClose();
      }
    }
  };

  const handleSubmit = () => {
    const allErrors = getErrors();
    if (allErrors.length) {
      setErrors(allErrors);
    } else {
      waitWithLoad(submitSurvey());
    }
  };

  const AWButtons = useMemo(() => {
    const previousButton = (
      <AWButton type="button" className="mb-md-0 mb-2" onClick={() => setPageIndex((prev) => prev - 1)}>
        {t('Action.return')}
      </AWButton>
    );
    const nextButton = (
      <AWButton type="button" onClick={handleNextPage}>
        {t('SurveyModal.next', 'Suivant')}
      </AWButton>
    );
    const submitButton = isSurveyEnabled ? (
      <AWButton
        type="button"
        disabled={isLoading}
        onClick={handleSubmit}
      >
        {t('Action.validate')}
      </AWButton>
    ) : <div />;

    return (
      <Row className="justify-content-center">
        {
          pageIndex === 0 ? (
            <Col md="6">
              {pageIndex + 1 === survey.content?.length ? submitButton : nextButton}
            </Col>
          ) : (
            <>
              <Col md="3">
                {previousButton}
              </Col>
              <Col md="3">
                {pageIndex + 1 === survey.content?.length ? submitButton : nextButton}
              </Col>
            </>
          )
        }
      </Row>
    );
  }, [pageIndex]);

  const pageTitle = useMemo(() => survey?.pageTitles?.find(
    (el) => el.position === pageIndex,
  )?.value, [pageIndex]);

  return (
    <Form>
      <div className="vh-40 overflow-auto my-auto">
        <p className="mb-3 font-style-bold">{pageTitle}</p>
        {
          survey.content?.length && survey.content[pageIndex]?.map((question) => (
            <Question
              key={question.key}
              question={question}
              disabled={!isSurveyEnabled}
              errors={errors?.find((a) => a.key === question.key)?.errors || []}
              handleInputChange={handleResponse}
            />
          ))
        }
      </div>
      <p className="text-end">
        {`${t('Pagination.page', 'Page')}  ${pageIndex + 1}/${survey.content?.length}`}
      </p>
      {AWButtons}
    </Form>
  );
};

export default SurveyModal;
