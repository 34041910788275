import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@root/contexts/user.context';
import AWButton from '@root/components/AWButtons/AWButton';
import { useHistory } from 'react-router-dom';
import { PROVIDER_BASE_PATH } from '@root/helpers/constants.helper';

const Success = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { user } = useContext(UserContext);

  return (
    <div className="h-100 w-50 mx-auto d-flex flex-column text-center justify-content-center">
      <div className="highlight-text--uppercased font-size-40 mb-5">
        {`${t('Success.congratulation', 'Félicitations')} !`}
      </div>
      <div className="text-dark font-style-bold font-size-24">
        {user.currentEnterprise?.name}
      </div>
      <p className="font-size-16 mb-5">
        {t('Success.registered', 'est maintenant référencée sur AddWorking')}
      </p>
      <AWButton
        type="button"
        className="w-auto"
        onClick={() => push(PROVIDER_BASE_PATH)}
      >
        {`${t('Success.dashboard', 'Continuer vers le dashboard')} ...`}
      </AWButton>
    </div>
  );
};

export default Success;
