import { QueryboyOptions } from '@root/interfaces/queryboy.interface';
import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import {
  LegalForm,
} from '@root/interfaces/enterprise.interface';
import { queryBoySearch } from '@root/helpers/utils';

const baseUrl = `${process.env.REACT_APP_ENTERPRISE_API}/v1/enterprise/user`;

export const getLegalForms = async (
  options: QueryboyOptions,
): Promise<ApiResponse<LegalForm[]>> => (
  safeFetch(
    `${baseUrl}/legal-forms${queryBoySearch(options)}`,
  )
);
