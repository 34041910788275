import React, {
  createContext,
  ReactNode,
  useState,
  useMemo,
} from 'react';

export interface Notification {
  message: string;
  variant: string;
}

type Notif = string | Notification;

interface IAlertContext {
  notif?: Notification;
  setNotif: (notif: Notif) => void;
}

export const AlertContext = createContext<IAlertContext>({
  setNotif: () => { },
});

const AlertContextProvider = ({ children }: { children: ReactNode }) => {
  const [notif, setNotifOnly] = useState<Notification>();

  const disableNotif = (): Promise<void> => new Promise((resolve) => {
    setTimeout(() => {
      setNotifOnly(undefined);
      resolve();
    }, 3000);
  });

  const setNotif = (_notif: Notif) => {
    setNotifOnly({
      message: typeof _notif === 'string' ? _notif : _notif.message,
      variant: typeof _notif === 'string' ? 'success' : _notif.variant,
    });
    disableNotif();
  };

  const value = useMemo(() => ({
    notif,
    setNotif,
  }), [notif]);

  return (
    <AlertContext.Provider
      value={value}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
