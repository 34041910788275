import React from 'react';
import {
  useRouteMatch,
  Switch,
  Route,
} from 'react-router-dom';
import ProtectedRoute from '@root/routes/ProtectedRoute';
import ApprovalsProvider from '@provider/views/Approvals/ApprovalsProvider';
import ApprovalContainerRoutes from './ApprovalContainerRoutes';

const ApprovalProviderRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={path} exact>
        <ApprovalsProvider />
      </ProtectedRoute>
      <Route path={`${path}/:approvalId`}>
        <ApprovalContainerRoutes />
      </Route>
    </Switch>
  );
};

export default ApprovalProviderRoutes;
