import React, { ReactNode } from 'react';
import LoginRouterContextProvider from './loginRouter.context';

const UserAppContext = ({ children }: { children: ReactNode }) => (
  <LoginRouterContextProvider>
    {children}
  </LoginRouterContextProvider>
);

export default UserAppContext;
