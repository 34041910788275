import React, { ReactNode } from 'react';

interface Props {
  /** Function used on button click */
  onClick?: () => void;
  /** Text and images inside the button */
  children: ReactNode;
  /** Margin Top  */
  marginTop?: string;
  /** Margin Bottom  */
  marginBottom?: string;
  /** Height for the box */
  height?: string;
  /** Font size for the box */
  fontSize?: string;
  /** Possibility to add new css classes to the button component */
  className?: string;
}

/**
 * SelectionBox is a component that is clickable, it allows a user to choose between
 * different options. You can change its height, margin and content.
 * @version 1
 * @since 7/10/2021
 */

const SelectionBox = ({
  onClick,
  height,
  children,
  marginTop,
  marginBottom,
  fontSize,
  className,
}: Props) => (
  <div className="d-flex justify-content-center">
    <button
      className={`selection-box ${className}`}
      style={{
        marginTop, marginBottom, height, fontSize,
      }}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  </div>
);

SelectionBox.defaultProps = {
  marginTop: '',
  marginBottom: '0',
  height: '',
  fontSize: '',
  onClick: () => { },
  className: '',
};

export default SelectionBox;
