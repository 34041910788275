import dayjs from 'dayjs';
import i18n from '@root/locales/i18n';
import { SubdomainToV2Light } from '@root/interfaces/cognito.interface';
import { Option } from '@root/interfaces/utils.interface';

require('dayjs/locale/en');
require('dayjs/locale/fr');
require('dayjs/locale/de');

export const goTo = (path: string) => {
  window.open(path, '_self');
};

export const queryToString = (query): string => (
  Object.keys(query).length ? `?${new URLSearchParams(query).toString()}` : ''
);

export const queryBoySearch = (options): string => {
  const result: { fields?: string, search?: string; } = {};
  if (options?.search && Object.keys(options.search)?.length) {
    result.search = JSON.stringify(options.search);
  }
  if (options?.fields) {
    result.fields = Array.isArray(options.fields)
      ? options.fields.join(',')
      : options.fields.replace(/\s/g, '');
  }
  return queryToString({
    ...options,
    ...result,
  });
};

export const getDomainParts = () => window.location.host.toLowerCase().split('.');

export const getFrameBaseUrl = (host: string) => {
  const subdomain = getDomainParts()[0];
  if (subdomain === SubdomainToV2Light.Edenred
    || subdomain === SubdomainToV2Light.EdenredDev
    || subdomain === SubdomainToV2Light.EdenredStaging
    || subdomain === SubdomainToV2Light.Sogetrel
    || subdomain === SubdomainToV2Light.SogetrelDev
    || subdomain === SubdomainToV2Light.SogetrelStaging
  ) {
    return `https://${subdomain}.platform.addworking.io`;
  }
  return host;
};

export enum FileTypes {
  IMAGE = 'IMAGE',
  PDF = 'PDF',
  DOCUMENT = 'DOCUMENT',
}

export const randString = (): string => (Math.random() + 1).toString(36).substring(7);

export const isTextEmpty = (text: string) => (
  !text || text.split('').every((char) => char === ' ')
);

export const capitalize = (string: string): string => (
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase());

export const toCamelCase = (
  string: string,
  opts: { ignoreUpper?: boolean, lower?: boolean },
): string => {
  const options = { ignoreUpper: false, lower: true, ...opts };
  const words = string
    .replace(/\s{2,}/g, ' ')
    .split(' ')
    .map((w) => (options.ignoreUpper ? w.charAt(0).toUpperCase() + w.slice(1) : capitalize(w)))
    .join('');
  return options.lower ? words.charAt(0).toLowerCase() + words.slice(1) : words;
};

export const formatSiret = (siret: string): string => {
  const joinedSiret = siret.split(' ').join('');
  if (joinedSiret.length === 14) {
    return `${siret.slice(0, 3)} ${siret.slice(3, 6)} ${siret.slice(6, 9)} ${siret.slice(9)}`;
  }
  return joinedSiret;
};

export const getSiren = (siret: string) => siret.slice(0, 9);

export const localize = () => {
  const days = [
    i18n.t('DatePicker.sunday', 'Di'),
    i18n.t('DatePicker.monday', 'Lu'),
    i18n.t('DatePicker.tuesday', 'Ma'),
    i18n.t('DatePicker.wednesday', 'Me'),
    i18n.t('DatePicker.thursday', 'Je'),
    i18n.t('DatePicker.friday', 'Ve'),
    i18n.t('DatePicker.saturday', 'Sa'),
  ];
  const months = [
    i18n.t('DatePicker.january', 'Janvier'),
    i18n.t('DatePicker.february', 'Février'),
    i18n.t('DatePicker.march', 'Mars'),
    i18n.t('DatePicker.april', 'Avril'),
    i18n.t('DatePicker.may', 'Mai'),
    i18n.t('DatePicker.june', 'Juin'),
    i18n.t('DatePicker.july', 'Juillet'),
    i18n.t('DatePicker.august', 'Août'),
    i18n.t('DatePicker.september', 'Septembre'),
    i18n.t('DatePicker.october', 'Octobre'),
    i18n.t('DatePicker.november', 'Novembre'),
    i18n.t('DatePicker.december', 'Décembre'),
  ];

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => 'dd/mm/yyyy',
    },
    match: {
      date: () => /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$i/,
    },
  };

  return locale;
};

export const toKey = (entity, fieldName = 'id'): string => (
  `${entity[fieldName] || randString()}_${entity.name || randString()}_${randString()} `
);

export const toSiren = (siret) => {
  if (!siret) return '';
  return siret.substr(0, siret.length - 5);
};

export const formatDate = (iso, format = 'DD/MM/YYYY'): string => (
  dayjs(iso).locale(i18n.language).format(format)
);

export const getFileType = (mime): string => {
  if (mime === 'application/pdf') {
    return FileTypes.PDF;
  }
  if (mime.startsWith('image/')) {
    return FileTypes.IMAGE;
  }
  return FileTypes.DOCUMENT;
};

const hexToRgb = (hex) => {
  const hexDigits = hex.slice(1); // Remove starting #
  if (hexDigits.length !== 6) {
    throw new Error('Only six-digit hex colors are allowed.');
  }

  const aRgbHex = hexDigits.match(/.{1,2}/g);
  const aRgb = [
    parseInt(aRgbHex[0], 16),
    parseInt(aRgbHex[1], 16),
    parseInt(aRgbHex[2], 16),
  ];
  return aRgb;
};

export const getFontColor = (hex) => {
  const [red, green, blue] = hexToRgb(hex);
  return (red * 0.299 + green * 0.587 + blue * 0.114) > 186 ? '#000000' : '#ffffff';
};

const base64ToArrayBuffer = (dataUrl: string) => {
  const base64Str = dataUrl.replace('data:', '').replace(/^.+,/, '');
  const binaryString = window.atob(base64Str);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i += 1) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const previewFileInNewTab = (dataUrl: string, contentType: string) => {
  const byte = base64ToArrayBuffer(dataUrl);
  const blob = new Blob([byte], { type: contentType });
  window.open(URL.createObjectURL(blob), '_blank');
};

export const getHigherObjectBy = (filter: any, data: any[]) => (
  data.length ? data.reduce(
    (acc, current) => (acc[filter] > current[filter] ? acc : current),
  ) : null
);

export const getPreviousYears = ({
  numberOfYears,
  startingYear = new Date().getFullYear(),
}: {
  numberOfYears: number,
  startingYear?: number
}) => {
  const years: number[] = [];
  for (let index = 0; index < numberOfYears; index += 1) {
    years.push(startingYear - index);
  }
  return years;
};

export const FILE_TYPES = {
  IMAGE: 'IMAGE',
  PDF: 'PDF',
  DOCUMENT: 'DOCUMENT',
};

export const GA_SCRIPT_SRC = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`;

export const GTAG_SCRIPT = `
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}', {
    custom_map: { dimension0: 'uid' },
    send_page_view: false
  });
`;

export const reportAnalytics = (userId: string) => {
  const { location: { pathname, search, hash } } = window;
  window.gtag('event', 'page_view', {
    page_full_path: pathname + search + hash,
    page_path: pathname,
    page_search: search,
    page_hash: hash,
  });
  window.gtag('event', 'uid_dimension', { uid: userId });
};

// Safe way to read utc date
export const newDate = (date?: string | Date) => new Date(dayjs(date).format());

export const statusList: Option[] = [
  {
    value: '0',
    label: i18n.t('Status.unseen'),
  },
  {
    value: '1',
    label: i18n.t('Status.seen'),
  },
  {
    value: '2',
    label: i18n.t('Status.prequalified'),
  },
  {
    value: '3',
    label: i18n.t('Status.accepted'),
  },
  {
    value: '4',
    label: i18n.t('Status.parked'),
  },
  {
    value: '5',
    label: i18n.t('Status.refused'),
  },
  {
    value: '6',
    label: i18n.t('Status.blacklisted'),
  },
];

export const getStatus = (byKey: string): Option => statusList.find((el) => el.value === byKey)!;

export const formatPhone = (phone = '') => {
  if (phone[0] === '+') {
    return `${phone.substring(0, 3)} ${phone.substring(3, 4)} ${phone.substring(4, 6)} ${phone.substring(6, 8)} ${phone.substring(8, 10)} ${phone.substring(10, 12)}`;
  }
  return `${phone.substring(0, 2)} ${phone.substring(2, 4)} ${phone.substring(4, 6)} ${phone.substring(6, 8)} ${phone.substring(8, 10)}`;
};

export const getLegalForm = (longLegalForm?: string): string => {
  if (!longLegalForm) return '';
  return ['SA', 'SAS', 'SASU', 'EURL', 'SARL', 'SCI']
    .reduce(
      (acc: string, legalform: string) => {
        if (longLegalForm.includes(legalform)) {
          // eslint-disable-next-line no-param-reassign
          acc = legalform;
        }
        return acc;
      },
      longLegalForm,
    );
};

export const numberToCurrency = (number: number, currency = 'EUR') => (
  new Intl.NumberFormat(i18n.language, { style: 'currency', currency, maximumFractionDigits: 0 }).format(number)
);

export const toStringRange = (min: number | string, max?: string | number): string => (
  max ? `${min} - ${max}` : `${min} +`
);

export const formatEmail = (email: string) => (
  email.toLowerCase().replace(/\s+/g, '')
);

export const deleteCookies = () => {
  const cookies = document.cookie.split(';');
  const domain = window.location.hostname.split('.').slice(1).join('.');
  // set past expiry to all cookies
  for (let i = 0; i < cookies.length; i += 1) {
    document.cookie = `${cookies[i]}=; Max-Age=0; path=/; domain=.${domain}`;
  }
};

export const mimeType = {
  html: 'text/html',
  pdf: 'application/pdf',
};
