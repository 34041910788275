import React from 'react';
import SignaturesBloc from '@root/components/SignaturesBloc/SignaturesBloc';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import NavSignataires from '@customer/views/Approvals/ApprovalSideContainer/NavSignataires';

const ApprovalSideContainer = () => {
  const { t } = useTranslation();

  return (
    <Col className="filtre-container padding-col">
      <h2 className=" mb-5">{t('Approvals.parametres', 'PARAMÈTRES DES SIGNATAIRES')}</h2>
      <NavSignataires />
      <SignaturesBloc />
    </Col>
  );
};

export default ApprovalSideContainer;
