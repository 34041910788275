import React from 'react';
import { useTranslation } from 'react-i18next';
import AWButton from '@root/components/AWButtons/AWButton';
import { goTo } from '@root/helpers/utils';
import { CUSTOMER_BASE_PATH, PROVIDER_BASE_PATH } from '@root/helpers/constants.helper';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="vh-100 d-flex flex-column justify-content-center align-items-center mx-5">
      <h1 className="text-center mb-5">
        {t('NotFound.pageNotFound')}
      </h1>
      <div>
        <h2 className="font-style-regular mb-4">
          {t('NotFound.description')}
        </h2>
        <ul className="no-style">
          <li className="font-size-18 mb-4">
            {t('NotFound.ifYouAreA')}
            &nbsp;
            <span className="font-style-bold">{`${t('Common.provider')},`}</span>
            &nbsp;
            <AWButton
              noFlex
              onClick={() => goTo(PROVIDER_BASE_PATH)}
              className="w-auto py-1 px-2"
            >
              {t('NotFound.click')}
            </AWButton>
            &nbsp;
            {t('NotFound.goToDashboard')}
          </li>
          <li className="font-size-18 mb-4">
            {t('NotFound.ifYouAreA')}
            &nbsp;
            <span className="font-style-bold">{`${t('Common.customer')},`}</span>
            &nbsp;
            <AWButton
              noFlex
              onClick={() => goTo(CUSTOMER_BASE_PATH)}
              className="w-auto py-1 px-2"
            >
              {t('NotFound.click')}
            </AWButton>
            &nbsp;
            {t('NotFound.goToDashboard')}
          </li>
        </ul>
        <h2 className="font-style-regular p-0">
          {t('NotFound.thankYou')}
        </h2>
      </div>
    </div>
  );
};

export default NotFound;
