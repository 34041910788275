import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import { queryBoySearch } from '@root/helpers/utils';
import {
  Checker,
  Connection,
  ConnectionConfig,
  CreateConnectionParams,
  Owner,
  Validator,
} from '@root/interfaces/connection.interface';
import { QueryboyOptions } from '@root/interfaces/queryboy.interface';

const baseUrl = `${process.env.REACT_APP_ENTERPRISE_API}/v1/enterprise/customer`;

export const createConnection = async (
  connection: CreateConnectionParams,
): Promise<ApiResponse<Connection>> => safeFetch(
  `${baseUrl}/connections`,
  {
    method: 'POST',
    body: JSON.stringify(connection),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
);

export const getConnectionConfigs = async (
  options: QueryboyOptions,
): Promise<ApiResponse<ConnectionConfig[]>> => (
  safeFetch(
    `${baseUrl}/connections/configs${queryBoySearch(options)}`,
  )
);

export const getEnterpriseConnections = (
  enterpriseId: string,
): Promise<ApiResponse<Connection[]>> => (
  safeFetch(`${baseUrl}/enterprises/${enterpriseId}/connections`)
);

export const getConnection = (connectionId): Promise<ApiResponse<Connection>> => (
  safeFetch(`${baseUrl}/connections/${connectionId}`)
);

export const getCheckersFromConnection = (connectionId): Promise<ApiResponse<Checker[]>> => (
  safeFetch(`${baseUrl}/connections/${connectionId}/checkers`)
);

export const getValidatorsFromConnection = (
  configId: string,
): Promise<ApiResponse<Validator[]>> => (
  safeFetch(`${baseUrl}/connections/configs/${configId}/validators`)
);

export const updateConnectionStep = (
  connectionId: string,
  stepNumber: number,
  status: string,
): Promise<ApiResponse<Connection>> => (
  safeFetch(
    `${baseUrl}/connections/${connectionId}/steps/${stepNumber}/${status}`,
    { method: 'PUT' },
  )
);

export const getConnectionTasksOwners = (connectionId: string): Promise<ApiResponse<Owner[]>> => (
  safeFetch(
    `${baseUrl}/connections/${connectionId}/tasks/owners`,
    { method: 'GET' },
  )
);

export const addUserToTask = (taskId: string, userId: string): Promise<ApiResponse> => (
  safeFetch(
    `${baseUrl}/connections/tasks/${taskId}/users/${userId}`,
    { method: 'PATCH' },
  )
);
