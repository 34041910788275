export enum ApprovalNotificationEventEnum {
  NEW_APPROVAL_CREATED = 'NEW_APPROVAL_CREATED',
  PROVIDER_VALIDATED = 'PROVIDER_VALIDATED',
  SIGNATURE_PROCESS_INITIATED = 'SIGNATURE_PROCESS_INITIATED',
  PROVIDER_HAS_SIGNED = 'PROVIDER_HAS_SIGNED',
}

export interface ApprovalNotification {
  event: ApprovalNotificationEventEnum;
  emails: (string | undefined)[];
  customer_enterprise?: string;
  customer_name?: string;
  provider_enterprise?: string;
  provider_name?: string;
}
