import React from 'react';
import { useTranslation } from 'react-i18next';
import Tag from '@customer/components/Tag/Tag';

interface Props {
  status: string,
}

export const Status = {
  Launched: 'launched',
  Stopped: 'stopped',
  Sketch: 'sketch',
};

export const StatusColor = {
  launched: '#12957d',
  stopped: '#3579f6',
  sketch: '#a0a0a0',
};

const StatusTag = ({ status }: Props) => {
  const { t } = useTranslation();

  return (
    <Tag color={StatusColor[status] || StatusColor.sketch} label={t(`Status.${status}`)} />
  );
};

export default StatusTag;
