import { Option } from '@root/interfaces/utils.interface';

export interface Provider {
  id?: string;
  name?: string;
  provider_id?: string;
  identification_number?: string;
  customer_id?: string;
  address?: Address;
  phoneNumbers?: string[];
  legalForm?: string;
  representatives?: Representative[];
  passworks?: ProviderPasswork[];
  cha?: string;
  tax_identification_number?: string;
  registration_town?: string;
  main_activity_code?: string;
  contractualization_language?: string;
}

export interface ProviderPasswork {
  job_name?: string,
  skills?: {
    skill_name: string,
    level: number,
  }[],
}

export interface ProviderInvitation {
  id: string;
  status: string;
  additional_data: {
    status: string;
  };
}

export enum ProvidersLoadingBtn {
  MORE = 'more',
  SEARCH = 'search',
  FILTER = 'filter',
}

export interface ProviderSourcing {
  id?: string;
  name?: string;
  identificationNumber?: string;
  legalForm?: string;
  address?: string;
}
export interface ProviderFilters {
  search?: string;
  departments?: string[];
  custom?: string[];
  certifications?: string[];
  activities?: string[];
  fields?: Record<string, Option[]>;
  businessTurnoverMin?: string;
  businessTurnoverMax?: string;
  hasVendorExternalId?: string;
}
export interface Representative {
  id?: string;
  firstname?: string;
  lastname?: string;
  job_title?: string;
  email?: string;
  data?: {
    firstname?: string;
    lastname?: string;
    email?: string;
  }
  user_id?: string;
  is_legal_representative?: boolean;
}
export interface Address {
  address?: string;
  additionnal_address?: string;
  zipcode?: string;
  town?: string;
  country?: string;
}

export interface Passwork {
  job_name: string,
  skill_name: string,
  level: number,
}

export interface SendEmailOptions {
  customer_id: string;
  customer_name: string;
  email: string;
}

export interface CustomFieldValue {
  id: string;
  value: string;
}

export interface Activity {
  activity?: string,
}

export interface Department {
  display_name?: string,
  insee_code?: number,
}

export interface ComplianceCount {
  provider_id: string;
  legal_total: number;
  business_total: number;
  legal_validated: number;
  business_validated: number;
}

export interface Partnership {
  customer_id: string,
  customer_name: string,
  provider_id: string,
  provider_name: string,
  partners_created_at: Date,
  partners_updated_at: Date,
  activity_starts_at: Date,
  activity_ends_at: Date,
  vendor_external_id: string,
  is_connected: true,
}

export interface ContractCount {
  contract_count: number,
}

export interface Certification {
  provider_id: string,
  label: string,
}

export interface BusinessAmount {
  business_amount: number,
}
