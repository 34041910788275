import React, {
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Error from '@provider/views/AddEnterprise/Error';
import Success from '@provider/views/AddEnterprise/Success';
import EnterpriseCreation from '@root/components/EnterpriseCreation/EnterpriseCreation';
import { EnterpriseCreationContext } from '@root/contexts/enterpriseCreation.context';
import { CreationStep, Enterprise } from '@root/interfaces/enterprise.interface';
import { UserContext } from '@root/contexts/user.context';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import useSafeFetch from '@root/hooks/useSafeFetch';
import { joinUserEnterpriseConfig } from '@root/api-configs/user.api.config';
import { AlreadyExist, NeedSupport } from '@root/helpers/catalog.error';
import JobForm from '@root/components/JobForm/JobForm';
import { createEnterpriseConfig } from '@root/api-configs/enterprise.api.config';
import { isTextEmpty } from '@root/helpers/utils';

const AddEnterpriseSwitch = () => {
  const { t } = useTranslation();
  const { enterprise } = useContext(EnterpriseCreationContext);
  const { user, refreshEnterprises } = useContext(UserContext);
  const [display, setDisplay] = useState<CreationStep>(CreationStep.Add);
  const [isError, setIsError] = useState(false);
  const [job, setJob] = useState('');
  const { waitWithLoad, isLoading } = useLoadingPromise();
  const associateUserToEnterprise = useSafeFetch(joinUserEnterpriseConfig).callApi;
  const createEnterpriseService = useSafeFetch<Enterprise>(createEnterpriseConfig).callApi;

  const associateUser = async () => {
    if (enterprise.identification_number && user.id) {
      const associateRes = await associateUserToEnterprise(
        {
          userId: user.id,
          identificationNumber: enterprise.identification_number,
          body: { job },
        },
      );
      if (associateRes?.success) {
        await refreshEnterprises(enterprise.identification_number);
        setDisplay(CreationStep.Success);
      } else if (associateRes?.hasError(NeedSupport)) {
        setDisplay(CreationStep.Error);
        setIsError(true);
      } else {
        setIsError(true);
      }
    }
  };

  const createEnterprise = async () => {
    const { legal_form, ...rest } = enterprise;
    const enterpriseResponse = await createEnterpriseService({ body: rest });
    if (enterpriseResponse?.success || enterpriseResponse?.hasError(AlreadyExist)) {
      await associateUser();
    }
  };

  const handleSubmit = async () => {
    await waitWithLoad(createEnterprise());
  };

  const handleChange = (value: string) => {
    setJob(value);
  };

  const currentView = useMemo(() => {
    switch (display) {
      case CreationStep.Success:
        return <Success />;

      case CreationStep.Error:
        return <Error />;

      case CreationStep.Role:
        return (
          <JobForm
            onSubmit={handleSubmit}
            onChange={handleChange}
            onReturn={() => setDisplay(CreationStep.Add)}
            error={isError}
            enterpriseName={enterprise?.name || ''}
            disabled={isLoading || isTextEmpty(job)}
            loading={isLoading}
          />
        );

      default:
        return (
          <EnterpriseCreation
            title={t('CreateEnterprise.createMyEnterprise', 'Référencer mon entreprise')}
            onSubmit={() => { setDisplay(CreationStep.Role); }}
          >
            <p className="mb-4">
              {t(
                'CreateEnterprise.createMyEnterpriseText',
                `Vous référencez ici l'entreprise à laquelle vous appartenez.
                Si vous appartenez à plusieurs entreprises ou filiales,
                il vous faudra refaire cette procédure pour chacune d'entre elles.`,
              )}
            </p>
          </EnterpriseCreation>
        );
    }
  }, [display, isError, enterprise.name, job]);

  return currentView;
};

export default AddEnterpriseSwitch;
