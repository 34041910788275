import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import AWContainer from '@root/components/AWContainer/AWContainer';
import { UserContext } from '@root/contexts/user.context';
import { getEnterpriseInvitations } from '@root/services/user.service';
import { UserInvitation } from '@root/interfaces/invitation.interface';
import AWLongCard from '@root/components/Cards/AWLongCard/AWLongCard';
import customer from '@root/assets/customer.svg';
import { formatDate } from '@root/helpers/utils';

const Contacts = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const [invitations, setInvitations] = useState<UserInvitation[]>([]);

  const fetchContacts = async () => {
    if (user.currentEnterprise?.id) {
      const invitationsRes = await getEnterpriseInvitations(user.currentEnterprise.id);
      if (invitationsRes.success) {
        setInvitations(invitationsRes.data);
      }
    }
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  return (
    <AWContainer>
      <AWContainer.Main title={t('Contacts.contacts', 'Contacts')}>
        {
          invitations.length ? (
            invitations.map((invitation) => (
              <AWLongCard
                key={invitation.id}
                icon={customer}
                disabled
                className="mb-3 text-black"
              >
                <Row>
                  <Col md={8}>
                    <p className="mb-2 text-truncate">
                      {invitation.email}
                    </p>
                    <p>
                      {`${t('Contacts.status', 'Statut')} : 
                    ${t(`Contacts.${invitation.status}`, invitation.status)}`}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="mb-2">
                      {`${t('Contacts.createdAt', 'Envoyée le')} :
                    ${formatDate(invitation.created_at) || '-'}`}
                    </p>
                    <p>
                      {`${t('Contacts.expiredAt', 'Expire le')} : 
                    ${invitation.expired_at || '-'}`}
                    </p>
                  </Col>
                </Row>
              </AWLongCard>
            ))
          ) : (
            <p>
              {t('Contacts.noInvitation', 'Aucune invitation')}
            </p>
          )
        }
      </AWContainer.Main>
    </AWContainer>
  );
};

export default Contacts;
