import React from 'react';
import Tooltip from '@root/components/Tooltip/Tooltip';
import { Checker, ConnectionTask, TooltipMessage } from '@root/interfaces/connection.interface';
import { useTranslation } from 'react-i18next';
import { User } from '@root/interfaces/user.interface';
import { MergeOf } from '@root/interfaces/utils.interface';

interface Props {
  task: ConnectionTask;
  stepNumber: number;
  checkers?: MergeOf<User, Checker>[];
}

const ConnectionTaskTooltip = ({ task, stepNumber, checkers }: Props) => {
  const { t } = useTranslation();

  switch (task.tooltipMessage) {
    case TooltipMessage.NotAuthorized:
      return (
        <Tooltip id={task.id}>
          {
            checkers?.length === 1 ? (
              <p className="text-dark font-size-12">
                {`${t(
                  'ConnectionTask.checker',
                  'Le compte suivant peut valider cette étape',
                )}: ${checkers[0].email}`}
              </p>
            ) : (
              <>
                <p className="text-dark font-size-12">
                  {`${t(
                    'ConnectionTask.checkers',
                    'Les comptes suivants peuvent valider cette étape',
                  )}: `}
                </p>
                <ul className="mt-2 ps-3 font-size-12">
                  {checkers?.map((checker) => (
                    <li key={checker.id}>
                      {checker.email}
                    </li>
                  ))}
                </ul>
              </>
            )
          }
        </Tooltip>
      );
    case TooltipMessage.MissingTasks:
      return (
        <Tooltip id={task.id}>
          <p className="text-dark font-size-12">
            {t(
              'ConnectionTask.missingValidTasks',
              'Toutes les tâches ne sont pas encore validées',
            )}
          </p>
        </Tooltip>
      );
    case TooltipMessage.ConditionNotFulfilled:
      return (
        <Tooltip id={task.id}>
          <p className="text-dark font-size-12">
            {t(
              'ConnectionTask.conditionNotFulfilled',
              'Les conditions pour valider cette étape ne sont pas remplies',
            )}
          </p>
        </Tooltip>
      );
    case TooltipMessage.PreviousStepNotValidated:
      return (
        <Tooltip id={task.id}>
          <p className="text-dark font-size-12">
            {t('ConnectionTask.previousStepNotValidated')}
          </p>
        </Tooltip>
      );
    case TooltipMessage.TaskNotReady:
      return (
        <Tooltip id={task.id}>
          <p className="text-dark font-size-12">
            {t(
              'ConnectionTask.TaskNotReady',
              "Cette tâche ne peut être renseignée qu'après validation de l'étape {{stepNumber}}",
              { stepNumber: stepNumber - 1 },
            )}
          </p>
        </Tooltip>
      );
    case TooltipMessage.StepInactive:
      return (
        <Tooltip id={task.id}>
          <p className="text-dark font-size-12">
            {t(
              'ConnectionTask.stepInactive',
            )}
          </p>
        </Tooltip>
      );
    default:
      return <> </>;
  }
};

ConnectionTaskTooltip.defaultProps = {
  checkers: [],
};

export default ConnectionTaskTooltip;
