import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Modal } from 'react-bootstrap';
import {
  Connection as IConnection,
  ConnectionStep,
  ConnectionStepStatus,
  ConnectionTask as IConnectionTask,
  ConnectionTaskTypes,
  TasksExternalData,
} from '@root/interfaces/connection.interface';
import AWContainer from '@root/components/AWContainer/AWContainer';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import FrameModal from '@provider/components/TaskModals/FrameModal/FrameModal';
import {
  getStepStatus,
} from '@provider/helpers/connection.helper';
import ModalContact from '@provider/components/TaskModals/ModalContact/ModalContact';
import ModalActivity from '@provider/components/TaskModals/ModalActivity/ModalActivity';
import FieldsModal from '@provider/components/TaskModals/FieldsModal/FieldsModal';
import SurveyModal from '@provider/components/TaskModals/SurveyModal/SurveyModal';
import { buildConnection, buildTasksExternalData } from '@provider/views/Connections/Connection/connection.builder';
import CardConnectionTask from '@root/components/Cards/CardConnectionTask/CardConnectionTask';
import { UserContext } from '@root/contexts/user.context';
import { PROVIDER_BASE_PATH } from '@root/helpers/constants.helper';

interface IModal {
  isOpen: boolean,
  isCloseButton?: boolean,
  title?: string,
  body?: React.ReactNode,
}

interface IAlert {
  show: boolean,
  message?: string,
  variant?: 'success' | 'danger',
}

const Connection = () => {
  const { t } = useTranslation();
  const { isLoading, waitWithLoad } = useLoadingPromise();
  const { id }: { id: string } = useParams();
  const { push, replace } = useHistory();
  const { user } = useContext(UserContext);

  const [connection, setConnection] = useState<IConnection>();
  const [tasksExternalData, setTasksExternalData] = useState<TasksExternalData>();
  const [alert, setAlert] = useState<IAlert>({ show: false });
  const [modal, setModal] = useState<IModal>({ isOpen: false });

  useEffect(() => {
    waitWithLoad((async () => {
      const connectionRes = await buildConnection(id);
      if (!connectionRes || connectionRes.provider_id !== user.currentEnterprise?.id) {
        replace(`${PROVIDER_BASE_PATH}/connections`);
      } else if (connectionRes) {
        const tasksExternalDataRes = await buildTasksExternalData(
          connectionRes.steps || [],
          connectionRes.provider_id,
        );
        setConnection(connectionRes);
        setTasksExternalData(tasksExternalDataRes);
      }
    })());
  }, [modal.isOpen, user.currentEnterprise?.id]);

  useEffect(() => {
    let timeout;
    if (alert.show) {
      timeout = setTimeout(() => {
        setAlert({ ...alert, show: false });
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [alert]);

  const handleClose = () => {
    setModal({ isOpen: false });
  };

  const handleTaskClick = (task: IConnectionTask, step: ConnectionStep) => {
    switch (task.content) {
      case ConnectionTaskTypes.DocumentType:
        setModal({
          isOpen: true,
          isCloseButton: true,
          title: t('FrameModal.uploadDocument', 'Téléverser votre document'),
          body: <FrameModal
            path={task.provider_data ? `/addworking/enterprise/${connection?.provider_id}/document/${task.provider_data}`
              : `/addworking/enterprise/${connection?.provider_id}/document/create?document_type=${task.customer_data}`}
          />,
        });
        break;

      case ConnectionTaskTypes.Fields:
        setModal({
          isOpen: true,
          isCloseButton: true,
          title: t('FieldsModal.enterpriseInfo', "Informations de l'entreprise"),
          body: <FieldsModal
            fields={task.customer_data?.split(';') || []}
            onClose={handleClose}
          />,
        });
        break;

      case ConnectionTaskTypes.Activity:
        setModal({
          isOpen: true,
          isCloseButton: true,
          title: t('ModalActivity.addDocument', 'Renseigner vos activités'),
          body: <ModalActivity
            id={connection?.provider_id || ''}
            initialActivities={tasksExternalData?.activities || []}
            onClose={handleClose}
          />,
        });
        break;

      case ConnectionTaskTypes.Contact:
        setModal({
          isOpen: true,
          isCloseButton: true,
          title: `${t('ModalContact.contact', 'Contact')} 
          ${tasksExternalData?.services?.find((s) => s.id === task.customer_data)?.label || ''}`,
          body: <ModalContact
            onClose={handleClose}
            connection={connection}
            task={task}
          />,
        });
        break;

      case ConnectionTaskTypes.Form:
        setModal({
          isOpen: true,
          isCloseButton: true,
          title: tasksExternalData?.surveys?.find((s) => s.id === task.customer_data)?.label,
          body: <SurveyModal
            onClose={handleClose}
            initialSurvey={tasksExternalData?.surveys?.find(
              (s) => s.id === task.customer_data,
            ) || {}}
            isSurveyEnabled={
              step.status === ConnectionStepStatus.InProgress
              || step.status === ConnectionStepStatus.Pending
            }
          />,
        });
        break;

      case ConnectionTaskTypes.BusinessCompliance:
        push(`/provider/v1/addworking/enterprise/${connection?.provider_id}/document#nav-documents-metier-tab`);
        break;

      case ConnectionTaskTypes.LegalCompliance:
        push(`/provider/v1/addworking/enterprise/${connection?.provider_id}/document#nav-documents-legaux-tab`);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <AWContainer isLoading={isLoading}>
        <AWContainer.Main
          title={t(
            'Connection.title',
            'Mise en relation avec {{customer}}',
            { customer: connection?.customer_name },
          )}
        >
          <p className="text-grey font-style-semibold">
            {t(
              'Connection.description',
              `Afin de finaliser votre mise en relation, 
              {{customer}} vous invite à compléter les éléments suivants.`,
              { customer: connection?.customer_name },
            )}
          </p>
          <p className="text-grey font-style-semibold mb-2">
            {t(
              'Connection.allStepsRequired',
              `Tous les éléments d'une même étape doivent être complétés
               afin que le client puisse la valider`,
            )}
          </p>
          {
            connection?.steps?.map((_step) => (
              <div key={_step.step}>
                <h2 className="text-dark my-4">
                  {`${t('Connection.step', 'Etape')} ${_step.step} : `}
                  {_step.visible ? (
                    <span
                      className="font-style-semibold"
                      style={{ color: getStepStatus(_step).color }}
                    >
                      {getStepStatus(_step).label}
                    </span>
                  ) : (
                    <span
                      className="font-style-semibold"
                    >
                      {t('Connection.notConcerned', 'Non concerné')}
                    </span>
                  )}
                </h2>
                {
                  _step.step_description && (
                    <p className="text-grey font-style-semibold mb-3">
                      {_step.step_description}
                    </p>
                  )
                }
                {_step.tasks.map((task) => (
                  <CardConnectionTask
                    key={task.id}
                    className="mb-3"
                    task={task}
                    actionText={t('Action.update')}
                    onClick={() => handleTaskClick(task, _step)}
                  />
                ))}
              </div>
            ))
          }
        </AWContainer.Main>
        <AWContainer.Side>
          {connection?.customer_logo && (
            <img
              src={connection.customer_logo}
              alt="client"
              className="connection__client-logo mb-4"
            />
          )}
          <p className="connection__client-description">
            {connection?.config_description}
          </p>
        </AWContainer.Side>
      </AWContainer>
      <Modal
        show={modal.isOpen}
        onHide={handleClose}
        size="lg"
        centered
      >
        {(modal.title || modal.isCloseButton) && (
          <Modal.Header closeButton={modal.isCloseButton}>
            <Modal.Title>{modal.title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          {modal.body}
        </Modal.Body>
      </Modal>
      <Alert
        show={alert.show}
        variant={alert?.variant}
        className="position-absolute bottom-0 end-0 m-4"
      >
        {alert.message}
      </Alert>
    </>
  );
};

export default Connection;
