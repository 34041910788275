import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import { queryBoySearch } from '@root/helpers/utils';
import { Contact, Service } from '@root/interfaces/contact.interface';
import { QueryboyOptions } from '@root/interfaces/queryboy.interface';

const baseUrl = `${process.env.REACT_APP_ENTERPRISE_API}/v1/enterprise/customer`;

export const getContacts = (options: QueryboyOptions): Promise<ApiResponse<Contact[]>> => (
  safeFetch(
    `${baseUrl}/contacts${queryBoySearch(options)}`,
  )
);

export const getService = (serviceId, options: QueryboyOptions): Promise<ApiResponse<Service>> => (
  safeFetch(
    `${baseUrl}/contacts/services/${serviceId}${queryBoySearch(options)}`,
  )
);
