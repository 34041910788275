import React from 'react';
import { ApprovalStep as IApprovalStep } from '@customer/interfaces/approval-step.interface';

interface Props {
  step: IApprovalStep;
}

/* eslint-disable */
const ApprovalStep = ({ step }: Props) => {
  return (
    <div className={`aw-step ${step.status}-step`}>
      <div className="aw-step__graphics">
        <div className="aw-step__line"></div>
        <div className="aw-step__circle"></div>
      </div>

      <div className="aw-step__content">{step.label}</div>
    </div>
  );
};
/* eslint-enable */
export default ApprovalStep;
