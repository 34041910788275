import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import AWConnectionModal from '@provider/components/AWConnectionModal/AWConnectionModal';
import {
  Connection as IConnection,
  // ConnectionFilter,
  ConnectionModal,
  ConnectionModalLoadingOption,
  ConnectionStatus,
} from '@root/interfaces/connection.interface';
import {
  getConnectionsByEnterpriseId,
} from '@provider/services/connection.service';
import { UserContext } from '@root/contexts/user.context';
import CardConnection from '@provider/components/CardConnection/CardConnection';
import AWContainer from '@root/components/AWContainer/AWContainer';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
// import ConnectionsFilters from '@provider/views/Connections/ConnectionsFilters';
import { getLogoFromEnterpriseId } from '@root/helpers/enterprise.helper';
import { AlertContext } from '@root/contexts/alert.context';
import { NotificationContext } from '@root/contexts/notifications.context';
import useSafeFetch, { useSafeFetchCallback } from '@root/hooks/useSafeFetch';
import { updateConnectionConfig } from '@root/api-configs/connection.api.config';

const Connections = () => {
  const { t } = useTranslation();
  const { setNotif } = useContext(AlertContext);
  const { push } = useHistory();
  const { user } = useContext(UserContext);
  const { setUnseenConnections } = useContext(NotificationContext);
  const { isLoading, waitWithLoad } = useLoadingPromise();

  const [connections, setConnections] = useState<IConnection[]>([]);
  const [filteredConnections, setFilteredConnections] = useState<IConnection[]>([]);
  const [connectionModal, setConnectionModal] = useState<ConnectionModal>();
  // const [filter, setFilter] = useState<ConnectionFilter>({});
  const [loadingOption, setLoadingOption] = useState<ConnectionModalLoadingOption>('');
  const getLogo = useSafeFetchCallback<string>(getLogoFromEnterpriseId);
  const updateConnection = useSafeFetch<IConnection>(updateConnectionConfig).callApi;

  useEffect(() => {
    (async () => {
      if (user.currentEnterprise?.id) {
        const connectionResponse = await waitWithLoad(
          getConnectionsByEnterpriseId(user.currentEnterprise.id),
        );
        if (connectionResponse.success) {
          setConnections(connectionResponse.data.sort((a, b) => (
            new Date(b.created_at as string).getTime() - new Date(a.created_at as string).getTime()
          )));
        }
      }
    })();
  }, [user.currentEnterprise?.id]);

  useEffect(() => {
    if (connections.length) {
      setFilteredConnections(connections);
    }
  }, [connections]);

  useEffect(() => {
    if (!isLoading) {
      setLoadingOption('');
    }
  }, [isLoading]);

  // const filterConnections = (_filter: ConnectionFilter) => {
  //   const tmpConnections = connections.filter((connection) => (
  //     connection.customer_name?.toLowerCase().includes(_filter.search || '')
  //     && (
  //       !_filter.statuses?.length
  //       || _filter.statuses.includes(connection.status as string)
  //     )
  //     && connection.provider_id === user.currentEnterprise?.id
  //   ));
  //   setFilteredConnections(tmpConnections);
  // };

  // useEffect(() => {
  //   filterConnections(filter);
  // }, [filter, connections, user.currentEnterprise]);

  const closeConnectionModal = () => setConnectionModal(undefined);

  const updateConnections = (connection: IConnection) => {
    const updatedConnections = connections.map((_connection) => (
      _connection.id === connection.id ? connection : _connection
    ));
    setConnections(updatedConnections);
    setUnseenConnections(updatedConnections);
  };

  const handleAccept = async () => {
    if (connectionModal?.id) {
      setLoadingOption('accept');
      const res = await waitWithLoad(
        updateConnection({
          connectionId: connectionModal.id,
          body: {
            status: ConnectionStatus.IN_PROGRESS,
          },
        }),
      );
      if (res?.success) {
        if (connectionModal.config_id) {
          updateConnections(res?.data);
          push(`/provider/connections/${connectionModal.id}`);
        } else {
          const validateRes = await updateConnection({
            connectionId: connectionModal.id,
            body: {
              status: ConnectionStatus.VALIDATED,
            },
          });
          if (validateRes?.success) {
            updateConnections({ ...connectionModal, status: ConnectionStatus.VALIDATED });
            setNotif({
              message: t(
                'Connections.youAreConnected',
                'Vous êtes en relation avec {{customer}}',
                { customer: connectionModal?.customer_name },
              ),
              variant: 'success',
            });
            setConnectionModal(undefined);
          }
        }
      }
    }
  };

  const handleDecline = async () => {
    if (connectionModal?.id) {
      setLoadingOption('decline');
      const res = await waitWithLoad(
        updateConnection({
          connectionId: connectionModal.id,
          body: {
            status: ConnectionStatus.DECLINED,
          },
        }),
      );
      if (res?.success) {
        updateConnections(res?.data);
        closeConnectionModal();
      }
    }
  };

  const handleShow = async () => {
    if (connectionModal?.id && connectionModal?.status === ConnectionStatus.UNSEEN) {
      const res = await updateConnection({
        connectionId: connectionModal.id,
        body: {
          status: ConnectionStatus.PENDING,
        },
      });
      if (res?.success) {
        updateConnections(res.data);
      }
    }
  };

  const onCardClick = (connection: IConnection) => async () => {
    if (
      connection.status === ConnectionStatus.UNSEEN
      || connection.status === ConnectionStatus.PENDING
    ) {
      const logo = await waitWithLoad(getLogo(connection.customer_id));
      setConnectionModal({
        enterpriseName: connection.customer_name,
        logo,
        message: connection.config_description,
        ...connection,
        withConfig: !!connection.config_id,
      });
    } else if (
      connection.status
      && [
        ConnectionStatus.DONE,
        ConnectionStatus.IN_PROGRESS,
        ConnectionStatus.VALIDATED,
      ].includes(connection.status)
      && connection.config_id
    ) {
      push(`/provider/connections/${connection.id}`);
    }
  };

  // const getStatusOptions = (): `${ConnectionStatus}`[] => {
  //   const options = new Set<`${ConnectionStatus}`>();
  //   connections.forEach((connection) => {
  //     if (connection.status) {
  //       options.add(connection.status);
  //     }
  //   });
  //   return Array.from(options);
  // };

  const isConnectionRefused = (connection: IConnection) => (
    connection.status === ConnectionStatus.ABORTED
    || connection.status === ConnectionStatus.DECLINED
    || connection.status === ConnectionStatus.REJECTED
  );

  return (
    <>
      <AWConnectionModal
        logo={connectionModal?.logo}
        message={connectionModal?.message}
        enterpriseName={connectionModal?.enterpriseName}
        show={!!connectionModal}
        onHide={closeConnectionModal}
        loadingOption={loadingOption}
        onShow={handleShow}
        onAccept={handleAccept}
        onDecline={handleDecline}
        withConfig={connectionModal?.withConfig}
      />
      <AWContainer isLoading={isLoading}>
        <AWContainer.Main
          title={t('Connections.title', 'Mises en relation')}
        >
          {
            filteredConnections?.length ? (
              filteredConnections.map((connection) => (
                <CardConnection
                  key={connection.id}
                  connection={connection}
                  isConnectionRefused={isConnectionRefused(connection)}
                  onClick={onCardClick(connection)}
                />
              ))
            ) : (
              <p className="text-grey mt-5 mx-5 text-center">
                {t(
                  'Connections.noConnection',
                  `Retrouvez ici les entreprises qui vous ont
                   invité à entrer en relation, que ce soit par une
                   invitation directe ou par un processus permettant 
                   de collecter des informations avant de prendre votre décision.`,
                )}
              </p>
            )
          }
        </AWContainer.Main>
        <AWContainer.Side />
      </AWContainer>
    </>
  );
};

export default Connections;
