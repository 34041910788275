import React, { useRef, useState, useEffect } from 'react';
import {
  Col, Container, Form, FormControl, InputGroup, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Editor } from '@tinymce/tinymce-react';
import { Block } from '@customer/interfaces/block.interface';
import AWButton from '@root/components/AWButtons/AWButton';

interface Props {
  block: Block | null;
  saveChanges: (content:string, description:string, type:string) => void;
  cancelChanges: () => void;
}

const BlockEditorForm = ({
  block,
  saveChanges,
  cancelChanges,
}: Props) => {
  const { t } = useTranslation();

  const editorRef = useRef<any>(null);

  const [description, setDescription] = useState<string>(block?.description || '');
  const [type, setType] = useState<string>(block?.type || 'clause');
  const [isDescriptionValid, setDescriptionValid] = useState<boolean>(false);

  const validateDescription = () => {
    setDescriptionValid(description.trim().length > 0);
  };

  useEffect(() => {
    if (block) {
      setDescription(block.description!);
      setType(block.type!);
      validateDescription();
    }

    return () => {
      setDescription('');
      setType('clause');
    };
  }, [block]);

  return (
    <Container>
      <InputGroup hasValidation className="mb-3">
        <InputGroup.Text>{t('Library.blockDescription', 'Description du bloc')}</InputGroup.Text>
        <FormControl
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onBlur={validateDescription}
          required
          isInvalid={!isDescriptionValid}
        />
        <FormControl.Feedback type="invalid">
          {t('Library.requiredField', 'Ce champ est obligatoire.')}
        </FormControl.Feedback>
      </InputGroup>

      <InputGroup className="mb-3">
        <InputGroup.Text>{t('Library.blockType', 'Type')}</InputGroup.Text>
        <Form.Select
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <option value="clause">{t('Library.clause', 'Clause')}</option>
        </Form.Select>

      </InputGroup>

      <div className="mb-3">
        <Editor
          tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
          onInit={(evt, editor) => {
            editorRef.current = editor;
          }}
          initialValue={block ? block.content! : `<p>${t('Library.startHere', 'Commencez-ici')}</p>`}
          init={{
            entity_encoding: 'raw',
            promotion: false,
            menubar: false,
            branding: false,
            elementpath: false,
            height: '80vh',
            toolbar: [
              { name: 'history', items: ['undo', 'redo'] },
              { name: 'styles', items: ['styles'] },
              { name: 'formatting', items: ['bold', 'italic'] },
              { name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'] },
              { name: 'indentation', items: ['outdent', 'indent'] },
            ],
          }}
        />
      </div>

      <Row className="mb-3">
        <Col xs="12" md="6" className="text-start mb-2">
          <AWButton onClick={cancelChanges}>{t('Action.cancel', 'Annuler')}</AWButton>
        </Col>
        <Col xs="12" md="6" className="text-end">
          <AWButton
            onClick={() => saveChanges(
              editorRef.current.getContent(),
              description,
              type,
            )}
            disabled={!isDescriptionValid}

          >
            {t(
              `${block ? 'Library.saveChanges' : 'Library.saveBlock'}`,
              `${block ? 'Enregistrer les modifications' : 'Enregistrer et créer le bloc'}`,
            )}
          </AWButton>
        </Col>
      </Row>
    </Container>
  );
};

export default BlockEditorForm;
