import React, {
  useEffect, useState, useContext, useMemo,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { ApprovalContext } from '@customer/contexts/approval.context';
import { Option } from '@root/interfaces/utils.interface';
import modelVariableRegex from '@customer/views/Approvals/NewApproval/NewApprovalSteps/utils/modelVariablesRegex';

const CheckStep = () => {
  const {
    modelSelected, modelVariableDictionary, setModelVariableDictionary,
  } = useContext(ApprovalContext);
  const [variables, setVariables] = useState<string[]>([]);
  const model = modelSelected.value;

  const modelVariables = useMemo(() => (model.match(modelVariableRegex.modelVariable)), [model]);

  const modelVariablesFormated = useMemo<Option[]>(() => {
    if (modelVariables) {
      return modelVariables?.map((el) => ({
        label: el.replace(modelVariableRegex.replaceVariable, ''),
        disabled: !!el.match(modelVariableRegex.selectedCustomerVariable),
        value: el.match(modelVariableRegex.selectedCustomerVariable) ? el : '',
      }));
    }
    return [];
  }, [modelVariables]);

  useEffect(() => {
    if (modelVariables?.length) {
      setVariables(modelVariablesFormated.map((el) => el.label));
    }
    if (!Object.keys(modelVariableDictionary).length) {
      const _modelVariableDictionary = modelVariablesFormated
        .reduce((acc: Record<string, Option>, el: Option) => {
          let label = el.label.replaceAll('_', ' ');
          label = label.charAt(0).toUpperCase() + label.slice(1);
          return {
            ...acc,
            [el.label]: { value: el.value, label, disabled: el.disabled },
          };
        }, {});
      setModelVariableDictionary(_modelVariableDictionary);
    }
  }, [modelVariables, modelVariableDictionary, modelVariablesFormated]);

  return (
    <div className="approvals-variables">
      <div className="approvals-variables__table">
        <div className="approvals-variables__table__header">
          <Row>
            <Col>Variables (techniques)</Col>
            <Col>Valeurs</Col>
            <Col>Labels</Col>
          </Row>
        </div>
        <div className="approvals-variables__table__body">
          {modelVariableDictionary
            ? variables?.map((variable) => (
              <Row
                key={modelVariableDictionary[variable].label}
                className="approvals-variables__table__body__row"
              >
                <Col xs={4} className="approvals-variables__table__body__case">
                  <span>{variable}</span>
                </Col>
                <Col xs={4} className="approvals-variables__table__body__case">
                  <Form.Group>
                    <Form.Control
                      defaultValue={
                          modelVariableDictionary[variable]?.disabled
                            ? 'Variable ST'
                            : modelVariableDictionary[variable].value
                        }
                      disabled={modelVariableDictionary[variable]?.disabled}
                      onChange={(event) => {
                        modelVariableDictionary[variable].value = event.target.value;
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={4} className="approvals-variables__table__body__case">
                  {modelVariableDictionary[variable].label}
                </Col>
              </Row>
            ))
            : ''}
        </div>
      </div>
    </div>
  );
};

export default CheckStep;
