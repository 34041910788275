import React from 'react';
import { useTranslation } from 'react-i18next';
import LabelValue from '@customer/components/LabelValue/LabelValue';
import { formatPhone, randString } from '@root/helpers/utils';
import { Representative } from '@root/interfaces/enterprise.interface';
import { Contact } from '@root/interfaces/contact.interface';
import { Connection } from '@root/interfaces/connection.interface';

interface Props {
  representatives: Representative[];
  phoneNumbers: string[];
  contacts?: Contact[];
  limitedContacts?: boolean;
  connection?: Connection;
  noConnectionContact?: boolean;
}

const commercial = 'commercial';
const security = 'Sécurité';

const EnterpriseContact = ({
  representatives,
  phoneNumbers,
  contacts,
  connection,
  limitedContacts,
  noConnectionContact,
}: Props) => {
  const { t } = useTranslation();

  const displayUser = (firstName?: string, lastName?: string, opt?: string) => (
    (`${firstName || ''} ${lastName || ''} ${opt ? ` - ${opt}` : ''}`).trim()
  );

  const displayContacts = (field: string) => (
    contacts?.length ? contacts?.filter((contact) => contact.label === field).map((c, i) => (
      <div key={c.id} className={i > 0 ? 'mt-2' : ''}>
        {displayUser(c.first_name, c.last_name)}
        <br />
        {c.email}
        <br />
        {formatPhone(c.phone_number || '')}
      </div>
    )) : '-'
  );

  return (
    <div className="enterprise-info">
      <LabelValue keyName={t('Enterprise.legalRepresentatives')}>
        {representatives?.length ? representatives.map((representative, i) => (
          <div key={representative.id || randString()} className={i > 0 ? 'mt-2' : ''}>
            {displayUser(
              representative.firstname,
              representative.lastname,
              representative.job_title,
            )}
            <br />
            {representative.email ?? null}
            {representative.email && representative.phone_numbers?.length ? ', ' : ''}
            {representative.phone_numbers?.length ? (
              representative.phone_numbers.map((pn) => formatPhone(pn)).join(', ')
            ) : null}
            <br />
          </div>
        )) : '-'}
      </LabelValue>
      <LabelValue keyName={t('Enterprise.phoneNumbers', 'Numéros de téléphone')}>
        {phoneNumbers?.length ? phoneNumbers.map((pn) => (
          <div key={randString()}>
            {formatPhone(pn)}
            <br />
          </div>
        )) : '-'}
      </LabelValue>
      {
        noConnectionContact ? null : (
          <LabelValue keyName={t('Enterprise.invitedContacts', 'Contacts invités à la mise en relation')}>
            {connection?.email ? connection?.email.split(';')?.map((email) => (
              <div key={email}>
                {email || ''}
                <br />
              </div>
            )) : '-'}
          </LabelValue>
        )
      }
      {
        limitedContacts ? null : (
          <>
            <LabelValue keyName={t('Enterprise.salesContacts', 'Contacts commerciaux')}>
              {displayContacts(commercial)}
            </LabelValue>
            <LabelValue keyName={t('Enterprise.securityContacts', 'Contacts sécurité')}>
              {displayContacts(security)}
            </LabelValue>
          </>
        )
      }
    </div>
  );
};

EnterpriseContact.defaultProps = {
  contacts: [],
  connection: {},
  limitedContacts: false,
  noConnectionContact: false,
};

export default EnterpriseContact;
