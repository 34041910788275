import React, { useContext, useEffect } from 'react';
import { convertModelDocumentFromStringToImg } from '@customer/views/Approvals/NewApproval/NewApprovalSteps/utils/convertHtmlToImg.service';
import { ApprovalProviderContext } from '@provider/contexts/approvalProvider.context';
import { mimeType } from '@root/helpers/utils';

const ApprovalPreview = () => {
  const { approvalToDisplay, modelSelected, pdfFilePath } = useContext(ApprovalProviderContext);
  let document = '';

  if (approvalToDisplay.mime_type === mimeType.html) {
    document = modelSelected;
  }
  if (approvalToDisplay.mime_type === mimeType.pdf) {
    document = pdfFilePath;
  }

  useEffect(() => {
    if (approvalToDisplay.mime_type === mimeType.html) {
      convertModelDocumentFromStringToImg(document, 'id-preview-temp-provider', 'id-preview-provider');
    }
  }, []);

  return (
    <div>
      {approvalToDisplay.mime_type === mimeType.html
        ? (
          <>
            <div
              id="id-preview-provider"
              className="approvalProviderPreview"
            />
            <div id="id-preview-temp-provider" />
          </>
        )
        : (
          <div className="preview__pdf">
            <iframe title="pdf preview" src={pdfFilePath} />
          </div>
        )}
    </div>
  );
};

export default ApprovalPreview;
