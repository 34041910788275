import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import partner from '@root/assets/employee_card_black.svg';
import { formatDate, formatSiret } from '@root/helpers/utils';
import AWLongCard from '@root/components/Cards/AWLongCard/AWLongCard';
import { ActivePartner } from '@root/interfaces/enterprise.interface';
import { AWColors } from '@root/interfaces/utils.interface';

interface Props {
  enterprise: ActivePartner,
  enterpriseType: 'provider' | 'customer',
  onClick?: () => void,
}

const CardEnterprise = ({
  enterprise: {
    legal_form,
    name,
    identification_number,
    legal_representative_name,
    contracts_count,
    business_total,
    business_validated_count,
    business_expired_soon,
    legal_total,
    legal_validated_count,
    legal_expired_soon,
    referenced_at,
    business_amount,
    last_contract_date,
    field_content,
    field_title,
    is_active,
  },
  enterpriseType,
  onClick,
}: Props) => {
  const { t } = useTranslation();

  const borderColor = useMemo(() => {
    if (
      legal_validated_count === legal_total
      && business_validated_count === business_total
      && legal_expired_soon === 0
      && business_expired_soon === 0
    ) return AWColors.Green;
    if (
      legal_validated_count === legal_total
      && business_validated_count === business_total
      && (legal_expired_soon !== 0 || business_expired_soon !== 0)
    ) return AWColors.Yellow;
    return AWColors.Red;
  }, []);

  return (
    <AWLongCard
      onClick={onClick}
      className={`mb-3 ${enterpriseType === 'provider' && !is_active ? 'opacity-50' : ''}`}
      borderColor={borderColor}
      icon={partner}
    >
      <Row>
        <Col xs={enterpriseType === 'provider' ? 4 : 6} className="d-flex flex-column justify-content-between">
          <h2 className="text-dark font-style-bold font-size-15 mb-0">
            {`${legal_form || ''} ${name}`}
          </h2>
          <p className="text-dark">
            {identification_number ? formatSiret(identification_number) : ''}
          </p>
          <p className="text-grey">
            {`${t('CardEnterprise.contact', 'Contact')}: ${legal_representative_name?.split('|').join(', ')} `}
          </p>
        </Col>
        <Col xs={enterpriseType === 'provider' ? 4 : 6} className="d-flex flex-column justify-content-between">
          {
            enterpriseType === 'provider' ? (
              <p className="text-grey">
                {`${t('CardEnterprise.partnerSince', 'Référencée depuis')}: `}
                <span className="text-dark">
                  {referenced_at ? formatDate(referenced_at) : '-'}
                </span>
              </p>
            ) : null
          }
          <p className="text-grey">
            {`${t('CardEnterprise.contracts', 'Contrats')}: `}
            <span className="text-dark font-style-bold">{`${contracts_count} `}</span>
            {
              contracts_count ? (
                <span className="text-dark">
                  {`(${t('CardEnterprise.last', 'dernier')}: ${last_contract_date ? formatDate(last_contract_date, 'MMMM YYYY') : '-'})`}
                </span>
              ) : null
            }
          </p>
          <p className="text-grey">
            {`${t('CardEnterprise.turnover', "Montant d'affaires")}: `}
            <span className="text-danger">
              {business_amount || '-'}
              &nbsp;
              €
            </span>
          </p>
        </Col>
        {
          enterpriseType === 'provider' ? (
            <Col xs="4" className="d-flex flex-column justify-content-between">
              <p className="text-grey">
                {`${t('CardEnterprise.legalConformity', 'Conformité légale')}: `}
                <span className="text-dark">
                  {legal_total === legal_validated_count
                    ? t('CardEnterprise.ok', 'ok') : (t('Status.inProgress'))}
                </span>
              </p>
              <p className="text-grey">
                {`${t('CardEnterprise.extendedConformity', 'Conformité étendue')}: `}
                <span className="text-dark">
                  {business_total === business_validated_count
                    ? t('CardEnterprise.ok', 'ok') : (t('Status.inProgress'))}
                </span>
              </p>
              {field_title ? (
                <p className="text-grey">
                  {field_title}
                  :&nbsp;
                  <span className="text-dark">{field_content ?? '-'}</span>
                </p>
              ) : null}
            </Col>
          ) : null
        }
      </Row>
    </AWLongCard>
  );
};

CardEnterprise.defaultProps = {
  onClick: () => { },
};

export default CardEnterprise;
