import React from 'react';

import { useHistory } from 'react-router-dom';
import chevron from '@root/assets/chevron-left.svg';

interface Props {
  children?: React.ReactNode;
  callback?: () => Promise<any>;
}

const ReturnButton = ({ children, callback }: Props) => {
  // Change page on click
  const history = useHistory();
  const changePage = async () => {
    if (callback) await callback();
    history.goBack();
  };

  return (
    <div className="d-flex ">
      <button
        type="button"
        onClick={changePage}
        className="return-button"
      >
        <div>
          <img src={chevron} alt="chevron left" />
          <div className="pl-2 d-inline-block return-button__text">
            {children}
          </div>
        </div>
      </button>
    </div>
  );
};

ReturnButton.defaultProps = {
  children: '',
  callback: () => Promise.resolve(),
};

export default ReturnButton;
