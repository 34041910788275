import React from 'react';
import {
  Col, Form, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Input from '@root/components/Input/Input';
import AWButton from '@root/components/AWButtons/AWButton';
import { AWColors } from '@root/interfaces/utils.interface';

interface Props {
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  handleFiltersSearch: (term:string) => void;
  searchTerm: string;
}

const BlockFilter = ({ setSearchTerm, handleFiltersSearch, searchTerm }: Props) => {
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    handleFiltersSearch(searchTerm);
  };

  const handleReset = () => {
    setSearchTerm('');
    handleFiltersSearch('');
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-4">

        <Input
          label={t('Library.blockDescription', 'Description')}
          type="text"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
      </Form.Group>
      <Row>
        <Col sm="12" className="d-flex">
          <Col sm="6">
            <AWButton>
              {t('Action.search', 'Rechercher')}
            </AWButton>
          </Col>

          <Col sm="6">
            <AWButton
              color={AWColors.Green}
              backgroundColor="white"
              onClick={handleReset}
            >
              {t('ContractFilters.reset', 'Réinitialiser')}
            </AWButton>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};

export default BlockFilter;
