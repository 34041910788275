import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import { queryBoySearch } from '@root/helpers/utils';
import {
  Department,
  Enterprise,
  Certification,
  Activity,
  BusinessTurnover,
  EmployeeNumber,
} from '@root/interfaces/enterprise.interface';
import { Field } from '@root/interfaces/field.interface';
import { ProposalResponse, Proposal } from '@customer/interfaces/proposal.interface';
import { QueryboyOptions } from '@root/interfaces/queryboy.interface';

const baseUrl = `${process.env.REACT_APP_ENTERPRISE_API}/v1/enterprise/customer`;

export const getEnterprises = (
  options = {},
): Promise<ApiResponse<Enterprise[]>> => (
  safeFetch(
    `${baseUrl}/enterprises${queryBoySearch(options)}`,
  )
);

export const getEnterprise = (
  identification_number: string,
  options = {},
): Promise<ApiResponse<Enterprise>> => (
  safeFetch(`${baseUrl}/enterprises/${identification_number}${queryBoySearch(options)}`)
);

export const createEnterprise = async (
  enterprise: Enterprise,
): Promise<ApiResponse<Enterprise>> => safeFetch(
  `${baseUrl}/enterprises`,
  {
    method: 'POST',
    body: JSON.stringify(enterprise),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
);

export const getCustomers = (
  identification_number: string,
  options = {},
): Promise<ApiResponse<Enterprise>> => (
  safeFetch(`${baseUrl}/enterprises/${identification_number}/customers${queryBoySearch(options)}`)
);

export const getAllDepartments = async (
  options: QueryboyOptions,
): Promise<ApiResponse<Department[]>> => (
  safeFetch(`${baseUrl}/enterprises/departments${queryBoySearch(options)}`)
);

export const getDepartments = async (
  identificationNumber: string,
  options: QueryboyOptions,
): Promise<ApiResponse<unknown[]>> => (
  safeFetch(`${baseUrl}/enterprises/${identificationNumber}/departments${queryBoySearch(options)}`)
);

export const getActivities = async (
  id: string,
  options: QueryboyOptions,
): Promise<ApiResponse<Activity[]>> => (
  safeFetch(`${baseUrl}/enterprises/${id}/activities${queryBoySearch(options)}`)
);

export const getField = async (
  fieldId: string,
): Promise<ApiResponse<Field>> => (
  safeFetch(`${baseUrl}/fields/${fieldId}`)
);

export const getCustomerCertifications = async (
  customerId: string,
  options: QueryboyOptions,
): Promise<ApiResponse<Certification[]>> => (
  safeFetch(`${baseUrl}/certifications/customers/${customerId}${queryBoySearch(options)}`)
);

export const getProposalResponse = async (
  id: string,
  options: QueryboyOptions,
): Promise<ApiResponse<ProposalResponse>> => (
  safeFetch(`${baseUrl}/proposal-responses/${id}${queryBoySearch(options)}`)
);

export const getProposal = async (
  id: string,
  options: QueryboyOptions,
): Promise<ApiResponse<Proposal>> => (
  safeFetch(`${baseUrl}/proposals/${id}${queryBoySearch(options)}`)
);

export const getBusinessTurnovers = async (
  enterpriseId: string,
  options: QueryboyOptions,
): Promise<ApiResponse<BusinessTurnover[]>> => (
  safeFetch(
    `${baseUrl}/enterprises/${enterpriseId}/turnovers${queryBoySearch(options)}`,
  )
);

export const getEmployeeNumbers = async (
  enterpriseId: string,
  options: QueryboyOptions,
): Promise<ApiResponse<EmployeeNumber[]>> => (
  safeFetch(
    `${baseUrl}/enterprises/${enterpriseId}/employees${queryBoySearch(options)}`,
  )
);
