import React from 'react';
import Skeleton from 'react-loading-skeleton';

const BONES = [[1, 2, 3], [4, 5, 6]];

const ConnectionSkeleton = () => (
  <div>
    <div className="text-dark font-size-21 my-4">
      <Skeleton />
    </div>
    {BONES.map((arr) => (
      <div key={JSON.stringify(arr)} className="mb-30">
        {
          arr.map((x) => (
            <Skeleton key={x.toString()} height="100px" className="mb-15" />
          ))
        }
      </div>
    ))}
  </div>
);

export default ConnectionSkeleton;
