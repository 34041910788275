import React from 'react';
import eye from '@root/assets/eye.svg';
import { getFile } from '@root/services/file.service';

interface Props {
  fileId: string,
}

const FileButton = ({ fileId }: Props) => {
  const getFileUrl = async () => {
    const urlResponse = await getFile(fileId);
    if (urlResponse.success) {
      const url = urlResponse.data.signedUrl;
      return url;
    }
    return null;
  };

  const goToFilePage = async () => {
    const url = await getFileUrl();
    if (url) window.open(url, '_blank');
  };

  return (
    <span
      onClick={goToFilePage}
      className="d-flex file-button"
    >
      {fileId || null}
      <img src={eye} alt="view file" />
    </span>
  );
};

export default FileButton;
