import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

import AppUserRoutes from '@user/routes/routes';
import AppProviderRoutes from '@provider/routes/routes';
import CustomerAppRoutes from '@customer/routes/routes';

import ProviderAppContextProvider from '@provider/contexts/providerApp.context';
import UserAppContextProvider from '@user/contexts/userApp.context';
import CustomerAppContextProvider from '@customer/contexts/customerApp.context';

const Routes = () => (
  <Switch>
    <Route path="/provider">
      <ProviderAppContextProvider>
        <AppProviderRoutes />
      </ProviderAppContextProvider>
    </Route>
    <Route path="/customer">
      <CustomerAppContextProvider>
        <CustomerAppRoutes />
      </CustomerAppContextProvider>
    </Route>
    <Route path="/">
      <UserAppContextProvider>
        <AppUserRoutes />
      </UserAppContextProvider>
    </Route>
  </Switch>
);

export default Routes;
