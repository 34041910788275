import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import { LocationFeatureQuery } from '@root/interfaces/mapbox.interface';

const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

/** Returns geographical data (feature) using Mapbox forward geocoding. */
const getFeature = async (query: LocationFeatureQuery) => {
  try {
    const geocoding = mbxGeocoding({ accessToken: mapboxToken });
    const res = await geocoding.forwardGeocode({
      countries: [query.country],
      types: ['place', query.type],
      query: query.content,
      // Only the most relevant place per query.
      limit: 1,
    }).send();

    // Result will always be first of array as we limit the query at 1.
    const feature = res.body.features[0];
    const { type, center, geometry } = feature;
    return { type, geometry, center };
  } catch (e) {
    throw new Error();
  }
};

const deg2rad = (deg) => deg * (Math.PI / 180);

/** Return the distance in kilometers between 2 points, using coordinates. */
const getDistanceFromLatLonInKm = (coords1: number[], coords2: number[]) => {
  const [lon1, lat1] = coords1;
  const [lon2, lat2] = coords2;
  const R = 6371; // Radius of the earth in kms.
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
    * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in kms.
  return d;
};

/** Formula to transform kilometers to pixels.
  * Used to update the map circle area from a location, as the user zooms in or out.
 */
const kmsToPixelsAtMaxZoom = (kms: number, latitude: number) => (
  (kms * 1000) / 0.075 / Math.cos((latitude * Math.PI) / 180)
);

export { getFeature, getDistanceFromLatLonInKm, kmsToPixelsAtMaxZoom };
