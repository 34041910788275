import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EnterpriseCreationContext } from '@root/contexts/enterpriseCreation.context';

const Error = () => {
  const { t } = useTranslation();
  const { enterprise } = useContext(EnterpriseCreationContext);

  return (
    <div className="h-100 w-50 mx-auto d-flex flex-column text-center justify-content-center">
      <div className="highlight-text--uppercased font-size-40 mb-5">
        {`${t('Error.Onboarding.thanks', 'Mercizzz')} !`}
      </div>
      <div className="text-dark font-style-bold font-size-24">
        {enterprise?.name}
      </div>
      <p className="font-size-16px mb-5">
        {t('Error.Onboarding.entityAlreadyExists', 'est déjà référencée sur AddWorking. Le rattachement nécessite des vérifications supplémentaires.')}
      </p>
      <p className="font-size-16px mb-5">
        {t('Error.Onboarding.supportTeamWillContactYou', 'Notre équipe support reviendra vers vous rapidement afin de finaliser ensemble votre inscription.')}
      </p>
    </div>
  );
};

export default Error;
