import React from 'react';
import { useTranslation } from 'react-i18next';
import FieldsDataForms from '@customer/components/ProviderInfo/FieldsDataForms';
import EnterpriseActivity from '@root/components/Enterprise/EnterpriseActivity';
import EnterpriseGeneral from '@root/components/Enterprise/EnterpriseGeneral';
import {
  Activity,
  BusinessTurnover,
  Certification,
  Department,
  EmployeeNumber,
  Enterprise,
} from '@root/interfaces/enterprise.interface';
import { Field } from '@root/interfaces/field.interface';
import { Option } from '@root/interfaces/utils.interface';
import { ComplianceCount, Partnership } from '@customer/interfaces/provider.interface';

interface Props {
  enterprise: Enterprise;
  businessTurnover: BusinessTurnover;
  employeeNumber: EmployeeNumber;
  complianceCount: ComplianceCount
  departments: Department[];
  activities: Activity[];
  certifications: Certification[];
  partnership: Partnership
  fields: Field[];
  customerFields: Field[];
  handleFieldChange: (fieldId: string) => (values: Option[] | Option) => Promise<void>;
}

const General = ({
  enterprise,
  businessTurnover,
  employeeNumber,
  complianceCount,
  departments,
  activities,
  certifications,
  partnership,
  fields,
  customerFields,
  handleFieldChange,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <EnterpriseGeneral
        enterprise={enterprise}
        businessTurnover={businessTurnover}
        employeeNumber={employeeNumber}
        complianceCount={complianceCount}
        partnership={partnership}
      />
      <h2 className="text-dark mt-5 mb-3">
        {t('Provider.activity', 'Activités')}
      </h2>
      <EnterpriseActivity
        departments={departments}
        activities={activities}
        certifications={certifications}
      />
      <h2 className="text-dark mt-5 mb-3">
        {t('Provider.personalized', 'Champs personnalisés')}
      </h2>
      <FieldsDataForms
        fields={fields}
        customerFields={customerFields}
        handleFieldChange={handleFieldChange}
      />
    </>
  );
};

export default General;
