import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import { values } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getAnswersCount as getAnswersCountService } from '@customer/services/survey.service';
import StatusTag, { Status, StatusColor } from '@customer/components/StatusTag/StatusTag';
import { Survey, SurveyAnswersCount } from '@root/interfaces/survey.interface';
import LineChart from '@customer/components/LineChart/LineChart';

interface Props {
  survey: Survey;
  linkTo?: string;
}

const CardSurvey = ({ survey, linkTo }: Props) => {
  const [answersCount, setAnswersCount] = useState<SurveyAnswersCount>({ count: 0, days: {} });
  const { t } = useTranslation();

  const getAnswersCount = async () => {
    if (survey.id) {
      const result = await getAnswersCountService(survey.id);
      if (result.success) {
        setAnswersCount(result.data as SurveyAnswersCount);
      }
    }
  };

  useEffect(() => {
    getAnswersCount();
  }, [survey]);

  const statusClassName = useMemo(
    () => {
      switch (survey?.status) {
        case Status.Launched:
          return 'card-survey--launched';
        case Status.Stopped:
          return 'card-survey--stopped';
        default:
          return 'card-survey--sketch';
      }
    },
    [survey.status],
  );

  const data: number[] = useMemo(
    () => (
      survey.status === Status.Sketch
        ? [0, 5, 10, 15, 16, 17, 10, 11, 18, 20]
        : values(answersCount.days)
    ),
    [survey, answersCount],
  );

  const todayCount = useMemo(() => (data[data.length - 1]), [data]);

  const Wrapper = useCallback(({ children }) => {
    if (linkTo) {
      return (
        <Link to={linkTo}>
          {children}
        </Link>
      );
    }
    return children;
  }, [linkTo]);

  return (
    <Wrapper>
      <div
        className={
          [
            'text-dark',
            'd-flex',
            'card-survey',
            statusClassName,
            linkTo && 'card-survey--hover',
            'my-3',
          ]
            .filter((word) => (!!word))
            .join(' ')
        }
      >
        <div className="py-4 fw-bold d-flex flex-column justify-content-between card-survey__content">
          <div className="d-flex">
            <h5 className="card-survey__label">{survey?.label}</h5>
            <div>
              <StatusTag status={survey.status || Status.Sketch} />
            </div>
          </div>
          <div>
            <p className="card-survey__description">{survey?.description}</p>
          </div>
          <div className="d-flex flex-row justify-content-between card-survey__details">
            {survey.status !== Status.Sketch && (
              <div className="d-flex flex-column">
                <p>
                  {answersCount.count}
                  &nbsp;
                  <span>{t('Answers.answerings', 'Répondant{{s}}', { s: answersCount.count > 1 ? 's' : '' }).toLowerCase()}</span>
                </p>
                <p>
                  <span>{t('Common.including', 'Dont').toLowerCase()}</span>
                  &nbsp;
                  {todayCount}
                  &nbsp;
                  <span>{t('Common.today', 'Aujourd\'hui').toLocaleLowerCase()}</span>
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="card-survey__chart pe-4 d-flex justify-content-end align-items-center">
          <LineChart
            data={data}
            color={survey.status ? StatusColor[survey.status] : StatusColor.sketch}
          />
        </div>
      </div>
    </Wrapper>
  );
};

CardSurvey.defaultProps = {
  linkTo: '',
};

export default CardSurvey;
