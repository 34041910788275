import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import arrow from '@root/assets/dropdown-arrow-grey.svg';
import redCross from '@root/assets/square-red-cross.svg';
import greenCheck from '@root/assets/square-green-check.svg';
import { toKey } from '@root/helpers/utils';
import { Option } from '@root/interfaces/utils.interface';

interface Props {
  items: Option[];
  value: Option;
  onSubmit?: (option?: string) => void;
  onChange?: (value: any) => void;
}

const DropdownMenu = ({
  items,
  value,
  onSubmit,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const [option, setOption] = useState<Option>(value);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleOption = (status: Option) => () => {
    setOption(status);
    setIsEditMode(true);
    if (onChange) {
      onChange(status);
    }
  };

  const handleSubmit = async () => {
    if (option && onSubmit) {
      onSubmit(option.value);
    }
  };

  const cancel = () => {
    setIsEditMode(false);
    setOption(value);
  };

  useEffect(() => {
    setOption(value);
    setIsEditMode(false);
  }, [value]);

  return (
    <div>
      <Dropdown className={`dropdown-container ${onSubmit ? 'dropdown-container__submit' : ''}`}>
        <Dropdown.Toggle
          variant="btn-sm"
          id="dropdown-basic"
          className="dropdown-list d-flex"
        >
          <span className="dropdown-title">
            {
              option?.label
              || t('Dropdown.select', 'Sélectionner une action')
            }
          </span>
          <span>
            <img src={arrow} alt="arrow down" className="arrow-dropdown" />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {items.map((item) => (
            <Dropdown.Item
              key={toKey(item)}
              onClick={handleOption(item)}
            >
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {onSubmit && isEditMode && (
        <span>
          <button
            onClick={handleSubmit}
            type="submit"
            className="no-button-style"
          >
            <img src={greenCheck} alt="change value" className="validate-icon" />
          </button>
          <button
            type="button"
            onClick={cancel}
            className="no-button-style"
            aria-label="cancel"
          >
            <img src={redCross} alt="change value" className="return-icon" />
          </button>
        </span>
      )}
    </div>

  );
};

DropdownMenu.defaultProps = {
  onChange: null,
  onSubmit: null,
};

export default DropdownMenu;
