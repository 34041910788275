import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MenuTabsContext } from '@root/contexts/menuTabs.context';
import { randString } from '@root/helpers/utils';

interface Props {
  icon: string,
  title: string,
  linkTo: string,
  disabled: boolean,
  count?: number,
}

const SideBarMenuItem = ({
  icon,
  title,
  linkTo,
  disabled,
  count,
}: Props) => {
  const { activeTab, setActiveTab, isV1Path } = useContext(MenuTabsContext);

  const isMenuActive = () => {
    if (isV1Path) {
      return activeTab.title === title && !disabled;
    }
    return activeTab.linkTo === linkTo && activeTab.title === title && !disabled;
  };

  return (
    <Link
      to={{ pathname: linkTo, state: { key: randString() } }}
      className={`${disabled ? 'content-disabled' : ''}`}
    >
      <li
        key={title}
        className="submenu-holder"
        onClick={() => ((!disabled) ? setActiveTab({ title, linkTo }) : '')}
        aria-hidden="true"
      >
        <div className={`opacity-45 submenu${isMenuActive() ? '--active' : ''}`}>
          <div className="icon-center">
            <img
              src={icon}
              alt={title}
              height="16px"
              width="16px"
            />
          </div>
          <p className="subtitle ms-3">
            {title}
            {count ? (
              <div className="subtitle__count-container">
                <span className="subtitle__count-icon">
                  {count}
                </span>
              </div>
            ) : ''}
          </p>
        </div>
      </li>
    </Link>
  );
};

SideBarMenuItem.defaultProps = {
  count: 0,
};

export default SideBarMenuItem;
