import {
  getEnterpriseMembers,
} from '@customer/services/provider.service';
import {
  Representative,
} from '@customer/interfaces/provider.interface';
import { ApiResponse } from '@root/helpers/response-handler';
import { User, Member } from '@root/interfaces/user.interface';
import { getUser } from '@root/services/user.service';

export const fetchUsersWithRights = async (
  identification_number?: string,
): Promise<Representative[]> => {
  if (!identification_number) return [];
  const userResponse: ApiResponse = await getEnterpriseMembers(identification_number, {
    fields: 'user_id,job_title,is_legal_representative,is_signatory,is_admin',
  });
  if (userResponse.success) {
    const users = userResponse.data as Member[];
    users.filter((user) => {
      if (user.is_admin || user.is_legal_representative || user.is_signatory) {
        return user;
      }
      return '';
    });
    const promises: Promise<User>[] = [];
    for (let i = 0; i < users.length; i += 1) {
      const id = users[i].user_id;
      const { is_admin } = users[i];
      const { is_signatory } = users[i];
      const { is_legal_representative } = users[i];
      if (id && (is_admin === true || is_legal_representative === true || is_signatory === true)) {
        promises.push(getUser(id) as Promise<User>);
      }
    }
    const members = await Promise.all(promises);
    return users.map((user, idx) => ({ ...user, ...members[idx] }));
  }
  return [];
};

export default fetchUsersWithRights;
