import { Option } from '@root/interfaces/utils.interface';
import i18n from '@root/locales/i18n';

export const marketTypeOptions: Option[] = [
  { value: 'all', label: i18n.t('ApprovalsFilters.all', 'Tous') },
  { value: 'public', label: i18n.t('ApprovalsFilters.publicMarketType', 'Marché public') },
  { value: 'private', label: i18n.t('ApprovalsFilters.privateMarketType', 'Marché privé') },
];

export const statusOptions: Option[] = [
  { value: 'unsigned', label: i18n.t('ApprovalsFilters.unsignedStatusOption', 'Aucune signature') },
  {
    value: 'waitingMyValidation',
    label: i18n.t('ApprovalsFilters.waitingMyValidation', 'En attente de ma validation'),
  },
  {
    value: 'providerSignatureMissing',
    label: i18n.t('ApprovalsFilters.providerSignatureMissing', 'En attente de ma signature'),
  },
  { value: 'signed', label: i18n.t('ApprovalsFilters.signedStatusOption', 'Signée') },
];
