import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ForgotPassword from '@user/components/ForgotPassword/ForgotPassword';
import { UserContext } from '@root/contexts/user.context';
import { LoginRouterContext, Views } from '@user/contexts/loginRouter.context';
import PasswordReset from '@user/components/PasswordReset/PasswordReset';
import ChooseProfile from '@user/components/ChooseProfile/ChooseProfile';
import EmailContainer from '@user/components/EmailContainer/EmailContainer';
import PasswordContainer from '@user/components/PasswordContainer/PasswordContainer';
import { REDIRECT_PARAM } from '@root/helpers/constants.helper';
import CognitoRegister from '@user/components/CognitoRegister/CognitoRegister';

const Login = () => {
  const { setRedirect } = useContext(UserContext);
  const { currentView } = useContext(LoginRouterContext);
  const [element, setElement] = useState(<EmailContainer />);
  const { search } = useLocation();

  useEffect(() => {
    const redirection = new URLSearchParams(search).get(REDIRECT_PARAM);
    if (redirection) setRedirect(redirection, true);
  }, []);

  useEffect(() => {
    switch (currentView) {
      case Views.Recovery:
        setElement(<ForgotPassword />);
        break;
      case Views.Update:
        setElement(<PasswordReset />);
        break;
      case Views.Profile:
        setElement(<ChooseProfile />);
        break;
      case Views.CognitoRegister:
        setElement(<CognitoRegister />);
        break;
      case Views.FirstConnection:
        setElement(<CognitoRegister firstConnection />);
        break;
      case Views.Email:
        setElement(<EmailContainer />);
        break;
      case Views.Password:
        setElement(<PasswordContainer />);
        break;
      default:
        setElement(<EmailContainer />);
        break;
    }
  }, [currentView]);

  return (
    <div>
      {element}
    </div>
  );
};

export default Login;
