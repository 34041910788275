import { Auth } from 'aws-amplify';
import { SessionUser } from '@root/interfaces/cognito.interface';
import avatar from '@root/assets/user.png';
import { REDIRECT_PARAM } from '@root/helpers/constants.helper';

const { REACT_APP_HOST_URL } = process.env;

interface Session {
  isLogged: boolean;
  token?: string;
  error?: unknown;
  user?: SessionUser;
}

export const getUserFromSession = (session): SessionUser => ({
  firstname: session.idToken.payload.given_name,
  lastname: session.idToken.payload.family_name,
  picture: session.idToken.payload.picture || avatar,
  email: session.idToken.payload.email,
  v1token: localStorage.getItem('v1token') || '',
  userId: '',
  enterprises: [],
});

export const currentSession = async (): Promise<Session> => {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const user = getUserFromSession(session);
    localStorage.setItem('token', token);

    return {
      isLogged: !!(user.email && user.v1token),
      user,
      token,
    };
  } catch (error) {
    return {
      isLogged: false,
      error,
    };
  }
};

export const logOut = async (error = false): Promise<void> => {
  localStorage.removeItem('token');
  localStorage.removeItem('v1token');
  localStorage.setItem(REDIRECT_PARAM, window.location.pathname);
  sessionStorage.removeItem('connection_modal_seen');
  await Auth.signOut();
  if (error) {
    window.open(REACT_APP_HOST_URL, '_self');
  } else {
    window.location.reload();
  }
};
