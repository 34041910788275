import React, { useMemo } from 'react';
import {
  Col,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AWButton from '@root/components/AWButtons/AWButton';
import user from '@root/assets/user.png';
import { AWColors } from '@root/interfaces/utils.interface';

type EventCallback = () => void;

interface Props {
  show: boolean;
  enterpriseName?: string;
  logo?: string;
  message?: string;
  loadingOption?: string;
  onHide?: EventCallback;
  onShow?: EventCallback;
  onAccept?: EventCallback;
  onDecline?: EventCallback;
  withConfig?: boolean;
}

const ACCEPT = 'accept';

const AWConnectionModal = ({
  enterpriseName,
  logo,
  message,
  show,
  loadingOption,
  onHide,
  onShow,
  onAccept,
  onDecline,
  withConfig,
}: Props) => {
  const { t } = useTranslation();

  const buttonLabel = useMemo(() => (
    withConfig ? t('Action.start') : t('Action.accept')
  ), [withConfig]);

  return (
    <Modal
      show={show}
      onShow={onShow}
      onHide={onHide}
      animation={false}
      centered
      backdrop={!!loadingOption && 'static'}
    >
      <Modal.Header
        className="d-flex flex-column justify-content-center align-items-center"
      >
        {
          logo ? <img src={logo} alt="companyLogo" className="collab-modal__avatar my-3" /> : <div className="collab-modal__avatar my-3" />
        }
        <p className="font-size-18 font-style-semibold">
          {enterpriseName}
        </p>
        <p className="text-grey">
          {withConfig ? t(
            'Collaboration.invitesYouToCollaborate',
            'Vous invite à suivre un processus de mise en relation',
          ) : t(
            'Collaboration.invitesYouToConnect',
            'Vous invite une mise en relation',
          )}
        </p>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center my-2">
          {message}
        </p>
      </Modal.Body>
      <Modal.Footer className="d-block">
        <Row className="my-2">
          <Col md="6" className="mb-3 mb-md-0">
            <AWButton
              backgroundColor="transparent"
              className="border"
              color={AWColors.Grey}
              type="button"
              disabled={!!loadingOption}
              onClick={onDecline}
            >
              {
                loadingOption === 'decline' ? (
                  <Spinner animation="border" variant="dark" size="sm" />
                ) : (
                  t('Action.decline')
                )
              }
            </AWButton>
          </Col>
          <Col md="6">
            <AWButton
              type="button"
              disabled={!!loadingOption}
              onClick={onAccept}
            >
              {
                (loadingOption === ACCEPT) ? (
                  <Spinner animation="border" variant="white" size="sm" />
                ) : buttonLabel
              }

            </AWButton>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

AWConnectionModal.defaultProps = {
  enterpriseName: '',
  logo: user,
  message: '',
  loadingOption: '',
  onHide: () => { },
  onShow: () => { },
  onAccept: () => { },
  onDecline: () => { },
  withConfig: true,
};

export default AWConnectionModal;
