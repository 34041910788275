import { ApiResponse, handleResponse } from '@root/helpers/response-handler';
import { queryToString } from '@root/helpers/utils';
import {
  Enterprise,
  EnterprisePappers,
  EnterprisePappersSearch,
  LegalForm,
} from '@root/interfaces/enterprise.interface';
import { PappersResponse } from '@root/interfaces/pappers.interface';

const {
  REACT_APP_API_PAPPERS_SEARCH,
  REACT_APP_API_PAPPERS_V2,
  REACT_APP_API_KEY_PAPPERS,
} = process.env;

const FOREIGN_ENTERPRISE = 'Société étrangère non immatriculée au RCS';

export const LEGAL_FORMS_REFS = [
  {
    pappers: 'Entrepreneur individuel',
    addworking: 'ei',
  },
  {
    pappers: "SA à conseil d'administration (s.a.i.)",
    addworking: 'SAI',
  },
];

export const enterpriseBlank: Enterprise = {
  identification_number: '',
  legal_form: '',
  name: '',
  address: {
    address: '',
    additionnal_address: '',
    zipcode: '',
    town: '',
  },
};

const isLegalFormValid = (legalForms: string[], pappersLegalForm: string) => {
  const specialFormat = LEGAL_FORMS_REFS.find((lfr) => lfr.pappers === pappersLegalForm);
  if (specialFormat) {
    return legalForms.includes(specialFormat.addworking);
  }
  return legalForms.includes(pappersLegalForm?.split(',')[0]?.toLowerCase() || '');
};

const concatSearchResult = (
  search: EnterprisePappersSearch,
  legalForms: LegalForm[],
): EnterprisePappers[] => {
  const searches: EnterprisePappers[] = [
    ...search.resultats_nom_entreprise,
    ...search.resultats_siren,
    ...search.resultats_siret,
  ];
  // Don't select companies without a siret or closed company
  const identificationNumbers: string[] = searches
    .filter((enterprise) => (
      enterprise.forme_juridique
      && enterprise.forme_juridique !== FOREIGN_ENTERPRISE
      && (enterprise.nom_entreprise || enterprise.denomination)
      && enterprise.siege.adresse_ligne_1
      && enterprise.siege.code_postal
      && enterprise.siege.ville
      && enterprise.siege.siret
      && !enterprise.date_cessation
      && isLegalFormValid(
        legalForms.map((lf) => lf.name || ''),
        enterprise.forme_juridique,
      )
    ))
    .map((enterprise) => enterprise.siege?.siret);
  // [ ...Array.from(new Set(array)) ] #=> remove all duplicated values
  return [...Array.from(new Set(identificationNumbers))]
    .map((element) => searches.find((enterprise) => enterprise?.siege?.siret === element)) as
    EnterprisePappers[];
};

export const searchCompaniesFromPappers = async (
  search: string,
  legalForms: LegalForm[],
): Promise<ApiResponse<EnterprisePappers[]>> => {
  const query = new URLSearchParams({
    q: search,
    longueur: '100',
    cibles: 'nom_entreprise,siren,siret',
  });
  const apiResponse = await handleResponse<EnterprisePappersSearch>(
    fetch(`${REACT_APP_API_PAPPERS_SEARCH?.replace('?q=', '')}?${query.toString()}`),
  );
  return apiResponse.transform<EnterprisePappers[]>((enterprises) => (
    Object.keys(enterprises).length
      ? concatSearchResult(enterprises, legalForms) : []
  ));
};

export const getEstablishments = async (
  siren: string,
): Promise<ApiResponse<PappersResponse>> => (
  handleResponse(
    fetch(
      `${REACT_APP_API_PAPPERS_V2}/entreprise${queryToString({
        siren,
        api_token: REACT_APP_API_KEY_PAPPERS,
      })}`,
    ),
  )
);
