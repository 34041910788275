import React, {
  useEffect, useState, useContext, useMemo,
} from 'react';
import { ApprovalProviderContext } from '@provider/contexts/approvalProvider.context';
import {
  Col, Form, Modal, Row,
} from 'react-bootstrap';
import { Option } from '@root/interfaces/utils.interface';
import modelVariableRegex from '@customer/views/Approvals/NewApproval/NewApprovalSteps/utils/modelVariablesRegex';
import AWButton from '@root/components/AWButtons/AWButton';
import { mimeType } from '@root/helpers/utils';
import { useTranslation } from 'react-i18next';
import { updateApproval, updateApprovalVariables } from '@root/services/approval.service';
import { ApprovalStatusEnum, ApprovalVariable } from '@root/interfaces/approval.interface';

const ApprovalVariables = () => {
  const { t } = useTranslation();
  const {
    approvalToDisplay,
    modelSelected,
    setModelSelected,
    modelVariableDictionary,
    setModelVariableDictionary,
  } = useContext(ApprovalProviderContext);
  const [variables, setVariables] = useState<string[]>([]);
  const [modal, setModal] = useState<{
    isOpen: boolean;
    title?: string;
  }>({ isOpen: false });

  let model = modelSelected;
  const modelVariables = useMemo(() => (model.match(modelVariableRegex.modelVariable)), [model]);

  const modelVariablesFormated = useMemo<Option[]>(() => {
    if (modelVariables) {
      return modelVariables?.map((el) => ({
        label: el.replace(modelVariableRegex.replaceVariable, ''),
        disabled: !el.match(modelVariableRegex.selectedProviderVariable),
        value: '',
      }));
    }
    return [];
  }, [modelVariables]);

  const setVariablesValue = () => {
    const approvalId = approvalToDisplay.id;
    const body: ApprovalVariable[] = [];
    const newModelVariablesFormated: Option[] = [];
    modelVariablesFormated.forEach((variable) => {
      newModelVariablesFormated.push({
        label: variable.label,
        value: modelVariableDictionary[variable.label].value,
      });
    });
    newModelVariablesFormated.forEach((variable) => {
      body.push({ variable: variable.label, value: variable.value });
      model = model.replace(`{{1.${variable.label}}}`, variable.value);
    });
    setModelSelected(model);
    updateApprovalVariables(body, approvalId);
    updateApproval({
      id: approvalToDisplay.id,
      status: ApprovalStatusEnum.WAITING_CUSTOMER_VALIDATION,
    }, approvalToDisplay.id)
      .then((res) => {
        if (res.success) {
          setModal({ isOpen: true, title: 'Validation' });
        } else {
          setModal({ isOpen: true, title: 'Erreur' });
        }
      });
  };

  // modal handling functions
  const closeModal = () => setModal({ isOpen: false });

  useEffect(() => {
    if (approvalToDisplay.mime_type === mimeType.html) {
      const modelVariablesProvider = modelVariablesFormated.filter((el) => el.disabled);
      if (modelVariablesProvider?.length) {
        setVariables(modelVariablesProvider.map((el) => el.label));
      }
      if (!Object.keys(modelVariableDictionary).length) {
        const _modelVariableDictionary = modelVariablesProvider
          .reduce((acc: Record<string, Option>, el: Option) => {
            let label = el.label.replaceAll('_', ' ');
            label = label.charAt(0).toUpperCase() + label.slice(1);
            return {
              ...acc,
              [el.label]: { value: '', label, disabled: !el.disabled },
            };
          }, {});
        setModelVariableDictionary(_modelVariableDictionary);
      }
    }
  }, [modelVariables, modelVariableDictionary, modelVariablesFormated]);

  return (
    <div className="approvals-variables">
      <div className="approvals-variables">
        {approvalToDisplay.mime_type === mimeType.html && variables.length
          ? (
            <div className="approvals-variables__table">
              <div className="approvals-variables__table__header">
                <Row>
                  <Col>{t('Approvals.variables', 'Variables (techniques)')}</Col>
                  <Col>{t('Approvals.valeurs', 'Valeurs')}</Col>
                  <Col>{t('Approvals.labels', 'Labels')}</Col>
                </Row>
              </div>
              <div className="approvals-variables__table__body">
                {modelVariableDictionary
                  ? variables?.map((variable) => (
                    <Row
                      key={modelVariableDictionary[variable].label}
                      className="approvals-variables__table__body__row"
                    >
                      <Col xs={4} className="approvals-variables__table__body__case">
                        <span>{variable}</span>
                      </Col>
                      <Col xs={4} className="approvals-variables__table__body__case">
                        <Form.Group>
                          <Form.Control
                            defaultValue={modelVariableDictionary[variable].value}
                            onChange={(event) => {
                              modelVariableDictionary[variable].value = event.target.value;
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={4} className="approvals-variables__table__body__case">
                        {modelVariableDictionary[variable].label}
                      </Col>
                    </Row>
                  ))
                  : ''}
              </div>
              <div className="approvals-variables__table__button">
                <AWButton marginTop="1em" onClick={() => { setVariablesValue(); }}>Valider</AWButton>
              </div>
            </div>
          )
          : (
            <div className="approvals-variables__message">
              {t('Approvals.variablesMessage', "Aucune variable à remplir pour cette demande d'agrément")}
            </div>
          )}
      </div>
      <Modal show={modal.isOpen} onHide={closeModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="approvals-variables__modal">
            {modal.title === 'Validation' ? (
              <>
                {t('Approvals.variablesModal', 'Vos variables ont bien été renseignées')}
              </>
            )
              : (
                <>
                  {t('Approvals.informationErrorModal', 'Une erreur est survenue')}
                </>
              )}
          </div>
        </Modal.Body>
      </Modal>
    </div>

  );
};

export default ApprovalVariables;
