import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import { Connection } from '@root/interfaces/connection.interface';
import { queryBoySearch } from '@root/helpers/utils';
import { QueryboyOptions } from '@root/interfaces/queryboy.interface';

const baseUrl = `${process.env.REACT_APP_ENTERPRISE_API}/v1/enterprise/provider`;

export const getConnectionsByEnterpriseId = (
  providerId: string,
): Promise<ApiResponse<Connection[]>> => (
  safeFetch(`${baseUrl}/enterprises/${providerId}/connections`)
);

export const getConnections = (options: QueryboyOptions): Promise<ApiResponse<Connection[]>> => (
  safeFetch(`${baseUrl}/connections${queryBoySearch(options)}`)
);

export const getConnection = (id: string): Promise<ApiResponse<Connection>> => (
  safeFetch(`${baseUrl}/connections/${id}`)
);

export const createField = (
  fieldId: string,
  body: { provider_id: string, content: string },
): Promise<ApiResponse> => (
  safeFetch(`${baseUrl}/fields/${fieldId}`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
);
