import { CountryCode } from '@root/interfaces/utils.interface';

export interface Address {
  address: string;
  additionnal_address: string;
  zipcode: string;
  town: string;
  country?: string;
}

export interface Representative {
  id?: string;
  firstname?: string;
  lastname?: string;
  job_title?: string;
  email?: string;
  phone_numbers?: string[];
  data?: {
    firstname?: string;
    lastname?: string;
    email?: string;
  }
  user_id?: string;
  is_legal_representative?: boolean;
  enterprise_id?: string;
  enterprise_name?: string;
}

export interface Enterprise {
  id?: string;
  identification_number?: string;
  legal_form?: string;
  legal_form_id?: string;
  name?: string;
  address?: Address;
  is_vendor?: boolean;
  is_customer?: boolean;
  country?: CountryCode;
  logo_id?: string;
  website?: string;
  has_website?: boolean;
  commercial_brochure?: string;
  has_commercial_brochure?: boolean;
  departments?: string[];
  main_activity_code?: string;
  activity?: string;
  registration_town?: string;
  contractualization_language?: string;
  tax_identification_number?: string;
  is_v2_compatible?: boolean;
  representatives?: Representative[];
  phone_numbers?: string[];
  job_title?: string;
}

interface SiegePappers {
  siret: string;
  siret_formate: string;
  nic: string;
  numero_voie: number;
  indice_repetition?: string;
  type_voie: string;
  libelle_voie: string;
  complement_adresse: string;
  adresse_ligne_1: string;
  adresse_ligne_2: string;
  code_postal: string;
  ville: string;
  latitude?: number;
  longitude?: number;
}

export interface EnterprisePappers {
  siren: string;
  siren_formate: string;
  diffusable: boolean;
  nom_entreprise: string;
  personne_morale: false;
  denomination?: string;
  nom: string;
  prenom: string;
  sexe: string;
  siege: SiegePappers;
  villes: string[];
  code_naf: string;
  libelle_code_naf: string;
  domaine_activite: string;
  conventions_collectives: string[];
  date_creation: string;
  date_creation_formate: string;
  entreprise_employeuse: number;
  entreprise_cessee: number;
  date_cessation?: string;
  categorie_juridique: string;
  forme_juridique: string;
  tranche_effectif?: string | number;
  effectif: string;
  effectif_min: number;
  effectif_max: number;
  annee_effectif: number;
  statut_rcs: string;
  chiffre_affaires?: number;
  resultat?: string | number;
  effectifs_finances?: string | number;
  annee_finances?: string | number;
  mention: string;
  greffe?: string;
}

export interface EnterprisePappersSearch {
  statusCode?: number;
  resultats_nom_entreprise: EnterprisePappers[];
  resultats_siren: EnterprisePappers[];
  resultats_siret: EnterprisePappers[];
}

interface EstablishmentChild {
  siret: string,
  date: string,
  transfert_siege: boolean;
  continuite_economique: boolean;
}

export interface Establishment {
  siret: string;
  siret_formate: string;
  nic: string;
  numero_voie?: string;
  indice_repetition?: string;
  type_voie?: string;
  libelle_voie?: string;
  complement_adresse?: string;
  adresse_ligne_1?: string;
  adresse_ligne_2?: string;
  code_postal?: string;
  ville?: string;
  pays?: string;
  code_pays?: string;
  latitude?: number;
  longitude?: number;
  code_naf?: string;
  libelle_code_naf?: string;
  etablissement_employeur: boolean;
  effectif?: string;
  effectif_min?: number;
  effectif_max?: number;
  tranche_effectif?: string;
  annee_effectif?: string;
  date_de_creation?: string;
  etablissement_cesse: boolean;
  date_cessation?: string;
  domiciliation?: string;
  siege: boolean;
  enseigne?: string;
  nom_commercial?: string;
  successeurs?: EstablishmentChild[];
  predecesseurs?: EstablishmentChild[];
}

export interface EnterpriseHasMember {
  identification_number: string,
  user_id: string,
  is_admin: boolean,
  enterprise_id?: string;
  name?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
}

export interface Customer {
  customer_identification_number: string,
  customer_name: string,
}

export interface ComplianceCount {
  provider_id: string;
  legal_total: number;
  business_total: number;
  legal_validated: number;
  business_validated: number;
}

export interface CustomerCount {
  customers: number;
}

export interface SkillCount {
  provider_id: string;
  customer_id: string;
  skills: number;
  total_skills: number;
}

export interface EnterpriseCompletion {
  id: string;
  legal_form: string;
  enterprise_name: string;
  identification_number: string;
  registration_town: string;
  main_activity_code: string;
  activity: string;
  field_activity: string;
  employees_count: string;
  address: string;
}

export interface Partner {
  customer_id: string,
  vendor_id: string,
  created_at: Date,
  updated_at: Date,
  activity_starts_at: Date,
  activity_ends_at: Date,
  is_connected: boolean,
}

export interface Department {
  id?: string;
  insee_code?: string;
  slug_name?: string;
  display_name?: string;
  prefecture?: string;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

export interface Activity {
  activity_id?: string,
  activity: string,
  source?: string,
  published?: boolean,
}

export interface BusinessTurnover {
  id?: string;
  year?: number;
  enterprise_id?: string;
  official_amount?: number;
  declarative_amount?: number;
  currency?: string;
  no_activity?: boolean;
  updated_at?: string;
  created_at?: string;
}

export interface EmployeeNumber {
  id?: string;
  enterprise_id?: string;
  year?: number;
  official_range_id?: string;
  official_min?: number;
  official_max?: number;
  declarative_range_id?: string;
  declarative_min?: number;
  declarative_max?: number;
  updated_at?: string;
  created_at?: string;
}

export interface EmployeeNumberRange {
  id?: string;
  min?: number;
  max?: number;
  created_at?: Date;
  updated_at?: Date;
}

export interface Certification {
  certification_id?: string;
  label?: string;
  provider_id?: string;
  customer_id?: string;
  certification_created_at?: Date;
  created_at?: Date;
  certification_updated_at?: Date;
  started_at?: Date;
  proof_file_id?: string;
  status?: string;
}

export interface PappersResponse {
  forme_juridique: string;
  nom_entreprise: string;
  denomination: string;
  etablissements: Establishment[];
  greffe?: string;
  domaine_activite?: string;
}

export interface LegalForm {
  id?: string;
  country?: string,
  name?: string;
  display_name?: string;
}

export interface LegalForms {
  fr?: LegalForm[],
  de?: LegalForm[],
  be?: LegalForm[],
}

export interface Skill {
  id?: string;
  name?: string;
}

export interface PhoneNumber {
  number: string;
}

export interface ActivePartner {
  id?: string;
  customer_id?: string;
  provider_id?: string;
  identification_number?: string;
  legal_form_code?: string;
  legal_form?: string;
  name?: string;
  referenced_at?: Date;
  legal_representative_name?: string;
  legal_representative_id?: string;
  legal_validated_count?: number;
  legal_expired_soon?: number;
  business_validated_count?: number;
  business_expired_soon?: number;
  legal_total?: number;
  business_total?: number;
  contracts_count?: number;
  last_contract_date?: Date;
  business_amount?: number;
  field_title?: string;
  field_content?: string;
  is_active?: boolean;
}

export enum EnterpriseFields {
  LegalFormId = 'legal_form_id',
  Name = 'name',
  Country = 'country',
  IdentificationNumber = 'identification_number',
  AddressAddress = 'address.address',
  AddressTown = 'address.town',
  AddressZipcode = 'address.zipcode',
  AddressAdditionnal = 'address.additionnal_address',
}

export enum CreationStep {
  Add = 'add',
  Role = 'role',
  Success = 'success',
  Error = 'error',
}
