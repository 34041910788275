import { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export enum Apps {
  User = 'user',
  Provider = 'provider',
  Customer = 'customer',
}

export type IApp = `${Apps}`;

const useCurrentApp = () => {
  const [app, setApp] = useState<IApp>();
  const { pathname } = useLocation();

  const isApp = useCallback((_app: IApp, _pathname?: string) => {
    const path = _pathname || pathname;
    return (
      (_app === Apps.Provider && path.startsWith(`/${Apps.Provider}`))
      || (_app === Apps.Customer && path.startsWith(`/${Apps.Customer}`))
      || (
        _app === Apps.User
        && !path.startsWith(`/${Apps.Customer}`)
        && !path.startsWith(`/${Apps.Provider}`)
      )
    );
  }, [pathname]);

  const getApp = useCallback(() => {
    if (isApp(Apps.Provider)) return Apps.Provider;
    if (isApp(Apps.Customer)) return Apps.Customer;
    return Apps.User;
  }, [app, isApp]);

  useEffect(() => {
    setApp(getApp());
  }, [pathname]);

  return { isApp, app, getApp };
};

export default useCurrentApp;
