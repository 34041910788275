import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '@root/routes/ProtectedRoute';
import Connections from '@provider/views/Connections/Connections';
import Connection from '@provider/views/Connections/Connection/Connection';

const ConnectionRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={path} exact>
        <Connections />
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/:id`} exact>
        <Connection />
      </ProtectedRoute>
    </Switch>
  );
};

export default ConnectionRoutes;
