import { CUSTOMER_BASE_PATH, PROVIDER_BASE_PATH } from '@root/helpers/constants.helper';

const PARAM = '#param';

export const getV2Redirection = (
  path: string,
  currentEnterpriseId?: string,
  params: string[] | RegExpMatchArray | null = [],
): string | null => {
  const replaceParams = (str: string) => {
    let i = 0;
    return str.split('/').filter((x) => !!x).reduce((acc: string, el: string) => {
      let element = el;
      if (el === PARAM && params?.length) {
        element = params[i];
        i += 1;
      }
      return `${acc}/${element}`;
    }, '');
  };

  const firstParam = params?.length ? params[0] : '';

  const isMyEnterprise = (id: string) => id === currentEnterpriseId;

  const redirections = [
    {
      v1: `${CUSTOMER_BASE_PATH}/v1/addworking/enterprise/${PARAM}/vendor`,
      v2: `${CUSTOMER_BASE_PATH}/providers`,
    },
    {
      v1: `${PROVIDER_BASE_PATH}/v1/dashboard`,
      v2: `${PROVIDER_BASE_PATH}`,
    },
    {
      v1: `${CUSTOMER_BASE_PATH}/v1/enterprise/${PARAM}`,
      v2: `${CUSTOMER_BASE_PATH}/enterprises/${firstParam}`,
      v2Alt: `${CUSTOMER_BASE_PATH}/providers/${firstParam}`,
      isAlt: !isMyEnterprise(firstParam),
    },
    {
      v1: `${PROVIDER_BASE_PATH}/v1/enterprise/${PARAM}`,
      v2: `${PROVIDER_BASE_PATH}/enterprises/${firstParam}`,
      v2Alt: `${PROVIDER_BASE_PATH}/customers/${firstParam}`,
      isAlt: !isMyEnterprise(firstParam),
    },
    {
      v1: '/v1/profile/customers',
      v2: `${PROVIDER_BASE_PATH}/customers`,
    },
  ];
  const redirection = redirections.find((p) => replaceParams(p.v1) === path);
  if (!redirection) return null;
  if (!redirection.v2Alt) return redirection.v2;
  return redirection.isAlt ? redirection.v2Alt : redirection.v2;
};
