import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import { Field } from '@root/interfaces/field.interface';

const { REACT_APP_ENTERPRISE_API } = process.env;

export const getField = async (
  fieldId: string,
): Promise<ApiResponse<Field>> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/provider/fields/${fieldId}`)
);
