import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AWButton from '@root/components/AWButtons/AWButton';
import { EMAIL_REGEX } from '@root/helpers/patterns';

interface Props {
  onSubmit?: (email: string) => void;
  existingEmails?: string[];
  defaultValue?: string;
}

const EmailForm = ({
  onSubmit,
  existingEmails,
  defaultValue,
}: Props) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(defaultValue || '');
  const [errorMessage, setErrorMessage] = useState('');

  const isEmailValid = () => {
    if (!EMAIL_REGEX.test(email)) {
      setErrorMessage(t('Errors.emailFormat', 'Merci de renseigner une adresse email valide'));
      return false;
    }
    if (existingEmails?.some((_email) => _email === email)) {
      setErrorMessage(t('Errors.emailTaken', 'Cette adresse email est déjà utilisée'));
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEmailValid() && onSubmit) {
      onSubmit(email);
      setEmail('');
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="mb-2">
      <Form.Group>
        <Form.Label>
          {`${t('Common.emailAddress', 'Adresse email')}:`}
        </Form.Label>
        <div className="d-flex justify-content-between">
          <Form.Control
            disabled={!!defaultValue}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {!defaultValue ? (
            <AWButton className="ms-4" disabled={!email}>
              {t('Common.add', 'Ajouter')}
            </AWButton>
          ) : null}
        </div>
      </Form.Group>
      <small className="text-danger">{errorMessage}</small>
    </Form>
  );
};

EmailForm.defaultProps = {
  defaultValue: '',
  onSubmit: () => { },
  existingEmails: [],
};

export default EmailForm;
