import React, { useCallback } from 'react';
import { AWColors } from '@root/interfaces/utils.interface';
import { Spinner } from 'react-bootstrap';

interface Props {
  /** Function used on button click */
  onClick?: (e?: any) => void;
  /** Text inside the button */
  children: React.ReactNode;
  /** Background color of the button */
  backgroundColor?: string;
  /** Type of button (submit, button...) */
  type?: 'button' | 'reset' | 'submit';
  /** Margin Top  */
  marginTop?: string;
  /** Margin Bottom  */
  marginBottom?: string;
  /** Text color inside the button */
  color?: string;
  /** Other classname to add if necessary */
  className?: string;
  /** Optional aria-label */
  label?: string;
  /** Optional button disabled */
  disabled?: boolean;
  noFlex?: boolean;
  containerClassname?: string;
  isLoading?: boolean;
  loaderColor?: string;
}

/**
 * AWButton is a button who allows to do an action depending of his type
 *  (ex: submit) and his function OnClick..
 * @version 1
 * @since 7/10/2021
 */

const AWButton = ({
  onClick,
  children,
  backgroundColor,
  type,
  marginTop,
  marginBottom,
  color,
  className,
  label,
  disabled,
  noFlex,
  containerClassname,
  isLoading,
  loaderColor,
}: Props) => {
  const FlexBox = useCallback(({ children: _children }) => {
    if (noFlex) return _children;
    return (
      <div className={`d-flex justify-content-center ${containerClassname}`}>
        {_children}
      </div>
    );
  }, [noFlex]);

  return (
    <FlexBox>
      <button
        className={`aw-button ${className}`}
        style={{
          backgroundColor,
          marginTop,
          marginBottom,
          color,
          opacity: disabled ? '0.8' : '1',
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
        // eslint-disable-next-line
        type={type}
        onClick={onClick}
        disabled={disabled}
        aria-label={label}
      >
        {
          isLoading ? (
            <Spinner animation="border" variant={loaderColor} size="sm" />
          ) : (
            children
          )
        }
      </button>
    </FlexBox>
  );
};

AWButton.defaultProps = {
  backgroundColor: AWColors.Green,
  color: 'white',
  type: 'submit',
  marginTop: '',
  marginBottom: '',
  className: '',
  label: '',
  disabled: false,
  onClick: () => { },
  noFlex: false,
  containerClassname: '',
  isLoading: false,
  loaderColor: 'white',
};

export default AWButton;
