import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Block as IBlock } from '@customer/interfaces/block.interface';
import AWButton from '@root/components/AWButtons/AWButton';
import GridIcon from '@root/assets/burger-menu.svg';
import ListIcon from '@root/assets/list.png';

interface Props {
  handleBlockCreate: () => void;
  isViewList: boolean;
  setViewList: (isViewList: boolean) => void;
  blocks: IBlock[];
  handleBlockUpdate: (id: string) => void;
  deleteBlock: (blockId: string) => void;
  BlockComponent: React.FC<{
    block: IBlock;
    onUpdate: (id: string) => void;
    onDelete: (blockId: string) => void;
  }>;
}

const BlockList = ({
  handleBlockCreate,
  isViewList,
  setViewList,
  blocks,
  handleBlockUpdate,
  deleteBlock,
  BlockComponent,
}:Props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Row className="align-items-center mb-3">
        <Col xs="12" sm="3">
          <AWButton onClick={handleBlockCreate}>{t('Library.createBlock', 'Nouveau bloc')}</AWButton>
        </Col>
        <Col xs="12" sm={{ span: 1, offset: 8 }}>
          <img
            alt="switch-view"
            src={isViewList ? GridIcon : ListIcon}
            height="20"
            width="20"
            onClick={() => setViewList(!isViewList)}
            className="float-end pointer"
          />
        </Col>
      </Row>

      <Row className="mt-1">
        {blocks.map((block) => (
          <BlockComponent
            key={block.id}
            block={block}
            onUpdate={handleBlockUpdate}
            onDelete={deleteBlock}
          />
        ))}
      </Row>
    </Container>

  );
};

export default BlockList;
