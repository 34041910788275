import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { UserContext } from '@root/contexts/user.context';
import useLoadingPromise from '@root/hooks/useLoadingPromise';

import AWLinkButton from '@root/components/AWButtons/AWLinkButton';
import AWContainer from '@root/components/AWContainer/AWContainer';
import CardConnection from '@customer/components/CardConnection/CardConnection';
import {
  Connection,
  // ConnectionConfig,
  // ConnectionFilter,
  ConnectionStatus,
} from '@root/interfaces/connection.interface';
// import ConnectionsFilters from '@customer/views/Connections/ConnectionsFilters';
import {
  initConnections,
  // filterConnections,
} from '@customer/views/Connections/connections.builder';
import RecipientsInvitation from '@customer/views/Connections/RecipientsInvitation';

const Connections = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { isLoading, waitWithLoad } = useLoadingPromise();
  const { push } = useHistory();

  const [connections, setConnections] = useState<Connection[]>([]);
  // const [configOptions, setConfigOptions] = useState<ConnectionConfig[]>([]);
  const [filteredConnections, setFilteredConnections] = useState<Connection[]>([]);
  // const [filter, setFilter] = useState<ConnectionFilter>({});
  const [selectedConnection, setSelectedConnection] = useState<Connection>({});

  useEffect(() => {
    (async () => {
      if (user.currentEnterprise?.id) {
        const {
          connections: _connections,
          // connectionConfig: _connectionConfig,
        } = await waitWithLoad(initConnections(user.currentEnterprise?.id));
        setConnections(_connections);
        // setConfigOptions(_connectionConfig);
      }
    })();
  }, []);

  useEffect(() => {
    if (connections.length) {
      setFilteredConnections(connections);
    }
  }, [connections]);

  // useEffect(() => {
  //   filterConnections(connections, filter);
  // }, [filter]);

  // const getStatusOptions = (): `${ConnectionStatus}`[] => {
  //   const options = new Set<`${ConnectionStatus}`>();
  //   connections.forEach((connection) => {
  //     if (connection.status) {
  //       options.add(connection.status);
  //     }
  //   });
  //   return Array.from(options);
  // };

  const isConnectionInactive = (_connection: Connection) => (
    _connection.status === ConnectionStatus.ABORTED
    || _connection.status === ConnectionStatus.DECLINED
    || _connection.status === ConnectionStatus.REJECTED
  );

  const handleCardClick = (connection) => {
    if (!isConnectionInactive(connection)) {
      push(`/customer/connections/${connection.id}`);
    }
  };

  const handleArrowClick = (connection) => {
    if (connection.status === ConnectionStatus.UNSEEN){
      setSelectedConnection(connection);
    }
  };

  return (
    <AWContainer
      isLoading={isLoading}
    >
      <AWContainer.Main
        title={t('Connections.title', 'Mises en relation')}
        titleSide={(
          <AWLinkButton
            href="/customer/connections/new"
            className="bg-white text-dark"
          >
            {t('Connections.createConnection', 'Créer une mise en relation')}
          </AWLinkButton>
        )}
      >
        {
          filteredConnections?.length ? (
            filteredConnections.map((connection) => (
              <CardConnection
                key={connection.id}
                connection={connection}
                isConnectionInactive={isConnectionInactive(connection)}
                onClick={() => handleCardClick(connection)}
                onClickArrow={() => handleArrowClick(connection)}
                isActivedArrow={connection.status === ConnectionStatus.UNSEEN}
              />
            ))
          ) : (
            <p className="text-grey mt-5 mx-5 text-center">
              {t(
                'Connections.noConnection',
                `Retrouvez ici les entreprises que vous avez invitées 
                à entrer en relation avec la vôtre, que ce soit par une
                invitation directe ou par un processus vous permettant 
                de collecter des informations avant de prendre votre décision.`,
              )}
            </p>
          )
        }
      </AWContainer.Main>
      <AWContainer.Side
        title={(selectedConnection?.email?.length
          ? t('InviteRecipientForm.resendInvitations')
          : '')}
      >
        {
            selectedConnection?.email?.length
              ? (
                <RecipientsInvitation
                  connection={selectedConnection}
                />
              )
              : <> </>
          }
      </AWContainer.Side>
    </AWContainer>
  );
};

export default Connections;
