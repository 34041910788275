/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import ContentBox from '@provider/components/ContentBox/ContentBox';

const Forbidden = () => {
  const { t } = useTranslation();
  return <ContentBox title={t('Alert.forbidden', "L'accès à ce contenu n'est pas autorisé")} />;
};

export default Forbidden;
