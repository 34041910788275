import React, { ReactNode } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import arrow from '@root/assets/dropdown-arrow-grey.svg';

interface Props {
  children: ReactNode,
  icon: string,
  onClick?: () => void,
  onClickArrow?: () => void,
  isActivedArrow?: boolean
  className?: string,
  borderColor?: string,
  disabled?: boolean,
}

const AWLongCard = ({
  children,
  icon,
  onClick,
  onClickArrow,
  isActivedArrow,
  className,
  borderColor,
  disabled,
}: Props) => (
  <Container
    className={`${disabled ? 'content-disabled ' : ''}aw-long-card p-0 rounded ${className}`}
  >
    <Row className="g-0 rounded-end">
      <Col
        xs="2"
        className="aw-long-card__picture rounded-start"
        style={{ borderLeft: `solid 4px ${borderColor}` }}
      >
        <img
          alt="card-icon"
          src={icon}
          height="50"
          width="50"
        />
      </Col>
      <Col
        xs="9"
        className="aw-long-card__info d-flex justify-content-end align-items-center"
        onClick={onClick}
      >
        <div
          className="flex-1"
        >
          {children}
        </div>
      </Col>
      {!disabled
        ? (
          <Col
            xs="1"
            onClick={onClickArrow}
            className="aw-long-card__arrow rounded-end d-flex justify-content-center align-items-center hover-block"
            disabled={!isActivedArrow}
          >
            <img
              className="ml-15 mr-5 "
              alt="details"
              height="20px"
              width="20px"
              src={arrow}
            />
          </Col>
        ) : <> </> }
    </Row>
  </Container>
);

AWLongCard.defaultProps = {
  onClick: () => { },
  onClickArrow: () => {},
  className: '',
  borderColor: '#333',
  disabled: false,
  isActivedArrow: false,
};

export default AWLongCard;
