import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import { Contact, Service } from '@root/interfaces/contact.interface';
import { queryBoySearch } from '@root/helpers/utils';
import { QueryboyOptions } from '@root/interfaces/queryboy.interface';

const baseUrl = `${process.env.REACT_APP_ENTERPRISE_API}/v1/enterprise/provider`;

export const createContact = (contact: Contact): Promise<ApiResponse> => (
  safeFetch(
    `${baseUrl}/contacts`,
    {
      method: 'POST',
      body: JSON.stringify(contact),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )
);

export const getContacts = (options: QueryboyOptions): Promise<ApiResponse<Contact[]>> => (
  safeFetch(
    `${baseUrl}/contacts${queryBoySearch(options)}`,
  )
);

export const getService = (serviceId, options: QueryboyOptions): Promise<ApiResponse<Service>> => (
  safeFetch(
    `${baseUrl}/contacts/services/${serviceId}${queryBoySearch(options)}`,
  )
);

export const deleteContact = (contactId: string): Promise<ApiResponse> => (
  safeFetch(
    `${baseUrl}/contacts/${contactId}`,
    { method: 'DELETE' },
  )
);
