import { HookApiConfig } from '@root/interfaces/api-response.interface';

const baseUrl = `${process.env.REACT_APP_ENTERPRISE_API}/v1/enterprise/#userType`;

export const getConnectionsByEnterpriseIdConfig: HookApiConfig = {
  url: `${baseUrl}/enterprises/#enterpriseId/connections`,
};

export const getConnectionsConfig: HookApiConfig = {
  url: `${baseUrl}/connections`,
};

export const getConnectionsSearchConfig: HookApiConfig = {
  url: `${baseUrl}/connections/search`,
};

export const updateConnectionConfig: HookApiConfig = {
  url: `${baseUrl}/connections/#connectionId`,
  method: 'PATCH',
};

export const getConnectionConfig: HookApiConfig = {
  url: `${baseUrl}/connections/#connectionId`,
};

export const getConnectionCheckersConfig: HookApiConfig = {
  url: `${baseUrl}/connections/#connectionId/checkers`,
};

export const getConnectionTasksOwnersConfig: HookApiConfig = {
  url: `${baseUrl}/connections/#connectionId/tasks/owners`,
};

export const getConnectionValidatorsConfig: HookApiConfig = {
  url: `${baseUrl}/connections/configs/#configId/validators`,
};

export const getConnectionConfigsConfig: HookApiConfig = {
  url: `${baseUrl}/connections/configs`,
};

export const updateConnectionStatusConfig: HookApiConfig = {
  url: `${baseUrl}/connections/#connectionId/status/#status`,
  method: 'PUT',
};

export const createConnectionConfig: HookApiConfig = {
  url: `${baseUrl}/connections`,
  method: 'POST',
};
