export enum TabApprovalProviderKeywordRoutes {
  INFOS = 'infos',
  PREVIEW = 'previsualisation',
  SIGNATURE = 'signature',
  SIGNATAIRES = 'signataires',
}

export enum TabApprovalProviderKeyword {
  INFOS = 'INFOS',
  PREVIEW = 'PRÉVISUALISATION',
  SIGNATURE = 'SIGNATURE',
  SIGNATAIRES = 'SIGNATAIRES',
}
