import { fetchLegalForm, fetchSkills } from '@customer/helpers/provider.helper';
import { Department, LegendreProvider } from '@customer/interfaces/legendre.interface';
import { TemplateCallback } from '@customer/interfaces/list.interface';
import { getDepartments } from '@customer/services/provider.service';
import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import { getStatus, queryBoySearch } from '@root/helpers/utils';
import { QueryboyOptions } from '@root/interfaces/queryboy.interface';

const LIST_FIELDS = [
  'id',
  'enterprise_name',
  'enterprise_identification_number',
  'enterprise_contractualization_language',
  'status',
  'vendor_id',
  'availability',
  'enterprise_legal_form_id',
];

const buildProvider = (
  provider: LegendreProvider,
  dependencies: any[],
  callback?: TemplateCallback,
): LegendreProvider => {
  const [skills, legalForms] = dependencies;
  const legalFormDisplayName = (legalForms.name).toUpperCase();
  const result = {
    ...provider,
    skills,
    legalForm: legalFormDisplayName,
    statusLabel: provider.status
      ? (getStatus(provider.status.toString())).label
      : getStatus('0').label,
  };
  return callback ? callback(result) : result;
};

const fetchProvidersDependencies = (providersList: LegendreProvider[]) => {
  const promises: any = [];
  for (let i = 0; i < providersList.length; i += 1) {
    const provider: LegendreProvider = providersList[i];
    if (provider.enterprise_identification_number) {
      promises.push(
        Promise.all([
          fetchSkills(
            provider.enterprise_identification_number,
            {
              fields: 'id,name',
            },
          ),
          fetchLegalForm(
            provider.enterprise_legal_form_id as string,
            {
              fields: 'name,display_name',
            },
          ),
        ]),
      );
    }
  }
  return Promise.all(promises);
};

const getProviderFromList = async (identification_number: string): Promise<ApiResponse> => (
  safeFetch(`${process.env.REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/lists${queryBoySearch({
    fields: [...LIST_FIELDS, 'customer_id', 'user_id'],
    search: { identification_number },
  })}`)
);

const fetchProviderFromList = async (identification_number: string): Promise<LegendreProvider> => {
  const providerResponse: ApiResponse = await getProviderFromList(identification_number);
  if (providerResponse.success) {
    return providerResponse.data as LegendreProvider;
  }
  return { enterprise_identification_number: '' };
};

const getList = async (
  id: number,
  opts: {
    page?: number;
    identification_number?: string
  },
): Promise<ApiResponse> => (
  safeFetch(`${process.env.REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/lists/${id}${queryBoySearch({
    fields: LIST_FIELDS,
    page_size: 25,
    page: opts.page,
  })}`)
);

const fetchList = async (
  listId: number,
  opts: { page?: number },
): Promise<LegendreProvider[]> => {
  const listResponse: ApiResponse = await getList(listId, opts);
  if (listResponse.success) {
    return listResponse.data as LegendreProvider[];
  }
  return [];
};

export const fetchListProvider = async (
  identification_number: string,
): Promise<LegendreProvider> => {
  const provider: LegendreProvider = await fetchProviderFromList(identification_number);
  if (provider) {
    const deps = await fetchProvidersDependencies([provider]);
    return buildProvider(provider, deps[0]);
  }
  return { enterprise_identification_number: identification_number };
};

export const fetchProviders = async (
  listId: number,
  page = 1,
  callback?: TemplateCallback,
): Promise<LegendreProvider[]> => {
  const list: LegendreProvider[] = await fetchList(listId, { page });
  const providersList = list.filter((provider: LegendreProvider) => (
    !!provider.enterprise_identification_number
  ));
  const deps = await fetchProvidersDependencies(providersList);
  return providersList
    .map((provider: LegendreProvider, index: number) => (
      buildProvider(provider, deps[index], callback)
    ));
};

export const fetchDepartments = async (
  identification_number: string,
  options: QueryboyOptions,
): Promise<Department[]> => {
  const departmentResponse: ApiResponse = await getDepartments(identification_number, options);
  if (departmentResponse.success) {
    return departmentResponse.data as Department[];
  }
  return [];
};
