export interface UserInvitation {
  id?: string;
  type?: string;
  status?: string;
  expired_at?: Date;
  email?: string;
  enterprise_guest_id?: string;
  enterprise_host_id?: string;
  user_host_id?: string;
  created_at?: string;
  updated_at?: string;
}

export enum InvitationStatus {
  PENDING = 'pending',
  VALIDATED = 'validated',
  REJECTED = 'rejected',
  ACCEPTED_IF_IS_MEMBER = 'accepted_if_is_member',
}

export enum InvitationStatusToInt {
  PENDING = 1,
  VALIDATED = 2,
  REJECTED = 3,
}
