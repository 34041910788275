import React from 'react';
import { Block as IBlock } from '@customer/interfaces/block.interface';
import {
  Card, Badge, Col,
} from 'react-bootstrap';
import EditIcon from '@root/assets/icon_modif.svg';
import DeleteIcon from '@root/assets/trash.svg';

interface Props {
  block: IBlock;
  onUpdate: (id: string) => void;
  onDelete: (id: string) => void;
}

const Block = ({
  block, onUpdate, onDelete,
}: Props) => (
  <Col xs="12" md="6" lg="4" className="d-flex mb-3">
    <Card border="secondary" className="flex-grow-1">
      <Card.Header>
        <Badge bg="secondary" text="white">{block.type}</Badge>
      </Card.Header>
      <Card.Body className="d-flex flex-column">
        <Card.Title>{block.description}</Card.Title>
        <Card.Text>{new Date(block.created_at!).toLocaleDateString('fr-FR')}</Card.Text>
        <div className="mt-auto d-flex justify-content-end align-items-center">
          <img
            alt="card-icon"
            src={EditIcon}
            height="20"
            width="20"
            onClick={() => onUpdate(block.id)}
            className="pointer me-3"
          />
          <img
            alt="card-icon"
            src={DeleteIcon}
            height="20"
            width="20"
            onClick={() => onDelete(block.id)}
            className="pointer"
          />
        </div>
      </Card.Body>
    </Card>
  </Col>

);

export default Block;
