import React, { useContext, useEffect, useMemo } from 'react';
import { Auth } from 'aws-amplify';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { UserContext } from '@root/contexts/user.context';
import AWButton from '@root/components/AWButtons/AWButton';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { deleteCookies } from '@root/helpers/utils';
import { UserStatus } from '@root/interfaces/cognito.interface';

import LoginContainer from '@user/components/LoginContainer/LoginContainer';
import { getUserStatus } from '@user/services/user.service';
import { LoginRouterContext, Views } from '@user/contexts/loginRouter.context';
import { EMAIL_REGEX } from '@root/helpers/patterns';

/**
 * displays a form asking the user to enter an email address,
 * then redirects the user to new pages depending on his status
 * in the database
 */
const EmailContainer = () => {
  const { t } = useTranslation();
  const { waitWithLoad, isLoading } = useLoadingPromise();

  const { user, setUser } = useContext(UserContext);
  const { setCurrentView } = useContext(LoginRouterContext);
  const history = useHistory();
  const { search } = useLocation();

  const getEmailFromSearch = () => {
    const decodedEmail = new URLSearchParams(search).get('email');
    if (!decodedEmail?.match(/\s/)) return decodedEmail;
    const kv = search
      .replace('?', '')
      .split('&')
      .filter((x) => !!x)
      .find((item) => item.includes('email='));
    if (!kv) return '';
    return kv.split('=')[1];
  };

  /**
   * Set context user according to email adress entered
   * in the form
   */
  const handleEmail = (e) => {
    setUser({ ...user, email: e.target.value });
  };

  /**
   * Verify user status from their email address and redirects
   * according to it
   */
  const loadUser = async (emailOrEvent) => {
    let email;
    if (typeof emailOrEvent === 'string') {
      email = emailOrEvent;
    } else {
      emailOrEvent.preventDefault();
      email = user.email;
    }
    if (email) {
      const res = await waitWithLoad(getUserStatus(email));
      if (res.success) {
        await Auth.signOut();
        deleteCookies();
        if (res.data.status === UserStatus.ExistsInDatabase) {
          const _user = res.data.data;
          if (_user) {
            _user.phoneNumber = _user?.phone_numbers?.length ? _user.phone_numbers[0] : undefined;
            setUser({ ...user, ..._user });
          }
          setCurrentView(res.data.enabled ? Views.CognitoRegister : Views.FirstConnection);
        } else {
          setCurrentView(Views.Password);
        }
      } else {
        history.push('/signup');
      }
    }
  };

  useEffect(() => {
    if (search) {
      const email = getEmailFromSearch();
      if (email) {
        setUser({ ...user, email });
        loadUser(email);
      }
    }
  }, []);

  const value = useMemo(() => (
    user.email || getEmailFromSearch() || ''
  ), [user.email, search]);

  return (
    <Form
      onSubmit={loadUser}
    >
      <LoginContainer>
        <h1>
          {t('SignIn.title', 'Connexion')}
        </h1>
        <Form.Group className="mb-4">
          <Form.Label htmlFor="signin-email">
            {`${t('SignIn.email', 'Adresse email')} :`}
          </Form.Label>
          <Form.Control
            value={value}
            onChange={handleEmail}
            id="signin-email"
            placeholder={t('SignIn.emailPlaceholder', 'john.doe@email.com')}
          />
        </Form.Group>
        <AWButton
          type="submit"
          disabled={!(user.email && EMAIL_REGEX.test(user.email)) || isLoading}
          isLoading={isLoading}
        >
          {t('SignIn.next', 'Continuer')}
        </AWButton>
        <Link
          className="d-xl-none text-center mt-2"
          to="/signup"
        >
          {t('SignIn.linkText', 'Pas encore de compte ?')}
        </Link>
      </LoginContainer>
    </Form>
  );
};

export default EmailContainer;
