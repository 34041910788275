import { Enterprise } from '@root/interfaces/enterprise.interface';

export interface SessionUser {
  id?: string;
  email?: string;
  password?: string;
  firstname?: string;
  lastname?: string;
  picture?: string;
  v1token?: string;
  userId?: string;
  gender?: string;
  phoneNumber?: string;
  passwordConfirmation?: string;
  confirmationToken?: string;
  isConfirmed?: boolean;
  is_confirmed?: boolean;
  last_login?: Date;
  enterprises?: Enterprise[];
  currentAppEnterprises?: Enterprise[];
  currentEnterprise?: Enterprise;
}

export interface CognitoUserAttributes {
  email?: string;
  email_verified?: boolean;
  family_name?: string;
  gender?: string;
  given_name?: string;
  name?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  sub?: string;
}

export enum SubdomainToV2Light {
  App = 'app',
  AppDev = 'app-dev-2',
  AppStaging = 'app-staging',
  Sogetrel = 'sogetrel',
  SogetrelDev = 'sogetrel-dev',
  SogetrelStaging = 'sogetrel-staging',
  Edenred = 'edenred',
  EdenredDev = 'edenred-dev',
  EdenredStaging = 'edenred-staging',
}

export enum UserStatus {
  Unconfirmed = 'UNCONFIRMED',
  ResetRequired = 'RESET_REQUIRED',
  ForceChangePassword = 'FORCE_CHANGE_PASSWORD',
  ExistsInDatabase = 'EXISTS_IN_DATABASE',
}

export interface IUserStatus {
  status: string;
  data?: SessionUser & { phone_numbers: string[] };
  enabled: boolean;
}

export enum CognitoErrors {
  PhoneNumberFormat = 'PHONE_NUMBER_FORMAT',
}
