import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Form,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@root/contexts/user.context';
import { uploadFileToS3 } from '@root/helpers/file.helper';
import { ApiResponse } from '@root/helpers/response-handler';
import {
  getPreviousYears, previewFileInNewTab, toStringRange,
} from '@root/helpers/utils';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { ConnectionTaskField, Department, IConnectionTaskField } from '@root/interfaces/connection.interface';
import {
  Enterprise,
  EmployeeNumber,
  BusinessTurnover,
  EmployeeNumberRange,
} from '@root/interfaces/enterprise.interface';
import {
  createEmployeeNumber,
  createEnterpriseTurnover,
  updateEmployeeNumber,
  updateEnterprise,
  updateEnterpriseTurnover,
} from '@provider/services/enterprise.service';
import AWButton from '@root/components/AWButtons/AWButton';
import ErrorInput from '@provider/components/ErrorInput/ErrorInput';
import { initFields } from '@provider/components/TaskModals/FieldsModal/fieldsModal.builder';
import { URL_REGEX } from '@root/helpers/patterns';
import AWSelect from '@root/components/AWSelect/AWSelect';

interface Props {
  fields: string[],
  onClose: () => void,
}

const MAX_SIZE = (1024 * 1000) * 5; // 5Mo
const MIME_ACCEPT = ['image/*', 'application/pdf'];
const MIME_REGEXP = new RegExp(MIME_ACCEPT.join('|').replace('*', '.*'));
const ADDWORKING_WEBSITE = 'https://www.addworking.com';
const EURO = 'EUR';

// 3 previous years if current month is before April.
const previousYears = new Date().getMonth() < 4
  ? getPreviousYears({ numberOfYears: 3, startingYear: new Date().getFullYear() - 1 })
  : getPreviousYears({ numberOfYears: 3 });
const previousYearOptions = previousYears.map((py) => ({
  value: py.toString(),
  label: py.toString(),
}));

const FieldsModal = ({ fields, onClose }: Props) => {
  const { t } = useTranslation();
  const { waitWithLoad, isLoading } = useLoadingPromise();
  const { user } = useContext(UserContext);
  const brochureFileRef = useRef<HTMLInputElement>(null);

  const [allDepartments, setAllDepartments] = useState<Department[]>([]);
  const [enterpriseFields, setEnterpriseFields] = useState<IConnectionTaskField>({});
  const [brochureFile, setBrochureFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [employeeNumber, setEmployeeNumber] = useState<EmployeeNumber>();
  const [employeeNumberRanges, setEmployeeNumberRanges] = useState<EmployeeNumberRange[]>([]);
  const [turnover, setTurnover] = useState<BusinessTurnover>();
  const [turnoverCurrency, setTurnoverCurrency] = useState(EURO);

  const optionsRange = useMemo(() => (
    employeeNumberRanges.map((r: EmployeeNumberRange) => ({
      label: toStringRange(r.min!, r.max),
      value: r.id,
    }))
  ), [employeeNumberRanges]);

  useEffect(() => {
    (async () => {
      const {
        allDepartments: _allDepartments,
        employeeNumberRanges: _employeeNumberRanges,
        enterpriseFields: _enterpriseFields,
        latestTurnover: _latestTurnover,
        latestEmployeeNumber: _latestEmployeeNumber,
      } = await initFields(
        user.currentEnterprise?.identification_number || '',
        fields,
      );
      if (_allDepartments?.length) setAllDepartments(_allDepartments);
      if (_employeeNumberRanges?.length) setEmployeeNumberRanges(_employeeNumberRanges);
      if (_enterpriseFields) setEnterpriseFields(_enterpriseFields);
      if (_latestTurnover) {
        setTurnover(_latestTurnover);
        if (_latestTurnover.currency) setTurnoverCurrency(_latestTurnover.currency);
      }
      if (_latestEmployeeNumber) setEmployeeNumber(_latestEmployeeNumber);
    })();
  }, []);

  useEffect(() => {
    if (
      fields.includes(ConnectionTaskField.COMMERCIAL_BROCHURE)
      && brochureFile
      && brochureFile instanceof File
    ) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setEnterpriseFields({
          ...enterpriseFields,
          commercialBrochure: { url: fileReader.result as string },
        });
      };
      fileReader.readAsDataURL(brochureFile);
    }
  }, [brochureFile]);

  const checkValidity = (field: `${ConnectionTaskField}`, isValid: boolean) => (
    fields.includes(field) ? isValid : true
  );

  const isSubmitEnabled = useMemo(() => {
    const {
      commercialBrochure,
      website,
      departments,
    } = enterpriseFields;
    return (
      checkValidity(
        ConnectionTaskField.COMMERCIAL_BROCHURE,
        !!(commercialBrochure?.url || commercialBrochure?.none),
      )
      && checkValidity(
        ConnectionTaskField.WEBSITE,
        !!(website?.url?.match(URL_REGEX) || website?.none),
      )
      && checkValidity(ConnectionTaskField.DEPARTMENTS, !!departments?.length)
      && checkValidity(
        ConnectionTaskField.EMPLOYEES_NUMBER,
        !!(employeeNumber?.year && employeeNumber?.declarative_range_id),
      )
      && checkValidity(
        ConnectionTaskField.TURNOVER,
        !!(
          turnover?.year
          && turnover.declarative_amount as number >= 0
          && turnoverCurrency
        ),
      )
    );
  }, [enterpriseFields, turnover, employeeNumber]);

  const handleBrochureChange = (e) => {
    const files = e?.target?.files;
    if (e?.target && files?.length) {
      const file = files[0];
      if (file.size > MAX_SIZE) {
        setErrorMessage(
          t(
            'FieldsModal.maxFileSize',
            'Fichier trop lourd, max: {{max}}',
            {
              max: '5Mo',
            },
          ),
        );
      } else if (!file.type.match(MIME_REGEXP)) {
        setErrorMessage(
          t(
            'FieldsModal.wrongMime',
            'Type de fichier invalide. Types valides : {{types}}',
            {
              types: MIME_ACCEPT.join(', '),
            },
          ),
        );
      } else {
        setErrorMessage('');
        setBrochureFile(file);
      }
    }
  };

  const handleCheck = (field: 'commercialBrochure' | 'website') => (e) => {
    setEnterpriseFields({
      ...enterpriseFields,
      [field]: { none: e.target.checked },
    });
  };

  const buildEnterprise = async () => {
    const { website, departments } = enterpriseFields;
    const enterprise: Enterprise = {};
    if (fields.includes(ConnectionTaskField.COMMERCIAL_BROCHURE)) {
      if (brochureFile) {
        const brochureKey = await uploadFileToS3(brochureFile);
        enterprise.commercial_brochure = brochureKey;
      } else {
        enterprise.has_commercial_brochure = false;
      }
    }
    if (fields.includes(ConnectionTaskField.DEPARTMENTS)) {
      enterprise.departments = departments?.map((d) => d.id);
    }
    if (fields.includes(ConnectionTaskField.WEBSITE)) {
      if (website?.url) {
        enterprise.website = website.url;
      } else {
        enterprise.has_website = false;
      }
    }
    return enterprise;
  };

  const handleBrochurePreview = () => {
    if (enterpriseFields.commercialBrochure?.url) {
      if (brochureFile) {
        previewFileInNewTab(
          enterpriseFields.commercialBrochure.url,
          brochureFile.type,
        );
      } else {
        window.open(enterpriseFields.commercialBrochure.url, '_blank');
      }
    }
  };

  const handleEmployeesNumberYear = ({ value }) => (
    setEmployeeNumber(
      enterpriseFields.employeeNumbers?.find(
        (_en) => _en.year?.toString() === value,
      ) || { year: value },
    )
  );

  const handleEmployeesNumber = ({ value }) => {
    setEmployeeNumber({
      ...employeeNumber,
      declarative_range_id: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const promises: Promise<ApiResponse>[] = [];
    const enterprise = await buildEnterprise();
    promises.push(updateEnterprise(
      user.currentEnterprise?.identification_number || '',
      enterprise,
    ));
    if (
      ConnectionTaskField.TURNOVER
      && turnover
      && user.currentEnterprise?.id
    ) {
      const enterpriseTurnover: BusinessTurnover = {
        ...turnover,
        currency: turnoverCurrency,
        no_activity: !turnover.declarative_amount,
      };
      promises.push(turnover.id
        ? updateEnterpriseTurnover(
          user.currentEnterprise.id,
          turnover.id,
          {
            declarative_amount: enterpriseTurnover.declarative_amount,
            no_activity: enterpriseTurnover.no_activity,
            currency: enterpriseTurnover.currency,
          },
        )
        : createEnterpriseTurnover(user.currentEnterprise.id, enterpriseTurnover));
    }
    if (
      ConnectionTaskField.EMPLOYEES_NUMBER
      && employeeNumber
      && user.currentEnterprise?.id
    ) {
      promises.push(employeeNumber.id
        ? updateEmployeeNumber(
          user.currentEnterprise.id,
          employeeNumber.id,
          { declarative_range_id: employeeNumber.declarative_range_id },
        )
        : createEmployeeNumber(user.currentEnterprise.id, employeeNumber));
    }
    const responses = await Promise.all(promises);
    if (responses.every((r) => r.success)) {
      onClose();
    } else {
      setErrorMessage(t('FieldsModal.errorOccured', 'Une erreur est survenue.'));
    }
  };

  const handleMainLandDepartments = () => {
    const mainLandDepartments = allDepartments.filter((dpt) => dpt.code?.length === 2);
    setEnterpriseFields({ ...enterpriseFields, departments: mainLandDepartments });
  };

  return (
    <Form onSubmit={(e) => waitWithLoad(handleSubmit(e))}>
      {fields.includes(ConnectionTaskField.TURNOVER) && (
        <Form.Group className="mb-3">
          <Form.Label className="font-size-18 font-style-bold">
            {t('FieldsModal.turnover', "Chiffre d'affaires")}
          </Form.Label>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  {t('FieldsModal.turnoverYear', 'Année')}
                </Form.Label>
                <AWSelect
                  height="35px"
                  noNullOption
                  value={previousYearOptions.find(
                    (option) => option.value === turnover?.year?.toString(),
                  )}
                  options={previousYearOptions}
                  onChange={(e) => setTurnover(
                    enterpriseFields.turnovers?.find(
                      (_t) => _t.year?.toString() === e.value,
                    ) || { year: e.value },
                  )}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  {t('FieldsModal.turnoverAmount', 'Montant déclaré')}
                </Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  value={turnover?.declarative_amount || turnover?.declarative_amount === 0
                    ? turnover.declarative_amount : ''}
                  onChange={(e) => setTurnover({
                    ...turnover,
                    declarative_amount: parseInt(e.target.value, 10),
                  })}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  {t('FieldsModal.currency', 'Devise')}
                </Form.Label>
                <Form.Control
                  value={turnoverCurrency}
                  maxLength={3}
                  onChange={(e) => setTurnoverCurrency(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form.Group>
      )}
      {fields.includes(ConnectionTaskField.EMPLOYEES_NUMBER) && (
        <Form.Group className="mb-3">
          <Form.Label className="font-size-18 font-style-bold">
            {t('FieldsModal.employeeNumber', 'Masse salariale')}
          </Form.Label>
          <Row>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  {t('FieldsModal.employeeNumberYear', 'Année')}
                </Form.Label>
                <AWSelect
                  height="35px"
                  noNullOption
                  value={previousYearOptions.find(
                    (option) => option.value === employeeNumber?.year?.toString(),
                  )}
                  options={previousYearOptions}
                  onChange={handleEmployeesNumberYear}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  {t('FieldsModal.employeeNumberCount', "Nombre d'employés")}
                </Form.Label>
                <AWSelect
                  value={
                    optionsRange.find((r) => r.value === employeeNumber?.declarative_range_id)
                  }
                  onChange={handleEmployeesNumber}
                  options={optionsRange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form.Group>
      )}
      {fields.includes(ConnectionTaskField.DEPARTMENTS) && (
        <Form.Group className="mb-3">
          <Form.Label>
            {t(
              'FieldsModal.geographicCover',
              "Couverture géographique (zone(s) d'intervention de votre entreprise)",
            )}
          </Form.Label>
          <AWSelect
            noNullOption
            isSearchable
            stayOpen
            isMulti
            valueMaxHeight="100px"
            value={enterpriseFields.departments}
            options={allDepartments}
            onChange={(v) => setEnterpriseFields({ ...enterpriseFields, departments: v })}
          />
          <p
            className="fields-modal__all-departments font-style-bold"
            onClick={handleMainLandDepartments}
            aria-hidden
          >
            {t('FieldsModal.mainLandDepartments', 'Départements de France métropolitaine')}
          </p>
        </Form.Group>
      )}
      {fields.includes(ConnectionTaskField.WEBSITE) && (
        <Form.Group className="mb-3">
          <Form.Label>
            {t('FieldsModal.website', 'Site internet')}
          </Form.Label>
          <Form.Control
            className="fields-modal__website"
            placeholder={ADDWORKING_WEBSITE}
            value={enterpriseFields.website?.url || ''}
            onChange={(e) => (
              setEnterpriseFields({
                ...enterpriseFields,
                website: { url: e.target.value },
              })
            )}
          />
          <div className="mt-1 d-flex align-items-center">
            <Form.Check
              checked={enterpriseFields.website?.none || false}
              type="checkbox"
              className="me-2"
              onChange={handleCheck('website')}
            />
            <Form.Label className="mb-0">
              {t('FieldsModal.noWebsite', "Je n'ai pas de site internet")}
            </Form.Label>
          </div>
        </Form.Group>
      )}
      {fields.includes(ConnectionTaskField.COMMERCIAL_BROCHURE) && (
        <Form.Group className="mb-3">
          <Form.Label>
            {t('FieldsModal.brochure', 'Plaquette commerciale')}
          </Form.Label>
          <div className="d-flex">
            <AWButton
              type="button"
              onClick={() => brochureFileRef.current?.click()}
              className="w-auto me-auto font-size-14"
            >
              {t('Action.upload')}
            </AWButton>
            {(enterpriseFields.commercialBrochure?.url || brochureFile) && (
              <AWButton
                className="w-auto me-auto ms-3 font-size-14"
                type="button"
                color="black"
                backgroundColor="transparent"
                onClick={handleBrochurePreview}
              >
                {brochureFile?.name || t('FieldsModal.previewDocument', 'Visualiser le document')}
              </AWButton>
            )}
          </div>
          <input
            className="d-none"
            ref={brochureFileRef}
            type="file"
            onChange={handleBrochureChange}
          />
          <div className="mt-1 d-flex align-items-center">
            <Form.Check
              checked={enterpriseFields.commercialBrochure?.none || false}
              type="checkbox"
              className="me-2"
              onChange={handleCheck('commercialBrochure')}
            />
            <Form.Label className="mb-0">
              {t('FieldsModal.noBrochure', "Je n'ai pas de plaquette commerciale")}
            </Form.Label>
          </div>
        </Form.Group>
      )}
      <ErrorInput error={!!errorMessage} message={errorMessage} type="form" />
      <AWButton
        disabled={!isSubmitEnabled || isLoading}
        className="w-50 my-auto mt-4"
      >
        {isLoading ? (
          <Spinner animation="border" variant="white" size="sm" />
        ) : (
          t('Action.validate')
        )}
      </AWButton>
    </Form>
  );
};

export default FieldsModal;
