import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import {
  MissionApproval,
  MissionCreation,
  MissionProvider,
  ProviderIdAndMail,
} from '@customer/interfaces/mission.interface';
import { Mission as IMission } from '@root/interfaces/mission.interface';
import { queryBoySearch } from '@root/helpers/utils';
import { QueryboyOptions } from '@root/interfaces/queryboy.interface';

const baseUrl = `${process.env.REACT_APP_MISSION_API_URL}/v1/mission/customer`;

export const getMissionProviders = async (
  offerId: string,
  options: QueryboyOptions,
): Promise<ApiResponse<MissionProvider[]>> => (
  safeFetch(`${baseUrl}/mission-offer/${offerId}/providers${queryBoySearch(options)}`)
);

export const getMissionOffer = async (
  offerId: string,
  options: QueryboyOptions,
): Promise<ApiResponse<IMission>> => (
  safeFetch(`${baseUrl}/mission-offer/${offerId}${queryBoySearch(options)}`)
);

export const addToCart = async (
  offerId: string,
  body: MissionCreation,
): Promise<ApiResponse<MissionProvider>> => (
  safeFetch(
    `${baseUrl}/mission-offer/${offerId}/providers`,
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )
);

export const deleteFromCart = async (
  offerId: string,
  providerId: string,
): Promise<ApiResponse> => (
  safeFetch(
    `${baseUrl}/mission-offer/${offerId}/providers/${providerId}`,
    { method: 'DELETE' },
  )
);

export const sendInvitation = async (
  offerId: string,
  body: ProviderIdAndMail[],
): Promise<ApiResponse> => (
  safeFetch(
    `${baseUrl}/mission-offer/${offerId}/sendInvitations`,
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )
);

export const getMissionsForApprovals = async (
  enterpriseId: string,
): Promise<ApiResponse<MissionApproval[]>> => safeFetch(`${baseUrl}/${enterpriseId}/missions?status=ready_to_start,in_progress`);
