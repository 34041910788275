import React from 'react';

interface Props {
  tabName: string;
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
}

const Tab = ({ tabName, activeTab, setActiveTab }: Props) => (
  <span
    className={`aw-nav-link${activeTab === tabName ? '--active' : ''}`}
    onClick={() => {
      setActiveTab(tabName);
    }}
  >
    {tabName}
  </span>
);

export default Tab;
