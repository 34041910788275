import React from 'react';

interface Props {
  color?: string;
  width?: string;
  height?: string;
}

const ComplianceSVG = ({ color, width, height }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 15 15">
    <path
      id="Icon_awesome-medal"
      data-name="Icon awesome-medal"
      d="M6.555,3.831,4.53.455A.937.937,0,0,0,3.726,0H.47A.469.469,0,0,0,.086.738l3.26,4.657A6.063,6.063,0,0,1,6.555,3.831ZM14.53,0H11.274a.937.937,0,0,0-.8.455L8.445,3.831a6.065,6.065,0,0,1,3.21,1.564L14.915.738A.469.469,0,0,0,14.53,0ZM7.5,4.687a5.156,5.156,0,1,0,5.156,5.156A5.156,5.156,0,0,0,7.5,4.687Zm2.711,4.607L9.1,10.378l.263,1.53a.336.336,0,0,1-.488.354L7.5,11.539l-1.374.722a.336.336,0,0,1-.488-.354l.263-1.53L4.79,9.295a.336.336,0,0,1,.186-.574L6.512,8.5,7.2,7.1a.337.337,0,0,1,.6,0L8.489,8.5l1.536.224a.336.336,0,0,1,.186.574Z"
      transform="translate(0)"
      fill={color}
    />
  </svg>
);

ComplianceSVG.defaultProps = {
  color: '#fff',
  width: '13.125',
  height: '15',
};

export default ComplianceSVG;
