import React, {
  createContext,
  ReactNode,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';

export enum Views {
  Login = 'login',
  Recovery = 'recovery',
  Update = 'update',
  Profile = 'profile',
  FirstConnection = 'first-connection',
  CognitoRegister = 'cognito-register',
  Email = 'email',
  Password = 'password',
}

interface ILoginRouterContext {
  currentView: Views;
  setCurrentView: Dispatch<SetStateAction<Views>>;
}

export const LoginRouterContext = createContext<ILoginRouterContext>({
  currentView: Views.Login,
  setCurrentView: () => {},
});

const LoginRouterContextProvider = ({ children }: { children: ReactNode }) => {
  const [currentView, setCurrentView] = useState(Views.Login);

  const value = useMemo(() => ({ currentView, setCurrentView }), [currentView]);

  return (
    <LoginRouterContext.Provider value={value}>
      {children}
    </LoginRouterContext.Provider>
  );
};

export default LoginRouterContextProvider;
