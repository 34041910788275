import React from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import contract from '@root/assets/contract.svg';
import { formatDate } from '@root/helpers/utils';
import AWLongCard from '@root/components/Cards/AWLongCard/AWLongCard';
import { Contract } from '@root/interfaces/contract.interface';

interface Props {
  contract: Contract,
  onClick?: () => void,
}

const CardContract = ({
  contract: {
    number,
    state,
    name,
    valid_from,
    valid_until,
    enterprise_name,
  },
  onClick,
}: Props) => {
  const { t } = useTranslation();
  return (
    <AWLongCard
      onClick={onClick}
      className="mb-3"
      icon={contract}
    >
      <Col xs={10} className="d-flex flex-column justify-content-between">
        <h2 className="text-dark font-style-bold font-size-15 mb-1">
          {`${name}`}
        </h2>
        <p className="text-dark mb-1">
          {number}
        </p>
        <p className="text-grey mb-1">
          {`${t('CardContract.enterprise', 'Entreprise')}: ${enterprise_name} `}
        </p>
      </Col>
      <Col xs={5} className="d-flex flex-column justify-content-between">
        <p className="text-grey mb-1">
          {`${t('CardContract.state', 'Etat')}: ${t(`ContractState.${state}`)} `}
        </p>
        <p className="text-grey mb-1">
          {`${t('CardContract.valid_from', 'Valable depuis')}: ${valid_from ? formatDate(valid_from, 'D MMMM YYYY') : '-'} `}
        </p>
        <p className="text-grey mb-1">
          {`${t('CardContract.valid_until', "Valable jusqu'au")}: ${valid_until ? formatDate(valid_until, 'D MMMM YYYY') : '-'} `}
        </p>
      </Col>
    </AWLongCard>
  );
};

CardContract.defaultProps = {
  onClick: () => { },
};

export default CardContract;
