import React, {
  createContext, useState, useMemo, ReactNode,
} from 'react';
import { ApprovalDetails, PropertySignature } from '@root/interfaces/approval.interface';
import { ApprovalDocumentModel } from '@root/interfaces/approvalDocument.interface';
import { MissionApproval } from '@customer/interfaces/mission.interface';
import { Option } from '@root/interfaces/utils.interface';

interface IApprovalContext {
  activeCardId: string;
  setActiveCardId: (activeCardId: string) => void;
  selectedMission: MissionApproval;
  setSelectedMission: (selectedMission: MissionApproval) => void;
  modelSelected: ApprovalDocumentModel;
  setModelSelected: (modelSelected: ApprovalDocumentModel) => void;
  pdfFile: File;
  setPdfFile: (pdfFile: File) => void;
  uploadedPdfUrl: string;
  setUploadedPdfUrl: (uploadedPdfUrl: string) => void;
  modelVariableDictionary: Record<string, Option>;
  setModelVariableDictionary: (dictionary: Record<string, Option>) => void;
  documentCompletedWithCustomerVariables: string;
  setDocumentCompletedWithCustomerVariables: (
    documentCompletedWithCustomerVariables: string
  ) => void;
  approvalToDisplay: ApprovalDetails;
  setApprovalToDisplay: (approvalToDisplay: ApprovalDetails) => void;
  sponsorEmail: string;
  setSponsorEmail: (sponsorEmail: string) => void;
  currentNewApprovalId: string;
  setCurrentNewApprovalId: (currentNewApprovalId: string) => void;
  variablesHaveBeenCreatedInDb: boolean;
  setVariablesHaveBeenCreatedInDb: (variablesHaveBeenCreatedInDb: boolean) => void;
  propertySignatures: PropertySignature[];
  setPropertySignatures: (propertySignatures: PropertySignature[]) => void;
  totalPages: number | null;
  setTotalPages: (totalPages: number) => void;
  currentPage: number;
  setCurrentPage: (currentPage: any) => void;
  pdfFilePath: string;
  setPdfFilePath: (modelSelected: string) => void;
  modelData: string;
  setModelData: (modelData: string) => void;
}

export const ApprovalContext = createContext<IApprovalContext>({
  // used to keep track of the mission selected in step 1
  activeCardId: '',
  setActiveCardId: () => { },
  selectedMission: {} as MissionApproval,
  setSelectedMission: () => { },
  // used to store model document at step 2 "choose model"
  modelSelected: {
    id: '',
    s3_file_id: '',
    value: '',
  },
  setModelSelected: () => { },
  // used to store uploaded pdf at step 2
  pdfFile: {
    lastModified: 0,
    name: '',
    webkitRelativePath: '',
    size: 0,
    type: '',
  } as File,
  setPdfFile: () => { },
  uploadedPdfUrl: '',
  setUploadedPdfUrl: () => { },
  // used for set model variables for all the approval stepper
  modelVariableDictionary: {},
  setModelVariableDictionary: () => { },
  currentNewApprovalId: '',
  setCurrentNewApprovalId: () => { },
  variablesHaveBeenCreatedInDb: false,
  setVariablesHaveBeenCreatedInDb: () => { },
  documentCompletedWithCustomerVariables: '',
  setDocumentCompletedWithCustomerVariables: () => { },
  approvalToDisplay: {
    id: '',
    mission_description: '',
    mission_label: '',
    document_id: '',
    mime_type: '',
    provider_identification_number: '',
    provider_legal_form: '',
    provider_name: '',
    sponsor_identification_number: '',
    sponsor_legal_form: '',
    sponsor_name: '',
    status: '',
    workfield_description: '',
    workfield_label: '',
  },
  setApprovalToDisplay: () => { },
  sponsorEmail: '',
  setSponsorEmail: () => { },
  propertySignatures: [],
  setPropertySignatures: () => { },
  totalPages: null,
  setTotalPages: () => { },
  currentPage: 1,
  setCurrentPage: () => { },
  pdfFilePath: '',
  setPdfFilePath: () => {},
  modelData: '',
  setModelData: () => {},
});

const ApprovalContextProvider = ({ children }: { children: ReactNode }) => {
  const [activeCardId, setActiveCardId] = useState('');
  const [selectedMission, setSelectedMission] = useState<MissionApproval>({
    id: '',
    starts_at: new Date(),
    ends_at: new Date(),
    status: '',
    label: '',
    amount: 0,
    customer_id: '',
    customer_name: '',
    provider_id: '',
    provider_name: '',
    customer_identification_number: '',
    customer_legal_form: '',
    provider_identification_number: '',
    provider_legal_form: '',
    workfield_name: '',
    workfield_address: '',
    description: '',
    market_type: '',
  });
  const [modelSelected, setModelSelected] = useState<ApprovalDocumentModel>({
    id: '',
    s3_file_id: '',
    value: '',
  });
  const [pdfFile, setPdfFile] = useState<File>(new File([], ''));
  const [uploadedPdfUrl, setUploadedPdfUrl] = useState<string>('');
  const [pdfFilePath, setPdfFilePath] = useState<string>('');
  const [modelVariableDictionary, setModelVariableDictionary] = useState({});
  const [currentNewApprovalId, setCurrentNewApprovalId] = useState<string>('');
  const [variablesHaveBeenCreatedInDb, setVariablesHaveBeenCreatedInDb] = useState<boolean>(false);
  const [documentCompletedWithCustomerVariables, setDocumentCompletedWithCustomerVariables] = useState<string>('');
  const [approvalToDisplay, setApprovalToDisplay] = useState<ApprovalDetails>({
    id: '',
    document_id: '',
    mime_type: '',
    mission_description: '',
    mission_label: '',
    provider_identification_number: '',
    provider_legal_form: '',
    provider_name: '',
    sponsor_identification_number: '',
    sponsor_legal_form: '',
    sponsor_name: '',
    status: '',
    workfield_description: '',
    workfield_label: '',
  });
  const [sponsorEmail, setSponsorEmail] = useState<string>('');
  const [propertySignatures, setPropertySignatures] = useState<PropertySignature[]>([
    {
      id: 1,
      signatureName: '',
      width: 200,
      height: 100,
      pageNumberSignature: 1,
      x: 0,
      y: 0,
    },
  ]);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [modelData, setModelData] = useState('');

  const value = useMemo(
    () => ({
      activeCardId,
      setActiveCardId,
      selectedMission,
      setSelectedMission,
      modelSelected,
      setModelSelected,
      pdfFile,
      setPdfFile,
      uploadedPdfUrl,
      setUploadedPdfUrl,
      modelVariableDictionary,
      setModelVariableDictionary,
      currentNewApprovalId,
      setCurrentNewApprovalId,
      variablesHaveBeenCreatedInDb,
      setVariablesHaveBeenCreatedInDb,
      documentCompletedWithCustomerVariables,
      setDocumentCompletedWithCustomerVariables,
      sponsorEmail,
      setSponsorEmail,
      approvalToDisplay,
      setApprovalToDisplay,
      propertySignatures,
      setPropertySignatures,
      totalPages,
      setTotalPages,
      currentPage,
      setCurrentPage,
      pdfFilePath,
      setPdfFilePath,
      modelData,
      setModelData,
    }),
    [
      activeCardId,
      selectedMission,
      modelSelected,
      pdfFile,
      uploadedPdfUrl,
      modelVariableDictionary,
      currentNewApprovalId,
      variablesHaveBeenCreatedInDb,
      documentCompletedWithCustomerVariables,
      approvalToDisplay,
      sponsorEmail,
      approvalToDisplay,
      propertySignatures,
      totalPages,
      currentPage,
      pdfFilePath,
      modelData,
    ],
  );

  return <ApprovalContext.Provider value={value}>{children}</ApprovalContext.Provider>;
};

export default ApprovalContextProvider;
