import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  href: string;
  children: JSX.Element;
  className?: string;
}

const AWLinkButton = ({
  href,
  className,
  children,
}: Props) => (
  <div className="d-flex justify-content-center">
    <Link to={href} className={`aw-button ${className}`}>
      {children}
    </Link>
  </div>
);

AWLinkButton.defaultProps = {
  className: '',
};

export default AWLinkButton;
