import React from 'react';
import {
  useRouteMatch,
  Switch,
  Route,
} from 'react-router-dom';
import ProtectedRoute from '@root/routes/ProtectedRoute';
import Approvals from '@customer/views/Approvals/Approvals';
import NewApprovalRoutes from '@customer/routes/newApprovalRoutes';
import ApprovalContainerRoutes from '@customer/routes/approvalContainerRoutes';

const ApprovalRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={path} exact>
        <Approvals />
      </ProtectedRoute>
      <Route path={`${path}/new`}>
        <NewApprovalRoutes />
      </Route>
      <Route path={`${path}/:approvalId`}>
        <ApprovalContainerRoutes />
      </Route>
    </Switch>
  );
};

export default ApprovalRoutes;
