import { Provider } from '@customer/interfaces/provider.interface';
import { User } from '@root/interfaces/user.interface';

export interface OfferInvite {
  sendInvite: boolean,
  offerBeginDate: Date,
  offerEndDate: Date,
  comments: string,
  emails: string[],
}

export interface Email {
  email: string,
}

export interface ProviderIdAndMail {
  id: string,
  emails: string[]
}

export interface MissionProvider extends Provider {
  id: string,
  mission_offer_id: string,
  label: string,
  details?: string,
  external_id?: string,
  status: string,
  need_quotation?: boolean,
  valid_from?: Date,
  valid_until?: Date,
  quantity?: number,
  unit_price?: number,
  unit?: number,
  created_by?: string,
  created_at?: Date,
  updated_at?: Date,
  deleted_at?: Date,
  accepted_by?: string,
  accepted_at?: Date,
  refused_by?: string,
  refused_at?: Date,
  vendor_enterprise_id: string,
  vendor_passwork_id?: string,
  file_id?: string,
  number?: number,
  legal_form_name?: string,
  identification_number?: string,
  enterprise_name?: string,
  users?: User[],
  emails: string[]
}

export type InvitationStep = 'create' | 'success' | 'error';

export interface MissionCreation {
  vendor_enterprise_id: string,
  status: string,
  label?: string;
  details?: string;
  valid_from?: Date;
  valid_until?: Date;
  mission_offer_id?: string;
}

export interface MissionApproval {
  id: string;
  starts_at: Date;
  ends_at: Date;
  status: string;
  label: string;
  amount: number;
  customer_id: string;
  customer_name: string;
  provider_id: string;
  provider_name: string;
  customer_identification_number?: string;
  customer_legal_form?: string;
  provider_identification_number?: string;
  provider_legal_form?: string;
  workfield_name?: string;
  workfield_address?: string;
  description?: string;
  market_type?: string;
}

export enum MissionOfferProvider {
  InBasket = 'inBasket',
}
