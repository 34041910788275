import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@root/contexts/user.context';
import { EMAIL_REGEX } from '@root/helpers/patterns';
import useCognito from '@root/hooks/useCognito';
import InfoMessage from '@root/components/InfoMessage/InfoMessage';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import LoginContainer from '@user/components/LoginContainer/LoginContainer';
import ReturnButton from '@user/components/ReturnButton/ReturnButton';
import AWButton from '@root/components/AWButtons/AWButton';
import { LoginRouterContext, Views } from '@user/contexts/loginRouter.context';
import { AWColors } from '@root/interfaces/utils.interface';

/**
 * the component is displayed during the second step of the connection when the user's
 * status has been approved.
 * It displays a form asking the user to enter their password in order to log in
 */
const PasswordContainer = () => {
  const { t } = useTranslation();
  const { isLoading, waitWithLoad } = useLoadingPromise();

  const { signIn: cognitoSignIn, message } = useCognito();

  const { user, setUser } = useContext(UserContext);
  const { setCurrentView } = useContext(LoginRouterContext);

  /**
   * Do the inscription with the email saved in user context and the password
   * enter by the user.
   */
  const signIn = async (e) => {
    e.preventDefault();
    await waitWithLoad(cognitoSignIn(user.email, user.password));
  };

  return (
    <LoginContainer>
      <Form
        onSubmit={signIn}
        className="d-flex flex-column justify-content-between flex-grow-1"
      >
        <h1>
          {t('SignIn.title', 'Connexion')}
        </h1>
        <div>
          <Form.Group>
            <Form.Label htmlFor="signin-password">
              {`${t('SignIn.password', 'Mot de passe')} :`}
            </Form.Label>
            <Form.Control
              id="signin-password"
              type="password"
              placeholder="••••••••"
              onChange={(e) => setUser({ ...user, password: e.target.value })}
            />
            <AWButton
              type="button"
              color={AWColors.Grey}
              className="p-0 mt-2 d-flex align-content-start"
              onClick={() => setCurrentView(Views.Recovery)}
              backgroundColor="transparent"
            >
              {`${t('SignIn.forgotPassword', 'Mot de passe oublié')} ?`}
            </AWButton>
          </Form.Group>
          {message && (
            <InfoMessage
              message={message.text}
              color={message.color}
              toSupport={message.toSupport}
            />
          )}
        </div>
        <AWButton
          type="submit"
          disabled={!(user.email && EMAIL_REGEX.test(user.email) && !!user.password) || isLoading}
          className="mt-4 mt-xl-0"
          isLoading={isLoading}
        >
          {t('Action.login')}
        </AWButton>
        <ReturnButton className="mt-2" onClick={() => setCurrentView(Views.Login)} />
      </Form>
    </LoginContainer>
  );
};

export default PasswordContainer;
