import React, { useContext } from 'react';
import { ApprovalProviderContext } from '@provider/contexts/approvalProvider.context';
import { ApprovalStatusEnum } from '@root/interfaces/approval.interface';
import { useTranslation } from 'react-i18next';

const ApprovalSignature = () => {
  const { t } = useTranslation();
  const { approvalToDisplay } = useContext(ApprovalProviderContext);
  return (
    <div>
      {
        approvalToDisplay.status === ApprovalStatusEnum.WAITING_PROVIDER_VALIDATION
          ? <div>IFrame Yousign</div>
          : <div>{t('Approvals.signatureMessage', 'En attente d’informations complémentaires')}</div>
      }
    </div>
  );
};

export default ApprovalSignature;
