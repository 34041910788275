import {
  Passwork,
  ProviderInvitation,
  SendEmailOptions,
  Activity,
  Department,
} from '@customer/interfaces/provider.interface';
import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import { queryToString, queryBoySearch } from '@root/helpers/utils';
import { ActivePartner } from '@root/interfaces/enterprise.interface';
import { Field } from '@root/interfaces/field.interface';
import { QueryboyOptions } from '@root/interfaces/queryboy.interface';

const { REACT_APP_ENTERPRISE_API, REACT_APP_COMPANY_API } = process.env;

export const getDepartments = async (
  identification_number: string,
  options: QueryboyOptions,
): Promise<ApiResponse<Department[]>> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/enterprises/${identification_number}/departments${queryBoySearch(options)}`)
);

export const getSkills = async (
  identification_number: string,
  options: QueryboyOptions,
): Promise<ApiResponse> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/enterprises/${identification_number}/skills${queryBoySearch(options)}`)
);

export const updateStatus = async (
  identification_number: string,
  status: string,
  opts?: SendEmailOptions,
): Promise<ApiResponse> => (
  safeFetch(
    `${REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/enterprises/list/${identification_number}${queryToString(opts || {})}`,
    {
      method: 'PATCH',
      body: JSON.stringify({ status }),
    },
  )
);

export const getAddress = async (identification_number: string): Promise<ApiResponse> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/enterprises/${identification_number}/addresses?fields=address,additionnal_address,zipcode,town,country`)
);

export const getEnterpriseMembers = async (
  identification_number: string,
  options,
): Promise<ApiResponse> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/enterprises/${identification_number}/members${queryBoySearch(options)}`)
);

export const getEnterprisePhoneNumber = async (
  identification_number: string,
): Promise<ApiResponse> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/enterprises/${identification_number}/phone_numbers?fields=number`)
);

export const getEnterpriseLegalForm = (id, options = {}) => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/legal-forms/${id}${queryBoySearch(options)}`)
);

export const getProviders = async (options: QueryboyOptions): Promise<ApiResponse> => (
  safeFetch(`${REACT_APP_COMPANY_API}/v1/company/customer/providers/${queryBoySearch(options)}`)
);

export const getActiveProviders = async (
  customerId: string,
  query: string,
): Promise<ApiResponse<ActivePartner[]>> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/enterprises/${customerId}/partners?${query}`)
);

export const getPartners = async (
  customerId: string,
  options: QueryboyOptions,
): Promise<ApiResponse<unknown[]>> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/customers/${customerId}/partners${queryBoySearch(options)}`)
);

export const getPassworkSkills = async (
  customerId: string,
  providerId: string,
): Promise<ApiResponse<Passwork[]>> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/enterprises/${customerId}/partners/${providerId}/skills`)
);

export const getInvitations = async (
  options: QueryboyOptions,
): Promise<ApiResponse<ProviderInvitation[]>> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/invitations${queryBoySearch(options)}`)
);
export const updateInvitation = async (
  invitationId: string,
  data: { status: string, provider_id: string },
): Promise<ApiResponse> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/invitations/${invitationId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  })
);

export const updateProviderCHA = async (
  customerId: string,
  providerId: string,
  data: { vendor_external_id: string },
): Promise<ApiResponse> => (
  safeFetch(
    `${REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/enterprises/${customerId}/provider/${providerId}`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
  )
);

export const getActivities = async (
  id: string,
  options: QueryboyOptions,
): Promise<ApiResponse<Activity[]>> => (
  safeFetch(`${REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/enterprises/${id}/activities${queryBoySearch(options)}`)
);

export const createFieldData = async (
  fieldId: string,
  data: {
    provider_id: string,
    content_ids: string[],
  },
): Promise<ApiResponse<Field[]>> => (
  safeFetch(
    `${REACT_APP_ENTERPRISE_API}/v1/enterprise/customer/fields/${fieldId}`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
  )
);
