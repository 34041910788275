import React, {
  useRef,
  useState,
  useEffect,
} from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import {
  getDistanceFromLatLonInKm,
  getFeature,
  kmsToPixelsAtMaxZoom,
} from '@root/components/LocationMap/locationMap.helper';
import { Address, LocationFeatureQuery } from '@root/interfaces/mapbox.interface';
import i18n from '@root/locales/i18n';
import { AWColors } from '@root/interfaces/utils.interface';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

interface Props {
  /** Main location to pin at the center of the map */
  origin: Address,
  /** Circle radius in kms, used to draw a circle around the origin */
  circleRadiusKm?: number,
  /** Places around the origin (buildings, companies, work sites...) */
  places?: Address[],
  /** Zoom level on the map - from 0 to 22. */
  zoom: number,
}

/**
 * LocationMap is a Mapbox based map.
 *  Display an exact location as origin, represented by a pin.
 *  Optionnaly, displays a circle around the origin, with places inside.
 * @version 1
 * @since 04/04/2022
 */

const LocationMap = ({
  origin,
  circleRadiusKm,
  places,
  zoom,
}: Props) => {
  const mapContainer = useRef(null);
  const map = useRef<mapboxgl.Map | null>(null);

  const [circleRadius, setCircleRadius] = useState(circleRadiusKm || 0);
  const [originCoordinates, setOriginCoordinates] = useState({ lng: 0, lat: 0 });
  const [markers, setMarkers] = useState<mapboxgl.Marker[]>([]);

  const displayMap = async () => {
    // Return if map already exists.
    if (map.current) return;

    const {
      number,
      street,
      postcode,
      city,
      country,
    } = origin.location; // Location origin details.

    // Transform origin details to location query object.
    const originQuery: LocationFeatureQuery = {
      type: origin.type,
      content: `${number || ''}, ${street || ''}, ${postcode}, ${city}`,
      country,
    };

    try {
      // Get origin geographical details.
      const originFeature = await getFeature(originQuery);
      const [lng, lat] = originFeature.center;
      setOriginCoordinates({ lng, lat });
      // Instantiate the map.
      map.current = new mapboxgl.Map({
        container: mapContainer.current ? mapContainer.current : '',
        style: 'mapbox://styles/mapbox/streets-v11',
        // Focus on origin location center.
        center: originFeature.center,
        zoom,
      })
        .addControl(new MapboxLanguage({
          defaultLanguage: i18n.language.split('-')[0],
        }))
        .addControl(new mapboxgl.ScaleControl({ position: 'bottom-right' }));

      // Add the origin marker to the map.
      new mapboxgl.Marker({ color: AWColors.Green })
        .setLngLat(originFeature.center).addTo(map.current);

      if (circleRadiusKm) {
        const radius = kmsToPixelsAtMaxZoom(circleRadiusKm, lat);
        map.current.on('load', () => {
          // Link origin data source to the map.
          map.current.addSource('circleAreaSource', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [{
                type: originFeature.type,
                geometry: originFeature.geometry,
              }],
            },
          });

          // Draws a circle around the origin center.
          map.current.addLayer({
            id: 'circleAreaLayer',
            type: 'circle',
            source: 'circleAreaSource',
            paint: {
              'circle-radius': {
                stops: [
                  [0, 0],
                  [20, radius],
                ],
                base: 2,
              },
              'circle-color': AWColors.Blue,
              'circle-opacity': 0.2,
            },
          });

          if (places?.length) {
            const newMarkers: mapboxgl.Marker[] = [];
            const companyMarkers = places.map(async (c) => {
              // Transform company details to location query object.
              const companyQuery: LocationFeatureQuery = {
                type: c.type,
                content: `${c.location.number}, ${c.location.street}, ${c.location.postcode}, ${c.location.city}`,
                country,
              };
              // Get company geographical details.
              const companyFeature = await getFeature(companyQuery);

              // Create the popup displaying the place name.
              const popup = new mapboxgl
                .Popup({
                  offset: 20,
                  focusAfterOpen: false,
                  closeButton: false,
                  className: 'mapbox-marker-popup',
                })
                .setText(c.name);

              // Instantiate a marker at company coordinates.
              const newMarker = new mapboxgl.Marker({ color: AWColors.Yellow, scale: 0.8 })
                .setLngLat(companyFeature.center)
                .setPopup(popup);

              newMarkers.push(newMarker);
            });
            Promise.all(companyMarkers).then(() => setMarkers(newMarkers));
          }
        });
      }
    } catch (err) {
      map.current = null;
    }
  };

  useEffect(() => {
    displayMap();
    return () => map.current?.remove();
  }, []);

  // Used to show/hide places as the circle radius updates.
  // Runs only if a list of places and a circle radius is given as props.
  useEffect(() => {
    if (
      circleRadiusKm
      && places?.length
      && circleRadius
      && map.current
      && markers.length
      && originCoordinates.lng
      && originCoordinates.lat
    ) {
      const radius = kmsToPixelsAtMaxZoom(circleRadius, originCoordinates.lat);
      // Updates the circle on the map.
      map.current.setPaintProperty('circleAreaLayer', 'circle-radius', {
        stops: [
          [0, 0],
          [20, radius],
        ],
        base: 2,
      });

      markers.forEach((marker) => {
        // Display only places which distance from origin are less or equal to the radius circle.
        marker?.remove();
        const { lng, lat } = marker.getLngLat();
        const markerCoords = [lng, lat];
        const originCoords = [originCoordinates.lng, originCoordinates.lat];
        const distanceFromOrigin = getDistanceFromLatLonInKm(originCoords, markerCoords);
        if (distanceFromOrigin <= circleRadius) {
          marker.addTo(map.current);
        }
      });
    }
  }, [circleRadius, markers]);

  return (
    <div className="mapbox-map-container">
      <div ref={mapContainer} className="mapbox-map" />
      {
        markers.length ? (
          <div className="map-overlay">
            <p className="text-secondary">
              {i18n.t(
                'LocationMap.inPerimeter',
                'Dans un rayon de: {{radius}}km',
                { radius: circleRadius },
              )}
            </p>
            <label htmlFor="circle-area" className="form-label">
              <input
                className="form-range"
                type="range"
                id="circle-area"
                min={0}
                max={200}
                value={circleRadius}
                onChange={(e) => setCircleRadius(parseInt(e.target.value, 10))}
              />
            </label>
          </div>
        ) : null
      }
    </div>
  );
};

LocationMap.defaultProps = {
  circleRadiusKm: 0,
  places: [],
};

export default LocationMap;
